const PUBLIC_ROUTES = [
  "/login",
  "/activate",
  "/change/password",
  "/forgot/password",
  "/onboarding",
  "/help",
  "/report/bug",
  "/approve-quote",
  "/approve-proposal",
];

export default PUBLIC_ROUTES;
