import { lazy, Suspense } from "react";
import {
  FolderPlusIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Button, Spinner, Popover } from "../../../../../shared";
import { renderAmount } from "../../../../../utils";
import { isAuthorized } from "../../../../../utils/authority";
import { ItemsTable } from "../../../Services/ItemsTable";
import { getJobMode, useJob } from "../../../../../hooks";
const EmptyTableState = lazy(() => import("./EmptyTableState"));

export const ServiceInfo = ({
  setModalVisible,
  modalVisible,
  job,
  serviceForm,
  editing,
  isJobActive,
  isPending,
  isNew,
  loading,
}) => {
  const {
    getItemsByType,
    mode: jobMode,
    isActive,
    isCompleted,
    isInvoiced,
    isQuoteMode,
  } = useJob();

  function handleCreate() {
    serviceForm.setFormInit({
      job,
    });
    setModalVisible(true);
  }

  const empty = (
    <div className="py-4 bg-white">
      <div className="text-center">
        <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
        <p className="text-sm text-gray-500">Get started by adding a service</p>
        <div className="mt-3">
          <Button
            onClick={handleCreate}
            size="xs"
            icon={<PlusCircleIcon className={`w-5 h-5 mr-2`} />}
          >
            Add Service
          </Button>
        </div>
      </div>
    </div>
  );

  // const wrapClasses = classNames(
  //   "flex flex-row justify-evenly items-center whitespace-nowrap"
  // );

  // const isHeaderActive = !isJobActive;

  function renderButtonText() {
    return `Add ${getJobMode(job.status)}`;
  }

  function renderButton(size = "sm") {
    return (
      isAuthorized("owner,admin") &&
      job.items?.length > 0 &&
      (editing || isNew || isPending) && (
        <div className="flex">
          <Button
            className="flex items-center"
            onClick={handleCreate}
            size={size}
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            {renderButtonText()}
          </Button>
        </div>
      )
    );
  }

  const items =
    job?.items &&
    job.items?.map((item) => {
      const data = {
        ...item,
        status: item.status,
        jobId: job._id,
      };
      return {
        ...data,
        subItem: [data],
      };
    });

  const quotes = getItemsByType("quote");
  const recommendations = getItemsByType("recommendation");
  const services = getItemsByType("service");

  const createdDate = new Date(job?.dateCreated);
  const cutoffDate = new Date("2025-03-11");
  const itemData =
    createdDate < cutoffDate
      ? [...quotes, ...services]
      : jobMode === "Quote"
      ? quotes
      : services;

  return (
    <div>
      <ItemsTable
        items={itemData?.length === 0 ? job?.items : itemData}
        setVisible={setModalVisible}
        visible={modalVisible}
        isQuoteMode={isQuoteMode}
        renderHeader={() => {
          return (
            <Header
              renderButton={renderButton}
              active={isJobActive}
              job={job}
              count={itemData.length}
              title={jobMode + "s"}
            />
          );
        }}
        // hideHeader={!isHeaderActive}
        job={job}
        form={serviceForm}
        loading={loading}
        editing={isAuthorized("owner,admin") ? editing : false}
        isJobActive={isJobActive}
        isPending={isPending}
        isNew={isNew}
        thClasses="rounded-t"
        emptyState={
          <Suspense fallback={<Spinner />}>
            <EmptyTableState
              form={serviceForm}
              job={job}
              setModalVisible={setModalVisible}
            />
          </Suspense>
        }
      />
      <div className="mt-2">{renderButton()}</div>
      {(isActive || isCompleted || isInvoiced) && (
        <ItemsTable
          items={recommendations}
          setVisible={setModalVisible}
          visible={modalVisible}
          renderHeader={() => {
            return (
              <Header
                renderButton={renderButton}
                active={isJobActive}
                job={job}
                count={recommendations?.length}
                title="Recommendations"
              />
            );
          }}
          // hideHeader={!isHeaderActive}
          job={job}
          form={serviceForm}
          loading={loading}
          editing={isAuthorized("owner,admin") ? editing : false}
          isJobActive={isJobActive}
          isPending={isPending}
          isNew={isNew}
          thClasses="rounded-t"
          emptyState={
            <Suspense fallback={<Spinner />}>
              <EmptyTableState
                form={serviceForm}
                job={job}
                setModalVisible={setModalVisible}
              />
            </Suspense>
          }
        />
      )}
    </div>
  );
};

export const Header = ({ job, active, renderButton, title, count }) => {
  return (
    <div className="flex items-center justify-between bg-white border-t rounded-t-lg text-xs font-medium p-3 text-left text-gray-500 uppercase border-b border-gray-200">
      <div className="flex items-center">
        {title}
        {!active && count > 0 && ` (${count})`}
      </div>
      <div className="flex items-center">{renderButton("xs")}</div>
    </div>
  );
};
