import { api } from "../../api";

const timelineApi = api.injectEndpoints({
  tagTypes: ["Timeline"],
  endpoints: (builder) => ({
    getEntityTimeline: builder.query({
      query: (entityId) => `/timeline/entity/${entityId}`,
      providesTags: (result, error, entityId) => [
        { type: "Timeline", id: entityId },
        { type: "Timeline", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetEntityTimelineQuery } = timelineApi;
