export const getDocumentFormData = async (assets, data) => {
  if (!assets) return null;

  const formData = new FormData();

  // Add metadata first
  formData.append("refId", data.refId);
  formData.append("refType", data.refType);
  formData.append("type", data.type);
  formData.append("uploadPath", data.uploadPath);

  // Then add files
  assets.forEach((asset, index) => {
    const extensionFromName = asset.name.split(".").pop() || "";
    const extensionFromMimeType = asset.mimeType
      ? asset.mimeType.split("/").pop()
      : "";
    const extension = extensionFromName || extensionFromMimeType || "";
    const fileName = `${data.type}-${data.refType}-${data.refId}-${
      index + 1
    }.${extension}`;

    // If asset is a File object, use it directly
    if (asset instanceof File) {
      formData.append("docs", asset);
    } else {
      // For compatibility with existing code that might pass uri
      formData.append(
        "docs",
        asset.file || {
          uri: asset.uri,
          type: asset.mimeType || `image/${extension}`,
          name: data.name || fileName,
        }
      );
    }
  });

  return formData;
};
