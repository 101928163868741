import { FC, useEffect, useMemo } from "react";
import { format } from "date-fns";
import classNames from "classnames";

interface TimelineItem {
  description: string;
  timestamp: string;
  type?: "message" | "note" | "status" | "update" | "approval";
  sender?: {
    name: string;
    avatar?: string;
  };
  isOutbound?: boolean;
  metadata?: Record<string, unknown>;
  isFullWidth?: boolean;
}

interface CommunicationsTimelineProps {
  items?: TimelineItem[];
  loading?: boolean;
}

const Message: FC<TimelineItem> = ({
  description,
  timestamp,
  type,
  metadata,
  sender,
}) => {
  return (
    <div className="flex flex-col items-center my-3 w-full">
      <div className="bg-gray-50 rounded-lg px-4 py-2 max-w-[100%] w-full">
        <div className="text-xs text-gray-400 text-center mt-1">
          {format(new Date(timestamp), "h:mm a")}
        </div>
        <div className="flex items-center justify-center gap-2">
          {type === "status" && (
            <div className="w-2 h-2 rounded-full bg-green-500" />
          )}
          {type === "update" && (
            <div className="w-2 h-2 rounded-full bg-blue-500" />
          )}
          {type === "note" && (
            <div className="w-2 h-2 rounded-full bg-yellow-500" />
          )}
          {type === "approval" && metadata?.status?.content === "approved" && (
            <div className="w-2 h-2 rounded-full bg-green-500" />
          )}
          {type === "approval" && metadata?.status?.content === "rejected" && (
            <div className="w-2 h-2 rounded-full bg-red-500" />
          )}

          <span className="text-sm text-gray-600 text-center">
            {description}
          </span>
        </div>
        {metadata && (
          <div className="w-full">
            {Object.entries(metadata)
              .filter(([key, value]) => !value.hidden)
              .map(([key, value]) => {
                const metadataValue = value;
                return (
                  metadataValue.content &&
                  (metadataValue?.type === "message" ? (
                    <MessageBubble
                      description={metadataValue.content}
                      timestamp={null}
                      type={metadataValue.type}
                      isOutbound={metadataValue.isOutbound}
                      sender={sender}
                    />
                  ) : (
                    <div key={key} className="text-xs text-gray-500">
                      {key}: {value}
                    </div>
                  ))
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const MessageBubble: FC<TimelineItem> = ({
  description,
  timestamp,
  isOutbound,
  sender,
  isFullWidth,
}) => {
  const bubbleClass = classNames("rounded-[20px] px-4 py-2 mb-2 shadow-sm", {
    "max-w-[90%]": !isFullWidth,
    "w-full": isFullWidth,
    "bg-blue-500 text-white ml-auto rounded-tr-sm": isOutbound,
    "bg-gray-100 text-gray-900 rounded-tl-sm": !isOutbound,
  });

  const timeClass = classNames("text-[10px] mt-1", {
    "text-gray-400 text-right": isOutbound,
    "text-gray-400": !isOutbound,
  });

  return (
    <div
      className={`flex flex-col ${isOutbound ? "items-end" : "items-start"}`}
    >
      {sender?.name && (
        <span className="text-xs text-gray-500 mb-1">
          {sender.name || "Name"}
        </span>
      )}
      <div className={bubbleClass}>
        <div>{description}</div>
      </div>
      {timestamp && (
        <div className={timeClass}>{format(new Date(timestamp), "h:mm a")}</div>
      )}
    </div>
  );
};

const DateSeparator: FC<{ date: string }> = ({ date }) => (
  <div className="flex justify-center my-4">
    <div className="bg-gray-200 rounded-full px-4 py-1 text-xs text-gray-600">
      {format(new Date(date), "MMMM d, yyyy")}
    </div>
  </div>
);

const CommunicationsTimeline: FC<CommunicationsTimelineProps> = ({
  items = [],
  loading = false,
}) => {
  const validItems = useMemo(() => {
    return items.filter((item) => {
      const timestamp = new Date(item.timestamp);
      return item.timestamp && !isNaN(timestamp.getTime());
    });
  }, [items, items?.length]);

  // Show loading state if explicitly loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-64 text-gray-500">
        <div className="animate-pulse">Loading timeline...</div>
      </div>
    );
  }

  // Show empty state only if we have no valid items and aren't loading
  if (!validItems.length) {
    return (
      <div className="flex items-center justify-center h-64 text-gray-500">
        No messages yet
      </div>
    );
  }

  let currentDate = "";

  return (
    <div className="p-4 space-y-2">
      {validItems.map((item, index) => {
        try {
          const messageDate = format(new Date(item.timestamp), "yyyy-MM-dd");
          const showDateSeparator = messageDate !== currentDate;
          currentDate = messageDate;

          return (
            <div key={index}>
              {showDateSeparator && <DateSeparator date={item.timestamp} />}
              {item.type === "message" ? (
                <MessageBubble {...item} />
              ) : (
                <Message {...item} />
              )}
            </div>
          );
        } catch (error) {
          console.error("Error rendering timeline item:", error, item);
          return null;
        }
      })}
    </div>
  );
};

export default CommunicationsTimeline;
