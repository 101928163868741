import React from "react";
import { Card, DateRangePicker, Select, Table } from "../../../shared";
import { SettingsHeader } from "..";
import { AreaChart } from "../../../shared/Charts";
import { taxLiabilityReport } from "../../../constants/aggregate";
import request from "../../../utils/request";
import { monthOptions } from "../../../constants/select";
import { data } from "react-router-dom";
import { useConfig } from "../../../hooks";
import { startOfDay, endOfDay } from "date-fns";
import { formatDate } from "@/utils";
import { jsPDF } from "jspdf";
import { Button } from "../../../shared";
import "jspdf-autotable";

/**
 * Tax Liability Reports component for managing and viewing tax-related reports
 * @returns {React.Component} Tax liability reporting interface
 */
const Reports = () => {
  const [dateRange, setDateRange] = React.useState(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    return {
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    };
  });
  const { parentCompany, appType } = useConfig();

  const begginingDate = new Date(startOfDay(dateRange.startDate));
  const endingDate = new Date(endOfDay(dateRange.endDate));

  const [viewType, setViewType] = React.useState("table");
  const [taxLiabilityData, setTaxLiabilityData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  // Add handler for month selection
  const handleMonthChange = (value) => {
    // Since value is already an object with year and month, we can use it directly
    const [year, month] = value.value.split("-");
    const firstDay = new Date(parseInt(year), parseInt(month), 1);
    const lastDay = new Date(parseInt(year), parseInt(month) + 1, 0);
    setDateRange({
      startDate: firstDay,
      endDate: lastDay,
    });
  };

  /**
   * Fetches tax liability report data based on provided parameters
   * @param {Object} data - Report parameters including date range and filters
   */
  const fetchTaxReport = async (data) => {
    setIsLoading(true);
    try {
      const taxStage = taxLiabilityReport(
        "appointmentDate",
        begginingDate.toString(),
        endingDate.toString(),
        parentCompany,
        appType
      );
      const taxResponse = await request(`/reports`, {
        method: "post",
        data: {
          stages: taxStage,
          modelName: "Job",
          config: {
            0: {
              type: "$match",
              useDate: "appointmentDate",
              // objectId: "parentCompany",
            },
          },
        },
      });
      setTaxLiabilityData(taxResponse.data);
    } catch (error) {
      console.error("Error fetching tax report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data when date range changes
  React.useEffect(() => {
    fetchTaxReport({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      parentCompany: data.parentCompany,
    });
  }, [dateRange]);

  // Format table columns for tax liability data
  const taxLiabilityColumns = [
    {
      header: "Dates",
      accessor: "dateRange",
      cell: ({ row, getValue }) => {
        return (
          <div>
            {`${formatDate(dateRange.startDate)} - ${formatDate(
              dateRange.endDate
            )}`}
          </div>
        );
      },
    },
    {
      header: "Gross Income",
      accessor: "gross",
      type: "currency",
      cell: ({ row, getValue }) => {
        const value = row.original.gross;
        return value;
      },
    },
    {
      header: "Non Taxable",
      accessor: "nonTaxable",
      type: "currency",
      cell: ({ row, getValue }) => {
        const value = row.original.nonTaxable;
        return value;
      },
    },
    {
      header: "Taxable",
      accessor: "taxable",
      type: "currency",
      cell: ({ row, getValue }) => {
        const value = row.original.taxable;
        return value;
      },
    },
    {
      header: "Tax Amount",
      accessor: "tax",
      type: "currency",
      cell: ({ row, getValue }) => {
        const value = row.original.tax;
        return value;
      },
    },
  ];

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(16);
    doc.text("Tax Liability Report", 14, 15);
    // doc.text(`${parentCompany.name} Tax Report`, 14, 25);
    doc.setFontSize(12);

    // Format data for the table
    const tableData = taxLiabilityData.map((row) => [
      row.dateRange,
      row.gross.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }),
      row.nonTaxable.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }),
      row.taxable.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }),
      row.tax.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }),
    ]);

    // Add table to PDF
    doc.autoTable({
      head: [
        ["Date Range", "Gross Income", "Non Taxable", "Taxable", "Tax Amount"],
      ],
      body: tableData,
      startY: 25,
      theme: "grid",
      styles: {
        fontSize: 8,
        cellPadding: 2,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [45, 150, 130],
        textColor: 255,
        fontSize: 8,
        fontStyle: "bold",
      },
    });

    // Save PDF
    doc.save(
      "Tax Liability Report - " +
        formatDate(dateRange.startDate, false, "MM/dd/yyyy") +
        " - " +
        formatDate(dateRange.endDate, false, "MM/dd/yyyy") +
        ".pdf"
    );
  };

  return (
    <div className="space-y-4">
      <SettingsHeader title="Tax Liability Report" />

      <div className="space-y-4">
        <div className="flex items-center justify-between mb-4">
          <div>
            {/* <Select
                value={monthOptions.find(
                  (option) =>
                    option.value ===
                    `${dateRange.startDate.getFullYear()}-${dateRange.startDate.getMonth()}`
                )}
                onChange={handleMonthChange}
                options={monthOptions}
                label="Select Month"
              /> */}
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChange={([start, end]) =>
                setDateRange({
                  startDate: start,
                  endDate: end,
                })
              }
            />
            {/* <Select
                value={viewType}
                onChange={(value) => setViewType(value)}
                options={[
                  // { value: "chart", label: "Chart View" },
                  { value: "table", label: "Table View" },
                ]}
                label="View Type"
              /> */}
          </div>

          <Button
            onClick={handleDownloadPDF}
            className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700 flex items-center mr-2"
          >
            <span className="mr-2">Download PDF</span>
            {/* You can add an icon here if desired */}
          </Button>
        </div>

        <div className="h-[400px]">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              Loading tax report data...
            </div>
          ) : (
            <>
              {/* {viewType === "chart" && (
                  <AreaChart height={350} data={taxLiabilityData} />
                )} */}
              {viewType === "table" && (
                <div>
                  <Table
                    data={taxLiabilityData}
                    columns={taxLiabilityColumns}
                    pagination
                    defaultPageSize={10}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
