import { getTableConfig } from "./table.configs";
import { useAuth } from "../../../hooks/useAuth";
import TableLayout from "../../../layouts/TableLayout";
import { useUpdatePayoutLegMutation } from "../../../redux/services/payouts/payoutsApi";
import { toast } from "react-toastify";
import { useConfig } from "../../../hooks";
const PayoutLegs = ({ data, loading }) => {
  const { user } = useAuth();

  const [updatePayoutLeg, { isLoading }] = useUpdatePayoutLegMutation();
  const { appType } = useConfig();

  const tableData = appType === "trucking" ? data.legs : data.shifts;

  const mappedData = tableData?.map((item) => {
    return {
      ...item,
    };
  });

  const { COLUMN_DEFINITIONS } = getTableConfig(appType);

  async function handleUpdate(e, setLoading) {
    let useCustomPayoutRate = false;
    if (e.column.id === "payoutRate") {
      useCustomPayoutRate = true;
    }

    let useCustomPayoutSupplemental = false;
    if (e.column.id === "payoutSupplemental") {
      useCustomPayoutSupplemental = true;
    }

    const updateData = {
      parent: user.parentCompany,
      legId: e.row.original._id,
      id: data._id,
      data: {
        ...e.data,
        useCustomPayoutRate: useCustomPayoutRate,
        useCustomPayoutSupplemental: useCustomPayoutSupplemental,
      },
    };

    const res = await updatePayoutLeg(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-leg-" + res.data._id,
    });

    if (res.status === "success") {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  if (!mappedData) return "Loading...";
  return (
    <div>
      <TableLayout
        loading={loading}
        hideActions
        data={mappedData}
        columns={COLUMN_DEFINITIONS}
        hideCheckbox
        onUpdate={handleUpdate}
        useTableSizes
        meta={{
          updateData: handleUpdate,
        }}
        // turnOffStyles
      />
    </div>
  );
};

export default PayoutLegs;
