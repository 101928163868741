import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { deleteLocation } from "../../redux/reducers/locations/locationSlice";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { toast } from "react-toastify";
import { renderCustomColumnDefs } from "../../shared/Table/table.utils";
import LocationCreateModal from "./LocationCreateModal";

import { useGetLocationsQuery } from "../../redux/services/locations/locationsApi";
import { useFetchAll } from "../../hooks/useFetchAll";

const ClientLocationsIndex = () => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [createModalVisible, setCreateModalVisible] = useState(false);

  // Memoize the default filters
  const defaultFilters = useMemo(() => {
    return params.id ? [{ id: "clientId", value: params.id }] : [];
  }, [params.id]);

  // Memoize the options
  const queryOptions = useMemo(
    () => ({
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      staleTime: 300000,
    }),
    []
  );

  const {
    pagination: { pageIndex, pageSize, setPagination },
    search: { handleGlobalSearch, value: searchValue },
    queryResult: { data, isLoading, isFetching, error },
    filters: { setFilters },
  } = useFetchAll({
    queryHook: useGetLocationsQuery,
    prefetch: false,
    defaultPageSize: 20,
    defaultFilters,
    options: {
      ...queryOptions,
      sortBy: "name",
      sortOrder: "asc",
    },
  });

  // Memoize table data
  const newTableData = useMemo(
    () => [
      {
        id: "name",
        required: true,
        cell: ({ row, getValue }) => {
          return (
            <div
              className="text-primary-500 hover:underline cursor-pointer"
              onClick={() => handleEdit(row.original)}
            >
              {getValue()}
            </div>
          );
        },
      },
      ...COLUMN_DEFINITIONS,
    ],
    []
  );

  const NEW_COLUMN_DEFINITIONS = useMemo(
    () => renderCustomColumnDefs(newTableData),
    [newTableData]
  );

  // Memoize handlers
  const handleEdit = useCallback((item) => {
    handleEditClick(item);
  }, []);

  const globalFilter = useCallback(
    (value) => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: prev.pageSize,
      }));
      handleGlobalSearch(value);
    },
    [setPagination, handleGlobalSearch]
  );

  async function handleEditClick(location, i, type) {
    showCreateEditModal();
    setEdit(true);
    setSelected({
      ...location,
      ...location.address,
      addressId: location.address._id,
      open: location.receivingHours?.open,
      close: location.receivingHours?.close,
      name: location.name,
      _id: location._id,
      edit: true,
    });
    // setFormInit({
    //   ...location,
    //   ...location.address.address,
    //   addressId: location.address._id,
    //   open: location.receivingHours?.open,
    //   close: location.receivingHours?.close,
    //   _id: location._id,
    // });
  }

  async function handleDelete(items) {
    const { parentCompany } = user;

    const res = await dispatch(
      deleteLocations({
        data: items.map((l) => l._id),
        parent: parentCompany,
        clientId: params.id,
      })
    ).unwrap();

    if (res.status === "success") {
      toast("Locations Deleted", {
        type: "success",
        toastId: "locationsDeleted",
      });
    }

    return res;
  }

  function showCreateEditModal() {
    setCreateModalVisible(true);
  }

  function closeCreateModal() {
    setSelected({});
    setCreateModalVisible(false);
  }

  if (error) {
    return (
      <div className="text-red-500">
        Error loading locations: {error.message}
      </div>
    );
  }

  return (
    <div className="h-full">
      <TableLayout
        mainAction={{ onClick: showCreateEditModal }}
        id="locations"
        loading={isLoading || isFetching}
        columns={NEW_COLUMN_DEFINITIONS}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        data={data?.locations || []}
        manualPagination={true}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        fullTextSearch={globalFilter}
        fullSelectedItem
      />
      <LocationCreateModal
        visible={createModalVisible}
        setVisible={closeCreateModal}
        editData={selected}
        clientId={params.id}
      />
    </div>
  );
};

export default React.memo(ClientLocationsIndex);
