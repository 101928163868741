import React from "react";
import { useForm } from "../../../../hooks/useForm";
import {
  Card,
  Button,
  DateRangePicker,
  Checkbox,
  Select,
  Badge,
  MultiSelect,
} from "../../../../shared";
import {
  // useGetDriverReportsQuery,
  useGenerateDriverReportMutation,
  // useGetDriverReportQuery,
} from "../../../../redux/services/reports/reportsApi";
import request from "../../../../utils/request";
import { payoutReportStage } from "../../../../constants/aggregate";

/**
 * DriverReports component displays and manages driver-specific reports
 * @param {Object} props - Component props
 * @param {Object} props.driver - Driver object containing driver information
 * @param {string} props.driverStatus - Current status of driver data loading
 */
const DriverReports = ({ driver, driverStatus }) => {
  const { form, updateForm, isValid } = useForm({
    initialValues: {
      selectedYear: null,
      selectedMonths: [],
      reportType: null,
    },
    validations: {
      selectedYear: { required: true },
      reportType: { required: true },
    },
  });

  // const [generateReport, { data: reports, isLoading }] =
  //   useGenerateDriverReportMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;

    // Get the actual year value from the form
    const selectedYear = form.selectedYear?.value || form.selectedYear;

    if (!selectedYear) {
      console.error("No year selected");
      return;
    }

    let startDate, endDate;

    if (form.reportType?.type === "monthly") {
      const months = Array.isArray(form.selectedMonths)
        ? form.selectedMonths.map((month) => month.value)
        : [];

      if (months.length > 0) {
        const sortedMonths = [...months].sort((a, b) => a - b);
        startDate = new Date(selectedYear, sortedMonths[0], 1);
        endDate = new Date(
          selectedYear,
          sortedMonths[sortedMonths.length - 1] + 1,
          0
        );
      }
    } else if (form.reportType?.type === "yearly") {
      startDate = new Date(selectedYear, 0, 1);
      endDate = new Date(selectedYear, 11, 31, 23, 59, 59, 999);
    }

    // Validate dates before proceeding
    if (
      !startDate ||
      !endDate ||
      isNaN(startDate.getTime()) ||
      isNaN(endDate.getTime())
    ) {
      console.error("Invalid date range", { startDate, endDate, form });
      return;
    }

    // Format dates for the API
    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();

    console.log("Formatted dates:", { formattedStartDate, formattedEndDate });

    const reportConfig = {
      stages: payoutReportStage(
        formattedStartDate,
        formattedEndDate,
        driver.parentCompany,
        driver._id,
        "driver"
      ),
      modelName: "Payout",
      config: {
        0: {
          type: "$match",
          useDate: "dateCreated",
          objectId: ["parentCompany", "driver"],
        },
      },
    };

    // Add this before the request
    console.log(
      "Sending report config:",
      JSON.stringify(reportConfig, null, 2)
    );

    try {
      const response = await request("/reports", {
        method: "post",
        data: reportConfig,
      });
      console.log("Report generated:", response);
    } catch (error) {
      console.error("Failed to generate report:", {
        error,
        requestData: reportConfig,
        dates: { formattedStartDate, formattedEndDate },
        driver: { parentCompany: driver.parentCompany, id: driver._id },
      });
    }
  };

  const reportTypeOptions = [
    { value: "monthlyPayout", label: "Monthly Payout Report", type: "monthly" },
    { value: "yearlyPayout", label: "Yearly Payout Report", type: "yearly" },
    // { value: "monthlyPerformance", label: "Monthly Performance Report" },
    // { value: "yearlyPerformance", label: "Yearly Performance Report" },
    // { value: "legStatus", label: "Leg Status Analysis" },
    // { value: "payoutDetail", label: "Detailed Payout Report" },
    // { value: "performance", label: "Performance Report" },
  ];

  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year, label: year.toString() };
  });

  console.log(form, "form");

  const monthOptions = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const metricOptions = [
    { value: "mileage", label: "Total Mileage" },
    { value: "earnings", label: "Earnings" },
    { value: "jobs", label: "Job Completion" },
    { value: "waitTime", label: "Wait Time" },
    { value: "performance", label: "Performance Metrics" },
  ];

  const renderDateSelection = () => {
    if (form.reportType?.type === "yearly") {
      return (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Year
          </label>
          <Select
            id="selectedYear"
            value={yearOptions.find(
              (opt) => opt.value === form.selectedYear?.value
            )}
            onChange={(selected) =>
              updateForm({
                id: "selectedYear",
                value: selected.value,
              })
            }
            options={yearOptions}
          />
        </div>
      );
    }

    if (form.reportType?.type === "monthly") {
      return (
        <div className="space-y-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Year
            </label>
            <Select
              id="selectedYear"
              value={yearOptions.find(
                (opt) => opt.value === form.selectedYear?.value
              )}
              onChange={(selected) =>
                updateForm({
                  id: "selectedYear",
                  value: selected.value,
                })
              }
              options={yearOptions}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Months
            </label>
            <MultiSelect
              id="selectedMonths"
              value={form.selectedMonths}
              onChange={(selected) => {
                updateForm({
                  id: "selectedMonths",
                  value: selected.value,
                });
              }}
              options={monthOptions}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  // Add this effect to debug form values
  React.useEffect(() => {
    console.log("Current form state:", {
      selectedYear: form.selectedYear,
      reportType: form.reportType,
      selectedMonths: form.selectedMonths,
    });
  }, [form]);

  // Add this at the top of your component
  React.useEffect(() => {
    console.log("Driver props:", {
      parentCompany: driver?.parentCompany,
      _id: driver?._id,
      driver,
    });
  }, [driver]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Report Creation Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Create New Report</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Report Type
            </label>
            <Select
              id="reportType"
              value={form.reportType?.value}
              onChange={(selected) => {
                updateForm({
                  id: "reportType",
                  value: selected,
                  selectedMonths: [], // Reset months when changing report type
                });
              }}
              options={reportTypeOptions}
            />
          </div>

          {renderDateSelection()}

          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Include Metrics
            </label>
            <div className="grid grid-cols-2 gap-2">
              {metricOptions.map((metric) => (
                <Checkbox
                  key={metric.value}
                  id="selectedMetrics"
                  label={metric.label}
                  value={metric.value}
                  checked={form.selectedMetrics?.includes(metric.value)}
                  onChange={(e) => {
                    const currentMetrics = form.selectedMetrics || [];
                    const newMetrics = e.target.checked
                      ? [...currentMetrics, metric.value]
                      : currentMetrics.filter((m) => m !== metric.value);
                    updateForm({ id: "selectedMetrics", value: newMetrics });
                  }}
                />
              ))}
            </div>
          </div> */}

          <Button type="submit" disabled={!isValid} onClick={handleSubmit}>
            Generate Report
          </Button>
        </form>
      </Card>

      {/* Reports List Card */}
      {/* <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Previous Reports</h3> */}
      {/* {isLoading ? (
          <div>Loading reports...</div>
        ) : ( */}
      {/* <div className="space-y-2">
          {reports?.map((report) => (
            <div
              key={report._id}
              className="p-3 border rounded hover:bg-gray-50"
            >
              <div className="flex justify-between items-center">
                <div className="space-y-1">
                  <div className="flex items-center space-x-2">
                    <span>
                      {new Date(report.createdAt).toLocaleDateString()}
                    </span>
                    <Badge label={report.reportType} />
                  </div>
                  <div className="text-sm text-gray-500">
                    Metrics: {report.metrics.join(", ")}
                  </div>
                </div>
                <Button size="sm" variant="outline">
                  Download
                </Button>
              </div>
            </div>
          ))}
        </div> */}
      {/* )} */}
      {/* </Card> */}
    </div>
  );
};

export default DriverReports;
