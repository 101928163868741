import React, { useEffect, useRef, useState } from "react";
import Charts, { AreaChart, PieChart } from "../../../shared/Charts";

const ClientJobChart = (props) => {
  return (
    <>
      <div className="mb-3 text-xs md:text-sm uppercase text-gray-500 font-bold">
        Jobs
      </div>
      <AreaChart
        height={350}
        data={props.data}
        appType={props.appType}
        dataType="jobs"
      />
    </>
  );
};

export default ClientJobChart;
