import React, { useState, useEffect } from "react";
import PayoutTable from "./Table";
import { parseFixed, renderAmount } from "../../utils";
import PayoutPdf from "../../shared/PdfGenerator/payoutPdf";
import { formatDate } from "../../utils";

const PayoutLayout = ({
  payout,
  children,
  trigger,
  onComplete,
  appType,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const parentCompany = payout.parentCompany;
  const payoutId = payout.payoutId;

  useEffect(() => {
    if (props.download) {
      const timeoutId = setTimeout(() => {
        onComplete?.();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [props.download, onComplete]);

  if (loading) {
    return "Loading";
  }

  function didParseCell({ cell, section, row, ...rest }) {
    cell.styles.fontSize = 7;
    if (section === "body") {
      cell.styles.fillColor = "white"; //[245, 245, 245];
    }
  }

  function didDrawPage({ pageCount, pageNumber, settings, doc, ...rest }) {
    var totalPagesExp = "{total_pages_count_string}";
    // FOOTER
    var str = "Page " + pageCount;
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp;
    }
    doc.setFontSize(10);
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    doc.text(str, settings.margin.left, pageHeight - 5);
  }

  function createHeader(pdf) {
    // Common header elements
    pdf.setFontSize(9);
    pdf.setFont("Times New Roman");
    pdf.setTextColor(100, 100, 100);
    pdf.text("Payout ID", 105, 5, "center");

    pdf.setFontSize(11);
    pdf.setTextColor("black");
    pdf.text(`${payout.payoutId}`, 105, 9, "center");

    // Company NAME
    pdf.setFontSize(20);
    pdf.setFont(undefined, "bold");
    pdf.text(parentCompany.name, 5, 20);

    // COMPANY ADDRESS
    // const address = `${parentCompany?.address}`;
    // const city = `${parentCompany?.city}`;

    // const customerAddress = `${customer?.address.city},${customer?.address.state} ${customer?.address.zipcode}`;

    // pdf.setFontSize(9);
    // pdf.setFont(undefined, "normal");
    // pdf.text(address, 5, 25);
    // pdf.text(city, 5, 29);

    // PAYOUT INFO
    pdf.setFontSize(9);
    pdf.setFont("Times New Roman");
    pdf.setTextColor(100, 100, 100);

    pdf.text("Paid To: ", 5, 30);
    pdf.setTextColor("black");

    if (appType === "trucking") {
      // Trucking specific header
      pdf.text(
        `${
          payout.organization
            ? payout.organization.name
            : payout.driver?.fullName
        }   ${formatDate(payout.payDate, null, true)} `,
        5,
        35
      );
      pdf.text(
        `Job dates ${formatDate(
          payout.startDate,
          null,
          true
        )} through ${formatDate(payout.endDate, null, true)} `,
        5,
        40
      );
      pdf.text(`Total Drops: ${payout.legs.length}`, 125, 35);

      pdf.text(
        `Standby Rate: ${renderAmount(payout.hourlyRate)}      Standby Hours: ${
          payout.hours
        }`,
        55,
        50
      );
      pdf.text(
        `Total Miles: ${payout.totalMileage.toLocaleString("en-US")}`,
        87,
        45
      );
      pdf.text(`Mileage Total: ${renderAmount(payout.mileageTotal)}`, 125, 45);
      pdf.text(
        `Supplemental Total: ${renderAmount(payout.supplementalTotal)}`,
        125,
        40
      );
      pdf.text(`Total Gross: ${renderAmount(payout.total)}`, 125, 50);
    } else if (appType === "serviceRepair") {
      // Service Repair specific header
      const employeeName = payout.employee?.user?.fullName || "";
      pdf.text(
        `${employeeName}   ${formatDate(payout.payDate, null, true)}`,
        5,
        35
      );
      pdf.text(
        `Pay Period: ${formatDate(payout.startDate, null, true)} - ${formatDate(
          payout.endDate,
          null,
          true
        )}`,
        5,
        40
      );

      // Service repair specific totals
      pdf.text(
        `Regular Rate: ${renderAmount(
          payout.hourlyRate
        )}    Hours: ${parseFixed(payout.hours)}`,
        55,
        45
      );
      pdf.text(`Regular Total: ${renderAmount(payout.hourlyTotal)}`, 125, 45);

      if (payout.overtimeRate) {
        pdf.text(
          `Overtime Rate: ${renderAmount(payout.overtimeRate)}    Hours: ${
            payout.overtimeHours || 0
          }`,
          55,
          50
        );
        // pdf.text(
        //   `Overtime Total: ${renderAmount(
        //     (payout.overtimeHours || 0) * payout.overtimeRate
        //   )}`,
        //   125,
        //   50
        // );
      }

      pdf.text(
        `Supplemental Total: ${renderAmount(payout.supplementalTotal)}`,
        125,
        50
      );
      pdf.text(`Total Gross: ${renderAmount(payout.total)}`, 125, 55);
    }
  }

  function willDrawCell({ cell, ...props }) {
    cell.styles.fontSize = 7;
  }

  const tableData = appType === "trucking" ? payout.legs : payout.shifts;

  const layout = (
    <>
      <PayoutTable {...props} tableData={tableData} />
    </>
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  const pdfFileName = `${payoutId.replace(/\./g, " ")}  ${formatDate(
    new Date(),
    null,
    true
  )}.pdf`;
  const pageData = "|  " + parentCompany.name + "  |  Payout  " + payoutId;

  return (
    <PayoutPdf
      startY={65}
      didDrawPage={didDrawPage}
      pageData={pageData}
      didParseCell={didParseCell}
      createHeader={createHeader}
      willDrawCell={willDrawCell}
      layout={layout}
      fileName={pdfFileName}
      id="payout-table"
      onComplete={() => {
        if (props.download) {
          onComplete?.();
        }
      }}
    >
      <Layout download={props.download}>{childrenWithProps}</Layout>
    </PayoutPdf>
  );
};

const Layout = (props) => {
  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  return childrenWithProps;
};

export default PayoutLayout;
