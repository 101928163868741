import { useNavigate } from "react-router-dom";
import Tabs from "../../../../shared/Tabs";
// import History from "./History";
// import All from "./All";
import Notes from "./Notes";
import { useEffect, useMemo, useRef, useState } from "react";
import { isAuthorized } from "../../../../utils/authority";
import { useJob } from "../../../../hooks";
import { useGetEntityTimelineQuery } from "../../../../redux/services/timeline/api";
import CommunicationsTimeline from "@/components/CommunicationsTimeline";

const SidebarContent = ({ form, updateForm, onFormChange, ...props }) => {
  const navigate = useNavigate();
  const { data: job } = useJob();
  const {
    data: timeline,
    isLoading: timelineLoading,
    isFetching,
  } = useGetEntityTimelineQuery(job?._id);

  const timelineItems = useMemo(() => {
    if (!job) return [];

    // Always show job creation
    const baseTimeline = [
      {
        id: "job-created",
        timestamp: new Date(job.dateCreated).toISOString(),
        sender: { name: "System" },
        title: "Job Created",
        description: "Job was created",
        type: "update",
      },
    ];

    // Add timeline items if available
    if (!timeline?.length) return baseTimeline;

    const additionalItems = timeline.map((item) => {
      const baseItem = {
        id: item._id,
        timestamp: new Date(item.timestamp).toISOString(),
        sender: {
          name: item.createdBy?.name || "System",
        },
      };

      switch (item.type) {
        case "quoteSent":
          return {
            ...baseItem,
            title: "Quote Sent",
            description: `Quote sent to ${item.data.recipients.join(", ")}`,
            type: "quote",
          };
        case "proposalSent":
          return {
            ...baseItem,
            title: "Proposal Sent",
            description: `${item.data.subject} to ${item.data.recipients.join(
              ", "
            )}`,
            type: "proposal",
          };
        case "jobStatus":
          return {
            ...baseItem,
            title: "Status Update",
            description:
              item.data.description ||
              `${item.data.reason} to ${item.data.new}`,
            type: "status",
          };
        case "approval":
          return {
            ...baseItem,
            title: "Approval Update",
            description: `${
              item.data.status === "approved" ? "Approved" : "Rejected"
            } by ${
              item.data.reviewedBy?.type !== "client"
                ? item.data.reviewedBy?.name
                : item.data.signature
            }`,
            type: "approval",
            sender: { name: item.data.reviewedBy.name },
            metadata: {
              status: {
                type: "update",
                content: item.data.status,
                hidden: true,
              },
              notes: {
                type: "message",
                isOutbound: item.data.reviewedBy?.type !== "client",
                content: item.data.notes,
              },
            },
          };
        case "note":
          return {
            ...baseItem,
            title: "Note",
            description: item.data.content,
            type: "message",
            isOutbound: true,
          };
        case "communication":
          return {
            ...baseItem,
            title: item.data.channel || "Communication",
            description: item.data.content,
            type: "message",
            isOutbound: item.data.direction === "outbound",
          };
        default:
          return {
            ...baseItem,
            title: item.type,
            description:
              typeof item.data === "string"
                ? item.data
                : JSON.stringify(item.data),
            type: "update",
          };
      }
    });

    return [...baseTimeline, ...additionalItems].sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
  }, [job, timeline, timeline?.length]);

  // Only show loading if we have no job data
  const isLoading = !job;

  const tabs = [
    {
      id: "All",
      content: (
        <CommunicationsTimeline items={timelineItems} loading={isLoading} />
      ),
    },
    {
      id: "Notes",
      content: <Notes job={job} form={form} onFormChange={onFormChange} />,
      hidden: !isAuthorized("owner,admin"),
    },
    // {
    //   id: "History",
    //   content: <History />,
    // },
  ];

  function handleTabClick(_e, _item, path) {
    navigate(path);
  }

  const sideTabs = useMemo(
    () => tabs.filter((item) => !item.hidden),
    [job, timeline]
  );

  return (
    <Tabs
      className="w-full h-full flex flex-col"
      style={props.wrapperStyle}
      panelStyle={{
        ...props.panelStyle,
        height: props.panelStyle?.height || "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      useSearchParams
      panelClasses="flex-1 border-t"
      panelItemClasses="h-full overflow-y-auto"
      tabListClasses="bg-white px-2 h-[38px] flex-shrink-0"
      tabclassName="mx-1"
      id="job-comms"
      onClick={handleTabClick}
      data={sideTabs}
      scrollable
      fullHeight
    />
  );

  // <>
  //   <div className="flex-grow h-full">
  //     <Tabs
  //       panelClasses="flex-auto bg-white"
  //       panelItemClasses="bg-gray-50 h-full"
  //       useSearchParams
  //       tabListClasses="bg-white px-2 flex-initial border-b"
  //       tabclassName="mx-1"
  //       id="commsMenu"
  //       onClick={handleTabClick}
  //       data={tabs}
  //     />
  //   </div>
  // </>
};

export default SidebarContent;
