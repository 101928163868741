import { Popover, Transition } from "@headlessui/react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import Calendar from "../Calendar";
import Input from "../Input";

export const DatePicker = (props) => {
  function handleChange(e) {
    const finalValue = formatDate(e);
    const dbValue = e;
    const data = {
      value: dbValue,
    };

    if (props.id) {
      data.id = props.id;
    }

    props.onChange && props.onChange(data);
  }

  function formatDate(value, dbFormat) {
    const newDate = new Date(value);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const date = newDate.getDate();

    if (dbFormat) {
      return `${month}-${date}-${year}`;
    }

    return `${month}-${date}-${year}`;
  }

  const trigger = (
    <div className="relative w-full">
      <Input
        placeholder={props.placeholder}
        required={props.required}
        value={props.value}
        label={props.label}
        size={props.size}
        onBlur={props.onBlur}
        icon={{
          right: <CalendarDaysIcon className="w-5 h-5" />,
        }}
      />
    </div>
  );

  const calendar = <Calendar value={props.value} onChange={handleChange} />;

  return (
    <Popover className="relative">
      <Popover.Button className="w-full">{trigger}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute z-10">{calendar}</Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default DatePicker;
