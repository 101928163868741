import { SERVICE_TYPE_OPTIONS } from "../../../constants/select";
import { Badge } from "../../../shared";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import {
  capitalizeFirstLetter,
  formatDate,
  isAuthorized,
  renderAmount,
} from "../../../utils";

// Config for table and form
const tableConfig = [
  {
    id: "priority",
    header: "Priority",
    size: 120,
    maxSize: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return <Badge label={capitalizeFirstLetter(value)} />;
    },
  },
  {
    id: "serviceType",
    header: "Service Type",
    size: 120,
    maxSize: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return value || "";
    },
  },
  {
    id: "totalCharges",
    header: "Total Charges",
    size: 120,
    maxSize: 120,
    cell: ({ getValue }) => {
      const value = getValue();
      return renderAmount(value || 0) || "$0.00";
    },
    visible: isAuthorized("admin, owner"),
  },
  {
    id: "parentCompany",
    header: "Company",
    minWidth: 170,
    visible: false,
  },
  {
    id: "jobId",
    header: "Job ID",
    minWidth: 120,
    visible: false,
  },
  {
    id: "client",
    header: "Client",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.name || "";
    },
  },
  {
    id: "author",
    header: "Created By",
    minWidth: 150,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.name || "";
    },
  },
  {
    id: "startDate",
    header: "Start Date",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
  {
    id: "endDate",
    header: "End Date",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value && formatDate(value, null, true);
    },
  },
  {
    id: "equipment",
    header: "Equipment",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.name || "";
    },
  },
  {
    id: "recommendations",
    header: "Recommendations",
    minWidth: 200,
    visible: false,
  },
  {
    id: "laborTime",
    header: "Labor Hours",
    minWidth: 120,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? `${value} hrs` : "";
    },
  },
  {
    id: "summary",
    header: "Summary",
    minWidth: 200,
    visible: false,
  },
  {
    id: "performedBy",
    header: "Performed By",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.map((employee) => employee.name).join(", ") || "";
    },
  },
  {
    id: "parts",
    header: "Parts Used",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.map((part) => part.name).join(", ") || "";
    },
  },
  {
    id: "services",
    header: "Services",
    minWidth: 170,
    visible: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return value?.map((service) => service.name).join(", ") || "";
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
