import { useMemo, createContext, useContext } from "react";
import {
  useUpdateJobMutation,
  useGetJobQuery,
} from "../redux/services/jobs/jobsApi";
import { IJob, ILeg, IMaintenance } from "../types";
import { getStage, getStages } from "../pages/Jobs/job-stages";
import { getAppType } from "../utils";
import {
  useAddJobItemMutation,
  useApproveJobItemsMutation,
} from "@/redux/services/jobs/jobItems";
import { toast } from "react-toastify";

interface JobContextValue {
  jobId?: string;
  data: IJob | null;
  loading: boolean;
  mode: string | null;
  isQuoteMode: boolean;
  isServiceMode: boolean;
  isNew: boolean;
  isPending: boolean;
  isBooked: boolean;
  isAssigned: boolean;
  isInProgress: boolean;
  isCompleted: boolean;
  isInvoiced: boolean;
  isActive: boolean;
  jobName: string;
  getItemsByType: (type: string) => IMaintenance[] | ILeg[];
  addItemTojob: (data: any) => Promise<any>;
  approveJobItems: (itemIds: string[]) => Promise<any>;
}

const JobContext = createContext<JobContextValue>({
  data: null,
  loading: false,
  mode: null,
  isQuoteMode: false,
  isServiceMode: false,
  isNew: false,
  isPending: false,
  isBooked: false,
  isAssigned: false,
  isInProgress: false,
  isCompleted: false,
  isInvoiced: false,
  isActive: false,
  jobName: "",
  getItemsByType: () => [],
  addItemTojob: () => Promise.resolve(),
  approveJobItems: () => Promise.resolve(),
});

/**
 * Determines the job mode based on the job status.
 * @param {string} status - The current status of the job.
 * @returns {string} - The job mode: "Quote" or "Service"
 */
export const getJobMode = (status: string): string => {
  if (["Pending", "New"].includes(status)) {
    return "Quote";
  }
  return "Service";
};

/**
 * Filters job items by type
 * @param {Array} items - Array of job items
 * @param {string} type - Type to filter by (e.g., "quote", "service")
 * @returns {Array} - Filtered job items
 */
export const getJobItemsByType = (items: any[] = [], type: string): any[] => {
  return items.filter(
    (item) => item.type?.toLowerCase() === type?.toLowerCase()
  );
};

interface JobProviderProps {
  children: React.ReactNode;
  jobId?: string;
}

export const JobProvider = ({ children, jobId }: JobProviderProps) => {
  const { data: jobData, isLoading: jobLoading } = useGetJobQuery(
    { jobId, drivers: true },
    { skip: !jobId }
  );
  const [_updateJob, { isLoading: jobUpdating }] = useUpdateJobMutation();
  const [addJobItem] = useAddJobItemMutation();
  const [approveJobItems, { isLoading: approveJobItemsLoading }] =
    useApproveJobItemsMutation();

  const job = jobData || {};
  const appType = getAppType();
  const stages = getStages(appType);

  const jobMode = useMemo(() => getJobMode(job?.status), [job?.status]);

  const jobName = job.jobId || job.loadNumber || job.orderNumber;

  // Get current stage based on status
  const currentStage = Object.values(stages).find(
    (stage) =>
      stage.id === job.status?.toLowerCase() || stage.title === job.status
  );

  // Status checks based on job stages
  const isNew = currentStage?.id === "new";
  const isPending = currentStage?.id === "pending";
  const isBooked = currentStage?.id === "booked";
  const isAssigned = currentStage?.id === "assigned";
  const isInProgress = currentStage?.id === "inProgress";
  const isCompleted = currentStage?.id === "completed";
  const isInvoiced = currentStage?.id === "invoiced";

  // Active jobs are those that are beyond quote stage but not completed/invoiced
  const isActive = !isNew && !isPending && !isCompleted && !isInvoiced;

  async function addItemTojob(data: any) {
    try {
      const res = await addJobItem({
        jobId: job._id,
        data,
      });

      if ("error" in res) {
        toast.error(res.error?.data?.message || "Failed to create job item");
        return res;
      }

      toast.success("Job item created");
      return res;
    } catch (error) {
      console.error("Error adding job item:", error);
      toast.error("An unexpected error occurred while creating job item");
      throw error;
    }
  }

  async function approveSelectedJobItems(itemIds: string[]) {
    const approveItems = await approveJobItems({
      id: job._id,
      data: { itemIds },
    });

    if (approveItems.error) {
      toast(approveItems.error.data?.message || "Error approving items", {
        type: "error",
        toastId: "approve-items",
      });
      return;
    }

    return approveItems;
  }

  const value = useMemo(
    () => ({
      jobId,
      data: job,
      loading: jobLoading || jobUpdating,
      mode: jobMode,
      isQuoteMode: jobMode === "Quote",
      isServiceMode: jobMode === "Service",
      isNew,
      isPending,
      isBooked,
      isAssigned,
      isInProgress,
      isCompleted,
      isInvoiced,
      isActive,
      jobName,
      addItemTojob,
      getItemsByType: (type: string) => getJobItemsByType(job.items, type),
      approveJobItems: approveSelectedJobItems,
    }),
    [jobId, job, jobLoading, jobUpdating, jobMode]
  );

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export const useJob = (): JobContextValue => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJob must be used within a JobProvider");
  }
  return context;
};
