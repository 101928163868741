import { useEffect } from "react";
import * as Sentry from "@sentry/react";

declare global {
  interface Performance {
    memory?: {
      usedJSHeapSize: number;
      totalJSHeapSize: number;
      jsHeapSizeLimit: number;
    };
  }
}

const MEMORY_THRESHOLD = 0.8; // 80% of available memory
const PERFORMANCE_CHECK_INTERVAL = 30000; // Check every 30 seconds

export const PerformanceMonitor = () => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const checkPerformance = () => {
      // Check memory usage if available
      if (window.performance && window.performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize, jsHeapSizeLimit } =
          window.performance.memory;

        const memoryUsageRatio = usedJSHeapSize / jsHeapSizeLimit;

        // Report high memory usage
        if (memoryUsageRatio > MEMORY_THRESHOLD) {
          Sentry.withScope((scope) => {
            scope.setLevel("warning");
            scope.setTag("type", "memory_warning");
            scope.setExtra("memoryMetrics", {
              usedJSHeapSize,
              totalJSHeapSize,
              jsHeapSizeLimit,
              usageRatio: memoryUsageRatio,
            });
            Sentry.captureMessage("High memory usage detected");
          });
        }
      }

      // Check navigation timing metrics
      const navigationTiming = window.performance.getEntriesByType(
        "navigation"
      )[0] as PerformanceNavigationTiming;
      if (navigationTiming) {
        const metrics = {
          loadTime: navigationTiming.duration,
          domInteractive: navigationTiming.domInteractive,
          domComplete: navigationTiming.domComplete,
          firstContentfulPaint: window.performance
            .getEntriesByType("paint")
            .find((entry) => entry.name === "first-contentful-paint")
            ?.startTime,
        };

        // Report slow page loads
        if (metrics.loadTime > 3000) {
          // 3 seconds threshold
          Sentry.withScope((scope) => {
            scope.setLevel("warning");
            scope.setTag("type", "slow_page_load");
            scope.setExtra("performanceMetrics", metrics);
            Sentry.captureMessage("Slow page load detected");
          });
        }
      }

      // Check long tasks using PerformanceObserver since getEntriesByType doesn't support "longtask"
      let longTasks = [];
      if (
        typeof PerformanceObserver !== "undefined" &&
        PerformanceObserver.supportedEntryTypes?.includes("longtask")
      ) {
        // Create a temporary observer to collect existing long tasks
        const longTaskObserver = new PerformanceObserver((list) => {
          longTasks = longTasks.concat(list.getEntries());
        });

        try {
          longTaskObserver.observe({ type: "longtask", buffered: true });
          // Disconnect immediately after collecting buffered entries
          longTaskObserver.disconnect();
        } catch (error) {
          console.error("Error observing long tasks:", error);
        }
      }
      if (longTasks.length > 0) {
        Sentry.withScope((scope) => {
          scope.setLevel("warning");
          scope.setTag("type", "long_tasks");
          scope.setExtra("longTasks", longTasks);
          Sentry.captureMessage(`${longTasks.length} long tasks detected`);
        });
      }

      // Clear performance entries to prevent memory leaks
      window.performance.clearMarks();
      window.performance.clearMeasures();
      window.performance.clearResourceTimings();
    };

    // Start monitoring
    intervalId = setInterval(checkPerformance, PERFORMANCE_CHECK_INTERVAL);

    // Cleanup
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return null; // This is a monitoring component, it doesn't render anything
};
