import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import { updateInvoice } from "../../../redux/reducers/invoices/invoiceSlice";
import { invoiceUpdated } from "../../../redux/reducers/invoices/invoicesSlice";
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_OPTIONS,
} from "../../Invoices/table.config";
import { useNavigate } from "react-router-dom";
import CreateModal from "../../Invoices/CreateModal";
import { RootState, AppDispatch } from "../../../redux/store";
import { PaginationProps } from "@/types/base";

interface Invoice {
  _id: string;
  [key: string]: any;
}

interface Client {
  _id: string;
  [key: string]: any;
}

interface IInvoicePagination extends PaginationProps {
  pageIndex: number;
  pageSize: number;
}

interface ClientInvoicesTableProps {
  invoices: Invoice[];
  client: Client;
  loading?: boolean;
  pagination: IInvoicePagination;
  setPagination: (pagination: any) => void;
  handleGlobalSearch?: (search: string) => void;
}

const ClientInvoicesTable: React.FC<ClientInvoicesTableProps> = ({
  invoices,
  client,
  loading,
  pagination,
  setPagination,
  handleGlobalSearch,
}) => {
  const invoicesStatus = useSelector(
    (state: RootState) => state.invoices.status
  );
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAuth();

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  async function handleUpdate(
    e: any,
    setLoading: (loading: boolean) => void
  ): Promise<void> {
    const updateData = {
      parentId: user.parentCompany,
      invoiceId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateInvoice(updateData)).unwrap();

    if (res.status === "success") {
      dispatch(invoiceUpdated(res.data));

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  const globalFilter = useCallback(
    (value: string) => {
      if (!setPagination || !handleGlobalSearch) return;

      setPagination((prev: any) => ({
        ...prev,
        page: 1, // Reset to first page on search
        limit: prev.limit || 20,
      }));
      handleGlobalSearch(value);
    },
    [setPagination, handleGlobalSearch]
  );

  // const createPath = "invoices/create";

  console.log(invoices);

  return (
    <div>
      <TableLayout
        meta={{
          updateData: handleUpdate,
        }}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        id="invoices"
        loading={loading}
        columns={COLUMN_DEFINITIONS}
        pagination={pagination}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        onPaginationChange={setPagination}
        data={invoices || []}
        manualPagination={true}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        fullTextSearch={globalFilter}
        fullSelectedItem
      />
      <CreateModal
        client={client}
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default ClientInvoicesTable;
