import TruncatedText from "../../../../components/TruncatedText";
import ServiceDataCard from "./ServiceDataCard";

const WorkSummary = ({ summary }) => {
  return (
    <ServiceDataCard header="Work Summary" className="col-span-1 bg-white">
      <div className="px-4 py-3">
        {summary ? (
          <TruncatedText
            maxLength={700}
            text={summary}
            className="text-sm text-gray-600 whitespace-pre-wrap"
          />
        ) : (
          <p className="text-sm text-gray-400 italic">
            No work summary provided
          </p>
        )}
      </div>
    </ServiceDataCard>
  );
};

export default WorkSummary;
