import { Button, Modal } from "../../../shared";

/**
 * PayModal component for accepting payments.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.visible - Indicates if the modal is visible.
 * @param {Function} props.setVisible - Function to set the modal visibility.
 */
export const PayModal = ({ visible, setVisible }) => {
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hideFooter
      title="Accept Payment"
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="amount" className="text-sm font-medium">
            Amount
          </label>
          <input
            id="amount"
            type="number"
            className="border p-2 rounded-md"
            placeholder="Enter amount"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="paymentMethod" className="text-sm font-medium">
            Payment Method
          </label>
          <select id="paymentMethod" className="border p-2 rounded-md">
            <option value="">Select payment method</option>
            <option value="cash">Cash</option>
            <option value="card">Card</option>
            <option value="online">Online</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>
        <Button type="primary">Accept Payment</Button>
      </div>
    </Modal>
  );
};
