import { useState } from "react";
import { useGetClientsQuery } from "../redux/services/clients/clientsApi";
import InputDropdown from "../shared/InputDropdown";
import { AvatarClient } from "./AvatarClient";
import { useDebounceSearch, useForm } from "../hooks";
import { NewClientForm } from "../pages/Clients/NewClientForm";
import { Button } from "../shared";
import { removeEmptyValues } from "../utils";

export const ClientsDropdown = ({
  form,
  updateForm,
  errors = {},
  onClick,
  onChange,
  value,
  hideFooter,
}) => {
  const { search, handleSearch } = useDebounceSearch();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data: clients } = useGetClientsQuery({
    page: pageIndex + 1,
    limit: pageSize,
    sortBy: "name",
    sortOrder: "asc",
    search: search.useSearch,
    searchTerm: search.term,
  });

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  const [showForm, setShowForm] = useState(false);

  function handleFooterButtonClick() {
    updateForm({ id: "newClient", value: true });
    setShowForm(true);
  }

  function handleCancel() {
    updateForm({ id: "newClient", value: false });
    setShowForm(false);
  }

  function handleChange(e) {
    updateForm({
      id: "client",
      value: { ...form.client, [e.id]: e.value },
    });
  }

  return (
    <>
      {showForm ? (
        <>
          <NewClientForm
            form={form}
            updateForm={handleChange}
            errors={errors}
          />
          <Button onClick={handleCancel} type="link">
            <p className="font-bold">Cancel</p>
          </Button>
        </>
      ) : (
        <InputDropdown
          required
          label="Client"
          onChange={(e) => globalFilter(e)}
          value={value}
          placeholder="Search clients by name"
          data={clients?.clients || []}
          footerButtonClick={!hideFooter && handleFooterButtonClick}
          footerButton={
            !hideFooter && <p className="font-bold">+ New Client</p>
          }
          renderItem={(e) => {
            return (
              <AvatarClient
                key={e.item._id}
                onClick={() => onClick(e.item)}
                {...e}
                {...e.item}
                disableCancel
              />
            );
          }}
        />
      )}
    </>
  );
};
