import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PARENT_COMPANY, ROOT_API_URL } from "../config/defaultSettings";
import { getAppType } from "../utils";
import { useDispatch } from "react-redux";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const clearAuthData = async () => {
  try {
    const logoutResponse = await fetch(`${ROOT_API_URL}/account/logout`, {
      method: "POST",
      credentials: "include",
    });
  } catch (error) {
    console.error("Error during logout:", error);
  }

  // localStorage.removeItem(USER);
  // localStorage.removeItem(PARENT_COMPANY);

  // document.cookie = `${TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  // document.cookie = `${REFRESH_TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

  // window.location.replace("/login");
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_API_URL,
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.set("x-client-type", "web");
      headers.set("x-app-type", getAppType());

      // Only set parent company if it exists
      const parentCompany = localStorage.getItem(PARENT_COMPANY);
      if (parentCompany && parentCompany !== "null") {
        headers.set("x-parent-company", parentCompany);
      }

      return headers;
    },
  });

  let result = await baseQuery(args, api, extraOptions);

  // Version check
  if (result.meta?.response?.headers) {
    try {
      const serverVersion = result.meta.response.headers.get("x-version");
      const currentVersion = import.meta.env.PACKAGE_VERSION;

      if (serverVersion && serverVersion > currentVersion) {
        window.localStorage.setItem("version-update-needed", "true");
      }
      window.localStorage.setItem("version", currentVersion);
    } catch (err) {
      console.error("Version Check Error:", err);
    }
  }

  // Handle 401/403 errors
  if (result.error?.status === 401) {
    // Only try refresh if we get a TOKEN_EXPIRED error
    if (
      result.error?.data?.code === "TOKEN_EXPIRED" ||
      result.error?.data?.code === "NO_TOKEN"
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshResult = await baseQuery(
            { url: "/account/refresh", method: "POST" },
            api,
            extraOptions
          );

          if (refreshResult.data?.status === "success") {
            processQueue(null, refreshResult.data);
            // Retry original request
            result = await baseQuery(args, api, extraOptions);
          } else {
            processQueue(new Error("Token refresh failed"));
            await clearAuthData();
          }
        } catch (error) {
          console.error("Error during token refresh:", error);
          processQueue(error);
          await clearAuthData();
        } finally {
          isRefreshing = false;
        }
      } else {
        // Wait for the refresh to complete
        try {
          await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          // Retry original request after refresh
          result = await baseQuery(args, api, extraOptions);
        } catch (error) {
          console.error("Error processing queued request:", error);
          await clearAuthData();
        }
      }
    } else {
      // Only clear auth for non-TOKEN_EXPIRED errors
      await clearAuthData();
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User", "Auth"],
  endpoints: () => ({}),
});

export const clearApiCache = () => {
  const dispatch = useDispatch();
  return dispatch(api.util.resetApiState());
};

export const { usePrefetch } = api;
