import { api } from "../../api";

const usersApi = api.injectEndpoints({
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `users?${query}`;
      },
      providesTags: (data) =>
        data?.users
          ? [
              ...data.users.map(({ id }) => ({ type: "Users", id })),
              { type: "Users", id: "LIST" },
            ]
          : [{ type: "Users", id: "LIST" }],
    }),
    addUser: builder.mutation({
      query(body) {
        return {
          url: "users",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    getUser: builder.query({
      query: (id) => `users/${id}`,
      transformResponse: (response) => response.user,
      providesTags: (_result, _error, id) => [{ type: "Users", id }],
    }),
    updateUser: builder.mutation({
      query({ data, id }) {
        return {
          url: `/users/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Users", id },
        { type: "Employees" },
        { type: "Employee", id: "LIST" },
      ],
    }),
    deleteUser: builder.mutation({
      query(id) {
        return {
          url: `/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, id) => [{ type: "Users", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
} = usersApi;
