import { renderAmount } from "@/utils";

export const TotalCharges = ({ totalCharges }) => {
  return (
    <div className="pt-2 border-t">
      <div className="flex justify-between items-center text-base font-semibold">
        <span>Total Charges</span>
        <span>{renderAmount(totalCharges)}</span>
      </div>
    </div>
  );
};
