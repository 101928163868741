import { api } from "../../api";

export const itemsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendQuoteEmail: builder.mutation({
      query: ({ data, itemId }) => ({
        url: `/items/${itemId}/send-quote`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "Items", id: "LIST" },
        { type: "Timeline", id: "LIST" },
      ],
    }),
    approveItem: builder.mutation({
      query: ({ data, itemId }) => ({
        url: `/items/${itemId}/approvals`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: "Items", id: "LIST" },
          { type: "Timeline", id: "LIST" },
          { type: "Jobs", id: result?.data?.jobId },
        ];
      },
    }),
  }),
});

export const { useSendQuoteEmailMutation, useApproveItemMutation } = itemsApi;
