import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import useForm from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import { useAddJobMutation } from "../../../redux/services/jobs/jobsApi";
import { ServiceJobForm } from "./ServiceJobForm";
import { ClientsDropdown } from "../../../components/ClientsDropdown";
import {
  useAddClientMutation,
  useGetClientsQuery,
  useGetRecentClientsQuery,
} from "../../../redux/services/clients/clientsApi";
import useEmblaCarousel from "embla-carousel-react";
import { toast } from "react-toastify";
import { useConfig } from "../../../hooks";
import { AvatarClient } from "../../../components";
import { renderValidations } from "@/shared/Table/table.utils";

const validations = renderValidations([{ id: "client", required: true }]);

const CreateModal = ({ modalOpen, setModalOpen }) => {
  const { appType } = useConfig();
  const navigate = useNavigate();

  const { form, updateForm, clearForm, setFormInit, errors, isValid } = useForm(
    { validations }
  );

  const [slideInView, setSlideInView] = useState(0);

  // Carousel hook with debug logging
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    watchDrag: false,
  });

  const [submitButton, setSubmitButton] = useState("");

  const [addJob, { isLoading: addingJob }] = useAddJobMutation();
  const [addClient, { isLoading: addingClient }] = useAddClientMutation();
  const { data: clientsData } = useGetClientsQuery({ limit: 30 });
  const { data: recentClientsData } = useGetRecentClientsQuery();

  const clients = clientsData?.clients || [];

  const recentClients = useMemo(
    () => recentClientsData?.data || [],
    [recentClientsData]
  );

  function getButtonTitle() {
    return appType === "trucking" ? "Submit" : "Next";
  }

  useEffect(() => {
    setSubmitButton(getButtonTitle());
  }, [appType]);

  useEffect(() => {
    if (form.client) {
      setFormInit({ appointmentDate: null });
    }
  }, [form.client]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function saveJob() {
    try {
      const postData = {
        ...form,
        useLegacyCreate: false,
      };

      if (form.template) {
        postData.template = form.template.value;
      }

      const res = await addJob(postData).unwrap();

      if (res.status !== "success") {
        toast(res.message, {
          toastId: "add-job-error",
          type: res.status,
        });
        return;
      } else {
        closeModal();
        navigate("/jobs/" + res.data._id);
      }
    } catch (error) {
      console.error("Error saving job:", error);
      toast("Error saving job", {
        toastId: "add-job-error",
        type: "error",
      });
    }
  }

  const handleCancel = useCallback(() => {
    setSubmitButton(getButtonTitle());
    setSlideInView(0);
    clearForm(["client", "newClient"]);
    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi]);

  // Handle carousel back with debug
  const scrollPrev = useCallback(() => {
    if (emblaApi && emblaApi.canScrollPrev()) {
      setSubmitButton(getButtonTitle());
      setSlideInView(slideInView - 1);
      emblaApi.scrollPrev();
    }
  }, [emblaApi, slideInView]);

  // Handle carousel next with debug
  const scrollNext = useCallback(() => {
    if (emblaApi && emblaApi.canScrollNext()) {
      setSubmitButton("Submit");
      setSlideInView(slideInView + 1);
      emblaApi.scrollNext();
    } else {
      console.log("Cannot scroll next");
    }
  }, [emblaApi, slideInView]);

  /**
   * Handles the selection of a client and scrolls to the next view.
   *
   * @param {Object} cl - The client object selected by the user.
   */
  const handleSelectClient = useCallback(
    (cl) => {
      updateForm({ id: "client", value: cl });

      if (appType !== "trucking") {
        scrollNext();
      }
    },
    [scrollNext, updateForm, appType]
  );

  async function addClientAndScrollNext() {
    try {
      if (!form.client) {
        toast("Please fill out the client form before proceeding.", {
          toastId: "client-form-error",
          type: "error",
        });
        return;
      }

      const clientRes = await addClient(form.client).unwrap();

      toast(clientRes.message, {
        toastId: `add-client-${clientRes.status}`,
        type: clientRes.status,
      });

      if (clientRes.status !== "success") {
        return;
      }

      updateForm({ id: "client", value: clientRes.data });
      scrollNext();
    } catch (error) {
      toast("Error adding client", {
        toastId: "add-client-error",
        type: "error",
      });
    }
  }

  // Main modal button handler with debug
  function handleSubmit() {
    if (appType !== "trucking") {
      // If we're on the first slide (client selection)
      if (slideInView === 0) {
        if (form.client && !form.client?._id) {
          addClientAndScrollNext();
          return;
        }
        // If we have a client selected, just scroll next
        if (form.client?._id) {
          scrollNext();
          return;
        }
      }

      // Only save job if we're on the last slide
      if (slideInView === 1) {
        saveJob();
      }
    } else {
      saveJob();
    }
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      {appType !== "trucking" && submitButton === "Submit" && (
        <Button type="alternative" className="mr-2" onClick={scrollPrev}>
          Back
        </Button>
      )}
      <Button
        loading={addingJob}
        disabled={addingJob || !isValid}
        onClick={handleSubmit}
        type="primary"
      >
        {submitButton}
      </Button>
    </div>
  );

  const createModalContent = useMemo(() => {
    return (
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide" key={0}>
            {!form.client?._id ? (
              <div
                className="space-y-3"
                style={{
                  display: slideInView === 0 ? "block" : "none",
                }}
              >
                <ClientsDropdown
                  onClick={(e) => handleSelectClient(e)}
                  onChange={(e) => handleSelectClient(e)}
                  value={form.client}
                  form={form}
                  errors={errors}
                  updateForm={updateForm}
                />
                {!form.newClient && (
                  <>
                    <div className="flex items-center justify-between">
                      <div>Recent clients</div>
                    </div>
                    <div>
                      {recentClients.map((recent, i) => {
                        return (
                          <AvatarClient
                            key={"av-" + i}
                            disableCancel
                            onClick={() => handleSelectClient(recent)}
                            {...recent}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="mb-5">
                  <AvatarClient
                    selected
                    onCancel={handleCancel}
                    {...form.client}
                  />
                </div>
                <AdvancedSettings
                  defaultOpen
                  form={form}
                  updateForm={updateForm}
                />
              </>
            )}
          </div>

          <div className="embla__slide" key={1}>
            <div
              style={{
                display: slideInView === 1 ? "block" : "none",
              }}
            >
              <div className="mb-5">
                <AvatarClient
                  selected
                  onCancel={handleCancel}
                  {...form.client}
                />
                <ServiceJobForm form={form} updateForm={updateForm} />
              </div>
              <AdvancedSettings
                defaultOpen
                form={form}
                updateForm={updateForm}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [slideInView, form, clients.length, errors, emblaApi]);

  const title = <span className="mx-4">Create a new job</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-0"
    />
  );
};

export default CreateModal;
