import { api } from "../../api";
import { getSearchParams } from "../../../utils";

export const pdfTemplateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generatePDF: builder.mutation({
      query: ({ data, options }) => ({
        url: `/pdf/generate?${getSearchParams(options)}`,
        method: "POST",
        body: data,
        responseHandler: async (response) => {
          if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || "PDF generation failed");
          }

          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/pdf")) {
            throw new Error("Invalid response format. Expected PDF.");
          }

          const blob = await response.blob();
          if (!blob || blob.size === 0) {
            throw new Error("Generated PDF is empty");
          }

          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );

          return { url, blob };
        },
      }),
    }),

    getPdfTemplates: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `pdf/templates?${query}`;
      },
      providesTags: (data) =>
        data?.jobs
          ? [
              ...data.jobs.map(({ _id }) => ({
                type: "PdfTemplates",
                id: _id,
              })),
              { type: "PdfTemplate s", id: "LIST" },
            ]
          : [{ type: "PdfTemplates", id: "LIST" }],
    }),

    getDefaultPdfTemplate: builder.query({
      query: (type) => `/pdf/templates/default/${type}`,
      providesTags: (result, error, type) => [
        { type: "PdfTemplates", id: `default-${type}` },
      ],
    }),

    createPdfTemplate: builder.mutation({
      query: (template) => ({
        url: "/pdf/templates",
        method: "POST",
        body: template,
      }),
      invalidatesTags: ["PdfTemplates"],
    }),

    updatePdfTemplate: builder.mutation({
      query: ({ id, ...template }) => ({
        url: `/pdf/templates/${id}`,
        method: "PUT",
        body: template,
      }),
      invalidatesTags: ["PdfTemplates"],
    }),

    deletePdfTemplate: builder.mutation({
      query: (id) => ({
        url: `/pdf/templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),
  }),
});

export const {
  useGeneratePDFMutation,
  useGetPdfTemplatesQuery,
  useGetDefaultPdfTemplateQuery,
  useCreatePdfTemplateMutation,
  useUpdatePdfTemplateMutation,
  useDeletePdfTemplateMutation,
} = pdfTemplateApi;
