import {
  Bars3Icon,
  ChevronDoubleLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Drawer from "../../shared/Drawer";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Spinner from "../../shared/Spinner";

export const SidebarContainer = ({
  footerHeight = 24,
  hideSidebar,
  children,
  ...props
}) => {
  const parentRef = useRef();
  const childRef = useRef();
  const headerRef = useRef();
  const { size } = useWindowSize();
  const [open, setOpen] = useState(false);
  const isHidden = size.width < 1024;
  const [headerContext, setHeaderContext] = useState();

  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const getContentDimensions = () => {
    if (!parentRef.current || !childRef.current) {
      return {
        parentHeight: 0,
        parentWidth: parentRef.current?.clientWidth || 0,
        offsetTop: headerContext?.offsetTop || 0,
        offsetHeight: headerContext?.offsetHeight || 0,
      };
    }

    return {
      parentHeight: parentRef.current.clientHeight,
      parentWidth: parentRef.current.clientWidth,
      offsetTop: headerContext?.offsetTop || 0,
      offsetHeight: headerContext?.offsetHeight || 0,
    };
  };

  const getContentStyles = () => {
    const { offsetTop, offsetHeight } = getContentDimensions();

    return {
      wrapperStyle: {
        height: `calc(100% - ${offsetTop}px)`,
        minHeight: `calc(100% - ${offsetTop}px)`,
      },
      panelStyle: {
        height: `calc(100% - ${offsetTop - offsetHeight}px)`,
        overflowY: "auto",
      },
    };
  };

  const getSidebarDimensions = () => {
    if (!parentRef.current || !childRef.current) {
      return {
        parentHeight: 0,
        parentWidth: parentRef.current?.clientWidth || 0,
        offsetTop: headerContext?.offsetTop || 0,
        offsetHeight: headerContext?.offsetHeight || 0,
      };
    }

    return {
      parentHeight: parentRef.current.clientHeight,
      parentWidth: parentRef.current.clientWidth,
      offsetTop: headerContext?.offsetTop || 0,
      offsetHeight: headerContext?.offsetHeight || 0,
    };
  };

  const getSidebarStyles = () => {
    const { offsetTop, offsetHeight } = getSidebarDimensions();
    const footerSpace = props.footer ? footerHeight : 0; // Account for footer if present

    return {
      wrapperStyle: {
        height: `calc(100vh - ${offsetTop + offsetHeight + footerSpace}px)`,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      },
      panelStyle: {
        height: "100%",
        overflow: "hidden",
      },
    };
  };

  const renderHeader = (headerProps) => {
    if (!headerProps) return null;

    return (
      <div ref={headerRef} onClick={(e) => e.stopPropagation()}>
        {props.header ? (
          props.header({
            ...headerProps,
            ...props,
            headerRef,
            setHeaderContext,
            open,
            setOpen,
          })
        ) : (
          <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
            <div>{props.title}</div>
            <div className="flex items-center space-x-3">
              {props.actions}
              {!hideSidebar && (
                <div
                  onClick={() => setOpen(true)}
                  className="hover:bg-gray-100 cursor-pointer p-2 rounded-lg block lg:hidden"
                >
                  <ChevronDoubleLeftIcon className="w-5 text-gray-800" />
                </div>
              )}
            </div>
          </header>
        )}
      </div>
    );
  };

  const transition = "transition-all duration-200";

  const asideClasses = classNames(
    "flex flex-col border-l hidden lg:block m-0",
    transition,
    {
      "w-[20rem] sm:w-[25rem] md:w-[30rem] lg:w-[35rem] xl:w-[40rem]": open,
      "!w-[3rem] bg-white hover:bg-gray-100 cursor-pointer": !open,
    }
  );
  const childClasses = classNames(
    transition,
    "h-full",
    // " flex-auto",
    {
      "opacity-1": open,
      "opacity-0 hidden": !open,
    }
  );

  const footerClasses = classNames({
    "opacity-1": open,
    "opacity-0": !open,
  });

  const Icon = !open ? Bars3Icon : XMarkIcon;
  const contentClasses = classNames(
    "w-full",
    "flex flex-col h-screen"
    // "overflow-y-auto"
  );
  const iconWrapClasses = classNames(
    transition,
    "p-1 cursor-pointer flex text-gray-700 hover:bg-gray-100 rounded-lg",
    { "justify-center hover:bg-gray-100": !open, "justify-end bg-white": open }
  );
  const topClasses = classNames(
    "flex px-2 items-center",
    // "flex-initial",
    transition,
    {
      "justify-center": !open,
      "justify-between bg-white border-b": open,
    }
  );
  const sideTitleClass = classNames("flex p-[0.24rem] bg-white", transition, {
    hidden: !open,
    block: open,
  });
  const childWrapper = classNames("flex flex-grow overflow-hidden");

  function handleOpen() {
    setOpen(!open);
  }
  const iconClass = classNames("w-4 w-5 text-gray-800");

  const wrapperClass = classNames("flex border-t", props.className);
  return (
    <>
      <div ref={parentRef} className={wrapperClass}>
        <div className={contentClasses}>
          {!props.hideHeader && renderHeader(props)}

          <div ref={childRef} className={childWrapper}>
            {props.loading ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              children({
                ...getContentDimensions(),
                ...getContentStyles(),
                headerRef,
                headerContext,
              })
            )}
          </div>
        </div>

        {/* Sidebar */}
        {!hideSidebar && (
          <aside className={asideClasses} onClick={() => !open && handleOpen()}>
            <div className="flex flex-col h-full">
              <div ref={headerRef} className={topClasses}>
                <div className={sideTitleClass}>{props.sidebarTitle}</div>
                <div className={iconWrapClasses} onClick={handleOpen}>
                  <Icon className="h-6 w-6" />
                </div>
              </div>
              <div ref={childRef} className={childClasses}>
                {typeof props.sidebarContent === "function"
                  ? props.sidebarContent({
                      ...getSidebarDimensions(),
                      ...getSidebarStyles(),
                      headerRef,
                      headerContext,
                    })
                  : props.sidebarContent}
              </div>
              {props.footer && (
                <div
                  className={classNames(
                    footerClasses,
                    "flex-shrink-0" // Prevent footer from shrinking
                  )}
                  style={{ height: `${footerHeight}px` }} // Use fixed height instead of flex
                >
                  {props.footer}
                </div>
              )}
            </div>
          </aside>
        )}
      </div>
      {isHidden && (
        <Drawer
          position="right"
          widthClasses="max-w-md"
          title={props.sidebarTitle}
          body={
            <div className="flex flex-col bg-gray-50 h-[calc(100vh-64px)]">
              <div className="flex-1 overflow-y-auto">
                {typeof props.sidebarContent === "function"
                  ? props.sidebarContent({
                      parentHeight: window.innerHeight,
                      parentWidth: window.innerWidth,
                      offsetTop: 0,
                      offsetHeight: 0,
                      wrapperStyle: {
                        height: "100%",
                      },
                      panelStyle: {
                        height: "100%",
                      },
                      headerRef,
                      headerContext,
                    })
                  : props.sidebarContent}
              </div>
              {props.footer && (
                <div
                  className="flex-shrink-0"
                  style={{ height: `${footerHeight}px` }}
                >
                  {props.footer}
                </div>
              )}
            </div>
          }
          visible={open}
          setVisible={setOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
