const ServiceDataCard = ({ header, actions, children, className }) => {
  return (
    <div className={className + " w-full"}>
      {(header || actions) && (
        <div className="flex justify-between items-center">
          {typeof header === "function" ? (
            header()
          ) : (
            <h3 className="font-medium text-lg text-gray-800">{header}</h3>
          )}
          {actions && <div className="flex justify-end">{actions}</div>}
        </div>
      )}
      <div className="p-2">{children}</div>
    </div>
  );
};

export default ServiceDataCard;
