import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ErrorBoundary } from "./index";
import * as Sentry from "@sentry/react";

interface RouteErrorBoundaryProps {
  children: React.ReactNode;
}

const RouteErrorFallback = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleReset = () => {
    resetError();
    // Clear any error-related state
    navigate(location.pathname, { replace: true });
  };

  const handleGoHome = () => {
    resetError();
    navigate("/", { replace: true });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Oops! Something went wrong
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {error.message ||
              "An unexpected error occurred while loading this page"}
          </p>
          {process.env.NODE_ENV === "development" && (
            <pre className="mt-4 p-4 bg-gray-100 rounded-md overflow-auto text-xs">
              {error.stack}
            </pre>
          )}
        </div>
        <div className="mt-4 space-y-4">
          <button
            onClick={handleReset}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Try again
          </button>
          <button
            onClick={handleGoHome}
            className="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Go to homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export const RouteErrorBoundary: React.FC<RouteErrorBoundaryProps> = ({
  children,
}) => {
  const location = useLocation();

  return (
    <ErrorBoundary
      fallback={RouteErrorFallback}
      onError={(error, errorInfo) => {
        // Add route information to the error
        Sentry.withScope((scope) => {
          scope.setTag("route", location.pathname);
          scope.setExtra("locationState", location.state);
          scope.setExtra("errorInfo", errorInfo);
          Sentry.captureException(error);
        });
      }}
      resetKeys={[location.pathname]} // Reset the error boundary when the route changes
    >
      {children}
    </ErrorBoundary>
  );
};
