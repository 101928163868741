import classNames from "classnames";
import React from "react";

export const Radio = (props) => {
  function handleChange(e) {
    props.onChange && props.onChange(e.target);
  }

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";

  const radioClasses = classNames(
    props.className,
    "accent-primary-600 bg-gray-100 border-gray-300",
    { "w-6 h-6": sizeMd, "w-4 h-4": sizeSm }
  );

  const wrapperClasses = classNames(
    "flex items-center my-2",
    props.wrapperClassName
  );

  const labelClasses = classNames(
    "ml-2 text-sm font-medium text-gray-900",
    {
      "text-sm": props.size === "sm",
      "text-base": props.size === "md",
      "text-lg": props.size === "lg",
    },
    props.labelClassName
  );

  return (
    <div className={wrapperClasses}>
      <input
        id={props.id}
        type="radio"
        value={props.value}
        name={props.name}
        checked={props.checked}
        className={radioClasses}
        onChange={handleChange}
      />
      {props.label && (
        <label htmlFor={props.id} className={labelClasses}>
          {props.label}
        </label>
      )}
    </div>
  );
};

export default Radio;
