import React, { useState, useRef, useEffect, useCallback } from "react";
import { Avatar, Button } from "../shared";
import {
  ChevronUpDownIcon,
  PlusCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";

/**
 * A multi-select dropdown component for assigning users
 * @param {Object} props Component props
 * @param {Array} props.value - Currently selected users
 * @param {(users: IEmployee[]) => void} props.onChange - Callback when selection changes
 * @param {(term: string) => void} props.onSearch - Callback when search term changes
 * @param {() => void} [props.onCancel] - Optional callback when cancel is clicked
 * @param {() => void} props.onLoadMore - Callback when load more is clicked
 * @param {boolean} props.isEditable - Whether the dropdown is editable
 * @param {boolean} [props.list] - Optional flag for list view mode
 * @param {boolean} props.hasMore - Whether there are more users to load
 * @param {boolean} props.loading - Whether the users are loading
 * @param {Array} props.users - List of available users to select from
 */
export const AssignedToSelect = ({
  value = [],
  onChange,
  users = [],
  list = false,
  isEditable = false,
  onLoadMore,
  hasMore = false,
  loading = false,
  onSearch = () => {},
  onCancel = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: "50px",
    triggerOnce: false,
  });

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (inView && !loading && hasMore && onLoadMore) {
      const nextPage = currentPage + 1;
      onLoadMore(nextPage);
      setCurrentPage(nextPage);
    }
  }, [inView, loading, hasMore, onLoadMore, currentPage]);

  const isSelected = (userId) => {
    return selectedUsers.some((user) => (user.id || user._id) === userId);
  };

  useEffect(() => {
    const valueIds = value
      .map((u) => u.id || u._id)
      .sort()
      .join(",");
    const selectedIds = selectedUsers
      .map((u) => u.id || u._id)
      .sort()
      .join(",");

    if (valueIds !== selectedIds) {
      setSelectedUsers(value);
    }
  }, [value]);

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSelectedUsers(value); // Reset to original value when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [value]);

  const handleSave = () => {
    onChange(selectedUsers);
    setIsOpen(false);
  };

  const toggleUser = (user) => {
    if (!isEditable) return;
    setSelectedUsers((prev) => {
      const exists = prev.find((u) => u.id === user.id);
      if (exists) {
        return prev.filter((u) => u.id !== user.id);
      }
      return [...prev, user];
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isEditable) return;
    setIsOpen(!isOpen);
  };

  function renderList() {
    return (
      <div className="flex flex-row items-center gap-2">
        {renderTrigger()}
        <div className="flex flex-row items-center gap-2">
          {Array.isArray(selectedUsers) &&
            selectedUsers.map((item) => {
              return <UserCard key={item.id} {...item} />;
            })}
        </div>
      </div>
    );
  }

  function renderContent() {
    if (list) {
      return renderList();
    }
    return renderTrigger();
  }

  const triggerClass = classNames(
    "relative w-full flex items-center min-h-[40px] rounded-lg",
    {
      "cursor-pointer": isEditable,
      "cursor-default": !isEditable,
      "bg-white pl-1 pr-10 text-left border focus:outline-none focus:border-primary-500 focus:ring-2 focus:ring-white focus:ring-opacity-75 focus:ring-offset-2 focus:ring-offset-primary-300":
        !list,
    }
  );

  function renderTrigger() {
    return (
      <button
        onClick={(e) => handleClick(e)}
        className={triggerClass}
        // Prevent clicks from bubbling up
        onMouseDown={(e) => e.stopPropagation()}
      >
        <UserCardList list={list} selectedUsers={selectedUsers} />
        {!list && isEditable && (
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        )}
      </button>
    );
  }

  const handleCancel = () => {
    setSelectedUsers(value); // Reset to original value on cancel
    onCancel?.();
    setIsOpen(false);
  };

  // Handle search input changes
  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value); // Pass the raw value to the parent's handleSearch
  };

  // Reset search when dropdown closes
  useEffect(() => {
    if (!isOpen) {
      setSearchTerm("");
      onSearch(""); // Clear search in parent
      setSelectedUsers(value); // Reset selected users when dropdown closes
    }
  }, [isOpen, onSearch, value]);

  return (
    <div className="relative w-full min-w-44 max-w-56" ref={dropdownRef}>
      {renderContent()}

      {isOpen && isEditable && (
        <div className="absolute mt-1 w-full rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 flex flex-col">
          <div className="p-2 border-b">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchInput}
                placeholder="Search technicians..."
                className="w-full pl-8 pr-3 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                autoComplete="off"
                autoFocus
              />
              <MagnifyingGlassIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              {loading && searchTerm && (
                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-500" />
                </div>
              )}
            </div>
          </div>

          <div className="max-h-60 overflow-auto">
            {/* Show "No results found" message when appropriate */}
            {!loading && users.length === 0 && searchTerm && (
              <div className="py-2 text-center text-sm text-gray-500">
                No technicians found
              </div>
            )}

            {/* Selected users section */}
            {selectedUsers.length > 0 && (
              <>
                <div className="px-4 py-2 text-xs font-semibold text-gray-500 bg-gray-50">
                  Selected Technicians
                </div>
                {selectedUsers.map((user) => {
                  return (
                    <div
                      key={user.id}
                      onClick={() => toggleUser(user)}
                      className="relative cursor-pointer select-none py-2 pl-4 pr-4 bg-primary-500 text-white hover:bg-primary-600"
                    >
                      <div className="flex items-center space-x-2">
                        <Avatar name={user.user?.avatar} size="xs" />
                        <span className="text-sm">{user.user?.fullName}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-white">
                          ✓
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="border-b border-gray-200" />
              </>
            )}

            {/* Available users section */}
            {users
              .filter(
                (user) =>
                  !selectedUsers.some((selected) => selected.id === user._id)
              )
              .map((user) => {
                return (
                  <div
                    key={user._id}
                    onClick={() => toggleUser({ ...user, id: user._id })}
                    className="relative cursor-pointer select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-primary-500 hover:text-white"
                  >
                    <div className="flex items-center space-x-2">
                      <Avatar name={user.user?.avatar} size="xs" />
                      <span className="text-sm">{user.user?.fullName}</span>
                    </div>
                  </div>
                );
              })}

            {/* Load more section */}
            {(hasMore || loading) && (
              <div ref={loadMoreRef} className="py-2 text-center">
                {loading ? (
                  <div className="flex justify-center items-center space-x-2">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-500"></div>
                    <span className="text-sm text-gray-500">Loading...</span>
                  </div>
                ) : (
                  hasMore && (
                    <span className="text-sm text-gray-500">
                      Scroll for more...
                    </span>
                  )
                )}
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-2 p-2 pb-1 border-t bg-white">
            <Button type="alternative" size="xs" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" size="xs" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const UserCard = ({ user: { avatar, fullName, firstName, lastName } }) => {
  const name = fullName || `${firstName} ${lastName}`;
  return (
    <div className="flex items-center overflow-hidden">
      <div className="flex -space-x-3 mr-2">
        <div className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-sm font-medium">
          {avatar ? <Avatar src={avatar} size="xs" name={name} /> : name[0]}
        </div>
      </div>
      <span className="block truncate text-gray-800">{name}</span>
    </div>
  );
};

const UserCardList = ({ selectedUsers, list }) => {
  return (
    <div className="flex items-center overflow-hidden">
      <div className="flex -space-x-3 mr-2">
        {!list && selectedUsers?.length ? (
          selectedUsers.map((v, i) => (
            <div
              key={i}
              className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-sm font-medium"
            >
              {v.user?.fullName[0]}
            </div>
          ))
        ) : (
          <PlusCircleIcon className="w-5 h-5 text-primary-500" />
        )}
      </div>
      <span className="block truncate text-gray-800">
        {list
          ? "Add Technician"
          : selectedUsers?.length
          ? selectedUsers.map((person) => person.user?.fullName).join(", ")
          : "Select Technicians"}
      </span>
    </div>
  );
};
