import { lazy, Suspense, useMemo, useState } from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FilterItem } from "./FilterItem";
import { RichDatePickerPanel } from "../DatePicker/RichDatePickerPanel";

export const Filters = (props) => {
  const [filters, setFilters] = useState([]);

  function updateFilters(arr) {
    props.onFiltersChange && props.onFiltersChange(arr);
  }

  function handleFilterChange(arr) {
    setFilters(arr);
    updateFilters(arr);
  }

  function addFilter(e) {
    handleFilterChange([...filters, e]);
  }

  function removeFilter(e) {
    const newFilters = filters.filter((i) => i.id !== e.id);
    handleFilterChange(newFilters);
  }

  const addFilterButton = (
    <Button
      className="flex font-semibold items-center cursor-pointer border rounded-lg py-2 px-2 space-x-1 text-xs bg-white "
      id="1"
      type="light"
    >
      + Add Filter
    </Button>
  );

  const options = useMemo(() => props.filters, [props.options?.length]);

  function handleChange(e) {
    const filterData = filters.map((i) => {
      if (e.id === i.id) {
        return e;
      }
      return i;
    });
    handleFilterChange(filterData);
  }

  return (
    <div className="mt-2 flex items-center">
      <div className="mr-2">
        <Dropdown
          closeOnClick
          align="left"
          data={options}
          itemClick={addFilter}
          trigger={addFilterButton}
        />
      </div>
      <div className="flex items-center w-full space-x-2">
        {filters.map((item, idx) => {
          const defaultItem = props.filters.find((i) => i.id === item.id);
          const itemData = {
            id: item.id,
            type: item.type,
            label: item.label,
            content: item.content,
            defaultText: defaultItem.defaultText,
          };

          function dropdown(close) {
            const Component = item.content;
            return item.content ? (
              <Component
                close={close}
                value={item}
                onChange={(e) => {
                  handleChange({
                    displayText: e.title,
                    value: e.value,
                    ...itemData,
                  });
                }}
              />
            ) : (
              <RichDatePickerPanel
                close={close}
                value={item}
                onChange={(e) =>
                  handleChange({
                    displayText: e.title,
                    value: e.value,
                    ...itemData,
                    type: e.type,
                  })
                }
              />
            );
          }

          // const dropdown = item.content ? (
          //   (() => {
          //     const Component = item.content;
          //     return (
          //       <Component
          //         value={itemData}
          //         onChange={(e) => {
          //           handleChange({
          //             displayText: e.title,
          //             value: e.value,
          //             ...itemData,
          //           });
          //         }}
          //       />
          //     );
          //   })()
          // ) : (
          //   <RichDatePickerPanel
          //     value={item}
          //     onChange={(e) =>
          //       handleChange({
          //         displayText: e.title,
          //         value: e.value,
          //         ...itemData,
          //         type: e.type,
          //       })
          //     }
          //   />
          // );

          return (
            <FilterItem
              key={idx}
              dropdown={dropdown}
              title={item.label}
              value={item.value}
              displayText={item.displayText || item.defaultText}
              remove={() => removeFilter(item)}
            />
          );
        })}
      </div>
    </div>
  );
};
