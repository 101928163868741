import { useState } from "react";
import { AssignedToSelect } from "./AssignedToSelect";
import { IEmployee } from "@/types";

interface AssignUserFormProps {
  updateForm: (data: { id: string; value: string[] }) => void;
  employees: IEmployee[];
  handleLoadMoreEmployees: () => void;
  handleEmployeesSearch: (term: string) => void;
  employeesQuery?: {
    hasNext?: boolean;
  };
  loading: boolean;
}

export const AssignUserForm = ({
  updateForm,
  employees,
  handleLoadMoreEmployees,
  handleEmployeesSearch,
  employeesQuery,
  loading,
}: AssignUserFormProps) => {
  const [assignedUsers, setAssignedUsers] = useState<IEmployee[]>([]);

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="mb-4 text-gray-800">
        Please ensure a technician is assigned before proceeding to the next
        step.
      </p>

      <AssignedToSelect
        onChange={(value: IEmployee[]) => {
          setAssignedUsers(value);
          updateForm({
            id: "assignedTo",
            value: value.map((i) => i._id || i.id),
          });
        }}
        value={assignedUsers}
        users={employees.map((emp) => ({
          ...emp,
          id: emp._id,
        }))}
        isEditable={true}
        onLoadMore={handleLoadMoreEmployees}
        hasMore={employeesQuery?.hasNext}
        loading={loading}
        onSearch={handleEmployeesSearch}
      />
    </div>
  );
};
