import React, { useEffect, useRef, useState } from "react";
import NewestJobs from "./NewestJobs";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard, resetDashboard } from "../../redux/reducers/dashboard";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/Dropdown";
import FeedbackCard from "./FeedbackCard";
import { SidebarContainer } from "../../components";
import { TotalsOverView } from "./TotalsOverView";
import { JobStatusReport } from "./JobStatusReport";
import { useConfig } from "../../hooks";
import { InvoiceReport } from "./InvoiceReport";
import LatestInvoices from "./LatestInvoices";
const Dashboard = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { status, dashboard } = useSelector((state) => state.dashboard);
  const { appType } = useConfig();

  const formTypeOptions = [
    { value: "daily", label: "Todays" },
    { value: "weekly", label: "This Weeks" },
    { value: "monthly", label: "This Months" },
    { value: "yearly", label: "This Years" },
  ];
  const [viewType, setViewType] = useState(formTypeOptions[1]);

  function onFormChange(e) {
    setViewType(e);
    dispatch(resetDashboard());
  }

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        getDashboard({
          type: viewType.value,
        })
      );
    }
  }, [status, viewType]);

  useEffect(() => {
    return () => dispatch(resetDashboard());
  }, []);

  function renderLabel(str, type) {
    return `${type.label} ${str}`;
  }

  const formTypeTrigger = (
    <div className="flex items-center cursor-pointer font-semibold">
      {renderLabel("Data", viewType)}
      <ChevronDownIcon className="h-3 w-3 ml-1" />
    </div>
  );

  const actions = (
    <div>
      <Dropdown
        align="left"
        closeOnClick
        trigger={formTypeTrigger}
        data={formTypeOptions}
        itemClick={onFormChange}
      />
    </div>
  );

  const loading = status === "loading";

  const feedbacks =
    user.authority === "superAdmin" &&
    dashboard.feedbacks &&
    dashboard.feedbacks?.map((i) => {
      return (
        <FeedbackCard
          key={i._id}
          i={i}
          link={i._id}
          title={i.type}
          body={i.messages}
          color="orange"
          loading={loading}
        />
      );
    });

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = () => {
    return (
      <div ref={headerRef} className="p-2 border-b">
        {actions}
      </div>
    );
  };

  const isAdmin =
    user.authority === "admin" ||
    user.authority === "owner" ||
    user.authority === "superAdmin";

  return (
    <SidebarContainer header={renderHeader} hideSidebar className="w-full">
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = {
          height: `${parentHeight - offsetTop - offsetHeight}px`,
        };

        return (
          <div
            style={wrapperStyle}
            className="px-3 pb-3  overflow-y-auto w-full "
          >
            {/* {actions} */}
            {/* grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-2 md:gap-3 */}
            <div className="my-3 space-y-2">
              {isAdmin && <TotalsOverView loading={loading} data={dashboard} />}
              {isAdmin && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {/* <div className="col-span-2 md:col-span-4 lg:col-span-6 "> */}

                  {/* </div> */}
                  <JobStatusReport
                    viewType={viewType.value}
                    appType={appType}
                  />
                  <InvoiceReport viewType={viewType.value} appType={appType} />

                  <NewestJobs
                    title="Jobs"
                    status={status}
                    jobs={dashboard.jobs || []}
                  />
                  <LatestInvoices
                    title="Invoices"
                    status={status}
                    invoices={dashboard.invoices || []}
                  />
                </div>
              )}
              {/* {feedbacks} */}
              {/* {user.authority === "admin" && "superAdmin" && chartsTwo} */}
              {/* {user.authority === "superAdmin" && chartsTwo} */}
            </div>
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default Dashboard;
