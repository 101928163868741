import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../shared/Card";
import { useAuth } from "../../../hooks/useAuth";
import { useConfig, useFetchAll } from "../../../hooks";
import ClientInvoiceChart from "./ClientInvoiceChart";
import {
  clientJobsStage,
  clientInvoiceStage,
} from "../../../constants/aggregate";
import { renderAmount } from "../../../utils";
import Tabs from "../../../shared/Tabs";
import ClientSettings from "./Settings";
import ClientLocationsIndex from "../../Locations";
import ClientInvoicesTable from "./ClientInvoicesTable";
import ClientEquipmentIndex from "./ClientEquipmentIndex";
import request from "../../../utils/request";
import ClientJobsTab from "./ClientJobsTab";
import { SidebarContainer } from "../../../components";
import { useCrumbs } from "../../../hooks/useCrumbs";
import ClientJobChart from "./ClientJobChart";
import { EditableText } from "../../../shared";
import { useGetEquipmentQuery } from "../../../redux/services/equipment";
import { useGetClientQuery } from "../../../redux/services/clients/clientsApi";
import { useGetJobsQuery } from "@/redux/services/jobs/jobsApi";
import { useGetInvoicesQuery } from "@/redux/services/invoices/invoicesApi";

const ClientShow = (props) => {
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);
  const [invoiceReportData, setInvoiceReportData] = useState([]);

  const { user } = useAuth();
  const crumbs = useCrumbs();
  const params = useParams();
  const { appType } = useConfig();

  // Get client data
  const { data: clientData, isLoading: isClientLoading } = useGetClientQuery({
    id: params.id,
  });
  const client = clientData || {};

  const {
    queryResult: { data: jobsData, isLoading: isJobsLoading },
    pagination: { pageIndex, pageSize, setPagination },
    search: { handleGlobalSearch, value: searchValue },
  } = useFetchAll({
    queryHook: useGetJobsQuery,
    defaultPageSize: 20,
    defaultFilters: params.id ? [{ id: "client", value: params.id }] : [],
  });

  const jobs = jobsData?.jobs || [];

  // Get equipment data
  const {
    queryResult: { data: equipmentData, isLoading: isEquipmentLoading },
  } = useFetchAll({
    queryHook: useGetEquipmentQuery,
    defaultPageSize: 20,
    defaultFilters: params.id ? [{ id: "client", value: params.id }] : [],
  });

  // Get invoices data
  const {
    queryResult: { data: invoiceData, isLoading: isInvoiceLoading },
    pagination: {
      pageIndex: invoicePageIndex,
      pageSize: invoicePageSize,
      setPagination: setInvoicePagination,
    },
    search: {
      handleGlobalSearch: handleInvoiceGlobalSearch,
      value: invoiceSearchValue,
    },
  } = useFetchAll({
    queryHook: useGetInvoicesQuery,
    defaultPageSize: 20,
    defaultFilters: params.id ? [{ id: "client", value: params.id }] : [],
  });
  const invoices = invoiceData?.invoices || [];

  useEffect(() => {
    if (client._id) {
      crumbs.updateCrumbName({
        name: client.name,
        path: "/clients/" + client._id,
      });
      crumbs.setLoading(false);
    }
  }, [client._id]);

  function calculateOutstandingBalance() {
    if (invoiceReportData && invoiceReportData.length > 0) {
      return invoiceReportData.reduce(
        (sum, report) => sum + Number(report.OutstandingAmount || 0),
        0
      );
    }
    const invoiceMap = invoices;
    // Fallback to existing calculation
    return invoiceMap
      .filter((invoice) => invoice.status !== "paid")
      .reduce((sum, invoice) => sum + Number(invoice.total), 0);
  }

  function calculateTotalRevenue() {
    if (invoiceReportData && invoiceReportData.length > 0) {
      return invoiceReportData.reduce(
        (sum, report) => sum + Number(report.TotalAmount || 0),
        0
      );
    }
    // Fallback to existing calculation using jobs
    return jobs
      .map((item) => item.totalCharges)
      .reduce((sum, amount) => Number(sum) + Number(amount), 0);
  }

  // Replace renderTotalGrosss with calculateTotalRevenue
  const totalGross = calculateTotalRevenue();

  function onSave(data) {
    // dispatch(
    //   updateClient({ data, parentId: user.parentCompany, clientId: client._id })
    // );
  }

  const fetchReport = async () => {
    const stages = clientJobsStage(
      appType === "serviceRepair" ? "appointmentDate" : "transactionDate",
      user.parentCompany,
      params.id,
      appType
    );

    const invoiceStages = clientInvoiceStage(
      "dueDate",
      user.parentCompany,
      params.id,
      appType
    );

    const response = await request(`/reports`, {
      method: "post",
      data: {
        stages,
        modelName: "Job",
        config: {
          0: {
            type: "$match",
            _id: "client",
          },
        },
      },
    });
    const invoiceResponse = await request(`/reports`, {
      method: "post",
      data: {
        stages: invoiceStages,
        modelName: "Invoice",
        config: {
          0: {
            type: "$match",
            _id: "client",
          },
        },
      },
    });
    return Promise.all([response, invoiceResponse]).then((values) => {
      const finalData = values[0]?.data;
      const invoiceData = values[1]?.data;

      setReportData(finalData);
      setInvoiceReportData(invoiceData);
    });
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const tabs = useMemo(
    () => [
      {
        id: "Info",
        content: (
          <div className="p-3 space-y-4">
            {/* Metrics Row */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <Card className="p-3">
                <h3 className="text-sm font-medium text-gray-600">
                  Total Jobs
                </h3>
                <p className="text-2xl font-semibold mt-1">{jobsData?.count}</p>
              </Card>
              <Card className="p-3">
                <h3 className="text-sm font-medium text-gray-600">
                  Total Revenue
                </h3>
                <p className="text-2xl font-semibold mt-1">
                  {renderAmount(totalGross)}
                </p>
              </Card>
              <Card className="p-3">
                <h3 className="text-sm font-medium text-gray-600">
                  Outstanding Balance
                </h3>
                <p className="text-2xl font-semibold mt-1">
                  {renderAmount(calculateOutstandingBalance())}
                </p>
              </Card>
            </div>

            {/* Charts Row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card className="h-full">
                <ClientJobChart data={reportData} appType={appType} />
              </Card>
              <Card className="h-full">
                <ClientInvoiceChart
                  data={invoiceReportData}
                  appType={appType}
                />
              </Card>
            </div>
          </div>
        ),
      },
      {
        id: "Jobs",
        content: (
          <ClientJobsTab
            jobs={jobs}
            client={client}
            loading={isJobsLoading}
            pagination={{
              pageIndex: pageIndex,
              pageSize: pageSize,
              hasNext: jobsData?.hasNext,
              hasPrev: jobsData?.hasPrev,
              totalPages: jobsData?.totalPages,
              count: jobsData?.count,
              nextPage: jobsData?.nextPage,
              prevPage: jobsData?.prevPage,
              pagingCounter: jobsData?.pagingCounter,
            }}
            setPagination={setPagination}
            handleGlobalSearch={handleGlobalSearch}
          />
        ),
      },
      {
        id: "Equipment",
        content: (
          <ClientEquipmentIndex
            equipment={equipmentData?.equipment}
            client={client}
            loading={isEquipmentLoading}
          />
        ),
      },
      {
        id: "Locations",
        content: <ClientLocationsIndex />,
        scrollable: false,
      },
      {
        id: "Invoices",
        content: (
          <ClientInvoicesTable
            loading={isInvoiceLoading}
            pagination={{
              pageIndex: invoicePageIndex,
              pageSize: invoicePageSize,
              hasNext: invoiceData?.hasNext,
              hasPrev: invoiceData?.hasPrev,
              totalPages: invoiceData?.totalPages,
              count: invoiceData?.count,
              nextPage: invoiceData?.nextPage,
              prevPage: invoiceData?.prevPage,
              pagingCounter: invoiceData?.pagingCounter,
            }}
            setPagination={setInvoicePagination}
            handleGlobalSearch={handleInvoiceGlobalSearch}
            invoices={invoices}
            client={client}
          />
        ),
      },
      {
        id: "Settings",
        content: <ClientSettings client={client} />,
      },
    ],
    [
      client,
      jobs,
      equipmentData,
      invoices,
      invoiceData,
      isInvoiceLoading,
      invoicePageIndex,
      invoicePageSize,
      setInvoicePagination,
      handleInvoiceGlobalSearch,
    ]
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  // // const actions = (
  // //   <>
  // //     <span className="mr-5">Created: {formatDate(client.dateCreated)}</span>
  // //     <span>Updated: {formatDate(client.dateUpdated)}</span>
  // //   </>
  // // );

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="name"
        value={client.name}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        // loading={modalLoad}
        onSave={(e) => onSave({ ...e.data, name: e.value })}
      />
    </div>
  );

  return (
    <SidebarContainer
      title={title}
      header={renderHeader}
      // actions={actions}
      // sidebarContent={sidebarContent}
      hideSidebar
      // hideHeader
      // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <Tabs
            className={`w-full overflow-hidden`}
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            // panelClasses={`border-t overflow-y-scroll`}
            tabListClasses="bg-white px-2 h-[38px]"
            tabclassName="mx-1"
            id="invoice-show"
            onClick={(_e: any, _item: any, path: any) => navigate(path)}
            data={tabs}
          />
        );
      }}
    </SidebarContainer>
  );
};

export default ClientShow;
