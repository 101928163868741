import { useEffect } from "react";

export const usePerformanceMonitor = (componentName) => {
  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      for (const entry of list.getEntries()) {
        if (entry.duration > 100) {
          // Log long tasks
          console.warn(`Long task detected in ${componentName}:`, {
            duration: entry.duration,
            startTime: entry.startTime,
            name: entry.name,
          });
        }
      }
    });

    observer.observe({ entryTypes: ["longtask"] });

    return () => observer.disconnect();
  }, [componentName]);
};
