import { useDeleteMediaMutation } from "../redux/services/media/mediaApi";
import ListView from "./ListView";
import { Button, Modal } from "../shared";
import { useState } from "react";

export const AttachmentsView = ({
  attachments,
  fileUploaded,
  onDelete,
  onReplace,
  onEdit,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imageAttachments =
    attachments?.filter((item) => item.mimetype?.startsWith("image/")) || [];

  const handleFileOpen = (file) => {
    // Create an input element for file selection
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*"; // Accept all file types
    input.multiple = true;

    // Trigger the file input click
    input.click();

    // Handle file selection
    input.onchange = (e) => {
      const selectedFiles = e.target.files;
      if (selectedFiles) {
        fileUploaded(selectedFiles);
      }
    };
  };

  const handleEdit = (item) => {};

  const handleReplace = (item) => {};

  const handleDelete = (item) => {
    onDelete(item);
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };

  const confirmDelete = (item) => {
    setSelectedItem(item);
    setDeleteModalVisible(true);
  };

  const handleImageClick = (item) => {
    const index = imageAttachments.findIndex((img) => img.id === item.id);
    setCurrentImageIndex(index);
    setPreviewModalVisible(true);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? imageAttachments.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === imageAttachments.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <>
      <ListView
        items={attachments}
        onClick={handleFileOpen}
        title="Attachments"
        buttonTitle="Upload File"
        hideButton={true}
        subheader={
          attachments?.length > 0 && (
            <div className="border-t border-gray-200 pt-2">
              <div className="flex justify-between text-sm text-gray-600">
                <span>Total Files</span>
                <span>{attachments?.length} files</span>
              </div>
            </div>
          )
        }
        layout={({ items }) => (
          <div className="grid grid-cols-4 gap-3">
            {items.map((item) => (
              <div
                key={item.id}
                className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden"
              >
                <div
                  className="relative h-32 cursor-pointer"
                  onClick={() =>
                    item.mimetype?.startsWith("image/") &&
                    handleImageClick(item)
                  }
                >
                  {item.mimetype?.startsWith("image/") ? (
                    <img
                      src={item.url}
                      alt={item.name}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      <svg
                        className="h-8 w-8 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </div>
                  )}
                  <div className="absolute top-1 right-1">
                    <button
                      className="p-1 bg-white rounded-full shadow hover:bg-red-100"
                      title="Delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(item);
                      }}
                    >
                      <svg
                        className="h-3 w-3 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="p-2 border border-gray-200 border-t-0">
                  <p className="text-xs font-medium text-gray-900 truncate">
                    {item.filename}
                  </p>
                  <p className="text-xs text-gray-500">
                    {(item.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      />
      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setSelectedItem(null)}
        confirm={() => handleDelete(selectedItem)}
        title="Confirm Delete"
      >
        <div className="p-4">
          <p className="text-sm text-gray-600 mb-4">
            Are you sure you want to delete "{selectedItem?.filename}"? <br />{" "}
            This action cannot be undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        onClose={() => setEditModalVisible(false)}
        title="Edit Attachment"
      >
        {selectedItem && (
          <div className="p-4">
            <div className="mb-4">
              <label
                htmlFor="filename"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Filename
              </label>
              <input
                type="text"
                id="filename"
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={selectedItem.filename}
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    filename: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex justify-end gap-2">
              <Button type="alternative" onClick={() => setSelectedItem(null)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSave(selectedItem);
                  setSelectedItem(null);
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        visible={previewModalVisible}
        setVisible={setPreviewModalVisible}
        onClose={() => setPreviewModalVisible(false)}
        size="lg"
        hideFooter={true}
      >
        <div className="relative">
          <img
            src={imageAttachments[currentImageIndex]?.url}
            alt={imageAttachments[currentImageIndex]?.filename}
            className="w-full h-full object-contain max-h-[80vh]"
          />
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
            onClick={handlePrevImage}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
            onClick={handleNextImage}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AttachmentsView;
