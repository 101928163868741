import { api } from "../../api";

export const settingsApi = api.injectEndpoints({
  tagTypes: ["EmailConfig"],
  endpoints: (builder) => ({
    getEmailConfig: builder.query({
      query: () => ({
        url: "/customers/email-config",
        method: "GET",
      }),
      providesTags: ["EmailConfig"],
    }),

    updateEmailConfig: builder.mutation({
      query: (data) => ({
        url: "/customers/email-config",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EmailConfig"],
    }),
  }),
});

export const { useGetEmailConfigQuery, useUpdateEmailConfigMutation } =
  settingsApi;
