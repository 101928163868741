import React, { useState, useEffect } from "react";
import {
  useGetPerformanceLogsQuery,
  useGetPerformanceSummaryQuery,
  useGetIndustryStatsQuery,
} from "../../../redux/services/performance/performanceApi";
import { Card } from "../../../shared/Card";
import { DateRangePicker } from "../../../shared/DatePicker/DateRange";
import { Select } from "../../../shared/Select";
import { renderAmount } from "../../../utils";
import { Table } from "@/shared";
import { SidebarContainer } from "@/components";
import { ENVIRONMENT_OPTIONS, INDUSTRY_OPTIONS } from "@/constants/select";
import { usePerformanceMonitor } from "../../../hooks/usePerformanceMonitor";
import {
  renderColumnDefs,
  renderCustomColumnDefs,
} from "@/shared/Table/table.utils";

const PerformanceMonitor = () => {
  // Add performance monitoring
  usePerformanceMonitor("PerformanceMonitor");

  const [filters, setFilters] = useState({
    page: 1,
    limit: 25,
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // Last 7 days
    endDate: new Date(),
    industry: "",
    environment: "",
    minDuration: 1000, // Show slow requests by default
  });

  const debouncedFilters = useDebounce(filters, 500);

  const { data: logsData, isLoading: isLogsLoading } =
    useGetPerformanceLogsQuery(debouncedFilters);
  const { data: summaryData, isLoading: isSummaryLoading } =
    useGetPerformanceSummaryQuery({
      startDate: filters.startDate,
      endDate: filters.endDate,
      industry: filters.industry,
      environment: filters.environment,
    });
  const { data: industryStats, isLoading: isIndustryLoading } =
    useGetIndustryStatsQuery({
      startDate: filters.startDate,
      endDate: filters.endDate,
    });

  const columnDefinitions = [
    {
      id: "timestamp",
      header: "Time",
      cell: ({ getValue }) => new Date(getValue()).toLocaleString(),
    },
    {
      id: "url",
      header: "Endpoint",
      cell: ({ getValue }) => (
        <div className="text-primary-500 hover:underline cursor-pointer">
          {getValue()}
        </div>
      ),
    },
    {
      id: "method",
      header: "Method",
      cell: ({ getValue }) => (
        <span
          className={`px-2 py-1 rounded text-xs ${
            getValue() === "GET"
              ? "bg-green-100 text-green-800"
              : getValue() === "POST"
              ? "bg-blue-100 text-blue-800"
              : getValue() === "PUT"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {getValue()}
        </span>
      ),
    },
    {
      id: "duration",
      header: "Duration (ms)",
      cell: ({ getValue }) => (
        <span className={getValue() > 1000 ? "text-red-500" : "text-green-500"}>
          {getValue()}
        </span>
      ),
    },
    {
      id: "statusCode",
      header: "Status",
      cell: ({ getValue }) => (
        <span
          className={`px-2 py-1 rounded text-xs ${
            getValue() >= 500
              ? "bg-red-100 text-red-800"
              : getValue() >= 400
              ? "bg-yellow-100 text-yellow-800"
              : "bg-green-100 text-green-800"
          }`}
        >
          {getValue()}
        </span>
      ),
    },
    {
      id: "industry",
      header: "Industry",
      cell: ({ getValue }) => getValue() || "N/A",
    },
    {
      id: "route",
      header: "Route",
      cell: ({ getValue }) => getValue() || "N/A",
    },
    {
      id: "userId",
      header: "User ID",
      cell: ({ getValue }) => getValue() || "Anonymous",
    },
    {
      id: "tenant",
      header: "Tenant",
      cell: ({ getValue }) => getValue() || "N/A",
    },
    {
      id: "environment",
      header: "Environment",
      cell: ({ getValue }) => (
        <span className="px-2 py-1 rounded text-xs bg-gray-100">
          {getValue()}
        </span>
      ),
    },
    {
      id: "memoryUsage",
      header: "Memory (MB)",
      cell: ({ getValue }) =>
        getValue() ? (getValue() / (1024 * 1024)).toFixed(2) : "N/A",
    },
    {
      id: "responseSize",
      header: "Response Size (KB)",
      cell: ({ getValue }) =>
        getValue() ? (getValue() / 1024).toFixed(2) : "N/A",
    },
  ];

  return (
    <SidebarContainer hideSidebar hideHeader>
      {({ wrapperStyle, panelStyle }) => (
        <div style={wrapperStyle} className="space-y-6 p-4">
          <div style={panelStyle}>
            {/* Filters */}
            <Card className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <DateRangePicker
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onChange={(start, end) =>
                    setFilters((prev) => ({
                      ...prev,
                      startDate: start,
                      endDate: end,
                    }))
                  }
                />
                <Select
                  label="Industry"
                  value={filters.industry}
                  onChange={(value) =>
                    setFilters((prev) => ({ ...prev, industry: value }))
                  }
                  options={INDUSTRY_OPTIONS}
                />
                <Select
                  label="Environment"
                  value={filters.environment}
                  onChange={(value) =>
                    setFilters((prev) => ({ ...prev, environment: value }))
                  }
                  options={ENVIRONMENT_OPTIONS}
                />
                <div className="flex items-end">
                  <button
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        minDuration: prev.minDuration ? 0 : 1000,
                      }))
                    }
                    className={`px-4 py-2 rounded ${
                      filters.minDuration
                        ? "bg-red-500 text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {filters.minDuration
                      ? "Show All Requests"
                      : "Show Slow Requests Only"}
                  </button>
                </div>
              </div>
            </Card>

            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <Card className="p-4">
                <h3 className="text-sm font-medium text-gray-600">
                  Total Requests
                </h3>
                <p className="text-2xl font-semibold mt-1">
                  {logsData?.metrics?.totalRequests || 0}
                </p>
              </Card>
              <Card className="p-4">
                <h3 className="text-sm font-medium text-gray-600">
                  Average Duration
                </h3>
                <p className="text-2xl font-semibold mt-1">
                  {logsData?.metrics?.avgDuration || 0}ms
                </p>
              </Card>
              <Card className="p-4">
                <h3 className="text-sm font-medium text-gray-600">
                  Slow Requests
                </h3>
                <p className="text-2xl font-semibold mt-1 text-red-500">
                  {logsData?.metrics?.slowRequests || 0}
                </p>
              </Card>
              <Card className="p-4">
                <h3 className="text-sm font-medium text-gray-600">
                  Max Duration
                </h3>
                <p className="text-2xl font-semibold mt-1">
                  {logsData?.metrics?.maxDuration || 0}ms
                </p>
              </Card>
            </div>

            {/* Industry Stats */}
            <Card className="p-4">
              <h3 className="text-lg font-semibold mb-4">
                Performance by Industry
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {(industryStats || {})?.map((stat) => (
                  <div key={stat.industry} className="border rounded p-4">
                    <h4 className="font-medium">{stat.industry || "N/A"}</h4>
                    <div className="mt-2 space-y-1">
                      <p>Requests: {stat.count}</p>
                      <p>Avg Duration: {stat.avgDuration}ms</p>
                      <p>Slow Requests: {stat.slowRequests}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Card>

            {/* Performance Logs Table */}
            <Card className="p-4">
              <Table
                id="performance-logs"
                loading={isLogsLoading}
                columns={renderCustomColumnDefs(columnDefinitions)}
                data={logsData?.logs || []}
                pagination={logsData?.pagination}
                pageIndex={filters.page - 1}
                pageSize={filters.limit}
                onPaginationChange={(pagination) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: pagination.pageIndex + 1,
                  }))
                }
                manualPagination={true}
                virtualized={true}
                rowHeight={40}
              />
            </Card>
          </div>
        </div>
      )}
    </SidebarContainer>
  );
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default PerformanceMonitor;
