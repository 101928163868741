import classNames from "classnames";
import { TotalCharges } from "./TotalCharges";
import { TotalRow } from "./TotalRow";

export const Receipt = ({ list = { items: [], total: 0 }, className }) => {
  const wrapperClassName = classNames("space-y-3", className);
  return (
    <div className={wrapperClassName}>
      {list.items.map((item, index) => (
        <TotalRow key={index} {...item} />
      ))}
      <TotalRow
        className="space-y-0 pt-4 mt-2 border-t"
        label="Subtotal"
        amount={list.items
          .map((item) => item.amount)
          .reduce((a, b) => a + b, 0)}
        content={list.items}
        defaultOpen={true}
      />
      <TotalCharges totalCharges={list.total} />
    </div>
  );
};
