import React, { useState, useEffect } from "react";
import { Button, Modal, Popover, Spinner } from "../../../shared";
import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  PlusIcon,
  DocumentDuplicateIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import {
  useGetPdfTemplatesQuery,
  useDeletePdfTemplateMutation,
  useGeneratePDFMutation,
} from "@/redux/services/pdf/pdfTemplateApi";
import PDFTemplateModal from "./PDFTemplateModal";
import PDFPreviewModal from "./PDFPreviewModal";
import DOMPurify from "dompurify";
import Handlebars from "handlebars/dist/handlebars.min.js";
import { getSampleData } from "./utils/sampleData";

const PDFTemplates = () => {
  const [templateModal, setTemplateModal] = useState({
    visible: false,
    template: null,
    mode: "create", // create, edit, duplicate
  });
  const [previewModal, setPreviewModal] = useState({
    visible: false,
    template: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    template: null,
  });
  const [organizedTemplates, setOrganizedTemplates] = useState({
    workOrder: [],
    jobProposal: [],
    invoice: [],
  });

  const { data: templatesData, isLoading, error } = useGetPdfTemplatesQuery({});

  const [deleteTemplate, { isLoading: isDeleting }] =
    useDeletePdfTemplateMutation();

  const [generatePDF, { isLoading: isGeneratingPDF }] =
    useGeneratePDFMutation();

  useEffect(() => {
    if (templatesData) {
      const templates = Array.isArray(templatesData)
        ? templatesData
        : templatesData.templates || [];

      const organized = {
        workOrder: [],
        jobProposal: [],
        invoice: [],
      };

      // Then add regular templates
      templates.forEach((template) => {
        const type = template.type;
        if (organized.hasOwnProperty(type)) {
          organized[type].push(template);
        } else {
          console.warn(`Unknown template type: ${type}`, template);
        }
      });

      // Sort each category's templates
      Object.keys(organized).forEach((type) => {
        organized[type].sort((a, b) => {
          if (a.isSystemDefault && !b.isSystemDefault) return -1;
          if (!a.isSystemDefault && b.isSystemDefault) return 1;
          if (a.isDefault && !b.isDefault) return -1;
          if (!a.isDefault && b.isDefault) return 1;
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      });

      setOrganizedTemplates(organized);
    }
  }, [templatesData]);

  const handleDelete = async () => {
    try {
      await deleteTemplate(deleteModal.template._id);
      setDeleteModal({ visible: false, template: null });
    } catch (error) {
      console.error("Delete template error:", error);
    }
  };

  const templateCategories = [
    { type: "workOrder", label: "Work Orders", icon: "🔧" },
    { type: "jobProposal", label: "Job Proposals", icon: "📋" },
    { type: "invoice", label: "Invoices", icon: "💰" },
  ];

  const handleDuplicateTemplate = (template) => {
    setTemplateModal({
      visible: true,
      template: {
        ...template,
        name: `Copy of ${template.name}`,
        isDefault: false,
        isSystemDefault: false,
      },
      mode: "duplicate",
    });
  };

  const templateExamples = {
    workOrder: {
      name: "Work Order Example",
      description:
        "See how work orders are formatted with your company details, customer information, service items, and signatures.",
      icon: "🔧",
    },
    jobProposal: {
      name: "Job Proposal Example",
      description:
        "Preview how proposals look with project details, pricing breakdowns, terms and conditions.",
      icon: "📋",
    },
    invoice: {
      name: "Invoice Example",
      description:
        "View sample invoice layout with line items, taxes, totals, and payment information.",
      icon: "💰",
    },
  };

  const renderExampleCard = (type) => (
    <div className="flex flex-col items-center justify-center h-full min-h-[200px] rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 p-4 hover:bg-blue-100 transition-colors">
      <div className="text-3xl mb-3">{templateExamples[type].icon}</div>
      <h3 className="text-sm font-medium text-blue-700 mb-2">
        {templateExamples[type].name}
      </h3>
      <p className="text-xs text-blue-600 text-center">
        {templateExamples[type].description}
      </p>
      <Button
        variant="ghost"
        size="sm"
        className="mt-4 text-blue-700"
        onClick={() =>
          setPreviewModal({
            visible: true,
            template: { type, isExample: true },
          })
        }
      >
        <EyeIcon className="w-4 h-4 mr-2" />
        View Example
      </Button>
    </div>
  );

  // Register the same helpers as backend
  Handlebars.registerHelper("formatDate", function (date) {
    if (!date) return "";
    return new Date(date).toLocaleDateString();
  });

  Handlebars.registerHelper("formatMoney", function (amount) {
    if (typeof amount !== "number") return "0.00";
    return amount.toFixed(2);
  });

  const renderTemplatePreview = (template) => {
    try {
      // Get sample data for this template type
      const sampleData = getSampleData(template.type);

      // Compile template
      const compiledTemplate = Handlebars.compile(template.html);

      // Render with sample data
      const html = compiledTemplate(sampleData);

      // Wrap the HTML in a proper document structure with A4 formatting
      const wrappedHtml = `
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              /* PDF-specific reset and base styles */
              * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
              }
              
              body {
                font-family: "Helvetica", sans-serif;
                line-height: 1.6;
                color: #333;
                background: #fff;
              }

              .pdf-page {
                width: 210mm; /* A4 width */
                min-height: 297mm; /* A4 height */
                padding: 20mm; /* Standard margins */
                margin: 0 auto;
                background: white;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
              }

              /* Make sure template CSS takes precedence */
              ${template.css}
            </style>
          </head>
          <body>
            <div class="pdf-page">
              ${html}
            </div>
          </body>
        </html>
      `;

      return DOMPurify.sanitize(wrappedHtml);
    } catch (error) {
      console.error("Template preview error:", error);
      return '<div class="text-red-500">Error previewing template</div>';
    }
  };

  const renderTemplateCard = (template) => (
    <div
      key={template._id}
      className="bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow relative"
    >
      {/* System Default Badge */}
      {template.isSystemDefault && (
        <div className="absolute -top-2 -right-2">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 border border-purple-200">
            System Default
          </span>
        </div>
      )}

      {/* Template Header */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="font-medium text-gray-900">{template.name}</h3>
            <p className="text-sm text-gray-500 line-clamp-2 mt-1">
              {template.description || "No description provided"}
            </p>
            {template.version && (
              <p className="text-xs text-gray-400 mt-1">
                Version {template.version}
              </p>
            )}
          </div>
          {template.isDefault && !template.isSystemDefault && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              Default
            </span>
          )}
        </div>
      </div>

      {/* Template Preview */}
      <div className="relative w-full h-[200px] overflow-hidden border-b border-gray-200">
        {/* Isolated scope for template styles */}
        <div className="template-preview-scope">
          {/* Render template HTML with sample data */}
          <div
            className="absolute inset-0 transform scale-[0.25] origin-top-left"
            style={{
              width: "400%", // Increased to accommodate A4 proportions
              height: "400%",
            }}
          >
            <div
              className="w-full h-full"
              dangerouslySetInnerHTML={{
                __html: renderTemplatePreview(template),
              }}
            />
          </div>
        </div>
        {/* Overlay gradient */}
        <div className="absolute inset-x-0 bottom-0 h-20 bg-gradient-to-t from-white to-transparent" />
      </div>

      {/* Action Buttons */}
      <div className="flex items-center justify-end space-x-2 p-4 bg-gray-50 border-t border-gray-200">
        <Popover
          content="Preview"
          hover
          trigger={
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setPreviewModal({ visible: true, template })}
            >
              <EyeIcon className="w-4 h-4" />
            </Button>
          }
        />

        <Popover
          content="Download PDF Example"
          hover
          trigger={
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleGeneratePDF(template)}
              disabled={isGeneratingPDF}
            >
              {isGeneratingPDF ? (
                <Spinner size="sm" className="w-4 h-4" />
              ) : (
                <DocumentArrowDownIcon className="w-4 h-4" />
              )}
            </Button>
          }
        />

        {template.isSystemDefault ? (
          <Popover
            content="Duplicate"
            hover
            trigger={
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleDuplicateTemplate(template)}
              >
                <DocumentDuplicateIcon className="w-4 h-4" />
              </Button>
            }
          />
        ) : (
          <>
            <Popover
              content="Edit"
              hover
              trigger={
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    setTemplateModal({ visible: true, template, mode: "edit" })
                  }
                >
                  <PencilIcon className="w-4 h-4" />
                </Button>
              }
            />
            <Popover
              content="Delete"
              hover
              trigger={
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-red-600 hover:text-red-700"
                  onClick={() => setDeleteModal({ visible: true, template })}
                  disabled={isDeleting}
                >
                  <TrashIcon className="w-4 h-4" />
                </Button>
              }
            />
          </>
        )}
      </div>
    </div>
  );

  const renderNewTemplateCard = (type) => (
    <button
      onClick={() =>
        setTemplateModal({ visible: true, template: { type }, mode: "create" })
      }
      className="flex flex-col items-center justify-center h-full min-h-[200px] rounded-lg border-2 border-dashed border-gray-300 bg-white p-4 hover:border-gray-400 hover:bg-gray-50 transition-colors"
    >
      <PlusIcon className="w-8 h-8 text-gray-400" />
      <span className="mt-2 text-sm font-medium text-gray-600">
        New Template
      </span>
    </button>
  );

  const handleGeneratePDF = async (template) => {
    const startTime = performance.now();
    console.log("🚀 Starting PDF generation request:", {
      templateId: template._id,
      templateName: template.name,
      templateType: template.type,
      timestamp: new Date().toISOString(),
    });

    try {
      const sampleData = getSampleData(template.type);
      console.log("📦 Request payload:", {
        data: {
          templateId: template._id,
          data: sampleData,
        },
        options: {
          type: template.type,
          format: "A4",
          orientation: "portrait",
        },
      });

      const response = await generatePDF({
        data: {
          templateId: template._id,
          data: sampleData,
        },
        options: {
          type: template.type,
          format: "A4",
          orientation: "portrait",
        },
      }).unwrap();

      if (!response?.url) {
        throw new Error("No PDF URL in response");
      }

      console.log("✅ PDF generated successfully:", {
        status: "success",
        responseType: typeof response,
        hasUrl: Boolean(response?.url),
        hasPdfBlob: Boolean(response?.blob),
        timeElapsed: `${(performance.now() - startTime).toFixed(2)}ms`,
      });

      // Create a temporary link and trigger download
      const link = document.createElement("a");
      link.href = response.url;
      link.download = `${template.name || "document"}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the blob URL
      setTimeout(() => URL.revokeObjectURL(response.url), 100);
    } catch (error) {
      const errorDetails = {
        error: error.message,
        stack: error.stack,
        templateId: template._id,
        templateType: template.type,
        timeElapsed: `${(performance.now() - startTime).toFixed(2)}ms`,
        response: error.response,
        data: error.data,
        status: error.status,
      };

      console.error("❌ PDF generation failed:", errorDetails);

      // Show error in modal
      Modal.error({
        title: "PDF Generation Failed",
        content: `Failed to generate PDF: ${error.message || "Unknown error"}`,
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        Error loading templates. Please try again later.
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-lg font-medium">PDF Templates</h2>
        <p className="text-sm text-gray-500">
          Manage templates for work orders, jobs, and invoices. System defaults
          can be duplicated to create custom templates.
        </p>
      </div>

      {templateCategories.map(({ type, label, icon }) => (
        <div key={type} className="space-y-4">
          <h3 className="text-md font-medium text-gray-900 flex items-center gap-2">
            <span>{icon}</span>
            <span>{label}</span>
            <span className="text-sm text-gray-500">
              ({organizedTemplates[type]?.length || 0} templates)
            </span>
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* {renderExampleCard(type)} */}
            {organizedTemplates[type]?.map(renderTemplateCard)}
            <div className="h-full">{renderNewTemplateCard(type)}</div>
          </div>
        </div>
      ))}

      <PDFTemplateModal
        visible={templateModal.visible}
        template={templateModal.template}
        mode={templateModal.mode}
        onClose={() =>
          setTemplateModal({ visible: false, template: null, mode: "create" })
        }
      />

      <PDFPreviewModal
        visible={previewModal.visible}
        template={previewModal.template}
        onClose={() => setPreviewModal({ visible: false, template: null })}
      />

      <Modal
        title="Delete Template"
        visible={deleteModal.visible}
        onClose={() => setDeleteModal({ visible: false, template: null })}
        onConfirm={handleDelete}
        confirmText="Delete"
        confirmVariant="danger"
        loading={isDeleting}
      >
        <p>
          Are you sure you want to delete this template? This action cannot be
          undone.
        </p>
      </Modal>
    </div>
  );
};

export default PDFTemplates;
