import Card from "../../../../shared/Card";
import {
  ArrowDownTrayIcon,
  ArrowUpOnSquareStackIcon,
  DocumentChartBarIcon,
  XMarkIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../../../shared/Modal";
import { useState } from "react";
import Button from "../../../../shared/Button";
import Input from "../../../../shared/Input";
import EditableText from "../../../../shared/EditableText";
import useForm from "../../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import Select from "../../../../shared/Select";
import { customerStatusOptions } from "../../../../constants/select";
import classNames from "classnames";
import TextArea from "../../../../shared/TextArea";

const CustomerInfo = ({
  customer,
  form,
  handleUpdate,
  onFormChange,
  clearForm,
  ...props
}) => {
  const navigate = useNavigate();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [docName, setDocName] = useState("doc");
  const [emails, setEmails] = useState();

  const customerNumber = (
    <div className="mb-8">
      <p className="text-gray-500">Customer Name</p>
      <p className="font-semibold">{customer?.name}</p>
    </div>
  );

  // const companyInfo = (
  //   <div className="mb-8">
  //     <p className="font-extrabold text-4xl">{info.client.name}</p>
  //     <p>{info.client.address.address}</p>
  //     <p>
  //       {info.client.address.city}, {info.client.address.state}{" "}
  //       {info.client.address.zipcode}
  //     </p>
  //   </div>
  // );

  // const billTo = (
  //   <div>
  //     <p className="text-gray-500">BILL TO</p>
  //     <p className="font-semibold">{info.client.name}</p>
  //     <p className="text-gray-500">
  //       C/o:{" "}
  //       <span className="font-semibold text-black">{info.client.coowner}</span>
  //     </p>
  //     <p className="font-semibold">{info.client.address.address}</p>
  //     <p className="font-semibold">
  //       {info.client.address.city}, {info.client.address.state}{" "}
  //       {info.client.address.zipcode}
  //     </p>
  //   </div>
  // );

  function handlePdfEmail(e) {
    const output = props.getPdfOutput();
    // send email
  }

  function handlePdfDownload(e) {
    props.handleDownloadPdf();
  }

  function handleCancelNote() {
    setNoteModalVisible(false);
    clearForm();
  }

  const noteModalFooter = (
    <>
      <Button onClick={handleCancelNote} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleUpdate({ id: "note", value: form.note });
          handleCancelNote();
        }}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const noteModalContent = (
    <TextArea
      id="note"
      value={form?.note}
      onChange={(e) => onFormChange({ value: e.target.value, id: "note" })}
    />
  );

  function handleCancelEmail() {
    setEmailModalVisible(false);
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelEmail} type="alternative">
        Cancel
      </Button>
      <Button onClick={handlePdfEmail} type="primary">
        Send
      </Button>
    </>
  );

  const emailModalFooter = (
    <>
      <Button onClick={handleCancelEmail} type="alternative">
        Cancel
      </Button>
      <Button onClick={handlePdfEmail} type="primary">
        Send
      </Button>
    </>
  );

  const emailModalContent = (
    <div>
      <Input
        id="emails"
        label={"Emails - seperate by commas"}
        value={emails}
        onChange={({ value }) => setEmails(value)}
      />
      <Card className="p-2 mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-4">
            <DocumentChartBarIcon className="w-10 h-10" />
          </div>
          <div className="text-sm">
            <EditableText
              id="documentName"
              value={docName}
              displayText={(e) => <span className="">{e}.pdf</span>}
              iconClasses="h-3 w-3"
              onSave={(e) => setDocName(e.value)}
            />
          </div>
        </div>
        <div>
          <div
            onClick={handlePdfDownload}
            className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
          </div>
        </div>
      </Card>
    </div>
  );

  function handleDelete() {
    props.delete([info]);
  }

  function handleCancelDeleteModal() {
    setDeleteModalVisible(false);
  }

  const deleteModalFooter = (
    <>
      <Button onClick={handleCancelDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={handleDelete} type="primary">
        Delete
      </Button>
    </>
  );

  const deleteModalContent = (
    <div>Are you sure you want to delete this Client?</div>
  );

  const actions = [
    {
      id: "addNote",
      icon: ChatBubbleBottomCenterTextIcon,
      onClick: () => {
        onFormChange({ id: "note", value: customer.note });
        setNoteModalVisible(true);
      },
      label: "Add Note",
      type: "success",
    },
    // {
    //   id: "sendCopy",
    //   icon: ArrowUpOnSquareStackIcon,
    //   onClick: () => setEmailModalVisible(true),
    //   label: "Email",
    // },
    // {
    //   id: "pdf",
    //   icon: ArrowDownTrayIcon,
    //   onClick: handlePdfDownload,
    //   label: "To PDF",
    // },
    {
      id: "edit",
      icon: PencilSquareIcon,
      onClick: () => navigate(`/customer/${customer._id}/edit`),
      label: "Edit",
    },
    // {
    //   id: "delete",
    //   icon: XMarkIcon,
    //   onClick: () => setDeleteModalVisible(true),
    //   label: "Delete",
    // },
  ];

  return (
    <>
      <Card className="row-span-2 px-10 py-4">
        <div className="flex justify-between">
          <div>
            {customerNumber}
            {/* {companyInfo}
            {billTo} */}
          </div>
          <div className="flex flex-col items-center">
            <div className="flex justify-center">
              {actions.map((item) => {
                const Icon = item.icon;
                const circleClasses = classNames(
                  "border ease-in-out duration-300 rounded-full p-2 cursor-pointer",
                  {
                    "hover:border-gray-300 hover:bg-gray-200":
                      item.type === "default" || !item.type,
                    "hover:border-red-300 hover:bg-red-200":
                      item.type === "danger",
                    "hover:border-primary-300 hover:bg-primary-200":
                      item.type === "primary",
                    "hover:border-green-300 hover:bg-green-200":
                      item.type === "success",
                    "hover:border-orange-300 hover:bg-orange-200":
                      item.type === "warning",
                  }
                );
                return (
                  <div className="flex flex-col items-center mx-2">
                    <div onClick={item.onClick} className={circleClasses}>
                      <Icon className="w-6 h-6" />
                    </div>
                    <p className="font-semibold">{item.label}</p>
                  </div>
                );
              })}
            </div>
            <div className="mx-2 py-2 w-full">
              {/* <Select
                id="status"
                onChange={handleUpdate}
                value={customer.status}
                size="sm"
                placeholder={customer.status}
                options={customerStatusOptions}
              /> */}
            </div>
            <div className="mx-2 py-2 w-full">
              <span className="font-bold">Notes:</span>
              <div className="whitespace-pre">{customer?.note}</div>
            </div>
          </div>
        </div>
      </Card>
      {/* <PayoutTotals form={form} handleUpdate={handleUpdate} info={info} /> */}

      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title="Delete invoice"
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        onClose={() => setEmailModalVisible(false)}
        title="Send Invoice"
        footer={emailModalFooter}
        content={emailModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        onClose={() => setNoteModalVisible(false)}
        title="Add note"
        footer={noteModalFooter}
        content={noteModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default CustomerInfo;
