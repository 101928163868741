import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useAuth } from "../../hooks/useAuth";

const initState = {
  title: "Trucking",
  subTitle: "Login",
};

const passReset = {
  title: "Password Reset",
  subTitle:
    "An email will be sent to your inbox with further instructions on how to reset your password.",
};

const WebLayout = (props) => {
  const { user } = useAuth();
  const [header, setHeader] = useState(initState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/forgot/password") {
      setHeader(passReset);
    } else {
      setHeader(initState);
    }
  }, [location]);

  return (
    <div className="bg-gray-200 h-screen">
      <div className="h-full flex items-center justify-between flex-col">
        <Header />
        <div className="">
          {/* <div className="my-4">
            <h2 className="text-3xl font-bold">{header.title}</h2>
          </div>
          <div className="flex flex-row justify-start text-center">
            <div>
              <h3>{header.subTitle}</h3>
            </div>
          </div> */}
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WebLayout;
