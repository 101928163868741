import React, { useMemo } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

/**
 * PieChart component for visualizing invoice status data
 * @param {Object} props
 * @param {Array} props.data - Array of invoice status data objects
 * @param {string} props.title - Chart title
 * @returns {React.Component} Rendered PieChart
 */
export const PieChartComponent = ({ data = [], title }) => {
  // Custom colors for different statuses
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

  // Transform and filter data for the pie chart
  const chartData = useMemo(() => {
    return (
      data
        ?.map((item) => ({
          name: item.Name,
          value: item.Total,
          amount: item.TotalAmount,
          status: {
            Review: item.Review || 0,
            Sent: item.Sent || 0,
            Paid: item.Paid || 0,
          },
        }))
        .filter((item) => item.value > 0) || []
    );
  }, [data]);

  // Custom tooltip formatter - simplified to show only relevant status
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip bg-white p-2 border border-gray-200 rounded shadow">
          <p className="font-bold">{`${data.name}`}</p>
          {/* <p>{`Count: ${data.value}`}</p> */}
          <p>{`Amount: $${data.amount.toFixed(2)}`}</p>
          <p>{`${data.name} Count: ${data.status[data.name] || 0}`}</p>
        </div>
      );
    }
    return null;
  };

  // Calculate total, paid, and unpaid amounts
  const { totalAmount, paidAmount, unpaidAmount } = useMemo(() => {
    return data.reduce(
      (acc, item) => ({
        totalAmount: acc.totalAmount + (item.TotalAmount || 0),
        paidAmount:
          acc.paidAmount + (item.Name === "Paid" ? item.TotalAmount || 0 : 0),
        unpaidAmount:
          acc.unpaidAmount + (item.Name !== "Paid" ? item.TotalAmount || 0 : 0),
      }),
      { totalAmount: 0, paidAmount: 0, unpaidAmount: 0 }
    );
  }, [data]);

  return !data?.length ? (
    <div className="text-center text-gray-500">
      No Data To Display For this Time Range
    </div>
  ) : (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-1 mb-2">
        <div className="text-lg font-semibold">
          Total Amount: ${totalAmount.toFixed(2)}
        </div>
        <div className="text-sm text-green-600">
          Paid Amount: ${paidAmount.toFixed(2)}
        </div>
        <div className="text-sm text-orange-600">
          Unpaid Amount: ${unpaidAmount.toFixed(2)}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;
