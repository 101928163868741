import {
  ClockIcon,
  DocumentIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  TruckIcon,
  UserIcon,
  CalendarIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { getAppType } from "@/utils";
import { BookForm } from "@/components/BookForm";
import { AssignedToSelect } from "@/components";
import { AssignUserForm } from "@/components/AssignUserForm";
import StartJobForm from "@/components/StartJobForm";

interface StageFormProps {
  assignedTo: string[];
  [key: string]: any;
}

interface Stage {
  order: number;
  id: string;
  title: string;
  next?: string | ((options: { form: StageFormProps }) => string);
  button?: string;
  modalTitle?: string;
  icon: React.ComponentType;
  modal?: React.ComponentType<any>;
  color?: "orange" | "blue" | "yellow" | "cyan" | "gray" | "green" | "purple";
}

interface JobStages {
  [key: string]: {
    [key: number]: Stage;
  };
}

interface StageColors {
  text: {
    [key: string]: string;
  };
  bg: {
    [key: string]: string;
  };
  border: {
    [key: string]: string;
  };
}

export const jobStages: JobStages = {
  trucking: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
      icon: DocumentTextIcon,
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: "enRoute",
      button: "Start Job",
      color: "orange",
      icon: ClockIcon,
    },
    3: {
      order: 3,
      id: "enRoute",
      title: "En Route",
      next: "delivered",
      button: "Mark Delivered",
      color: "blue",
      icon: TruckIcon,
    },
    4: {
      order: 4,
      id: "delivered",
      title: "Delivered",
      next: "invoiced",
      color: "cyan",
      icon: CheckCircleIcon,
    },
    5: {
      order: 5,
      id: "invoiced",
      title: "Invoiced",
      color: "purple",
      icon: DocumentIcon,
    },
  },
  serviceRepair: {
    1: {
      order: 1,
      id: "new",
      title: "New",
      next: "pending",
      button: "Publish",
      modalTitle: "Publish Job",
      icon: DocumentTextIcon,
    },
    2: {
      order: 2,
      id: "pending",
      title: "Pending",
      next: ({ form }) => {
        return form.assignedTo.length > 0 ? "assigned" : "booked";
      },
      button: "Book",
      icon: ClockIcon,
      modal: (props) => <BookForm {...props} />,
      modalTitle: "Book Job",
      color: "orange",
    },
    3: {
      order: 3,
      id: "booked",
      title: "Booked",
      next: "assigned",
      button: "Assign",
      icon: CalendarIcon,
      modal: (props) => <AssignUserForm {...props} />,
      modalTitle: "Assign Job",
      color: "blue",
    },
    4: {
      order: 4,
      id: "assigned",
      title: "Assigned",
      next: "inProgress",
      button: "Start Job",
      modal: (props) => <StartJobForm {...props} />,
      icon: UserIcon,
      color: "yellow",
    },
    5: {
      order: 5,
      id: "inProgress",
      title: "In Progress",
      next: "completed",
      button: "Mark Completed",
      icon: WrenchIcon,
      modalTitle: "Mark Job as Completed",
      color: "cyan",
    },
    6: {
      order: 6,
      id: "completed",
      title: "Completed",
      next: "invoiced",
      icon: CheckCircleIcon,
      modalTitle: "Create Invoice",
      color: "green",
    },
    7: {
      order: 7,
      id: "invoiced",
      title: "Invoiced",
      icon: DocumentIcon,
      color: "purple",
    },
  },
};

export const stageColors: StageColors = {
  text: {
    orange: "text-orange-500",
    blue: "text-blue-500",
    yellow: "text-yellow-500",
    cyan: "text-cyan-500",
    gray: "text-gray-500",
    green: "text-green-500",
    purple: "text-purple-500",
  },
  bg: {
    orange: "bg-orange-100",
    blue: "bg-blue-100",
    yellow: "bg-yellow-100",
    cyan: "bg-cyan-100",
    gray: "bg-gray-100",
    green: "bg-green-100",
    purple: "bg-purple-100",
  },
  border: {
    orange: "border-orange-300",
    blue: "border-blue-300",
    yellow: "border-yellow-300",
    cyan: "border-cyan-300",
    gray: "border-gray-300",
    green: "border-green-300",
    purple: "border-purple-300",
  },
};

export const getStages = (
  type: string = getAppType()
): { [key: number]: Stage } => {
  return jobStages[type];
};

export const getStage = (
  key: string | number,
  type: string = getAppType()
): Stage => {
  let res: Stage = {} as Stage;
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key || jobStages[type][i].title === key
    );
    res = current ? jobStages[type][current] : ({} as Stage);
  } else {
    res = jobStages[type][key] || ({} as Stage);
  }
  return res;
};

interface NextStageOptions {
  status?: string;
  form?: StageFormProps;
  [key: string]: any;
}

export const getNextStage = (
  key: string | number,
  options: NextStageOptions = {},
  type: string = getAppType()
): Stage | null => {
  if (typeof key === "string") {
    const current = Object.keys(jobStages[type]).find(
      (i) => jobStages[type][i].id === key
    );
    if (current && !jobStages[type][current].next) {
      return null;
    }
    if (current && typeof jobStages[type][current].next === "function") {
      const nextStage = (jobStages[type][current].next as Function)({
        status: key,
        ...options,
      });
      return getStage(nextStage, type);
    }
    return current
      ? getStage(Number(jobStages[type][current].order) + 1, type)
      : null;
  }
  return getStage(Number(key) + 1, type);
};
