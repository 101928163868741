import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../shared/Button";
import Table from "../../../../shared/Table";
import { useAuth } from "../../../../hooks/useAuth";
import {
  useGetPayoutsQuery,
  useUpdatePayoutMutation,
} from "../../../../redux/services/payouts/payoutsApi";
import { COLUMN_DEFINITIONS } from "./table.config";

const EmployeePayouts = ({ employee }) => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  const { data: payouts = [], isLoading: isPayoutsLoading } =
    useGetPayoutsQuery(
      {
        parent: user.parentCompany,
        userId: user._id,
        employeeId: params.id,
      },
      {
        skip: !user._id || !params.id,
      }
    );

  console.log("employeeId", typeof params.id, params.id);

  const [updatePayout] = useUpdatePayoutMutation();

  async function handleUpdate(e, setLoading) {
    try {
      const res = await updatePayout({
        parentId: user.parentCompany,
        payoutId: e.row.original._id,
        data: e.data,
      }).unwrap();

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.error("Failed to update payout:", error);
      setLoading(false);
    }
  }

  function handleClick() {
    navigate(`/payouts/create`);
  }

  return (
    <div className="xl:col-span-6 col-span-8 max-h-[450px]">
      <Table
        scrollable
        actions={<Button title="Create Payout" onClick={handleClick} />}
        id="employee-payouts"
        title="Payouts"
        loading={isPayoutsLoading}
        columns={COLUMN_DEFINITIONS}
        data={payouts}
        hideCheckbox
        updateData={handleUpdate}
      />
    </div>
  );
};

export default EmployeePayouts;
