import { BookingItemsList } from "@/components/BookingItemsList";
import { Button, DateTimePicker, Modal, TextArea } from "@/shared";
import { useState } from "react";

const ItemApprovalModal = ({
  item,
  onClose,
  onApprove,
  visible,
  setVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());

  const handleApprove = async (data) => {
    try {
      setLoading(true);
      await onApprove({
        itemId: item._id,
        notes,
      });
      onClose();
    } catch (error) {
      console.error("Error approving item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={onClose}
      size="md"
      loading={loading}
      confirm={handleApprove}
      title="Approve Item"
      submitText="Approve"
    >
      <BookingItemsList
        items={[item]}
        appType="service"
        selectedItems={[]}
        onItemToggle={() => {}}
        hideCheckbox
      />
      <DateTimePicker
        onChange={(value) => setAppointmentDate(value)}
        value={appointmentDate}
        labels
      />
      <div className="mb-4">
        <TextArea
          id="notes"
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Example: Approved for repair work. Please notify customer upon completion."
          labelClassName="mb-2"
        />
      </div>
    </Modal>
  );
};

export default ItemApprovalModal;
