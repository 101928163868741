import { Button } from "../shared/Button";
import { PlusIcon } from "@heroicons/react/24/outline";

const defaultItemLayout = (item) => <div>{JSON.stringify(item)}</div>;

export const ListView = ({
  items = [],
  onClick,
  title,
  hideHeader,
  buttonTitle,
  itemLayout = defaultItemLayout,
  layout,
  subheader,
  headerContent,
  ...props
}) => {
  // Verify itemLayout is a function
  const renderItem =
    typeof itemLayout === "function" ? itemLayout : defaultItemLayout;

  return (
    <div className="space-y-2">
      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex items-end">
            <h3 className="font-medium text-lg text-gray-800 mr-2">{title}</h3>
            {headerContent && headerContent}
          </div>
          {!props.hideButton && (
            <Button
              className="inline-flex items-center"
              type="alternative"
              size="xs"
              rounded="full"
              onClick={onClick}
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              {buttonTitle}
            </Button>
          )}
        </div>
      )}
      {subheader && subheader}
      {items?.length > 0 ? (
        layout ? (
          layout({ items })
        ) : (
          <ul className="gap-2">
            {items.map((item, index) => (
              <li key={index}>{renderItem(item, props, index)}</li>
            ))}
          </ul>
        )
      ) : (
        <div className="text-center py-8 bg-gray-50 rounded-lg">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
          <p className="mt-2 text-gray-500">
            {props.emptyMessage || `No ${title?.toLowerCase() || "items"} yet`}
          </p>
          <p className="text-sm text-gray-400">
            {buttonTitle || "Add items"} to get started
          </p>
        </div>
      )}
    </div>
  );
};

export default ListView;
