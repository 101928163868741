import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import getTableConfig from "./table.configs";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CreateModal from "./CreateModal";
import { useTranslation } from "react-i18next";
import {
  useGetJobsQuery,
  useUpdateJobMutation,
} from "../../redux/services/jobs/jobsApi";
import { usePrefetch } from "../../redux/api";
import { useDebounceSearch } from "../../hooks";
import { DeleteJobModal } from "../../modals";
import { ClientsFilterList, StatusFilterList } from "../../components";
import { removeEmptyValues, capitalizeFirstLetter } from "../../utils";
import { isAuthorized } from "../../utils/authority";
import { toast } from "react-toastify";
import { useConfig } from "../../hooks/";
import { renderCustomColumnDefs } from "../../shared/Table/table.utils";
import { Badge } from "../../shared/Badge";

const Jobs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appType } = useConfig();
  const [deselectItems, setDeselectItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const tableConfig = useMemo(() => getTableConfig(appType), [appType]);
  const { search, handleSearch } = useDebounceSearch();

  const searchFilters = useMemo(() => {
    return filters.reduce((a, v) => {
      if (v.id === "transactionDate") {
        return {
          ...a,
          startDate: v.value?.start.toISOString(),
          endDate: v.value?.end.toISOString(),
        };
      }
      return { ...a, [v.id]: v.value };
    }, {});
  }, [filters]);

  const queryParams = useMemo(
    () => ({
      page: pageIndex + 1,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
      ...removeEmptyValues(searchFilters),
    }),
    [pageIndex, pageSize, search.useSearch, search.term, searchFilters]
  );

  const { data, isLoading } = useGetJobsQuery(queryParams);
  const [updateJob] = useUpdateJobMutation();

  const prefetchPage = usePrefetch("getJobs");

  const prefetchNext = useCallback(() => {
    if (data?.hasNext) {
      prefetchPage({
        ...queryParams,
        page: data.nextPage,
      });
    }
  }, [prefetchPage, data?.hasNext, data?.nextPage, queryParams]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, prefetchNext]);

  const handleEdit = useCallback(
    (itemId) => {
      navigate(`/jobs/${itemId}`);
    },
    [navigate]
  );

  const handleUpdate = useCallback(
    async (e, setLoading) => {
      try {
        const res = await updateJob({
          id: e.row.original._id,
          data: e.data,
        }).unwrap();

        toast(res.message, {
          type: res.status,
          toastId: `update-job-${res.status}`,
        });
      } finally {
        setTimeout(() => setLoading(false), 500);
      }
    },
    [updateJob]
  );

  const handleDeleteClick = useCallback(() => {
    setDeleteVisible(true);
  }, []);

  const cannotCreateOrEdit = useMemo(() => !isAuthorized("owner, admin"), []);

  const globalFilter = useCallback(
    (value) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      handleSearch(value);
    },
    [handleSearch]
  );

  const filterTypes = useMemo(
    () =>
      [
        {
          id: "transactionDate",
          type: "date",
          label: "Transaction Date",
          defaultText: "All Time",
          defaultValue: {
            id: "allTime",
            value: null,
          },
        },
        {
          id: "status",
          type: "multiple",
          label: "Status",
          defaultText: "All",
          content: StatusFilterList,
        },
        {
          id: "client",
          remove: !isAuthorized("owner, admin, user, driver"),
          type: "multiple",
          label: "Client",
          defaultText: "All Clients",
          content: ClientsFilterList,
        },
      ].filter((i) => !i.remove),
    []
  );

  const handleDeselect = useCallback(() => {
    setDeselectItems(true);
    setTimeout(() => setDeselectItems(false), 500);
  }, []);

  return (
    <div className="">
      <TableLayout
        id="jobs"
        title={t("jobs.main")}
        buttonSize="xs"
        loading={isLoading}
        columns={tableConfig.COLUMN_DEFINITIONS}
        data={data?.jobs}
        pageSizeOptions={tableConfig.PAGE_SIZE_OPTIONS}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        globalSearchFilters={["transactionDate", "loadNumber"]}
        fullTextSearch={globalFilter}
        sendSelectedItems={setSelectedItems}
        handleDeleteClick={handleDeleteClick}
        clearSelectedItems={deselectItems}
        filters={filterTypes}
        onFiltersChange={setFilters}
        hideCheckbox={!isAuthorized("owner,admin")}
        meta={{
          updateData: handleUpdate,
        }}
      />
      <CreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
      <DeleteJobModal
        deselectItems={handleDeselect}
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        jobs={selectedItems}
      />
    </div>
  );
};

export default Jobs;
