// User account types
export const accountTypes = [
  { label: "Admin", value: "Admin", appType: ["trucking", "serviceRepair"] },
  { label: "Driver", value: "Driver", appType: ["trucking"] },
  { label: "Client", value: "Client", appType: ["trucking", "serviceRepair"] },
  { label: "Technician", value: "Technician", appType: ["serviceRepair"] },
];

// Service type
export const SERVICE_TYPE_OPTIONS = [
  { value: "Routine", label: "Routine" },
  { value: "Repair", label: "Repair" },
  { value: "Inspection", label: "Inspection" },
  { value: "Upgrade", label: "Upgrade" },
  { value: "Installation", label: "Installation" },
  { value: "Maintenance", label: "Maintenance" },
  { value: "Calibration", label: "Calibration" },
  { value: "Troubleshooting", label: "Troubleshooting" },
  { value: "Replacement", label: "Replacement" },
  { value: "Emergency", label: "Emergency" },
  { value: "Warranty", label: "Warranty" },
  { value: "Preventive", label: "Preventive" },
];

export const monthOptions = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

// Priority
export const PRIORITY_OPTIONS = [
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" },
  { value: "Critical", label: "Critical" },
];

// Invoice status
export const statusOptions = [
  { id: "paid", label: "Paid ", value: "Paid" },
  { id: "sent", label: "Sent", value: "Sent" },
  { id: "review", label: "Review ", value: "Review" },
];

export const customerStatusOptions = [
  { id: "paid", label: "Paid ", value: "Paid" },
  { id: "pastDue", label: "Past Due", value: "Past Due" },
  { id: "", label: "Review ", value: "Review" },
];

// Payout type
export const payoutTypes = [
  { id: "individual", label: "Individual", value: "Individual" },
  { id: "organization", label: "Organization", value: "Organization" },
];

export const jobStatusOptions = [
  { id: "complete", label: "Complete ", value: "Complete" },
  { id: "pending", label: "Pending", value: "Pending" },
  { id: "enRoute", label: "En Route ", value: "En Route" },
];

export const feedbackStatusOptions = [
  { id: "pending", label: "Pending ", value: "Pending" },
  { id: "inprogress", label: "In Progress", value: "In Progress" },
  { id: "resolved", label: "Resolved ", value: "Resolved" },
];

export const payoutTypeOptions = [
  { id: "mileage", label: "Mileage", value: "Mileage" },
  { id: "empty", label: "Empty", value: "Empty" },
  { id: "flat", label: "Flat", value: "Flat" },
];

export const payoutRateOptions = [
  { id: "hourly", label: "Hourly", value: "Hourly" },
  { id: "flat", label: "Flat", value: "Flat" },
];

export const manifestMileageOptions = [
  { id: "defaultMileage", label: "Default Mileage", value: "Default Mileage" },
  { id: "customMileage", label: "Custom Mileage", value: "Custom Mileage" },
  // { id: "geolocation", label: "Geolocation", value: "Geolocation" },
];

export const ENVIRONMENT_OPTIONS = [
  { value: "", label: "All Environments" },
  { value: "dev", label: "Development" },
  { value: "alpha", label: "Alpha" },
  { value: "beta", label: "Beta" },
  { value: "prod", label: "Production" },
];

export const INDUSTRY_OPTIONS = [
  { value: "", label: "All Industries" },
  { value: "transportation", label: "Transportation" },
  { value: "warehousing", label: "Warehousing" },
  { value: "construction", label: "Construction" },
  { value: "retail", label: "Retail" },
  { value: "plumbing", label: "Plumbing" },
  { value: "hvac", label: "HVAC" },
  { value: "electrical", label: "Electrical" },
  { value: "other", label: "Other" },
];

export const accessoryOptions = [
  {
    id: "appointment",
    label: "Appointment",
    value: "Appointment",
    description: "",
  },
  { id: "admin_fee", label: "Admin Fee", value: "Admin Fee", description: "" },
  { id: "bobtail", label: "Bobtail", value: "Bobtail", description: "" },
  { id: "chassis", label: "Chassis", value: "Chassis", description: "" },
  {
    id: "chassis_split",
    label: "Chassis Split",
    value: "Chassis Split",
    description: "",
  },
  { id: "citation", label: "Citation", value: "Citation", description: "" },
  {
    id: "clean_truck_fee",
    label: "Clean Truck Fee",
    value: "Clean Truck Fee",
    description: "",
  },
  { id: "cleaning", label: "Cleaning", value: "Cleaning" },
  { id: "congestion", label: "Congestion", value: "Congestion" },
  {
    id: "deadhead_mileage_charge",
    label: "Deadhead Mileage Charge",
    value: "Deadhead Mileage Charge",
  },
  { id: "demurrage", label: "Demurrage", value: "Demurrage" },
  { id: "devanning", label: "Devanning", value: "Devanning" },
  { id: "drop_fee", label: "Drop Fee", value: "Drop Fee" },
  { id: "dry_run", label: "Dry Run", value: "Dry Run" },
  { id: "dwell_fee", label: "Dwell Fee", value: "Dwell Fee" },
  { id: "empty_back", label: "Empty Back", value: "Empty Back" },
  {
    id: "exam_site_stop_off",
    label: "Exam Site Stop Off",
    value: "Exam Site Stop Off",
  },
  { id: "expedited_fee", label: "Expedited Fee", value: "Expedited Fee" },
  { id: "flip_charge", label: "Flip Charge", value: "Flip Charge" },
  { id: "fuel_surcharge", label: "Fuel Surcharge", value: "Fuel Surcharge" },
  { id: "gate_fee", label: "Gate Fee", value: "Gate Fee" },
  { id: "grade_b_pallets", label: "Grade B Pallets", value: "Grade B Pallets" },
  { id: "hazmat", label: "Hazmat", value: "Hazmat" },
  { id: "in_bond", label: "In-Bond", value: "In-Bond" },
  {
    id: "initial_pallet_storage",
    label: "Initial Pallet Storage",
    value: "Initial Pallet Storage",
  },
  { id: "layover", label: "Layover", value: "Layover" },
  { id: "live_load_fee", label: "Live Load Fee", value: "Live Load Fee" },
  { id: "lumper", label: "Lumper", value: "Lumper" },
  { id: "maintenance", label: "Maintenance", value: "Maintenance" },
  {
    id: "missed/rescheduled_order",
    label: "Missed/Rescheduled Order",
    value: "Missed/Rescheduled Order",
  },
  {
    id: "out_of_route_miles",
    label: "Out of Route Miles",
    value: "Out of Route Miles",
  },
  { id: "over_the_road", label: "Over the Road", value: "Over the Road" },
  { id: "overweight", label: "Overweight", value: "Overweight" },
  {
    id: "pallet_outbound_handling",
    label: "Pallet Outbound Handling",
    value: "Pallet Outbound Handling",
  },
  { id: "palletization", label: "Palletization", value: "Palletization" },
  { id: "pallets", label: "Pallets", value: "Pallets" },
  { id: "per_diem", label: "Per Diem", value: "Per Diem" },
  { id: "permit", label: "Permit", value: "Permit" },
  { id: "pier_pass", label: "Pier Pass", value: "Pier Pass" },
  { id: "placard", label: "Placard", value: "Placard" },
  { id: "port_fee", label: "Port Fee", value: "Port Fee" },
  { id: "pre_pull", label: "Pre-Pull", value: "Pre-Pull" },
  { id: "rail_storage", label: "Rail Storage", value: "Rail Storage" },
  {
    id: "recurring_pallet_storage",
    label: "Recurring Pallet Storage",
    value: "Recurring Pallet Storage",
  },
  { id: "redelivery", label: "Redelivery", value: "Redelivery" },
  { id: "reefer", label: "Reefer", value: "Reefer" },
  {
    id: "residential_delivery",
    label: "Residential Delivery",
    value: "Residential Delivery",
  },
  { id: "restocking_fee", label: "Restocking Fee", value: "Restocking Fee" },
  { id: "scale_ticket", label: "Scale Ticket", value: "Scale Ticket" },
  { id: "shrink_wrap", label: "Shrink Wrap", value: "Shrink Wrap" },
  { id: "sku_separation", label: "SKU Separation", value: "SKU Separation" },
  { id: "special", label: "Special", value: "Special" },
  { id: "standBy", label: "Stand By", value: "Stand By" },
  {
    id: "special_equipment",
    label: "Special Equipment",
    value: "Special Equipment",
  },
  { id: "stop_off", label: "Stop Off", value: "Stop Off" },
  { id: "storage", label: "Storage", value: "Storage" },
  { id: "tolls", label: "Tolls", value: "Tolls" },
  { id: "transload", label: "Transload", value: "Transload" },
  { id: "tri_axle", label: "Tri-Axle", value: "Tri-Axle" },
  {
    id: "weekend_delivery",
    label: "Weekend Delivery",
    value: "Weekend Delivery",
  },
  { id: "x_ray", label: "X-Ray", value: "X-Ray" },
  { id: "yard_diversion", label: "Yard Diversion", value: "Yard Diversion" },
];

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AA", label: "APO - AA" },
  { value: "AE", label: "APO - AE" },
  { value: "AP", label: "APO - AP" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];
