import { capitalizeFirstLetter } from "@/utils";
import { Badge, Checkbox } from "../shared";
import { FormLabel } from "@/shared/Input/FormLabel";

interface TruckingItem {
  _id: string;
  loadNumber: string;
  orderNumber: string;
  loadType: string;
  weight: number;
  totalCharges: number;
}

interface ServiceItem {
  _id: string;
  summary: string;
  serviceType: string;
  appointmentDate: string;
  quotedSummary: string;
  totalCharges: number;
}

type Item = TruckingItem | ServiceItem;

interface BookingItemsListProps {
  items: Item[];
  appType: "trucking" | "service";
  selectedItems: string[];
  onItemToggle: (id: string) => void;
  hideCheckbox?: boolean;
  approvalStatus?: string;
}

export const BookingItemsList: React.FC<BookingItemsListProps> = ({
  items,
  appType,
  selectedItems,
  onItemToggle,
  hideCheckbox,
  approvalStatus,
}) => {
  const isTruckingItem = (item: Item): item is TruckingItem => {
    return appType === "trucking";
  };

  const isServiceItem = (item: Item): item is ServiceItem => {
    return appType === "service";
  };

  return (
    <div className="space-y-1">
      {items.map((item) => (
        <div key={item._id} className="flex items-center p-3 border rounded">
          {!hideCheckbox && (
            <Checkbox
              checked={selectedItems.includes(item._id)}
              onChange={() => onItemToggle(item._id)}
              id={item._id}
            />
          )}
          {approvalStatus && (
            <Badge
              color={
                approvalStatus === "approved"
                  ? "green"
                  : approvalStatus === "rejected"
                  ? "red"
                  : "gray"
              }
              size="sm"
            >
              {capitalizeFirstLetter(approvalStatus)}
            </Badge>
          )}
          <label htmlFor={item._id} className="ml-2 flex-1">
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <span className="font-medium">
                  {isTruckingItem(item)
                    ? `Load #${item.loadNumber}`
                    : item.summary?.substring(0, 100)}
                </span>
              </div>
              <div className="text-sm font-medium">
                ${item.totalCharges?.toFixed(2)}
              </div>
            </div>

            {isTruckingItem(item) ? (
              <div className="text-sm text-gray-500">
                Order: {item.orderNumber} | Type: {item.loadType} | Weight:{" "}
                {item.weight}lbs
              </div>
            ) : (
              <div className="text-sm text-gray-500">
                Type: {item.serviceType} | Appointment:{" "}
                {item.appointmentDate
                  ? new Date(item.appointmentDate).toLocaleDateString()
                  : "Not scheduled"}
              </div>
            )}

            {isServiceItem(item) && (
              <div className="text-sm text-gray-500 mt-1">
                {item.quotedSummary}
              </div>
            )}
          </label>
        </div>
      ))}

      {items.length === 0 && (
        <p className="text-gray-500">
          {appType === "trucking" ? "No loads available" : "No jobs available"}
        </p>
      )}
    </div>
  );
};
