import ServiceDataCard from "./ServiceDataCard";

const EquipmentCard = ({ equipment }) => {
  const formatDate = (date) => {
    if (!date) return "—";
    return new Date(date).toLocaleDateString();
  };

  return (
    <ServiceDataCard header="Equipment" className="col-span-1 bg-white">
      <div className="px-3 py-2">
        <div className="border border-gray-200 rounded-lg overflow-hidden">
          <div className="bg-gray-50 px-3 py-1.5 border-b border-gray-200">
            <div className="flex items-center space-x-2">
              <span className="font-semibold text-gray-700 text-sm">
                {equipment?.name}
              </span>
              {equipment?.status && (
                <span
                  className={`ml-auto text-xs px-2 py-0.5 rounded-full ${
                    equipment.status === "Available"
                      ? "bg-green-100 text-green-800"
                      : equipment.status === "In Use"
                      ? "bg-blue-100 text-blue-800"
                      : equipment.status === "Under Maintenance"
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {equipment.status}
                </span>
              )}
            </div>
          </div>

          <div className="p-3">
            {equipment ? (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-xs text-gray-500 uppercase">Type</p>
                  <p className="text-xs font-medium text-gray-900">
                    {equipment.type || "-"}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Manufacturer
                  </p>
                  <p className="text-xs font-medium text-gray-900">
                    {equipment.manufacturer || "—"}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Model</p>
                  <p className="text-xs font-medium text-gray-900">
                    {equipment.model || "—"}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Serial Number
                  </p>
                  <p className="text-xs font-mono font-medium text-gray-900">
                    {equipment.serialNumber || "—"}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Purchase Date
                  </p>
                  <p className="text-xs font-medium text-gray-900">
                    {formatDate(equipment.purchaseDate)}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Odometer</p>
                  <p className="text-xs font-medium text-gray-900">
                    {equipment.odometer || "—"}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Last Maintenance
                  </p>
                  <p className="text-xs font-medium text-gray-900">
                    {formatDate(equipment.lastMaintenanceDate)}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Next Due</p>
                  <p className="text-xs font-medium text-gray-900">
                    {formatDate(equipment.nextMaintenanceDate)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-xs text-gray-500 uppercase">Type</p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Manufacturer
                  </p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Model</p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Serial Number
                  </p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Purchase Date
                  </p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Odometer</p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">
                    Last Maintenance
                  </p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase">Next Due</p>
                  <div className="h-4 w-3/4 bg-gray-200 rounded mt-1"></div>
                </div>
              </div>
            )}
          </div>

          <div className="bg-gray-50 px-3 py-1.5 border-t border-gray-200">
            <div className="flex justify-between items-center">
              <span className="text-[10px] text-gray-500">
                ID: {equipment?.id || "••••••"}
              </span>
              <span className="text-[10px] text-gray-500">
                {new Date().toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ServiceDataCard>
  );
};

export default EquipmentCard;
