import React, { useEffect, useState } from "react";
import Input from "../../shared/Input";
import Checkbox from "../../shared/Checkbox";
import Button from "../../shared/Button";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { FORM_OPTIONS } from "./form.config";
import { useAuth } from "../../hooks/useAuth";
import useQuery from "../../hooks/useQuery";
import ContactModal from "./ContactModal";
import { PageLoading } from "../../shared";

const Login = () => {
  const queries = useQuery();
  const { form, updateForm, isValid } = useForm(FORM_OPTIONS);
  const { login, user, initialized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const email = queries.get("email");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (email && !form.email) {
      handleUpdateForm({ id: "email", value: email });
    }
  }, [form.email]);

  useEffect(() => {
    if (initialized && user) {
      const from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    }
  }, [initialized, user, navigate, location]);

  function handleUpdateForm(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    try {
      login(form);
    } catch (err) {
      throw new Error(err);
    }
  }

  // If not initialized, show loading
  if (!initialized) {
    return <PageLoading />;
  }

  // If user is authenticated, redirect to home or intended destination
  if (user) {
    const from = location.state?.from?.pathname || "/";
    return <Navigate to={from} replace />;
  }

  // Show login form only if user is not authenticated
  return (
    <>
      <div className="flex flex-col items-center justify-center md:justify-start my-auto pt-8 md:pt-0 px-1 md:px-5 lg:px-24">
        <p className="text-center text-4xl font-bold pb-5">Welcome</p>
        <div className="w-3/4">
          <div className="my-3">
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="username"
              onChange={handleUpdateForm}
              value={form.email}
              placeholder="Email"
            />
          </div>
          <div className="my-3">
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              onChange={handleUpdateForm}
              value={form.password}
              placeholder="Password"
            />
          </div>
          <div className="flex items-center justify-between">
            <Checkbox
              label="Remember me"
              id="rememberMe"
              onChange={(e) => handleUpdateForm({ id: e.id, value: e.checked })}
            />
            <Link
              to="/forgot/password"
              className="hover:underline text-primary-600 font-semibold"
            >
              Forgot Password?
            </Link>
          </div>
          <div className="my-3">
            <Button
              type="primary"
              disabled={!isValid}
              onClick={handleSubmit}
              className="w-full"
            >
              Submit
            </Button>
          </div>
        </div>
        <div className="text-center pt-10">
          <p>
            Don't have an account?{" "}
            <a
              onClick={() => setModalOpen(true)}
              className="underline text-primary-600 font-semibold cursor-pointer"
            >
              Click here
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
