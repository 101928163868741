import { DatePicker, TextArea, TimeInput } from "../../../shared";
import {
  formatDate,
  formatDateOrTime,
  updateDate,
  updateTime,
} from "../../../utils";
import { parseISO, format } from "date-fns";

export const ServiceJobForm = ({ form, updateForm }) => {
  const handleDateChange = (value) => {
    const date = form.appointmentDate;
    const newDate = updateDate(date, value);
    updateForm({ id: "appointmentDate", value: newDate });
  };

  const handleTimeChange = (value) => {
    const date = form.appointmentDate;
    const newTime = updateTime(date, value);
    updateForm({ id: "appointmentDate", value: newTime });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
      <div className="col-span-1 md:col-span-2">
        <TextArea
          label="Original Call"
          placeholder="Enter original call details here..."
          name="originalCall"
          value={form.originalCall}
          onChange={(e) =>
            updateForm({ id: "originalCall", value: e.target.value })
          }
        />
      </div>
      <div>
        <DatePicker
          label="Appointment Date"
          placeholder="Select appointment date"
          name="appointmentDate"
          value={formatDateOrTime(form.appointmentDate, "date")}
          onChange={({ value }) => handleDateChange(value)}
        />
      </div>
      <div>
        <TimeInput
          label="Appointment Time"
          placeholder="Select appointment time"
          value={formatDateOrTime(form.appointmentDate, "time")}
          onChange={({ value }) => handleTimeChange(value)}
        />
      </div>
    </div>
  );
};

export default ServiceJobForm;
