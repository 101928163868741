import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { oneDark } from "@codemirror/theme-one-dark";
import { EditorView } from "@codemirror/view";
import { Decoration, DecorationSet } from "@codemirror/view";
import { RangeSetBuilder } from "@codemirror/state";
import type { Extension } from "@codemirror/state";

export interface CodeEditorProps {
  value: string;
  onChange: (value: string) => void;
  language?: "html" | "css";
  height?: string;
  placeholder?: string;
  readOnly?: boolean;
  theme?: "light" | "dark";
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  value,
  onChange,
  language = "html",
  height = "400px",
  placeholder = "",
  readOnly = false,
  theme = "dark",
}) => {
  // Setup editor extensions based on language
  const getLanguageExtension = (): Extension => {
    switch (language) {
      case "html":
        return html();
      case "css":
        return css();
      default:
        return html();
    }
  };

  // Custom theme settings
  const themeExtension = EditorView.theme({
    "&": {
      height,
      fontSize: "14px",
    },
    ".cm-content": {
      fontFamily: "monospace",
      padding: "10px 0",
    },
    ".cm-line": {
      padding: "0 10px",
    },
    ".cm-placeholder": {
      color: "#888",
    },
  });

  // Handlebars syntax highlighting
  const handlebarsTags = EditorView.decorations.of(
    (view: EditorView): DecorationSet => {
      const builder = new RangeSetBuilder<Decoration>();
      const content = view.state.doc.toString();
      const pattern = /{{[^}]+}}/g;
      let match;

      while ((match = pattern.exec(content)) !== null) {
        const start = match.index;
        const end = start + match[0].length;
        builder.add(
          start,
          end,
          Decoration.mark({ class: "handlebars-expression" })
        );
      }

      return builder.finish();
    }
  );

  // Combine all extensions
  const extensions: Extension[] = [
    getLanguageExtension(),
    themeExtension,
    handlebarsTags,
    theme === "dark" ? oneDark : [],
    EditorView.lineWrapping,
    EditorView.editable.of(!readOnly),
  ];

  return (
    <div className="border rounded-md overflow-hidden">
      <style>{`
        .handlebars-expression {
          color: #c678dd;
          font-weight: bold;
        }
        .cm-editor {
          height: ${height};
        }
        .cm-focused {
          outline: none !important;
        }
      `}</style>
      <CodeMirror
        value={value}
        onChange={onChange}
        extensions={extensions}
        placeholder={placeholder}
        className={`${theme === "dark" ? "bg-gray-900" : "bg-white"}`}
        basicSetup={{
          lineNumbers: true,
          highlightActiveLineGutter: true,
          highlightSpecialChars: true,
          history: true,
          foldGutter: true,
          drawSelection: true,
          dropCursor: true,
          allowMultipleSelections: true,
          indentOnInput: true,
          syntaxHighlighting: true,
          bracketMatching: true,
          closeBrackets: true,
          autocompletion: true,
          rectangularSelection: true,
          crosshairCursor: true,
          highlightActiveLine: true,
          highlightSelectionMatches: true,
          closeBracketsKeymap: true,
          defaultKeymap: true,
          searchKeymap: true,
          historyKeymap: true,
          foldKeymap: true,
          completionKeymap: true,
          lintKeymap: true,
        }}
      />
    </div>
  );
};

export default CodeEditor;
