import { useState, useCallback, useEffect, useRef } from "react";
import { useDebounceSearch } from "./useDebounceSearch";
import { debounce } from "lodash";

export const useFetchAll = ({
  queryHook,
  defaultPageSize = 20,
  defaultFilters = [],
  prefetch = true,
  options = {},
}) => {
  // Pagination state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  // Search functionality
  const { search, handleSearch, clearSearch } = useDebounceSearch();

  // Filters state
  const [filters, setFilters] = useState(defaultFilters);

  // Add debounce to filter changes
  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFilters(newFilters);
    }, 300),
    []
  );

  // Add request cancellation
  const abortController = useRef(new AbortController());

  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
  }, []);

  // Process filters
  const searchFilters = filters.reduce((acc, filter) => {
    if (filter.id === "transactionDate") {
      return {
        ...acc,
        startDate: filter.value?.start?.toISOString(),
        endDate: filter.value?.end?.toISOString(),
      };
    }
    return { ...acc, [filter.id]: filter.value };
  }, {});

  // Build query params
  const queryParams = {
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
    sortBy: options.sortBy,
    sortOrder: options.sortOrder,
    ...searchFilters,
  };

  const filteredQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  // Skip the query if we have default filters but they haven't been set yet
  const shouldSkip = defaultFilters.length > 0 && filters.length === 0;

  // Execute the query with filters as dependency
  const queryResult = queryHook(filteredQueryParams, {
    skip: shouldSkip,
    ...options,
  });

  // Handle global search
  const handleGlobalSearch = useCallback(
    (value) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      handleSearch(value);
    },
    [handleSearch]
  );

  // Prefetch next page if available
  const prefetchNext = useCallback(() => {
    if (
      prefetch &&
      queryResult.data?.hasNext &&
      queryResult.data.nextPage <= queryResult.data.totalPages &&
      queryResult.prefetch
    ) {
      queryResult.prefetch({
        ...filteredQueryParams,
        page: queryResult.data.nextPage,
      });
    }
  }, [
    prefetch,
    queryResult.data,
    pageSize,
    search.useSearch,
    search.term,
    searchFilters,
    queryResult.prefetch,
  ]);

  useEffect(() => {
    prefetchNext();
  }, [prefetchNext]);

  // Reset pagination and trigger refetch when filters change
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    // Only call refetch if the query is not in its initial state
    if (queryResult.refetch && !queryResult.isUninitialized) {
      queryResult.refetch();
    }
  }, [filters, queryResult.refetch, queryResult.isUninitialized]);

  return {
    // Pagination
    pagination: {
      pageIndex,
      pageSize,
      setPagination,
    },
    // Search
    search: {
      handleGlobalSearch,
      clearSearch,
      search,
      value: search.term,
    },
    // Filters
    filters: {
      filters,
      setFilters,
    },
    // Query results
    queryResult,
  };
};
