import { useEffect, useState } from "react";
import { Card } from "../../shared";
import { AreaChart } from "../../shared/Charts";
import { dateStage } from "../../constants/aggregate";
import { getParentCompany } from "../../utils/authority";
import {
  endOfWeek,
  endOfMonth,
  startOfMonth,
  startOfDay,
  endOfToday,
  startOfWeek,
} from "date-fns";
import request from "../../utils/request";

export const JobStatusReport = (props) => {
  const [reportData, setReportData] = useState();

  useEffect(() => {
    fetchReport({ type: props.viewType });
  }, [props.viewType]);

  const newDate = new Date();

  // Start of day
  const startOfDayDate = startOfDay(newDate);
  const endOfDayDate = endOfToday(
    newDate
    // new Date(todayIso).setUTCHours(23, 59, 59, 999)
  );
  // Start of week
  const startOfWeekDate = new Date(
    startOfWeek(newDate)
    // newDate.setDate(newDate.getDate() - newDate.getDay())
  );
  const endOfWeekDate = new Date(endOfWeek(newDate));
  // Start of month
  const startOfMonthDate = new Date(
    startOfMonth(newDate)
    // .getFullYear(),
    // newDate.getMonth(),
    // 0
  );
  const endOfMonthDate = new Date(endOfMonth(newDate));

  const startOfYear = new Date(newDate.getFullYear(), 0);
  const endOfYear = new Date(newDate.getFullYear(), 12);

  const fetchReport = async (view) => {
    const type = view.type;
    let start = "";
    let end = "";
    if (type === "daily") {
      start = startOfDayDate;
      end = endOfDayDate;
    } else if (type === "weekly") {
      start = startOfWeekDate;
      end = endOfWeekDate;
    } else if (type === "monthly") {
      start = startOfMonthDate;
      end = endOfMonthDate;
    } else if (type === "yearly") {
      start = startOfYear;
      end = endOfYear;
    }
    // else {
    //   start = startOfYear;
    //   end = startOfDay;
    // }

    const stages = dateStage(
      props.appType === "serviceRepair" ? "appointmentDate" : "transactionDate",
      start.toString(),
      end.toString(),
      type,
      getParentCompany(),
      props.appType
    );

    const response = await request(`/reports`, {
      method: "post",
      data: {
        stages,
        modelName: "Job",
        config: {
          0: {
            type: "$match",
            useDate:
              props.appType === "serviceRepair"
                ? "appointmentDate"
                : "transactionDate",

            // _id: "parentCompany",
          },
        },
      },
    });
    return Promise.all([response]).then((values) => {
      const finalData = values[0]?.data;

      setReportData(finalData);
    });
  };

  return (
    // col-span-3 md:col-span-6
    <div className="min-h-[430px] rounded-lg row-span-1 flex flex-col font-bold h-full">
      <Card className="h-full">
        <div className="mb-3 ext-xs md:text-sm uppercase text-gray-500 ">
          Job Statuses
        </div>
        <AreaChart
          height={350}
          data={reportData}
          appType={props.appType}
          dataType="jobs"
        />
      </Card>
    </div>
  );
};
