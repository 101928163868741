import { useJob, useForm } from "@/hooks";
import { useState, lazy, Suspense } from "react";
import { Header } from "@pages/Jobs/Show/Info/ServiceRepair/ServiceInfo";
import { ItemsTable } from "@pages/Jobs/Services/ItemsTable";
import { Button, Spinner } from "@/shared";
import { isAuthorized } from "@/utils";

const EmptyTableState = lazy(() =>
  import("@pages/Jobs/Show/Info/ServiceRepair/EmptyTableState")
);

const JobQuotes = ({
  quotes,
  services,
  jobMode,
  renderButton,
  isJobActive,
  isPending,
  isNew,
  job,
  editing,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const serviceForm = useForm();

  function handleCreate() {
    serviceForm.setFormInit({
      job,
    });
    setModalVisible(true);
  }

  function renderButtonText() {
    return `Add ${getJobMode(job.status)}`;
  }

  function renderButton(size = "sm") {
    return (
      isAuthorized("owner,admin") &&
      job.items?.length > 0 &&
      (editing || isNew || isPending) && (
        <div className="flex">
          <Button
            className="flex items-center"
            onClick={handleCreate}
            size={size}
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            {renderButtonText()}
          </Button>
        </div>
      )
    );
  }

  return (
    <ItemsTable
      items={quotes}
      setVisible={setModalVisible}
      visible={modalVisible}
      renderHeader={() => {
        return (
          <Header
            renderButton={renderButton}
            active={isJobActive}
            job={job}
            count={quotes?.length}
            title="Quotes"
          />
        );
      }}
      // hideHeader={!isHeaderActive}
      job={job}
      form={serviceForm}
      loading={loading}
      editing={isAuthorized("owner,admin") ? editing : false}
      isJobActive={isJobActive}
      isPending={isPending}
      isNew={isNew}
      thClasses="rounded-t"
      emptyState={
        <Suspense fallback={<Spinner />}>
          <EmptyTableState
            form={serviceForm}
            job={job}
            setModalVisible={setModalVisible}
          />
        </Suspense>
      }
    />
  );
};

export default JobQuotes;
