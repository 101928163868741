import { useState, useEffect } from "react";
import { useForm } from "../hooks";
import { useGetEmployeesQuery } from "../redux/services/employees/employeesApi";
import {
  DatePicker,
  TimeInput,
  TextArea,
  Button,
  DateTimePicker,
  Checkbox,
  Popover,
} from "../shared";
import { formatDateOrTime } from "../utils";
import { AssignedToSelect } from "./AssignedToSelect";
import AppointmentDate from "@/pages/Jobs/Show/Info/ServiceRepair/AppointmentDate";
import { useConfig } from "../hooks/useConfig";
import { useJob } from "@/hooks/useJob";
import { FormLabel } from "@/shared/Input/FormLabel";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { BookingItemsList } from "./BookingItemsList";

interface Employee {
  _id: string;
  id?: string;
  [key: string]: any;
}

interface BookFormProps<T = any> {
  form: T;
  updateForm: (arg: UpdateFormArg<T>) => void;
  employees?: Employee[];
  handleLoadMoreEmployees?: () => void;
  handleEmployeesSearch?: (term: string) => void;
  employeesQuery?: {
    hasNext?: boolean;
  };
  loading?: boolean;
  appointmentRequired?: boolean;
}

export const BookForm = <T extends Record<string, any>>({
  form,
  updateForm,
  employees = [],
  handleLoadMoreEmployees,
  handleEmployeesSearch,
  employeesQuery,
  loading,
  appointmentRequired = true,
}: BookFormProps<T>) => {
  const { appType } = useConfig();
  const { getItemsByType } = useJob();
  const [assignedUsers, setAssignedUsers] = useState<Employee[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    form.selectedItems || []
  );

  useEffect(() => {
    if (
      form.selectedItems &&
      JSON.stringify(form.selectedItems) !== JSON.stringify(selectedItems)
    ) {
      setSelectedItems(form.selectedItems);
    }
  }, [JSON.stringify(form.selectedItems)]);

  const items =
    appType === "trucking"
      ? [] // Replace with actual trucking items
      : getItemsByType("quote");

  const handleItemToggle = (itemId: string): void => {
    const newSelectedItems = selectedItems.includes(itemId)
      ? selectedItems.filter((id) => id !== itemId)
      : [...selectedItems, itemId];

    setSelectedItems(newSelectedItems);

    if (
      JSON.stringify(newSelectedItems) !== JSON.stringify(form.selectedItems)
    ) {
      updateForm({
        id: "selectedItems",
        value: newSelectedItems,
      });
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full">
        <DateTimePicker
          required={appointmentRequired}
          className="w-full"
          placeholder="Select appointment date and time"
          onChange={(value) => updateForm({ id: "appointmentDate", value })}
          value={form.appointmentDate}
          labels
        />
      </div>
      <div className="w-full">
        <TextArea
          id="notes"
          name="notes"
          label="Notes"
          placeholder="Add any additional notes..."
          value={form.notes}
          onChange={(e) => updateForm({ id: "notes", value: e.target.value })}
        />
      </div>
      {employees?.length > 0 && (
        <div className="w-full">
          <div className="flex items-center gap-2">
            <FormLabel htmlFor="assignedTo">Assigned To</FormLabel>
            <Popover
              className="w-43 block mb-2 text-sm text-gray-900"
              position="top"
              hover
              trigger={
                <InformationCircleIcon className="h-4 w-4 text-gray-400" />
              }
              offset={[-12, 0]}
            >
              <div className="p-3">
                <p className="text-sm text-gray-700 mb-2">
                  This field is optional. You can assign team members now or
                  later during dispatch.
                </p>
              </div>
            </Popover>
          </div>
          <AssignedToSelect
            onChange={(value: Employee[]) => {
              setAssignedUsers(value);
              updateForm({
                id: "assignedTo",
                value: value.map((i) => i._id || i.id),
              });
            }}
            value={assignedUsers}
            users={employees.map((emp) => ({
              ...emp,
              id: emp._id,
            }))}
            isEditable={true}
            onLoadMore={handleLoadMoreEmployees}
            hasMore={employeesQuery?.hasNext}
            loading={loading}
            onSearch={handleEmployeesSearch}
          />
        </div>
      )}
      <FormLabel required>
        Select {appType === "trucking" ? "Loads" : "Services"}
      </FormLabel>
      <p className="text-gray-600">
        Please select one or more{" "}
        {appType === "trucking" ? "loads" : "services"} from the list below to
        approve.
      </p>
      <BookingItemsList
        items={items}
        appType={appType}
        selectedItems={selectedItems}
        onItemToggle={handleItemToggle}
      />

      {items.length === 0 && (
        <p className="text-gray-500">
          {appType === "trucking" ? "No loads available" : "No jobs available"}
        </p>
      )}
    </div>
  );
};
