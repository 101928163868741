import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import {
  formatDate,
  renderAmount,
  renderTotalMileage,
} from "../../../../../utils";

// Config for table and form
const tableConfig = [
  {
    id: "jobId",
    header: "Work Order",
    visible: true,
    required: true,
    minSize: 180,
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "appointmentDate",
    header: "Appointment Date",
    minSize: 170,
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },

  {
    id: "performedBy",
    visible: false,
    cell: ({ getValue }) => {
      return getValue().firstName;
    },
    minSize: 50,
  },
  {
    header: "Parts Total",
    id: "partsTotal",
    minSize: 150,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Labor Total",
    id: "hourlyTotal",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Service Fee",
    id: "serviceFeeTotal",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Other Charges",
    id: "totalOtherCharges",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Sales Tax",
    id: "salesTax",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
  {
    header: "Total",
    id: "calculatedTotalCharges",
    minSize: 120,
    cell: ({ getValue }) => {
      return renderAmount(getValue());
    },
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
