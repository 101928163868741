import React, { useMemo } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { colors } from "./colors";

/**
 * Dynamic Area Chart component that handles different types of status data visualization
 * @param {Object} props - Component props
 * @param {Array} props.data - Chart data array
 * @param {string} props.title - Chart title
 * @param {string} props.appType - Application type (serviceRepair/trucking)
 * @param {string} props.dataType - Data type to display (jobs/invoices)
 * @param {number} props.height - Chart height
 */
export const AreaChartComponent = ({
  data,
  title,
  appType,
  dataType = "jobs",
  ...props
}) => {
  // Define status configurations based on appType and dataType
  const statusConfig = useMemo(
    () => ({
      jobs: {
        serviceRepair: [
          { key: "New", color: colors.primary, gradientId: "five" },
          { key: "Pending", color: colors.orange, gradientId: "six" },
          { key: "Booked", color: colors.blue, gradientId: "four" },
          { key: "Assigned", color: colors.cyan, gradientId: "three" },
          { key: "In Progress", color: colors.purple, gradientId: "two" },
          { key: "Completed", color: colors.green, gradientId: "one" },
          { key: "Invoiced", color: colors.purple, gradientId: "two" },
        ],
        trucking: [
          { key: "New", color: colors.primary, gradientId: "five" },
          { key: "Pending", color: colors.orange, gradientId: "six" },
          { key: "En Route", color: colors.blue, gradientId: "four" },
          { key: "Delivered", color: colors.cyan, gradientId: "three" },
          { key: "Invoiced", color: colors.purple, gradientId: "two" },
          { key: "Total", color: colors.green, gradientId: "one" },
        ],
      },
      invoices: {
        all: [
          { key: "Review", color: colors.primary, gradientId: "five" },
          { key: "Sent", color: colors.orange, gradientId: "six" },
          { key: "Paid", color: colors.green, gradientId: "one" },
          { key: "Total", color: colors.blue, gradientId: "four" },
        ],
      },
    }),
    []
  );

  // Get active statuses based on dataType and appType
  const activeStatuses = useMemo(() => {
    if (dataType === "invoices") {
      return statusConfig.invoices.all;
    }
    return appType === "serviceRepair"
      ? statusConfig.jobs.serviceRepair
      : statusConfig.jobs.trucking;
  }, [appType, dataType, statusConfig]);

  // Define currency fields at component level or via props
  const currencyFields = ["OutstandingAmount", "PaidAmount", "TotalAmount"]; // adjust based on your needs

  // Create a reusable currency formatter
  const formatCurrency = (value) => {
    // Ensure value is a number and handle potential null/undefined
    const numValue = Number(value);
    if (isNaN(numValue)) return "$0.00";

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(numValue);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      // Define display order and formatting
      const displayOrder = [
        { key: "Review", format: "number" },
        { key: "Sent", format: "number" },
        { key: "Paid", format: "number" },
        { key: "Total", format: "number" },
        { key: "OutstandingAmount", format: "currency" },
        { key: "PaidAmount", format: "currency" },
        { key: "TotalAmount", format: "currency" },
      ];

      return (
        <div className="custom-tooltip bg-white p-3 border border-gray-200 rounded shadow">
          <p className="font-bold text-lg border-b pb-2 mb-2">{data.Name}</p>
          <div className="space-y-1">
            {displayOrder.map(({ key, format }) => {
              if (data[key] !== undefined) {
                return (
                  <div key={key} className="flex justify-between">
                    <span className="text-gray-600">
                      {key.replace(/([A-Z])/g, " $1").trim()}:
                    </span>
                    <span className="font-medium ml-4">
                      {format === "currency"
                        ? formatCurrency(data[key])
                        : new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 0,
                          }).format(data[key])}
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid opacity={0.5} vertical={false} />
        <XAxis dataKey="Name" tick={{ fontSize: 13 }} />
        <YAxis allowDecimals={false} tick={{ fontSize: 13 }} />

        <defs>
          <linearGradient id="one" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.green} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.green} stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="two" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.purple} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.purple} stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="three" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.cyan} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.cyan} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="four" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.blue} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.blue} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="five" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.orange} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.orange} stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="six" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.primary} stopOpacity={0} />
            <stop offset="95%" stopColor={colors.primary} stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <Tooltip content={<CustomTooltip />} />
        <Legend wrapperStyle={{ fontSize: "13px" }} />

        {activeStatuses.map(({ key, color, gradientId }) => (
          <Area
            key={key}
            type="monotone"
            strokeWidth={3}
            dataKey={key}
            fillOpacity={1}
            stroke={color}
            fill={`url(#${gradientId})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
