import { useEffect, useState, useMemo } from "react";
import { Input, SettingsPanel, Modal } from "../../shared";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useConfig } from "../../hooks/useConfig";
import request, { renderQueries } from "../../utils/request";
import { useAuth } from "../../hooks/useAuth";
import { SettingsHeader } from ".";
import { AddressForm } from "../../components/Address";
import { AddressModal } from "../../components";
import { toast } from "react-toastify";
import { getDocumentFormData } from "@/utils/media";
import { useUpdateCustomerLogoMutation } from "../../redux/services/customers/customersApi";
import users from "@/redux/reducers/users";

const GeneralSettings = () => {
  const { addConfig, configs } = useConfig();
  const { form, setFormInit, updateForm } = useForm();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [updateCustomerLogo] = useUpdateCustomerLogoMutation();

  useEffect(() => {
    setFormInit({
      slug: { data: configs?.parentCompany?.slug },
      name: { data: configs?.parentCompany?.name },
      email: { data: configs?.parentCompany?.email },
      phone: { data: configs?.parentCompany?.phone },
      address: { data: configs?.parentCompany?.address?.fullAddress },
      logo: { data: configs?.parentCompany?.logo?.url },
    });
  }, [configs?.parentCompany?.name]);

  function handleBlur(key, type) {
    const edited = _.pickBy(form, function (obj, key) {
      return obj.updated;
    });
    const res = _.mapValues(edited, (value) => value.data);

    if (Object.keys(res).length === 0) return;

    if (type === "parentCompany") {
      handleParentCompanySave(res);
    } else {
      handleConfigSave(res, key, type);
    }
  }

  async function handleParentCompanySave(data) {
    setLoading(true);
    delete data.configKey;

    const updatedParent = await request(
      "/customers/" +
        configs.parentCompany._id +
        "?" +
        renderQueries({ parent: user.parentCompany }),
      { method: "put", data }
    );

    setLoading(false);
  }

  async function handleAddressSave(addressData) {
    setModalLoading(true);
    const data = {
      ...addressData,
      type: "address",
    };

    const updatedParent = await request(
      "/customers/" +
        configs.parentCompany._id +
        "?" +
        renderQueries({ parent: user.parentCompany }),
      { method: "put", data }
    );

    if (updatedParent) {
      setAddressModalVisible(false);
      // Update the form with new address
      setFormInit({
        ...form,
        address: { data: updatedParent.data.address?.fullAddress },
      });
    }
    setModalLoading(false);
  }

  function handleConfigSave(data, key, type) {
    setLoading(true);
    if (type) {
      data.type = type;
    }
    data.configKey = key;
    addConfig(data);
    setLoading(false);
  }

  function onFormChange(e) {
    setFormInit({ [e.id]: { data: e.value, updated: true } });
  }

  const data = useMemo(
    () => [
      {
        title: "Organization Slug",
        subtitle: "A unique ID used to identify this organization",
        content: (
          <Input
            onBlur={() => handleBlur("slug", "parentCompany")}
            id="slug"
            onChange={onFormChange}
            value={form.slug?.data}
            size="md"
            placeholder="Can only use a dash to seperate words"
          />
        ),
      },
      {
        title: "Display Name",
        subtitle: "Your organizations name",
        content: (
          <Input
            onBlur={() => handleBlur("name", "parentCompany")}
            id="name"
            onChange={onFormChange}
            value={form.name?.data}
            size="md"
            placeholder="Display Name"
          />
        ),
      },
      {
        title: "Email",
        subtitle: "The main email associated with the account",
        content: (
          <Input
            onBlur={() => handleBlur("email", "parentCompany")}
            id="email"
            onChange={onFormChange}
            value={form.email?.data}
            size="md"
            placeholder="Account Email"
          />
        ),
      },
      {
        title: "Phone",
        subtitle: "The main telephone associated with the account",
        content: (
          <Input
            onBlur={() => handleBlur("phone", "parentCompany")}
            id="Phone"
            onChange={onFormChange}
            value={form.phone?.data}
            size="md"
            placeholder="(555)555-5555"
          />
        ),
      },
      {
        title: "Address",
        subtitle: "The main address associated with the account",
        content: (
          <Input
            id="address"
            onChange={onFormChange}
            value={form.address?.data}
            size="md"
            placeholder="Address"
            onClick={() => setAddressModalVisible(true)}
            readOnly
          />
        ),
      },
    ],
    [form]
  );

  /**
   * Handles file uploads for attachments
   * @param {FileList} files - Files selected by the user
   * @returns {Promise<void>}
   */
  const handleAddFile = async (files) => {
    // Input validation
    if (!files || files.length === 0) {
      toast.warn("No files selected", {
        toastId: "no-files-selected",
      });
      return;
    }

    try {
      // Convert FileList to array and validate file types/sizes if needed
      const fileArray = Array.from(files).map((file) => {
        // Optional: Add file validation here
        if (file.size > 10 * 1024 * 1024) {
          // 10MB limit example
          throw new Error(`File ${file.name} exceeds size limit`);
        }
        return file;
      });

      const formData = await getDocumentFormData(fileArray, {
        refId: configs?.parentCompany?._id,
        refType: "parentCompany",
        type: "logo",
        uploadPath: "logos",
      });

      if (!formData) {
        throw new Error("Failed to create form data");
      }

      // Upload files and handle response
      const response = await updateCustomerLogo({
        id: user.parentCompany,
        data: formData,
      }).unwrap();

      // Verify response
      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }

      updateForm({ id: "logo", value: response.data });

      // Show success notification
      toast.success("Files uploaded successfully", {
        toastId: "files-upload-success",
      });
    } catch (error) {
      // Handle specific error types
      if (error.status === 413) {
        toast.error("Files too large - Maximum size is 2MB", {
          toastId: "files-too-large",
        });
      } else if (error.status === 415) {
        toast.error("Unsupported file type", {
          toastId: "unsupported-file-type",
        });
      } else {
        toast.error(error.message || "Failed to upload files", {
          toastId: "upload-failed",
        });
      }

      // Re-throw error for parent component handling if needed
      throw error;
    }
  };

  const brandingData = useMemo(
    () => [
      {
        content: (
          <div className="bg-white rounded-lg shadow-sm p-2">
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col md:flex-row md:items-center gap-6">
                {form.logo?.data ? (
                  <div className="relative group">
                    <img
                      src={form.logo.data}
                      alt="Company Logo"
                      className="h-24 w-auto object-contain border border-gray-200 rounded-lg p-3 bg-white transition-all"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 flex items-center justify-center transition-all rounded-lg">
                      <button
                        className="opacity-0 group-hover:opacity-100 bg-primary-500 hover:bg-primary-600 text-white rounded-full p-2 transition-all transform hover:scale-105"
                        onClick={() =>
                          document.getElementById("logo-upload").click()
                        }
                        aria-label="Replace logo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="h-32 w-64 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors cursor-pointer"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 text-gray-400 mb-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="text-gray-500 font-medium">
                      Click to upload logo
                    </span>
                  </div>
                )}

                <div className="flex flex-col space-y-4">
                  <div className="text-sm text-gray-600 space-y-1">
                    <p className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-2 text-primary-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Recommended size: 300x100 pixels
                    </p>
                    <p className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-2 text-primary-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Max file size: 2MB
                    </p>
                    <p className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-2 text-primary-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      Formats: PNG, JPG, SVG
                    </p>
                  </div>
                </div>
              </div>

              <input
                type="file"
                id="logo-upload"
                className="hidden"
                accept="image/png, image/jpeg, image/svg+xml"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    if (file.size > 2 * 1024 * 1024) {
                      alert("File size exceeds 2MB limit");
                      return;
                    }

                    handleAddFile([file]);
                  }
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [form]
  );

  return (
    <>
      <SettingsHeader title="General" />
      <SettingsPanel title="Branding" data={brandingData} />
      <SettingsPanel title="General" data={data} />
      <AddressModal
        visible={addressModalVisible}
        setVisible={setAddressModalVisible}
        onClose={() => setAddressModalVisible(false)}
        title="Edit Address"
        submit={handleAddressSave}
        footer={
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setAddressModalVisible(false)}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button
              onClick={() => handleAddressSave(form)}
              className="btn btn-primary"
              disabled={modalLoading}
            >
              Save Changes
            </button>
          </div>
        }
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default GeneralSettings;
