import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const jobItemsApi = api.injectEndpoints({
  tagTypes: ["JobItems"],
  endpoints: (builder) => ({
    getJobItems: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `job/items?${query}`;
      },
      transformResponse: (response) => response?.data,
      providesTags: (data) =>
        data?.jobItems
          ? [
              ...data.jobItems.map(({ _id }) => ({
                type: "JobItems",
                id: _id,
              })),
              { type: "JobItems", id: "LIST" },
            ]
          : [{ type: "JobItems", id: "LIST" }],
    }),
    addJobItem: builder.mutation({
      query({ jobId, data, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: `jobs/${jobId}/items?${params}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => {
        return [
          { type: "Jobs", id: data.jobId },
          { type: "Timeline", id: "LIST" },
        ];
      },
    }),
    updateJobItem: builder.mutation({
      query({ data, jobId, jobItemId, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: `jobs/${jobId}/items/${jobItemId}?${params}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, _error, arg) => {
        return [
          { type: "Jobs", id: arg.jobId },
          { type: "Timeline", id: "LIST" },
        ];
      },
    }),
    deleteJobItems: builder.mutation({
      query({ data, ...rest }) {
        const params = getSearchParams(rest);
        return {
          url: `jobs/${rest.jobId}/items/${rest.jobItemId}?${params}`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, data) => {
        return [
          { type: "Jobs", id: data.jobId },
          { type: "Timeline", id: "LIST" },
        ];
      },
    }),
    getJobItemMedia: builder.query({
      query: ({ id, ...rest }) =>
        `job/items/${id}/media?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (result) => {
        const arr = Array.isArray(result)
          ? result
          : [...result.misc, result.pod, result.bol];
        return result
          ? [
              ...arr.map(({ id }) => ({ type: "Media", id })),
              { type: "Media", id: "LIST" },
            ]
          : [{ type: "Media", id: "LIST" }];
      },
    }),
    removePartFromJobItem: builder.mutation({
      query: ({ jobId, jobItemId, partId }) => {
        return {
          url: `jobs/${jobId}/items/${jobItemId}/parts/${partId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { jobId, jobItemId }) => [
        { type: "JobItems", id: jobItemId },
        { type: "Jobs", id: jobId },
      ],
    }),
    addPartsToJobItem: builder.mutation({
      query: ({ jobId, jobItemId, data }) => {
        return {
          url: `jobs/${jobId}/items/${jobItemId}/parts`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, { jobId, jobItemId }) => [
        { type: "JobItems", id: jobItemId },
        { type: "Jobs", id: jobId },
        { type: "Timeline", id: "LIST" },
      ],
    }),
    approveJobItems: builder.mutation({
      query: ({ id, data }) => {
        return {
          method: "PUT",
          url: `jobs/${id}/items-approval`,
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "JobItems", id: "LIST" },
        { type: "Jobs", id },
        { type: "Timeline", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJobItemsQuery,
  useAddJobItemMutation,
  useUpdateJobItemMutation,
  useDeleteJobItemsMutation,
  useRemovePartFromJobItemMutation,
  useAddPartsToJobItemMutation,
  useApproveJobItemsMutation,
} = jobItemsApi;
