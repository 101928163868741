import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  lazy,
  Suspense,
} from "react";
import {
  AssignedToSelect,
  ClientCard,
  SidebarContainer,
} from "../../../components";
import {
  Tabs,
  Button,
  Badge,
  Toggle,
  Avatar,
  EditableText,
  Modal,
  Dropdown,
  ButtonDropdown,
  Spinner,
} from "../../../shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobInfo from "./Info";
import Accessories from "./Accessories";
import {
  useAuth,
  useDebounceSearch,
  useJob,
  JobProvider,
  useCrumbs,
  useConfig,
  useForm,
} from "../../../hooks";
import { jobUpdated } from "../../../redux/reducers/jobs/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { FORM_OPTIONS } from "../table.config";
import SidebarContent from "./SidebarContent";
import {
  ChevronDoubleLeftIcon,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import request, { renderQueries } from "../../../utils/request";
import { getNextStage, getStage } from "../job-stages";
import { camelCase } from "lodash";
import { toast } from "react-toastify";
import {
  useDeleteJobsMutation,
  useGetJobQuery,
  useUpdateGlobalJobRatesMutation,
  useUpdateJobMutation,
} from "../../../redux/services/jobs/jobsApi";
import { useAddInvoiceMutation } from "../../../redux/services/invoices/invoicesApi";
import { capitalizeFirstLetter } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";
import JobEquipment from "./Equipment";
import { useGetEmployeesQuery } from "../../../redux/services/employees/employeesApi";
import { useAddTemplateMutation } from "../../../redux/services/templates/api";
import { useApproveJobItemsMutation } from "@/redux/services/jobs/jobItems";
import { renderValidations } from "@/shared/Table/table.utils";
import JobQuotes from "./Quotes";
import { DeleteJobModal, TemplateModal, JobActionModal } from "../../../modals";

// Create a reusable modal wrapper with loading state
const LazyModal = ({ children, ...props }) => (
  <Suspense
    fallback={
      <Modal {...props} content={<Spinner size="lg" className="m-auto" />} />
    }
  >
    {children}
  </Suspense>
);

const JobsShow = () => {
  const params = useParams();

  return (
    <JobProvider jobId={params.id}>
      <JobsShowDetails />
    </JobProvider>
  );
};

// Move this outside the component or memoize it if it needs component scope
const getModalContentForStage = (stage, props) => {
  if (!stage?.modal) return "";

  if (React.isValidElement(stage.modal)) {
    return stage.modal;
  }

  if (typeof stage.modal === "function") {
    return stage.modal(props);
  }

  return stage.modal;
};

// Move this outside the component to avoid recreating on each render
const getValidationsForStatus = (status) => {
  switch (status) {
    case "Booked":
      return renderValidations([{ id: "assignedTo", required: true }]);
    case "Pending":
      return renderValidations([
        { id: "appointmentDate", required: true },
        { id: "selectedItems", required: true },
      ]);
    default:
      return renderValidations([]); // Always return a consistent structure
  }
};

const JobsShowDetails = () => {
  // Library Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Custom Hooks
  const { user } = useAuth();
  const { configs, appType } = useConfig();
  const crumbs = useCrumbs();

  // React Hooks
  const [loading, setLoading] = useState(true);
  const [modalLoad, setModalLoad] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);
  const [mainActionModal, setMainActionModal] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [createInvoiceVisible, setCreateInvoiceVisible] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [validations, setValidations] = useState(() => renderValidations([]));
  const [allEmployees, setAllEmployees] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const isServiceRepair = appType === "serviceRepair";

  // Form hook
  const { form, setFormInit, clearForm, updateForm, isValid } = useForm(
    isServiceRepair ? { validations } : {}
  );

  const {
    data: jobData,
    loading: jobLoading,
    jobId: _id,
    isNew,
    isPending,
    isBooked,
    isActive: isJobActive,
    getItemsByType,
    approveJobItems,
  } = useJob();

  const [addInvoice, { isLoading: addingInvoice }] = useAddInvoiceMutation();
  const [updateJob, { isLoading: jobUpdating }] = useUpdateJobMutation();
  const [updateGlobalJobRates, { isLoading: globalRatesUpdating }] =
    useUpdateGlobalJobRatesMutation();

  const job = jobData || {};

  const { search, handleSearch } = useDebounceSearch();

  // Modify the employees query to include pagination and search
  const {
    data: employeesData,
    isLoading: loadingEmployees,
    isFetching: fetchingMoreEmployees,
  } = useGetEmployeesQuery({
    role: "Technician",
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
  });

  // Update validations when job status changes
  useEffect(() => {
    if (job.status) {
      setValidations(getValidationsForStatus(job.status));
    }
  }, [job.status]);

  // Reset pagination and employees list when search changes
  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setAllEmployees([]); // Reset the accumulated list
  }, [search.term]);

  // Update allEmployees when new data comes in
  useEffect(() => {
    if (employeesData?.employees) {
      if (pageIndex === 0) {
        // Reset the list if it's the first page
        setAllEmployees(employeesData.employees);
      } else {
        // Append new employees to existing list
        setAllEmployees((prev) => {
          const newEmployees = employeesData.employees.filter(
            (newEmp) =>
              !prev.some((existingEmp) => existingEmp._id === newEmp._id)
          );
          return [...prev, ...newEmployees];
        });
      }
    }
  }, [employeesData?.employees, pageIndex]);

  // Function to load more data
  const handleLoadMoreEmployees = (nextPage) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: nextPage - 1, // Subtract 1 because pageIndex is 0-based
    }));
  };

  // Update form helper
  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  // Constant Variables
  const jobId = job.jobId || job.loadNumber || job.orderNumber;
  const editing = isEditing;
  const stage = getStage(job.status);

  /* 
    Helper function to get rate based on current job options
  */
  function renderRate(name, useGlobal) {
    if (useGlobal || form.useGlobalRates || job.useGlobalRates) {
      return configs.rates?.[name];
    } else {
      return job.client?.rates?.[name];
    }
  }

  const jobShowActions = useMemo(
    () =>
      [
        {
          value: "edit",
          appTypes: ["trucking", "serviceRepair"],
          label: "Edit",
          onClick: () => setisEditing(true),
        },
        {
          value: "delete",
          appTypes: ["trucking", "serviceRepair"],
          label: "Delete",
          onClick: () => setDeleteVisible(true),
        },
        {
          value: "template",
          appTypes: ["serviceRepair"],
          label: "Create Template",
          onClick: () => setShowTemplateModal(true),
        },
      ].filter((i) => {
        if (!i.appTypes.includes(appType)) return false;
        if (isNew && (i.value === "edit" || i.value === "delete")) return false;
        return true;
      }),
    [appType, job.status]
  );

  useEffect(() => {
    if (configs) {
      crumbs.setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  useEffect(() => {
    return () => {
      crumbs.setRightContent(null);
    };
  }, []);

  // First useEffect for form initialization
  useEffect(() => {
    if (job._id) {
      const init = {
        ...job,
        fuelRate: job.fuelRate || renderRate("fuelRate") || renderRate("fuel"),
        baseRate: job.baseRate || renderRate("baseRate") || renderRate("base"),
        flatRate: job.flatRate || renderRate("flatRate"),
        transactionDate: job.transactionDate,
      };

      crumbs.updateCrumbName({
        name: jobId,
        path: "/jobs/" + job._id,
      });
      crumbs.setLoading(false);
      setFormInit(init);
      setTimeout(() => setLoading(false), 500);
    }
  }, [job._id, editing]);

  // Separate useEffect for right content
  useEffect(() => {
    crumbs.setRightContent(
      <Dropdown
        align="right"
        trigger={
          <EllipsisHorizontalIcon className="cursor-pointer text-gray-800 w-6" />
        }
        data={jobShowActions}
      />
    );
  }, []);

  useEffect(() => {
    if (
      job.assignedTo &&
      JSON.stringify(assignedUsers) !== JSON.stringify(job.assignedTo)
    ) {
      setAssignedUsers(
        job.assignedTo
          .filter((userData) => userData)
          .map((userData) => ({
            ...userData,
            id: userData?._id || userData?.id, // Ensure we have a consistent id field
          }))
      );
      setFormInit({ ...form, assignedTo: job.assignedTo });
    }
  }, [job.assignedTo, job.assignedTo?.length]);

  const tabs = useMemo(() =>
    [
      {
        id: "Info",
        appTypes: ["trucking", "serviceRepair"],
        content: (
          <JobInfo
            form={{ form, setFormInit, clearForm, updateForm }}
            job={job}
            editing={editing}
            isJobActive={isJobActive}
            isNew={isNew}
            isPending={isPending}
            loading={jobLoading || loading}
            setLoading={setLoading}
            updateJobData={updateJobData}
            approveJobItems={approveJobItems}
          />
        ),
      },
      // {
      //   id: "Equipment",
      //   content: (
      //     <JobEquipment
      //       form={{ form, setFormInit, clearForm, updateForm }}
      //       job={job}
      //     />
      //   ),
      // },
      {
        id: "Quotes",
        appTypes: ["serviceRepair"],
        hidden: "New,Pending,Booked",
        content: (
          <JobQuotes
            form={{ form, setFormInit, clearForm, updateForm }}
            job={job}
            quotes={getItemsByType("Quote")}
            isJobActive={isJobActive}
            editing={editing}
            isNew={isNew}
            isPending={isPending}
            loading={jobLoading || loading}
            setLoading={setLoading}
            updateJobData={updateJobData}
          />
        ),
      },
      // {
      //   id: "Legs",
      //   title: `Legs (${job.legs?.length || 0})`,
      //   content: (
      //     <LegsTable
      //       job={job}
      //       setVisible={setLegModalVisible}
      //       visible={legModalVisible}
      //       renderHeader={() => renderHeader(job)}
      //       form={legForm}
      //       editing={editing}
      //     />
      //   ),
      //   hidden: "New,Pending,Booked",
      // },
      {
        id: "Accessories",
        appTypes: ["trucking", "serviceRepair"],
        content: (
          <Accessories
            appType={appType}
            accessories={job?.accessories}
            user={user}
            jobId={job?._id}
            editing={editing}
          />
        ),
      },
      // {
      //   id: "Files",
      //   content: (
      //     <Files
      //       user={user}
      //       jobId={job?._id}
      //       files={job?.files}
      //       editing={editing}
      //     />
      //   ),
      //   hidden: "New",
      // },
    ].filter((item) => {
      if (!item.appTypes.includes(appType)) {
        return false;
      }
      if (item.hidden?.includes(job.status)) {
        return false;
      }
      return true;
    })
  );

  function handleTabClick(_e, _item, path) {
    // location.hash = e.target.innerHTML;
    navigate(path);
  }

  function getDefaultTab() {
    if (location.hash) {
      const tabHash = location.hash.slice(1);
      return tabs.findIndex((tab) => tab.id === tabHash);
    }
    return;
  }

  const title = (
    <div className="flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="jobId"
        value={jobId}
        size="md"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        disabled={!isAuthorized("owner,admin")}
        loading={modalLoad}
        onSave={(e) => updateJobData({ ...e.data, loadNumber: e.value })}
      />
      {job.status && (
        <Badge
          color={stage?.color}
          label={capitalizeFirstLetter(job.status)}
          size="lg"
          className="ml-2"
        />
      )}
      {isAuthorized("owner,admin") && job.invoiceId?.invoiceId && (
        <Badge
          // color={stage?.color}
          link={`/invoices/${job.invoiceId._id}`}
          label={`Invoice: ${job.invoiceId.invoiceId}`}
          size="lg"
          className="ml-2"
        />
      )}
    </div>
  );

  /* 
    Handle use global rates toggle
  */
  async function handleGlobalRates({ target }) {
    setLoading(true);
    let data = { useGlobalRates: target.checked, id: target.id };

    data.fuelRate =
      renderRate("fuelRate", data.useGlobalRates) ||
      renderRate("fuel", data.useGlobalRates);
    data.baseRate =
      renderRate("baseRate", data.useGlobalRates) ||
      renderRate("base", data.useGlobalRates);

    if (form.useFlatRate) {
      data.flatRate = renderRate("flatRate", data.useGlobalRates);
    }

    const res = await updateGlobalJobRates({ id: form._id, data }).unwrap();

    setFormInit(res.data);
    // dispatch(jobUpdated(res.data.data));
    setLoading(false);
  }

  /* 
    Handle use flat rate toggle
  */
  async function handleFlatRate({ target }) {
    setLoading(true);

    let data = {
      useFlatRate: target.checked,
      id: target.id,
      useGlobalRates: form.useGlobalRates,
    };

    data.flatRate = target.checked && renderRate("flatRate");
    setFormInit(data);
    const res = await updateGlobalJobRates({ id: form._id, data }).unwrap();

    // dispatch(jobUpdated(res.data.data));
    setLoading(false);
  }

  function getNextStatus() {
    return getNextStage(camelCase(job.status), { job, form }).title;
  }

  /* 
    Update Job 
  */
  async function updateJobData(data) {
    setModalLoad(true);

    const updateData = {
      id: _id,
      data: {
        ...data,
      },
    };

    setMainActionModal(false);
    const res = await updateJob(updateData).unwrap();

    if (res.status) {
      dispatch(jobUpdated(res.data));
      if (editing) {
        setisEditing(false);
      }
    }

    setModalLoad(false);
    return res;
  }

  async function updateJobStatus(e) {
    let updateData = { ...form, items: job.items, legs: job.legs };

    if (updateData.selectedItems?.length) {
      await approveJobItems(updateData.selectedItems);
    }

    if (assignedUsers.length) {
      updateData.assignedTo = assignedUsers.map((i) => i._id);
    }

    if (stage.id === "new" || editing) {
      updateData.transactionDate = form.transactionDate;
    }

    if (!editing) {
      updateData.status = getNextStatus();
    }

    updateJobData(updateData);
  }

  function renderButtonTitle() {
    const res = getStage(camelCase(job.status));
    return res?.button;
  }

  const actions = useMemo(() => {
    return (
      isAuthorized("owner,admin") && (
        <>
          {(stage?.secondaryButton || (isJobActive && !job.invoiceId)) && (
            <Button
              size="sm"
              onClick={() => setCreateInvoiceVisible(true)}
              type="alternative"
            >
              Create Invoice
            </Button>
          )}
          {(stage?.button || editing) && (
            <Button
              split
              onClick={() => setMainActionModal(true)}
              className="min-w-24"
              size="sm"
            >
              {editing && job.status !== getStage(1).title
                ? "Save"
                : renderButtonTitle()}
            </Button>
          )}
        </>
      )
    );
  }, [stage, isJobActive, job.invoiceId, editing, isNew]);

  const rateActions = isAuthorized("owner,admin") && (editing || isNew) && (
    <>
      <div className="flex items-center space-x-2">
        <span className="text-gray-800">Global Rates:</span>
        <Toggle
          id="useGlobalRates"
          size="sm"
          checked={form.useGlobalRates}
          onChange={(e) => handleGlobalRates({ target: e.target })}
        />
      </div>
      <div className="flex items-center  space-x-2">
        <span className="text-gray-800">Flat Rate:</span>
        <Toggle
          id="useFlatRate"
          size="sm"
          checked={form.useFlatRate}
          onChange={(e) => handleFlatRate({ target: e.target })}
        />
      </div>
    </>
  );

  const saveAssignedUsers = async (users) => {
    try {
      await updateJobData({ assignedTo: users.map((i) => i._id || i.id) });
      setAssignedUsers(users);
    } catch (error) {
      console.error("Error saving assigned users:", error);
    }
  };

  const handleEmployeesSearch = (term) => {
    handleSearch({ term, useSearch: !!term });
  };

  const assignedTo = useMemo(
    () => (
      <AssignedToSelect
        onChange={saveAssignedUsers}
        value={assignedUsers}
        users={allEmployees.map((emp) => ({
          ...emp,
          id: emp._id,
        }))}
        isEditable={isAuthorized("owner,admin")}
        onLoadMore={handleLoadMoreEmployees}
        hasMore={employeesData?.hasNext}
        loading={loadingEmployees || fetchingMoreEmployees}
        onSearch={handleEmployeesSearch}
        onCancel={() => setAssignedUsers(assignedUsers)}
      />
    ),
    [
      assignedUsers,
      allEmployees,
      loadingEmployees,
      fetchingMoreEmployees,
      employeesData?.hasNext,
    ]
  );

  async function createInvoice() {
    const invoiceData = { jobs: [{ _id: job._id }], client: job.client };
    const res = await addInvoice(invoiceData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "inv-job-create",
    });
    setCreateInvoiceVisible(false);
  }

  let headerActions;
  switch (appType) {
    case "serviceRepair":
      headerActions = assignedTo;
      break;
    default:
      headerActions = rateActions;
  }

  const renderHeader = useMemo(() => {
    return (headerProps) => {
      if (!headerProps) return null;

      return (
        <div ref={headerProps.headerRef} onClick={(e) => e.stopPropagation()}>
          <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
            <div>{headerProps.title}</div>
            <div className="flex items-center space-x-3">
              <div className="md:flex items-center space-x-3 hidden">
                {headerActions}
              </div>
              {headerProps.actions}
              {headerProps.setOpen && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    headerProps.setOpen(true);
                  }}
                  className="hover:bg-gray-100 cursor-pointer p-2 rounded-lg block lg:hidden"
                >
                  <ChevronDoubleLeftIcon className="w-5 text-gray-800" />
                </div>
              )}
            </div>
          </header>
          {headerProps.actions && (
            <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
              {headerProps.actions}
            </div>
          )}
        </div>
      );
    };
  }, [headerActions]);

  async function handleCreateTemplate(data) {}

  const sidebarContent = ({ ...sidebarProps }) => {
    return (
      <SidebarContent
        updateForm={updateForm}
        form={form}
        // handleUpdate={handleUpdate}
        onFormChange={onFormChange}
        job={job}
        {...sidebarProps}
      />
    );
  };

  const modalFooter = (
    <>
      <Button onClick={() => setMainActionModal(false)} type="alternative">
        Cancel
      </Button>
      <Button
        loading={modalLoad}
        disabled={modalLoad || !isValid}
        onClick={updateJobStatus}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const modalCreateFooter = (
    <>
      <Button onClick={() => setCreateInvoiceVisible(false)} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingInvoice}
        disabled={addingInvoice}
        onClick={createInvoice}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  // async function handleDelete() {
  //   const res = await deleteJobs({ data: [job._id] });

  //   toast(res.data.message, {
  //     type: res.data.status,
  //     toastId: "delete-jobs",
  //   });

  //   if (res.data.status === "success") {
  //     navigate("/jobs");
  //   }

  //   return res.data;
  // }

  const modalContent = useMemo(() => {
    const modalProps = {
      form,
      setFormInit,
      clearForm,
      updateForm,
      updateJobData,
      employees: allEmployees,
      handleLoadMoreEmployees,
      handleEmployeesSearch,
      employeesQuery: employeesData,
      assignedUsers,
      setAssignedUsers,
    };

    // Get the current stage based on the job status
    const currentStage = getStage(job.status);

    if (currentStage) {
      const stageContent = getModalContentForStage(currentStage, modalProps);
      if (stageContent) return stageContent;
    }

    // Fallback messages
    if (editing) return "Are you sure you want to save this job?";
    if (job.status === "New")
      return "Are you sure you want to publish this job?";
    if (job.status === "Pending")
      return "Are you sure you want to start this job?";
    if (job.status === "En Route")
      return "Are you sure you want to mark this job complete?";
    return "";
  }, [
    job.status,
    editing,
    form,
    allEmployees,
    employeesData,
    assignedUsers,
    // Add other dependencies that affect the modal content
  ]);

  const renderTabHeader = useMemo(() => {
    return (headerProps) => (
      <div className="flex flex-row justify-between bg-white items-center">
        <div>{headerProps}</div>

        <div className="flex flex-row items-center mr-6">
          <Dropdown
            align="right"
            closeOnClick
            trigger={
              <div className="flex flex-row items-center">
                <Avatar
                  src={job.client?.avatar}
                  name={job.client?.name}
                  size="xs"
                />
                <div className="ml-2 text-md text-gray-700">
                  {job.client?.name}
                </div>
              </div>
            }
            data={<ClientCard client={job.client} />}
            itemClick={onFormChange}
          />
        </div>
      </div>
    );
  }, [job.client, onFormChange]);

  // Handle loading state
  if (jobLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner size="lg" />
      </div>
    );
  }

  // Handle error state
  if (!jobData) {
    return <div>Error loading job data.</div>; // Show an error message
  }

  return (
    <>
      <div className="flex items-center justify-between px-4">
        <div className="flex items-center space-x-24"></div>
      </div>
      <SidebarContainer
        title={title}
        header={renderHeader}
        actions={actions}
        hideSidebar={!isAuthorized("owner,admin")}
        sidebarContent={sidebarContent}
        sidebarTitle={<h2 className="text-xl bg-white py-1">Communications</h2>}
      >
        {({ wrapperStyle, panelStyle }) => (
          <Tabs
            className="w-full h-full flex flex-col"
            style={wrapperStyle}
            panelStyle={panelStyle}
            useSearchParams
            panelClasses="p-2 border-t"
            tabListClasses="bg-white px-2 h-[38px] flex-shrink-0"
            tabclassName="mx-1"
            tabHeader={renderTabHeader}
            id="job-show"
            onClick={handleTabClick}
            data={tabs}
          />
        )}
      </SidebarContainer>
      {mainActionModal && (
        <JobActionModal
          visible={mainActionModal}
          setVisible={setMainActionModal}
          modalContent={modalContent}
          stage={stage}
          modalLoad={modalLoad}
          isValid={isValid}
          onSubmit={updateJobStatus}
        />
      )}
      {deleteVisible && (
        <DeleteJobModal
          visible={deleteVisible}
          setVisible={setDeleteVisible}
          jobs={[job]}
        />
      )}
      {createInvoiceVisible && (
        <LazyModal>
          <Modal
            visible={createInvoiceVisible}
            setVisible={setCreateInvoiceVisible}
            onClose={() => setCreateInvoiceVisible(false)}
            title="Create Invoice"
            footer={modalCreateFooter}
            content="Are you sure you want to create an invoice with this job?"
            size="md"
            footerPosition="right"
          />
        </LazyModal>
      )}
      {showTemplateModal && (
        <TemplateModal
          visible={showTemplateModal}
          setVisible={setShowTemplateModal}
          onClose={() => setShowTemplateModal(false)}
          onSubmit={handleCreateTemplate}
          type="job"
          template={{
            name: "Job Template",
            items:
              job?.items?.map((i) => ({
                serviceType: i.serviceType,
                priority: i.priority,
                serviceFee: i.serviceFee,
                summary: i.summary,
                hours: i.hoursWorked,
                parts: i.parts,
              })) || [],
          }}
        />
      )}
    </>
  );
};

export default JobsShow;
