import { Modal, Input, Select, Tabs } from "@/shared";
import { useState, useEffect } from "react";
import CodeEditor from "@/shared/CodeEditor";
import { useGetDefaultPdfTemplateQuery } from "@/redux/services/pdf/pdfTemplateApi";

const PDFTemplateModal = ({ visible, onClose, template }) => {
  const [form, setForm] = useState({
    name: template?.name || "",
    type: template?.type || "",
    html: template?.html || "",
    css: template?.css || "",
    settings: template?.settings || {},
  });

  const [editorStates, setEditorStates] = useState({
    html: {
      value: template?.html || "",
      language: "html",
      theme: "dark",
      height: "calc(100vh - 300px)",
      extensions: [],
    },
    css: {
      value: template?.css || "",
      language: "css",
      theme: "dark",
      height: "calc(100vh - 300px)",
      extensions: [],
    },
  });

  const [previewHtml, setPreviewHtml] = useState("");

  // Fetch default template when type changes
  const { data: defaultTemplate, isSuccess: defaultTemplateLoaded } =
    useGetDefaultPdfTemplateQuery(form.type, {
      skip: !form.type || !!template, // Skip if no type selected or editing existing template
    });

  // Update editors with default template when available
  useEffect(() => {
    if (defaultTemplateLoaded && defaultTemplate && !template) {
      setEditorStates((prev) => ({
        html: {
          ...prev.html,
          value: defaultTemplate.html,
        },
        css: {
          ...prev.css,
          value: defaultTemplate.css,
        },
      }));

      setForm((prev) => ({
        ...prev,
        html: defaultTemplate.html,
        css: defaultTemplate.css,
        settings: {
          ...prev.settings,
          ...defaultTemplate.settings,
        },
      }));
    }
  }, [defaultTemplate, defaultTemplateLoaded, template]);

  useEffect(() => {
    // Combine HTML and CSS for preview
    const combinedHtml = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${editorStates.css.value}
          </style>
        </head>
        <body>
          ${editorStates.html.value}
        </body>
      </html>
    `;
    setPreviewHtml(combinedHtml);
  }, [editorStates.html.value, editorStates.css.value]);

  const handleEditorChange = (type, value) => {
    setEditorStates((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        value,
      },
    }));

    // Also update form state to maintain compatibility
    setForm((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const tabsData = [
    {
      id: "html",
      title: "HTML",
      content: (
        <CodeEditor
          {...editorStates.html}
          onChange={(value) => handleEditorChange("html", value)}
        />
      ),
    },
    {
      id: "css",
      title: "CSS",
      content: (
        <CodeEditor
          {...editorStates.css}
          onChange={(value) => handleEditorChange("css", value)}
        />
      ),
    },
    {
      id: "settings",
      title: "Settings",
      content: (
        <div className="space-y-4">
          <Input
            label="Name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
          <Select
            label="Type"
            value={form.type}
            onChange={(value) => setForm({ ...form, type: value })}
            options={[
              { value: "invoice", label: "Invoice" },
              { value: "workOrder", label: "Work Order" },
              { value: "jobProposal", label: "Job Proposal" },
            ]}
            required
            disabled={!!template} // Disable type change for existing templates
          />
        </div>
      ),
    },
  ];

  const handleSave = () => {
    if (!form.name || !form.type) {
      return;
    }
    // Use the editor states for saving
    onClose({
      ...form,
      html: editorStates.html.value,
      css: editorStates.css.value,
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={template ? "Edit Template" : "New Template"}
      size="xl"
      confirm={handleSave}
      submitText="Save"
    >
      <div className="flex h-[calc(100vh-200px)]">
        <div className="flex-1 border-r border-gray-200">
          <Tabs data={tabsData} className="w-full" panelClasses="mt-4" />
        </div>
        <div className="flex-1 pl-4">
          <div className="bg-white rounded-lg shadow-lg p-4 h-full">
            <div className="font-medium text-gray-700 mb-2">Live Preview</div>
            <iframe
              srcDoc={previewHtml}
              title="Template Preview"
              className="w-full h-[calc(100%-2rem)] border-0"
              sandbox="allow-same-origin"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PDFTemplateModal;
