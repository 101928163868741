import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useSigninMutation,
  useCurrentUserQuery,
  useLogoutMutation,
} from "../redux/services/account/accountApi";
import { toast } from "react-toastify";
import { PARENT_COMPANY, USER, APP_TYPE } from "../config";
import { useLocalStorage } from "./useLocalStorage";
import { PageLoading } from "../shared";
import { useConfig } from "./useConfig";
import { clearApiCache } from "../redux/api";
import PUBLIC_ROUTES from "@/constants/public.routes";

const AuthContext = createContext({
  user: null,
  loading: true,
  logout: async () => {},
  login: async () => ({ success: false }),
  isAuthenticated: false,
  initialized: false,
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useLocalStorage(USER, null);
  const { cleanUpConfig } = useConfig();
  const [_parentCompany, setParentCompany] = useLocalStorage(
    PARENT_COMPANY,
    null
  );

  const [signinMutation, { isLoading: signinLoading }] = useSigninMutation();
  const [logoutMutation, { isLoading: logoutLoading }] = useLogoutMutation();

  // Skip the current user query if we're on the login page
  const skipCurrentUser =
    !PUBLIC_ROUTES.some((path) => location.pathname.startsWith(path)) && !user;

  const { data: currentUser, isLoading: currentUserLoading } =
    useCurrentUserQuery(undefined, {
      skip: !initialized || skipCurrentUser,
    });

  const [initializationLoading, setInitializationLoading] = useState(true);

  // Combine all loading states more efficiently
  const isLoading = useMemo(
    () =>
      signinLoading ||
      logoutLoading ||
      currentUserLoading ||
      initializationLoading,
    [signinLoading, logoutLoading, currentUserLoading, initializationLoading]
  );

  // Move isAuthenticated to useMemo
  const isAuthenticated = useMemo(() => !!user?.active, [user?.active]);

  // Optimize initialization effect
  useEffect(() => {
    const initialize = async () => {
      if (initialized) return; // Prevent multiple initializations

      try {
        setInitializationLoading(true);
        if (
          user &&
          !PUBLIC_ROUTES.some((path) => location.pathname.startsWith(path))
        ) {
          // Simplified waiting mechanism
          if (currentUserLoading) {
            await new Promise((resolve) => {
              const checkLoading = setInterval(() => {
                if (!currentUserLoading) {
                  clearInterval(checkLoading);
                  resolve();
                }
              }, 100);
            });
          }
        }
        setInitialized(true);
      } finally {
        setInitializationLoading(false);
      }
    };

    initialize();
  }, [currentUserLoading, user, location.pathname, initialized]);

  // Optimize current user effect
  useEffect(() => {
    if (!initialized || currentUserLoading) return;

    if (currentUser) {
      setUser(currentUser.user);
      setParentCompany(
        currentUser.user.parentCompany || currentUser.user.organization
      );
    } else if (!skipCurrentUser) {
      setUser(null);
      setParentCompany(null);
    }
  }, [currentUser, currentUserLoading, initialized, skipCurrentUser]);

  // Handle authentication state changes
  useEffect(() => {
    if (!initialized) return;

    const isLoginPage = location.pathname === "/login";

    if (user && isLoginPage) {
      // Redirect authenticated users away from login page
      const intendedPath = location.state?.from?.pathname || "/";
      navigate(intendedPath, { replace: true });
    } else if (!user && !isLoginPage && !currentUserLoading) {
      // Redirect unauthenticated users to login page
      // Allow access to activation and password reset routes
      if (PUBLIC_ROUTES.includes(location.pathname)) {
        return;
      }
      // Redirect to login with current location
      navigate("/login", {
        replace: true,
        state: { from: location },
      });
    }
  }, [user, initialized, location.pathname]);

  const loginUser = async (credentials) => {
    try {
      const userData = await signinMutation({
        user: credentials,
      }).unwrap();

      if (userData.status === "success") {
        setUser(userData.user);
        if (userData.user?.parentCompany) {
          localStorage.setItem(PARENT_COMPANY, userData.user.parentCompany);
        }
        return { success: true };
      }

      return { success: false, error: userData.message || "Login failed" };
    } catch (err) {
      return {
        success: false,
        error: err.data?.message || "Login failed",
        details: err,
      };
    }
  };

  const logoutUser = async () => {
    try {
      await logoutMutation().unwrap();
    } catch (err) {
      console.error("Logout error:", err);
    } finally {
      // Clear everything regardless of logout API success
      setUser(null);
      localStorage.removeItem(PARENT_COMPANY);

      // Clear Redux state
      cleanUpConfig();
      clearApiCache();

      // Navigate to login
      navigate("/login", { replace: true });
    }
  };

  const value = useMemo(
    () => ({
      user,
      loading: isLoading,
      logout: logoutUser,
      login: loginUser,
      isAuthenticated,
      initialized,
    }),
    [user, isAuthenticated, isLoading, initialized]
  );

  // Only show loading on initial load
  if (!initialized && isLoading) {
    return <PageLoading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
