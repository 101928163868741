import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";
import classNames from "classnames";

export const ButtonDropdown = (props) => {
  const [dropShow, setDropShow] = useState(false);
  const { split = false } = props;

  function handleClick() {
    if (!split && props.onClick) {
      props.onClick();
    }
    setDropShow(!dropShow);
  }

  const trigger = split ? (
    <div className="inline-flex">
      <Button
        {...props}
        className={classNames("rounded-r-none ", props.className)}
        type={props.type}
        onClick={props.onClick}
        size={props.size}
      >
        {props.children || props.title}
      </Button>
      <Button
        className="rounded-l-none border-l border-gray-300 !px-1"
        type={props.type}
        onClick={handleClick}
        size={props.size}
      >
        <ChevronDownIcon className="h-4 w-4" />
      </Button>
    </div>
  ) : (
    <Button
      {...props}
      className="flex items-center"
      type={props.type}
      onClick={handleClick}
      size={props.size}
    >
      {props.children || props.title}
      <ChevronDownIcon className="h-4 w-4 ml-2" />
    </Button>
  );

  return (
    <Dropdown
      {...props}
      id="button-dropdown"
      closeOnClick
      data={props.data || props.options}
      trigger={trigger}
    />
  );
};

export default ButtonDropdown;
