import { useRef, useState, Suspense, lazy } from "react";
import { useDispatch } from "react-redux";
import { renderCustomColumnDefs } from "../../../shared/Table/table.utils";
import { COLUMN_DEFINITIONS } from "./table.config";
import { useAuth } from "../../../hooks/useAuth";
import { ExpandedItem } from "./ExpandedItem";
import _ from "lodash";
import { isAuthorized } from "../../../utils/authority";
import { Badge, Dropdown, Spinner } from "../../../shared";
import { JobItemsTable } from "../../../components";
import {
  BanknotesIcon,
  EllipsisHorizontalIcon,
  PaperAirplaneIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { IconButton } from "../../../components";
import { capitalizeFirstLetter } from "@/utils";
const ItemCreateModal = lazy(() => import("./ServiceCreate/Modal"));

export const ItemsTable = ({
  job,
  form = {},
  visible = false,
  setVisible,
  items,
  defaultExpanded,
  ...props
}) => {
  const tableRef = useRef(null);
  const { user } = useAuth();
  const [addVisible, setAddVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const newTableData = [
    {
      id: "status",
      size: 70,
      cell: ({ getValue, row }) => {
        const statusHasBeenUpdated =
          row.original.approval?.status !== "pending";
        const isApproved = row.original.approval?.status === "approved";
        return (
          <>
            <Badge className="!px-1 mr-1" color="blue">
              {getValue()}
            </Badge>
            {statusHasBeenUpdated && (
              <Badge className="!px-1" color={isApproved ? "green" : "red"}>
                {capitalizeFirstLetter(row.original.approval.status)}
              </Badge>
            )}
          </>
        );
      },
    },
    ...COLUMN_DEFINITIONS,
  ];

  const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  function handleEditItem(e, jobData) {
    const updateItem = {
      ...e,
      client: jobData?.client,
      appointmentDate: e.appointmentDate,
      appointmentTime: e.appointment?.time,
      job: jobData,
      edit: true,
    };

    form.setFormInit(updateItem, { setErrors: true });

    setVisible(true);
  }

  function handleAddAccessoryToLeg(e) {
    const updateItem = {
      ...e,
      client: job?.client,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setAddVisible(true);
  }

  function onModalCancel() {
    form.clearForm();
    setVisible(false);
  }

  function resetExpandedRows() {
    tableRef.current.resetExpandedRows(false);
  }

  return (
    <>
      <JobItemsTable
        id={props.id}
        tableData={items}
        columns={NEW_COLUMN_DEFINITIONS}
        loading={loading}
        pageSize={100}
        innerWrapClass="overflow-y-visible"
        defaultExpanded={defaultExpanded}
        // globalSearchFilters={["transactionDate", "loadNumber", "orderNumber"]}
        job={job}
        expandedLayout={(e) => (
          <ExpandedItem
            {...e}
            {...props}
            form={form}
            job={job}
            loading={loading}
            setLoading={setLoading}
            addAccessory={handleAddAccessoryToLeg}
            editItem={handleEditItem}
            resetExpandedRows={resetExpandedRows}
            setCreateModalVisible={setVisible}
          />
        )}
        {...props}
      />
      <Suspense fallback={<Spinner />}>
        <ItemCreateModal
          visible={visible}
          setVisible={setVisible}
          onCancel={onModalCancel}
          form={form}
          job={job}
        />
      </Suspense>

      {/* <Accessories
        user={user}
        legId={form._id}
        accessories={form.accessories}
        visible={addVisible}
        setVisible={setAddVisible}
        removeVisible={removeVisible}
        setRemoveVisible={setRemoveVisible}
      /> */}
    </>
  );
};

export default ItemsTable;
