import classNames from "classnames";
import { useState, useRef } from "react";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";
import {
  PopoverButton,
  Popover as PopoverEl,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

export const Popover = (props) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: props.position || "bottom",
    modifiers: [
      { name: "offset", options: { offset: props.offset || [0, 8] } },
      // Add strategy modifier to ensure popover is always on top
      { name: "preventOverflow", options: { boundary: "viewport" } },
    ],
  });

  const popClasses = classNames(
    "border top-full left-0 mt-2 p-2 bg-white shadow-lg rounded-lg text-sm max-w-96",
    // Increase z-index to ensure popover appears above modal
    "z-[9999]",
    props.className
  );

  const handleMouseEnter = () => {
    if (props.hover) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.hover) {
      setIsHovered(false);
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setIsHovered(false);
  };

  return (
    <PopoverEl>
      {({ open, close }) => (
        <>
          <div
            ref={setTargetElement}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <PopoverButton>{props.trigger}</PopoverButton>
          </div>

          <Portal>
            <div
              ref={popperElRef}
              style={{
                ...styles.popper,
                // Ensure popover container also has high z-index
                zIndex: 9999,
              }}
              {...attributes.popper}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Transition
                show={open || (props.hover && isHovered)}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => {
                  setPopperElement(null);
                  handleClose();
                }}
              >
                <PopoverPanel static className={popClasses}>
                  {props.content || (
                    <div className="space-y-2">{props.children}</div>
                  )}
                </PopoverPanel>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </PopoverEl>
  );
};

export default Popover;
