import { Button, SettingsPanel, Spinner } from "../../../shared";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../redux/services/users/usersApi";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { roles } from "../../../utils/route.utils";
import { capitalizeFirstLetter } from "../../../utils";
import Radio from "../../../shared/Radio";
import { useEffect, useState } from "react";
import { SettingsHeader } from "..";
import ButtonDropdown from "../../../shared/ButtonDropdown";
import { sendEmail } from "../../../redux/reducers/users/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCrumbs } from "../../../hooks/useCrumbs";

export const UserShow = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const crumbs = useCrumbs();
  const { data: userData, isLoading, isFetching } = useGetUserQuery(params.id);
  const [updateUser] = useUpdateUserMutation();

  const loading = isLoading || isFetching;
  const user = userData || {};
  const authRole = user.accountHolder ? "owner" : user.authority;
  const [role, setRole] = useState("");

  useEffect(() => {
    crumbs.updateCrumbName({
      name: user.fullName,
      path: `/settings/users/${user._id}`,
    });
    crumbs.setLoading(false);
  }, [crumbs.loading, user._id]);

  useEffect(() => {
    crumbs.setLoading(true);
    setRole(authRole);
  }, [authRole]);

  function itemLayout(item, i) {
    const added = format(new Date(2014, 1, 11), "PP");
    return (
      <div className="grid grid-cols-3 p-4 bg-white rounded-b-lg">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="text-gray-900 font-medium">Email</div>
              <div className="text-gray-600">{user.email}</div>
            </div>
            <div>
              <div className="text-gray-900 font-medium">Status</div>
              <div className="text-gray-600">
                {user.active ? "Active" : "Inactive"}
              </div>
            </div>
            <div>
              <div className="text-gray-900 font-medium">Date Added</div>
              <div className="text-gray-600">{added}</div>
            </div>
          </>
        )}
      </div>
    );
  }

  function roleLayout(item, i) {
    return (
      <div
        className="flex items-center space-x-3 p-3 bg-white rounded-b-lg"
        onClick={() => setRole(item.auth)}
      >
        <div>
          <Radio
            id="role"
            checked={item.auth === role}
            onChange={() => setRole(item.auth)}
          />
        </div>
        <div>
          <div className="text-gray-900 font-medium">
            {capitalizeFirstLetter(item.auth)}
          </div>
          <div className="text-gray-600">{item.description}</div>
        </div>
      </div>
    );
  }

  function onCancel() {
    setRole(authRole);
  }

  function handleUpdateUser() {
    handleSave({ authority: role, type: role });
  }

  function handleSave(formData) {
    const data = { id: user._id, data: formData };
    updateUser(data);
  }

  async function email(type) {
    const email = await dispatch(
      sendEmail({ userId: params.id, type, parent: user.parentCompany })
    ).unwrap();

    toast(email.message, {
      type: email.status,
      toastId: "emailSent",
    });
  }

  const data = [user];

  const actions = [
    {
      id: "resend",
      title: "Resend Activate Email",
      onClick: () => email("activate"),
    },
    {
      id: "change-pass",
      title: "Send Change Password email",
      onClick: () => email("changePassword"),
    },
    {
      id: "deactivate",
      title: "Deactivate User",
      onClick: () => handleSave({ active: false }),
    },
  ].filter((i) => {
    if (user.active && i.id === "resend") {
      return false;
    }
    return true;
  });

  const actionsButton = (
    <ButtonDropdown size="xs" data={actions}>
      Actions
    </ButtonDropdown>
  );

  return (
    <div className="space-y-4">
      <SettingsHeader title={user.fullName} actions={actionsButton} />
      <SettingsPanel
        itemLayout={itemLayout}
        title="General"
        loading
        data={data}
      />
      <SettingsPanel
        itemLayout={roleLayout}
        title="Role"
        data={roles.filter((i) => !i.hide)}
      />
      {role !== authRole && !loading && (
        <>
          <Button type="alternative" className="mr-2" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleUpdateUser}>Save</Button>
        </>
      )}
    </div>
  );
};
