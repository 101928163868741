import { PlusCircleIcon } from "@heroicons/react/24/outline";
import UserCard from "./UserCard";
import { AssignedToSelect } from "./AssignedToSelect";
import { useGetEmployeesQuery } from "../redux/services/employees/employeesApi";
import { useState } from "react";

export const TechniciansList = ({ technicians, form, updateForm }) => {
  const { data: employees } = useGetEmployeesQuery({ role: "Technician" });
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center">
      <h3 className="font-medium text-sm md:text-base mb-1 md:mb-0 text-gray-800 mr-2">
        Performed By
      </h3>
      {!isEditing && technicians?.length > 0 ? (
        <ul className="flex items-center space-x-2">
          {technicians.map(({ user }) => (
            <li className="flex items-center space-x-2">
              <UserCard
                key={user._id}
                avatar={user.avatar}
                name={user.fullName || user.firstName + " " + user.lastName}
              />
            </li>
          ))}
          {/* <li
            onClick={() => setIsEditing(true)}
            className="flex items-center space-x-2"
          >
            <PlusCircleIcon className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer transition-colors duration-50 ease-in-out" />
          </li> */}
        </ul>
      ) : (
        <div className="flex items-center space-x-2">
          <AssignedToSelect
            onChange={(value) => {
              updateForm({ id: "performedBy", value });
              setIsEditing(false);
            }}
            value={technicians}
            users={employees?.employees || []}
          />
        </div>
      )}
      {/* <div className="flex flex-row items-center">
    </div> */}
    </div>
  );
};

export default TechniciansList;
