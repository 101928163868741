import React, { useState, useEffect, forwardRef } from "react";
import JobItemPdf from "../../shared/PdfGenerator/jobItempdf";
import { serviceReportLayout } from "./layouts/serviceReportLayout";

const JobItemLayout = forwardRef(
  ({ jobName, jobItem, children, trigger, itemModel, ...props }, ref) => {
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [pdfConfig, setPdfConfig] = useState(null);

    useEffect(() => {
      if (!jobItem) return;

      const { content, styles } = serviceReportLayout(jobItem, {
        formatCurrency: (amount) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount || 0),
      });

      setPdfConfig({
        fileName: `${jobName || "work-order"}.pdf`,
        id: "job-item-content",
        layout: <div dangerouslySetInnerHTML={{ __html: content }} />,
        pageData: `Generated on ${new Date().toLocaleDateString()}`,
        headStyles: {
          fillColor: [56, 188, 164],
          textColor: [255, 255, 255],
          lineWidth: 0.5,
        },
        styles: {
          font: "helvetica",
          overflow: "linebreak",
          cellPadding: 4,
          ...styles,
        },
        startY: 40,
        createHeader: (doc) => {
          doc.setFontSize(16);
          doc.text("Service Report", 105, 20, { align: "center" });
          doc.setFontSize(10);
        },
      });

      // Show preview by default after config is ready
      setIsPreviewVisible(true);
    }, [jobItem]);

    const handlePreviewToggle = () => {
      setIsPreviewVisible((prev) => !prev);
    };

    return (
      <div ref={ref}>
        {trigger && (
          <div className="pdf-controls">
            {trigger && <div className="custom-trigger">{trigger}</div>}
            <button onClick={handlePreviewToggle} className="preview-button">
              {/* {isPreviewVisible ? "Hide Preview" : "Show Preview"} */}
            </button>
          </div>
        )}

        <JobItemPdf
          serviceData={jobItem}
          fileName={`${jobName || "service-report"}.pdf`}
        />

        {children}
      </div>
    );
  }
);

JobItemLayout.displayName = "JobItemLayout";

export default JobItemLayout;
