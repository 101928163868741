import { Badge, Popover } from "../shared";
import { isAuthorized, renderAmount } from "../utils";
import { TrashIcon } from "@heroicons/react/24/outline";

export const PartCard = ({
  part = {},
  quantity,
  unitPrice,
  baseUnitPrice,
  isLast,
  onDelete,
}) => {
  const name = part?.name || part?.description || part?.partNumber;

  return (
    <div
      className={`flex flex-col hover:bg-primary-50 rounded overflow-hidden border-b border-gray-200 bg-white w-full px-4 py-2 ${
        isLast ? "border-b-0" : ""
      }`}
    >
      {!name ? (
        <Popover
          className="w-43"
          position="top"
          hover
          trigger={
            <div className="flex justify-between w-full">
              <div className="text-gray-500 text-sm py-2 w-full">
                The referenced part could not be found
              </div>
              <button
                onClick={() => onDelete(part)}
                className="text-red-600 hover:text-red-800"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          }
          offset={[-12, 0]}
        >
          <div className="p-3">
            <p className="text-sm text-gray-700 mb-2">
              This part may have been deleted from the inventory while still
              being referenced in this job.
            </p>
            <p className="text-sm text-gray-500">
              To resolve this, either remove this part from the job or
              restore/recreate the part in inventory if needed.
            </p>
          </div>
        </Popover>
      ) : (
        <div className="flex flex-col">
          <div className="flex items-center justify-between w-full mb-1">
            <div className="flex">
              {part.image && (
                <img
                  src={part.image}
                  alt={`Image of ${part.name}`}
                  className="h-16 w-16 object-cover mr-4"
                />
              )}
              <div className="flex flex-col">
                <div className="font-bold text-sm">{part.name}</div>
                <div className="text-gray-700 text-sm">
                  <p>{part.partNumber}</p>
                </div>
                <div className="text-gray-500 text-sm">
                  <p>{part.description}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {quantity && (
                <div className="text-gray-700 text-sm">
                  <p>
                    {isAuthorized("owner, admin")
                      ? `${quantity} × ${renderAmount(
                          baseUnitPrice
                        )} = ${renderAmount(quantity * baseUnitPrice)}`
                      : `Quantity: ${quantity}`}
                  </p>
                </div>
              )}
              {onDelete && (
                <button
                  onClick={() => onDelete(part)}
                  className="text-red-600 hover:text-red-800"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
          <div className="flex">
            <Badge color="primary" label={part.status} />
          </div>
        </div>
      )}
    </div>
  );
};
