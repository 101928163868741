import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { formatDate } from ".";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatTimezone(
  date,
  tz = timezone,
  format = "yyyy-MM-dd HH:mm:ss zzz",
  options
) {
  return formatInTimeZone(date, tz, format, options);
}

/**
 * Updates the date part of a Date object.
 * @param {Date} date - The Date object to update.
 * @param {Date} newDate - The new date as a Date object.
 * @returns {Date} The updated Date object.
 */
export const updateDate = (date, newDate) => {
  if (!newDate) return null;

  // Create a copy of the original date, or use current date if none exists
  const updatedDate = date ? new Date(date) : new Date();

  // Update only the date components while preserving time
  updatedDate.setFullYear(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate()
  );

  return updatedDate;
};

/**
 * Updates the time part of a Date object.
 * @param {Date} date - The Date object to update.
 * @param {string} newTime - The new time in 'HH:MM' format.
 * @returns {Date} The updated Date object.
 */
export const updateTime = (date, newTime) => {
  if (!date || !newTime) return null;
  const updatedDate = new Date(date); // Create copy to avoid modifying original

  try {
    // Parse time string with AM/PM format
    const [time, period] = newTime.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format
    hours = parseInt(hours);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    updatedDate.setHours(hours);
    updatedDate.setMinutes(parseInt(minutes));
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return updatedDate;
  } catch (e) {
    console.error("Error updating time:", e);
    return null;
  }
};

/**
 * Formats a Date object for display with optional AM/PM format.
 * @param {Date} date - The Date object to format.
 * @param {string} type - The type to use. 'date' for YYYY-MM-DD, 'time' for HH:MM, 'timeAMPM' for HH:MM AM/PM.
 * @returns {string} The formatted date or time.
 */
export const formatDateOrTime = (date, type) => {
  if (!date) return "";

  // Handle case where date is an object with value property
  const dateValue = date?.value || date;

  // Ensure we have a valid Date object
  const dateObj = dateValue instanceof Date ? dateValue : new Date(dateValue);

  if (isNaN(dateObj.getTime())) return "";

  try {
    if (type === "date") {
      return formatDate(dateObj);
    } else if (type === "time") {
      return format(dateObj, "HH:mm");
    } else if (type === "timeAMPM") {
      return format(dateObj, "hh:mm a");
    }
  } catch (err) {
    console.error("Error formatting date/time:", err);
    return "";
  }

  return "";
};
