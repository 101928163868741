import Card from "../../../shared/Card";
import {
  ArrowDownTrayIcon,
  DocumentChartBarIcon,
  XMarkIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../../shared/Modal";
import { useState } from "react";
import Button from "../../../shared/Button";
import Input from "../../../shared/Input";
import EditableText from "../../../shared/EditableText";
import { useNavigate } from "react-router-dom";
import Select from "../../../shared/Select";
import { statusOptions } from "../../../constants/select";
import classNames from "classnames";
import TextArea from "../../../shared/TextArea";
import { renderMenuItems } from "../../../utils/route.utils";
import { useAuth } from "../../../hooks/useAuth";
import { Badge, DatePicker } from "../../../shared";
import _ from "lodash";
import { formatDate } from "../../../utils";
import { useDeletePayoutsMutation } from "../../../redux/services/payouts/payoutsApi";
import { useConfig } from "../../../hooks";
import { toast } from "react-toastify";

const PayoutInfo = ({
  info,
  form,
  appType,
  handleUpdate,
  onFormChange,
  updateForm,
  clearForm,
  setLoading,
  loading,
  ...props
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [docName, setDocName] = useState("doc");
  const [emails, setEmails] = useState(
    info.organization ? info.organization.email : info.driver?.email
  );
  const [deletePayouts, { isLoading: isDeleting }] = useDeletePayoutsMutation();

  async function handleSave({ target }, charge) {
    setLoading(true);
    const data = {
      [target.id]: target.value,
      value: Number(target.value),
      legacy: false,
      charge,
      id: target.id,
    };
    handleUpdate(data);
  }

  function handlePdfEmail(e) {
    const output = props.getPdfOutput();
    // send email
  }

  function handlePdfDownload(e) {
    props.handleDownloadPdf();
  }

  function handleCancelNote() {
    setNoteModalVisible(false);
    clearForm("note");
  }

  const noteModalFooter = (
    <>
      <Button onClick={handleCancelNote} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleUpdate({ id: "note", value: form.note, legacy: false });
          handleCancelNote();
        }}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const noteModalContent = (
    <TextArea
      id="note"
      value={form.note}
      onChange={(e) => onFormChange({ value: e.target.value, id: "note" })}
    />
  );

  function handleCancelEmail() {
    setEmailModalVisible(false);
  }

  const emailModalFooter = (
    <>
      <Button onClick={handleCancelEmail} type="alternative">
        Cancel
      </Button>
      <Button onClick={handlePdfEmail} type="primary">
        Send
      </Button>
    </>
  );

  const emailModalContent = (
    <div>
      <Input
        id="emails"
        label={"Emails - seperate by commas"}
        value={emails}
        onChange={({ value }) => setEmails(value)}
      />
      <Card className="p-2 mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-4">
            <DocumentChartBarIcon className="w-10 h-10" />
          </div>
          <div className="text-sm">
            <EditableText
              id="documentName"
              value={docName}
              displayText={(e) => <span className="">{e}.pdf</span>}
              iconClasses="h-3 w-3"
              onSave={(e) => setDocName(e.value)}
            />
          </div>
        </div>
        <div>
          <div
            onClick={handlePdfDownload}
            className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
          </div>
        </div>
      </Card>
    </div>
  );

  async function handleDelete() {
    const res = await deletePayouts({ data: [info._id] }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "delete-payouts",
    });

    if (res.status === "success") {
      navigate("/payouts");
    }
  }

  function handleCancelDeleteModal() {
    setDeleteModalVisible(false);
  }

  const deleteModalFooter = (
    <>
      <Button onClick={handleCancelDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button onClick={handleDelete} loading={isDeleting} type="primary">
        Delete
      </Button>
    </>
  );

  const deleteModalContent = (
    <div>Are you sure you want to delete this payout?</div>
  );

  const actions = [
    {
      id: "addNote",
      icon: ChatBubbleBottomCenterTextIcon,
      onClick: () => {
        onFormChange({ id: "note", value: info.note });
        setNoteModalVisible(true);
      },
      label: "Add Note",
      type: "success",
      appType: ["trucking", "serviceRepair"],
    },
    // {
    //   id: "sendCopy",
    //   icon: ArrowUpOnSquareStackIcon,
    //   onClick: () => setEmailModalVisible(true),
    //   label: "Email",
    // },
    {
      id: "pdf",
      icon: ArrowDownTrayIcon,
      onClick: handlePdfDownload,
      label: "To PDF",
      appType: ["trucking", "serviceRepair"],
    },
    {
      id: "edit",
      icon: PencilSquareIcon,
      onClick: () => navigate(`/payouts/${info._id}/edit`),
      label: "Edit",
      authority: "admin",
      appType: ["trucking"],
    },
    {
      id: "delete",
      icon: XMarkIcon,
      onClick: () => setDeleteModalVisible(true),
      label: "Delete",
      authority: "admin",
      appType: [""],
    },
  ].filter((i) => {
    if (!i.appType.includes(appType)) return false;
    if (info.manifestId && i.id === "edit") {
      return false;
    }
    return true;
  });

  const menuItems = renderMenuItems(actions, user.authority);
  const doesNotHaveAuthority =
    user.authority === "client" || user.authority === "user";

  return (
    <>
      <Card className="row-span-2 ">
        <div className="flex justify-between">
          <div className="flex flex-col mx-2 grid grid-cols-4">
            <div className="mx-2">
              <DatePicker
                id="payDate"
                value={formatDate(form.payDate, null, true)}
                // updateForm={updateForm}
                onChange={(e) => {
                  updateForm({ value: e.value, id: "payDate" });
                  handleSave({
                    target: { value: e.value, id: "payDate" },
                  });
                }}
                // onBlur={(e) => {
                //   updateForm({ value: e.value, id: "payDate" });
                // }}
                label="Pay Date"
                placeholder="Select a date"
              />
            </div>
            {appType === "trucking" && (
              <>
                <div className="mx-2">
                  <Input
                    // loading={loading}
                    id="mileageRate"
                    placeholder="Mileage Rate"
                    label="Mileage Rate"
                    // charge="mileageRate"
                    value={form.mileageRate}
                    // status={payout.status}
                    // edit={editing || isNew}
                    type="number"
                    updateForm={updateForm}
                    onBlur={(e) => handleSave(e, "mileageRate")}
                  />
                </div>
                <div className="mx-2">
                  <Input
                    // loading={loading}
                    id="emptyRate"
                    placeholder="Empty Rate"
                    label="Empty Rate"
                    // charge="mileageRate"
                    value={form.emptyRate}
                    // status={payout.status}
                    // edit={editing || isNew}
                    type="number"
                    updateForm={updateForm}
                    onBlur={(e) => handleSave(e, "emptyRate")}
                  />
                </div>
              </>
            )}
            <div className="mx-2">
              <Input
                // loading={loading}
                id="hourlyRate"
                placeholder="Hourly Rate"
                label="Hourly Rate"
                // charge="mileageRate"
                value={form.hourlyRate}
                // status={payout.status}
                // edit={editing || isNew}
                type="number"
                updateForm={updateForm}
                onBlur={(e) => handleSave(e, "hourlyRate")}
              />
            </div>
            {appType === "serviceRepair" && (
              <div className="mx-2">
                <Input
                  id="overtimeRate"
                  placeholder="Overtime Rate"
                  label="Overtime Rate"
                  value={form.overtimeRate}
                  type="number"
                  updateForm={updateForm}
                  onBlur={(e) => handleSave(e, "overtimeRate")}
                />
              </div>
            )}
            {/* {companyInfo}
            {billTo} */}
          </div>
          {/* </div> */}
          <div className="flex flex-col items-center">
            {!doesNotHaveAuthority && (
              <>
                <div className="flex justify-center">
                  {menuItems.map((item) => {
                    const Icon = item.icon;
                    const circleClasses = classNames(
                      "border ease-in-out duration-300 rounded-full p-2 cursor-pointer",
                      {
                        "hover:border-gray-300 hover:bg-gray-200":
                          item.type === "default" || !item.type,
                        "hover:border-red-300 hover:bg-red-200":
                          item.type === "danger",
                        "hover:border-primary-300 hover:bg-primary-200":
                          item.type === "primary",
                        "hover:border-green-300 hover:bg-green-200":
                          item.type === "success",
                        "hover:border-orange-300 hover:bg-orange-200":
                          item.type === "warning",
                      }
                    );
                    return (
                      <div className="flex flex-col items-center mx-2">
                        <div onClick={item.onClick} className={circleClasses}>
                          <Icon className="w-6 h-6" />
                        </div>
                        <p className="font-semibold">{item.label}</p>
                      </div>
                    );
                  })}
                </div>
                {user.authority.includes("dmin") && (
                  <div className="mx-2 py-2 w-full">
                    <Select
                      id="status"
                      onChange={handleUpdate}
                      value={info.status}
                      size="sm"
                      placeholder={info.status}
                      options={statusOptions}
                    />
                  </div>
                )}
              </>
            )}

            {/* <div>{info.manifestId}</div> */}
            <div className="mx-2 py-2 w-full">
              <span className="font-bold">Notes:</span>
              <div className="whitespace-pre">{info.note}</div>
            </div>
          </div>
        </div>
      </Card>

      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title="Delete invoice"
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        onClose={() => setEmailModalVisible(false)}
        title="Send Invoice"
        footer={emailModalFooter}
        content={emailModalContent}
        size="md"
        footerPosition="right"
      />
      <Modal
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        onClose={() => setNoteModalVisible(false)}
        title="Add note"
        footer={noteModalFooter}
        content={noteModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default PayoutInfo;
