import { Outlet, NavLink } from "react-router-dom";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../utils";
import { menu } from "./menu.map";
import { useConfig } from "../../hooks";

const Settings = () => {
  const { appType } = useConfig();

  return (
    <div className="h-full">
      <div className="grid grid-cols-5 divide-x border-t h-full">
        <div className="p-6 bg-white">
          {Object.keys(menu).map((title, key) => {
            return (
              <div key={key} className="space-y-2">
                <div>
                  <span className="font-medium">
                    {capitalizeFirstLetter(title)}
                  </span>
                </div>
                <div className="space-y-2 flex flex-col">
                  {menu[title].map((item, idx) => {
                    if (
                      (item === "Templates" || item === "Reports") &&
                      appType === "trucking"
                    ) {
                      return null;
                    }
                    return (
                      <NavLink
                        key={idx}
                        to={`/settings/${item.toLowerCase()}`}
                        className={({ isActive }) =>
                          classNames(
                            "text-gray-500 cursor-pointer hover:text-gray-600",
                            { "text-primary-700 font-semibold": isActive }
                          )
                        }
                      >
                        {item}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-span-4 space-y-4 overflow-scroll p-3">
          <div className="space-y-2 overflow-y-scroll">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

export const SettingsHeader = (props) => {
  return (
    <div className="flex items-center justify-between">
      <div className="text-gray-800 text-2xl font-bold">{props.title}</div>
      <div>{props.actions}</div>
    </div>
  );
};
