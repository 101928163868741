import { useNavigate } from "react-router-dom";
import { Combobox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Button from "../Button";
import { FormLabel } from "../Input/FormLabel";

export const InputDropdown = (props) => {
  const [data, setData] = useState(() => props.data);
  const [selected, setSelected] = useState(() => "");
  const [query, setQuery] = useState("");
  const [showError, setShowError] = useState(false);
  const filteredData =
    query === ""
      ? data
      : data.filter((item) =>
          item.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [data.length, props.data.length]);

  function handleClick(item, i) {
    item.onClick ? item.onClick(item) : navigate(item.path);
  }

  function renderItem({ item, active, selected }) {
    const title = item.title || item.label;
    const itemClasses = classNames(
      "group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer",
      {
        "bg-primary-600 text-white": active,
        "text-gray-900": !active,
        "text-gray-100 !cursor-not-allowed": item.disabled,
      }
    );
    return props.renderItem ? (
      props.renderItem({ selected, active, item })
    ) : (
      <div
        onClick={item.disabled ? null : () => handleClick(item)}
        className={itemClasses}
      >
        <span className="mr-1.5">{item.icon}</span>
        {typeof title === "function" ? title({ item, active }) : title}
      </div>
    );
  }
  const labelClasses = classNames(
    "block mb-1",
    "text-sm font-medium",
    props.labelClasses,
    {
      "text-gray-900": !showError,
      "text-red-700": showError,
    }
  );

  const dataWrapperClasses = classNames(
    "absolute mt-2 w-56 rounded-md w-full z-10",
    "bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
    // {
    //   "origin-top-right right-0": props.align === "right" || !props.align,
    // },
    // { "origin-top-left left-0": props.align === "left" },
    props.dataWrapperClassName
  );

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";

  const classes = classNames(
    "shadow-sm bg-white",
    "block w-full focus:outline-none focus-visible:ring-1 focus:ring-1 focus-visible:ring-primary-500",
    "border rounded-lg bg-transparent",
    { "p-4 text-md": sizeLg },
    { "p-2.5 text-sm": sizeMd },
    { "p-2 text-xs": sizeSm },
    {
      "border-gray-300 focus:ring-primary-500 focus:border-primary-500 text-gray-900":
        !showError,
    },
    {
      "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:outline-2 focus:ring-red-500 focus:border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500":
        showError,
    },
    { "pl-8": props.icon && !isIconReactElement && props.icon.left },
    props.className
  );
  const wrapperClasses = classNames("block", props.wrapperClasses);

  return (
    <div className="w-full relative z-1000">
      {props.label && (
        <FormLabel
          htmlFor={props.id}
          required={props.required}
          className={labelClasses}
        >
          {props.label}
        </FormLabel>
      )}
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div
            className={wrapperClasses}
            //className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
          >
            <Combobox.Input
              placeholder="Select or search for a client"
              className={classes}
              displayValue={(item) => item?.name}
              onChange={(event) =>
                props.onChange
                  ? props.onChange(event.target.value)
                  : setQuery(event.target.value)
              }
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredData.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((item) => (
                  <Combobox.Option
                    key={item._id || item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-1 px-3 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) =>
                      renderItem({ selected, active, item })
                    }
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {props.footerButton && (
        <div className="flex justify-start mt-2">
          <Button type="link" onClick={props.footerButtonClick}>
            {props.footerButton}
          </Button>
        </div>
      )}
    </div>

    // <>
    //   <>
    //   <span
    //     className={`block truncate ${
    //       selected ? "font-medium" : "font-normal"
    //     }`}
    //   >
    //     {person.name}
    //   </span>
    //   {selected ? (
    //     <span
    //       className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
    //         active ? "text-white" : "text-teal-600"
    //       }`}
    //     >
    //       <CheckIcon className="h-5 w-5" aria-hidden="true" />
    //     </span>
    //   ) : null}
    // </>
    //   <Menu as="div" className="relative  text-left">
    //     {({ open }) => (
    //       <>
    //         <Menu.Button as="div">
    //           <Input
    //             value={props.value}
    //             placeholder={props.placeholder}
    //             label={props.label}
    //             onChange={props.onChange}
    //             icon={{
    //               right: (
    //                 <div className="text-gray-500 text-sm">
    //                   <ChevronDownIcon
    //                     className={`h-5 w-5 ${open ? "rotate-180" : ""}`}
    //                   />
    //                 </div>
    //               ),
    //             }}
    //           />
    //         </Menu.Button>
    //         {open && (
    //           <div>
    //             {/*
    //             Using the `static` prop, the `Menu.Items` are always
    //             rendered and the `open` state is ignored.
    //           */}
    //             <Menu.Items as="div" static className={dataWrapperClasses}>
    //               {data && data.map((item, i) => renderItem(item, i))}
    //             </Menu.Items>
    //           </div>
    //         )}
    //       </>
    //     )}
    //   </Menu>
    // </>
  );
};

export default InputDropdown;
