import React, { useState, useEffect } from "react";
import {
  useUpdateEmailConfigMutation,
  useGetEmailConfigQuery,
} from "../../redux/services/config/settings";
import { Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import { useForm } from "@/hooks";
import { Input, TextArea, Button, Toggle, Form } from "@/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EmailSettings() {
  const [enabled, setEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showDKIM, setShowDKIM] = useState(false);

  const { form, updateForm, register, errors } = useForm();

  const [updateEmailConfig] = useUpdateEmailConfigMutation();
  const { data: emailConfig, isLoading } = useGetEmailConfigQuery();

  // useEffect(() => {
  //   if (emailConfig?.data) {
  //     const config = emailConfig.data;
  //     setEnabled(config.enabled || false);
  //     reset({
  //       host: config.host || "",
  //       port: config.port || "",
  //       secure: config.secure || true,
  //       auth: {
  //         user: config.auth?.user || "",
  //         pass: config.auth?.pass || "",
  //       },
  //       fromName: config.fromName || "",
  //       fromEmail: config.fromEmail || "",
  //       dkimPrivateKey: config.dkimPrivateKey || "",
  //       dkimSelector: config.dkimSelector || "",
  //       dkimDomain: config.dkimDomain || "",
  //     });
  //   }
  // }, [emailConfig, reset]);

  const onSubmit = async () => {
    try {
      await updateEmailConfig({
        ...form,
        auth: { user: form.user, pass: form.pass },
        enabled,
        // parentCompany,
      }).unwrap();
      toast.success("Email settings updated successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.data?.message || "Failed to update email settings");
    }
  };

  const handleEnable = ({ target }) => {
    if (!target && form.host) {
    }

    setEnabled(target.checked);
  };

  function handleSave() {}

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4 divide-y divide-gray-200">
      <div className="space-y-4 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Email Settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Configure your email settings to send emails using your own email
              server.
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <Toggle
                size="sm"
                checked={enabled}
                setToggled={handleEnable}
                label="Enable Custom Email Server"
              />
            </div>

            {enabled && (
              <>
                <div className="sm:col-span-3">
                  <Input
                    type="text"
                    label="SMTP Host"
                    {...register("host", { required: enabled })}
                    error={errors.host ? "SMTP host is required" : ""}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    type="number"
                    label="SMTP Port"
                    {...register("port", { required: enabled })}
                    error={errors.port ? "SMTP port is required" : ""}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    type="text"
                    label="SMTP Username"
                    {...register("user", { required: enabled })}
                    error={errors.auth?.user ? "SMTP username is required" : ""}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    type={showPassword ? "text" : "password"}
                    label="SMTP Password"
                    {...register("pass", { required: enabled })}
                    error={errors.auth?.pass ? "SMTP password is required" : ""}
                    rightElement={
                      <Button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        variant="text"
                        size="sm"
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    }
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    type="text"
                    label="From Name"
                    {...register("fromName")}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    type="email"
                    label="From Email"
                    {...register("fromEmail")}
                  />
                </div>

                <div className="sm:col-span-6 mt-6">
                  <Toggle
                    size="sm"
                    checked={showDKIM}
                    onChange={({ target }) => setShowDKIM(target.checked)}
                    label="Configure DKIM"
                  />
                </div>

                {showDKIM && (
                  <>
                    <div className="sm:col-span-6">
                      <TextArea
                        label="DKIM Private Key"
                        {...register("dkimPrivateKey")}
                        rows={4}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        type="text"
                        label="DKIM Selector"
                        {...register("dkimSelector")}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        type="text"
                        label="DKIM Domain"
                        {...register("dkimDomain")}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Button type="primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
