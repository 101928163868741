import { format } from "date-fns";

/**
 * Generates the layout configuration for a service report PDF
 * @param {Object} serviceData - Service data to display
 * @param {Object} formatters - Formatting utility functions
 * @returns {Object} Layout configuration with styles and content
 */
export const serviceReportLayout = (serviceData, formatters) => {
  const { formatCurrency } = formatters;

  const generatePartsListHTML = (parts) => {
    if (!parts || parts.length === 0)
      return '<p class="no-parts">No parts used</p>';

    return `
      <table class="parts-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Part/Service Number</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          ${parts
            .map(
              (part) => `
            <tr>
              <td>${part.description || "N/A"}</td>
              <td>${part.partNumber || "N/A"}</td>
              <td>${part.quantity || "0"}</td>
            </tr>
          `
            )
            .join("")}
        </tbody>
      </table>
    `;
  };

  return {
    styles: `
      .container { padding: 20px; font-family: Arial, sans-serif; }
      .header { margin-bottom: 20px; }
      .company-name { font-size: 24px; font-weight: bold; margin-bottom: 5px; }
      .company-info { margin-bottom: 15px; }
      .work-order-number { font-size: 14px; margin-bottom: 10px; text-align: right; }
      
      .equipment-info {
        border: 1px solid #ddd;
        padding: 10px;
        margin: 15px 0;
      }
      
      .service-details {
        margin: 15px 0;
        font-size: 12px;
        line-height: 1.4;
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      
      th {
        background-color: #38BCA4;
        color: white;
        text-align: left;
        padding: 8px;
        font-size: 12px;
      }
      
      td {
        padding: 6px 8px;
        border: 1px solid #ddd;
        font-size: 12px;
      }
    `,
    content: `
      <div class="container">
        <div class="header">
          <h1 class="company-name">Ochoa Material Handling</h1>
          <div class="company-info">
            <p>Phone: ${serviceData.phone || "5624578833"}</p>
            <p>Email: ${serviceData.email || "forklift@attunelogic.com"}</p>
          </div>
          <div class="work-order-number">
            <p>Work Order No. ${serviceData.id || ""}</p>
            <p>Date: ${format(
              new Date(serviceData.date || new Date()),
              "MM/dd/yyyy"
            )}</p>
            <p>Customer: ${serviceData.customer?.name || "NEW"}</p>
            <p>Phone: ${serviceData.customer?.phone || ""}</p>
            <p>Email: ${serviceData.customer?.email || ""}</p>
            <p>Address: ${serviceData.customer?.address || ""}</p>
          </div>
        </div>

        <div class="equipment-info">
          <h2>Equipment Information</h2>
          <p>Make: ${serviceData.equipment?.make || ""}</p>
          <p>Model: ${serviceData.equipment?.model || ""}</p>
          <p>Serial: ${serviceData.equipment?.serial || ""}</p>
          <p>Hour Meter: ${serviceData.equipment?.hourMeter || ""}</p>
          <p>Unit Type: ${serviceData.equipment?.unitType || ""}</p>
        </div>

        <div class="service-details">
          <h2>Service Details</h2>
          <p>${serviceData.serviceDetails || ""}</p>
        </div>

        <div class="parts-section">
          ${generatePartsListHTML(serviceData.parts)}
        </div>
      </div>
    `,
  };
};
