import React, { useState } from "react";
import ManifestTable from "./Table";
import { formatDate } from "../../utils";
import ManifestGenerator from "../../shared/PdfGenerator/manifestpdf";

const ManifestLayout = ({ manifest, children, trigger, ...props }) => {
  const [loading, setLoading] = useState(false);

  const parentCompany = manifest.parentCompany;
  const manifestId = manifest.manifestId;

  if (loading) {
    return "Loading";
  }

  function didParseCell({ cell, section, row, ...rest }) {
    cell.styles.fontSize = 7;
    if (section === "body") {
      if (cell.text[0].startsWith("Date") || cell.text[0].startsWith("Dest")) {
        cell.styles.fillColor = "white"; //[245, 245, 245];
      }
      if (
        cell.text[0].startsWith("Dest") ||
        cell.text[0].startsWith("Origin") ||
        cell.text[0].startsWith("*")
      ) {
        if (cell.text[0].startsWith("*")) {
          cell.styles.lineWidth = { top: 0, right: 0, bottom: 0.1, left: 0 };
          cell.styles.cellPadding = { top: 2, right: 0, bottom: 5, left: 0 };
        }
        cell.styles.halign = "center";
      }
    }
  }

  function didDrawPage({ pageCount, pageNumber, settings, doc, ...rest }) {
    var totalPagesExp = "{total_pages_count_string}";
    // FOOTER
    var str = "Page " + pageCount;
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp;
    }
    doc.setFontSize(10);
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    doc.text(str, settings.margin.left, pageHeight - 5);
  }

  function createHeader(pdf) {
    // PAYOUT NUMBER
    pdf.setFontSize(9);
    pdf.setFont("Times New Roman");
    pdf.setTextColor(100, 100, 100);
    pdf.text("DRIVER MANIFEST", 100, 5);

    pdf.setFontSize(11);
    pdf.setTextColor("black");
    pdf.text(`${manifest.manifestId}`, 100, 9);

    // Company NAME
    pdf.setFontSize(20);
    pdf.setFont(undefined, "bold");
    pdf.text(parentCompany.name, 5, 20);

    // COMPANY ADDRESS
    // const address = `${parentCompany?.address}`;
    // const city = `${parentCompany?.city}`;

    // const customerAddress = `${customer?.address.city},${customer?.address.state} ${customer?.address.zipcode}`;

    // pdf.setFontSize(9);
    // pdf.setFont(undefined, "normal");
    // pdf.text(address, 5, 25);
    // pdf.text(city, 5, 29);

    // MANIFEST INFO
    pdf.setFontSize(9);
    pdf.setFont("Times New Roman");
    pdf.setTextColor(100, 100, 100);

    // pdf.text("", 5, 30);
    pdf.setTextColor("black");
    pdf.text(
      `${
        manifest.organization
          ? manifest.organization.name
          : manifest.driver.fullName
      }   ${formatDate(manifest.startDate, null, true)} through ${formatDate(
        manifest.endDate,
        null,
        true
      )}`,
      5,
      35
    );
    pdf.text(`Total Drops: ${manifest.legs.length}`, 90, 35);

    // pdf.text(`Total Mileage: ${payout.totalMileage}`, 5, 50);
    // pdf.text(`C/o: ${customer?.coowner}`, 5, 50);
    // pdf.text(`${customer?.address.address}`, 5, 55);
    // pdf.text(customerAddress, 5, 60);
  }

  function willDrawCell({ cell, ...props }) {
    cell.styles.fontSize = 7;
  }

  const layout = (
    <>
      <ManifestTable {...props} tableData={manifest.legs} />
    </>
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  const pageData = "|  " + parentCompany.name + "  |  Manifest  " + manifestId;
  const pdfFileName = `${manifestId.replace(/\./g, " ")}  ${formatDate(
    new Date(),
    null,
    true
  )}.pdf`;

  return (
    <ManifestGenerator
      startY={40}
      pageData={pageData}
      didDrawPage={didDrawPage}
      didParseCell={didParseCell}
      createHeader={createHeader}
      willDrawCell={willDrawCell}
      layout={layout}
      fileName={pdfFileName}
      id="manifest-table"
    >
      <Layout download={props.download}>{childrenWithProps}</Layout>
    </ManifestGenerator>
  );
};

const Layout = (props) => {
  const childrenWithProps = React.Children.map(props.children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  return childrenWithProps;
};

export default ManifestLayout;
