import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PDFViewer from "../pdfViewer";
import { format } from "date-fns";
import { formatTimeWorked } from "../../utils";

// Helper function to get selected hours
const getSelectedHours = (data) => {
  if (!data) return 0;

  const hours = (() => {
    switch (data.selectedHourType) {
      case "quotedHours":
        return data.quotedHours;
      case "hoursWorked":
        return data.hoursWorked;
      case "actualHours":
        return data.actualHours;
      case "adjustedHours":
        return data.adjustedHours;
      default:
        return data.hoursWorked;
    }
  })();

  const numericHours = Number(hours) || 0;
  return formatTimeWorked(numericHours).display;
};

// Helper function to calculate totals
const calculateTotals = (serviceData) => {
  const partsTotal = (serviceData.parts || []).reduce(
    (sum, part) => sum + part.quantity * part.unitPrice,
    0
  );

  const selectedHours = getSelectedHours(serviceData);
  const laborTotal =
    serviceData.hourlyTotal || selectedHours * (serviceData.hourlyRate || 0);
  const serviceFee = serviceData.serviceFee || 0;
  const totalCharges = partsTotal + laborTotal + serviceFee;

  return {
    partsTotal,
    laborTotal,
    serviceFee,
    totalCharges,
  };
};

// Helper function to draw section boxes
const drawSection = (doc, title, x, y, width, height) => {
  const radius = 3;
  doc.setDrawColor(200, 200, 200);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(x, y, width, height, radius, radius, "S");
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(title, x + 5, y + 10);
};

// Helper function to calculate section height
const calculateSectionHeight = (details, startY, doc) => {
  let maxY = startY + 25;
  let xPos = 15;
  let currentY = startY + 20;

  details.forEach((detail) => {
    const textWidth = doc.getTextWidth(`${detail.label} ${detail.value}`);
    if (xPos + textWidth > 195) {
      currentY += 7;
      maxY = Math.max(maxY, currentY + 5);
      xPos = 15;
    } else {
      xPos += textWidth + 5;
    }
  });

  return maxY - startY;
};

// Main PDF generation function
export const generateWorkOrderPDF = async (serviceData) => {
  const doc = new jsPDF("p", "mm", "a4");
  const totals = calculateTotals(serviceData);

  // Company Info
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(serviceData.parentCompany?.name || "", 10, 20);

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Phone: ${serviceData.parentCompany?.phone || "5624578833"}`,
    10,
    30
  );
  doc.text(
    `Address: ${serviceData.parentCompany?.address?.fullAddress || ""}`,
    10,
    35
  );
  doc.text(
    `Email: ${serviceData.parentCompany?.email || "forklift@attunelogic.com"}`,
    10,
    40
  );

  // Work Order Info
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text("Work Order No.", 120, 15);
  doc.setFont("helvetica", "bold");
  doc.text("Repair Work Order", 120, 20);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Date: ${format(
      new Date(serviceData.appointmentDate || new Date()),
      "MM/dd/yyyy"
    )}`,
    120,
    30
  );
  doc.text(`Customer: ${serviceData.client?.name || ""}`, 120, 35);
  doc.text(`Phone: ${serviceData.client?.phone || ""}`, 120, 40);
  doc.text(`Email: ${serviceData.client?.email || ""}`, 120, 45);
  doc.text(
    `Address: ${serviceData.client?.address?.fullAddress || ""}`,
    120,
    50
  );

  // Equipment details
  const equipmentDetails = [
    { label: "Make:", value: serviceData.equipment?.manufacturer || "" },
    { label: "Model:", value: serviceData.equipment?.model || "" },
    { label: "Serial:", value: serviceData.equipment?.serialNumber || "" },
    { label: "Hour Meter:", value: serviceData.equipment?.odometer || "" },
    { label: "Unit Type:", value: serviceData.equipment?.type || "" },
  ];

  const sectionHeight = calculateSectionHeight(equipmentDetails, 60, doc);
  drawSection(
    doc,
    `Equipment Information - ${serviceData.equipment?.name || ""}`,
    10,
    60,
    190,
    sectionHeight
  );

  // Equipment details rendering
  let xPos = 15;
  let yPos = 80;
  const minSpacing = 5;

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.setDrawColor(200, 200, 200);
  doc.line(15, 73, 195, 73);

  equipmentDetails.forEach((detail) => {
    const textWidth = doc.getTextWidth(`${detail.label} ${detail.value}`);
    if (xPos + textWidth > 195) {
      xPos = 15;
      yPos += 7;
    }
    doc.text(`${detail.label} ${detail.value}`, xPos, yPos);
    xPos += textWidth + minSpacing;
  });

  // Service Details Section
  const splitText = doc.splitTextToSize(serviceData.summary || "", 180);
  const summaryHeight = splitText.length * 5 + 40;

  drawSection(
    doc,
    "Service Details",
    10,
    sectionHeight + 70,
    190,
    summaryHeight
  );
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.setDrawColor(200, 200, 200);
  doc.line(15, sectionHeight + 83, 195, sectionHeight + 83);

  yPos = sectionHeight + 95;
  splitText.forEach((line) => {
    doc.text(line, 15, yPos);
    yPos += 5;
  });

  // Technician and Labor Hours
  const technicianY = yPos + 10;
  doc.text(
    `Technician: ${serviceData.performedBy?.[0]?.user?.fullName || ""}`,
    15,
    technicianY
  );
  doc.text(`Labor Hrs: ${getSelectedHours(serviceData)}`, 150, technicianY);

  // Parts Table
  doc.autoTable({
    startY: technicianY + 20,
    head: [["Description", "Part/Service Number", "Qty"]],
    body: (serviceData.parts || []).map((part) => [
      part.part?.name || part.part?.description || "N/A",
      part.part?.partNumber || "N/A",
      part.quantity || "0",
    ]),
    headStyles: {
      fillColor: [56, 188, 164],
      textColor: [255, 255, 255],
      halign: "left",
    },
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 80 },
      2: { cellWidth: 30, halign: "center" },
    },
    theme: "grid",
    margin: { left: 10, right: 10 },
    styles: {
      cellPadding: 3,
      fontSize: 10,
      lineWidth: 0.5,
      lineColor: [200, 200, 200],
    },
    didDrawCell: function (data) {
      if (data.row.index === 0 && data.column.index === 0) {
        doc.roundedRect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          2,
          2,
          "S"
        );
      }
    },
  });

  // Totals Section
  const finalY = doc.lastAutoTable.finalY + 10;
  const totalsX = doc.internal.pageSize.width - 90;

  [
    { label: "Parts Total:", value: totals.partsTotal },
    { label: "Labor Total:", value: totals.laborTotal },
    { label: "Service Fee:", value: totals.serviceFee },
    { label: "Total Charges:", value: totals.totalCharges },
  ].forEach((item, index) => {
    const yPos = finalY + index * 10;

    if (index === 3) {
      doc.setDrawColor(56, 188, 164);
      doc.setFillColor(56, 188, 164);
      doc.roundedRect(totalsX - 5, yPos - 4, 85, 8, 2, 2, "F");
      doc.setTextColor(255, 255, 255);
    }

    doc.text(item.label, totalsX, yPos);
    doc.text(
      `$${item.value.toFixed(2)}`,
      doc.internal.pageSize.width - 20,
      yPos,
      {
        align: "right",
      }
    );

    doc.setTextColor(0, 0, 0);
  });

  return doc;
};

const JobItemPdf = ({ serviceData, fileName, id, children }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    let mounted = true;

    const generatePdf = async () => {
      try {
        const doc = await generateWorkOrderPDF(serviceData);

        if (mounted) {
          const blob = doc.output("blob");
          setPdfBlob(blob);
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    };

    generatePdf();

    return () => {
      mounted = false;
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [serviceData]);

  return (
    <div>
      {pdfUrl && (
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">
              {fileName || "Work Order"}
            </h2>
            <a
              href={pdfUrl}
              download={`${fileName || "work-order"}.pdf`}
              className="inline-flex items-center px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
            >
              Download PDF
            </a>
          </div>
          <div className="mx-auto rounded-lg overflow-y-scroll border border-primary-600/20 shadow-md">
            <PDFViewer
              url={pdfUrl}
              style={
                {
                  // width: "100%",
                  // height: "100%",
                  // maxWidth: "595px",
                }
              }
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default JobItemPdf;
