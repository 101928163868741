import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import { useNavigate, useParams } from "react-router-dom";
import CreateModal from "../../Jobs/CreateModal";
import { getAppType } from "@/utils";
import getTableConfig from "../../Jobs/table.configs";
import { useUpdateJobMutation } from "../../../redux/services/jobs/jobsApi";
import { PaginationProps } from "@/types/base";

const { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } = getTableConfig(getAppType());

interface Job {
  _id: string;
  [key: string]: any;
}

interface Client {
  [key: string]: any;
}

interface IJobsPagination extends PaginationProps {
  pageIndex: number;
  pageSize: number;
}

interface ClientJobsTabProps {
  jobs: Job[];
  client: Client;
  loading: boolean;
  pagination: IJobsPagination;
  setPagination: (value: React.SetStateAction<IJobsPagination>) => void;
  handleGlobalSearch: (value: string) => void;
}

interface UpdateEvent {
  row: {
    original: Job;
  };
  data: any;
}

const ClientJobsTab: React.FC<ClientJobsTabProps> = ({
  jobs,
  client,
  loading,
  pagination,
  setPagination,
  handleGlobalSearch,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [updateJob] = useUpdateJobMutation();

  async function handleUpdate(
    e: UpdateEvent,
    setLoading: (loading: boolean) => void
  ): Promise<void> {
    try {
      setLoading(true);
      const updateData = {
        id: e.row.original._id,
        data: e.data,
      };
      await updateJob(updateData).unwrap();
    } catch (error) {
      console.error("Error updating job:", error);
    } finally {
      setLoading(false);
    }
  }

  const globalFilter = useCallback(
    (value: string) => {
      if (!setPagination || !handleGlobalSearch) return;

      setPagination((prev) => ({
        ...prev,
        page: 1, // Reset to first page on search
        limit: prev.limit || 20,
      }));
      handleGlobalSearch(value);
    },
    [setPagination, handleGlobalSearch]
  );

  return (
    <div>
      <TableLayout
        meta={{
          updateData: handleUpdate,
        }}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        id="jobs"
        loading={loading}
        columns={COLUMN_DEFINITIONS}
        pagination={pagination}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        onPaginationChange={(newPagination) => {
          setPagination((prev) => ({
            ...prev,
            page: newPagination.pageIndex + 1, // Convert 0-based to 1-based
            limit: newPagination.pageSize,
          }));
        }}
        data={jobs || []}
        manualPagination={true}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        fullTextSearch={globalFilter}
        fullSelectedItem
      />
      <CreateModal
        client={client}
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </div>
  );
};

export default ClientJobsTab;
