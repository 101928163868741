import { useState } from "react";
import { renderAmount } from "@/utils";
export const TotalRow = ({
  label,
  amount,
  content,
  contentClassName,
  className,
  showArrow = true,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const hasContent = Array.isArray(content) ? content.length > 0 : !!content;

  return (
    <div className={`space-y-1 ${className}`}>
      <div
        className={`flex justify-between items-center text-sm ${
          hasContent ? "cursor-pointer" : ""
        }`}
        onClick={() => hasContent && setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <span className="text-sm font-medium text-gray-500">{label}</span>
          {showArrow && hasContent && (
            <svg
              className={`w-4 h-4 ml-2 text-gray-500 transform transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </div>
        <span className="font-medium">{renderAmount(amount)}</span>
      </div>
      <div
        className={`pl-4 overflow-hidden transition-all duration-200 ${contentClassName}`}
        style={{
          maxHeight: isOpen || !showArrow ? "500px" : "0",
          opacity: isOpen || !showArrow ? 1 : 0,
        }}
      >
        {Array.isArray(content)
          ? content.map((item, index) => (
              <div
                key={index}
                className="text-xs text-gray-500 flex justify-between"
              >
                <span>{item.label}</span>
                <span>{renderAmount(item.amount)}</span>
              </div>
            ))
          : content}
      </div>
    </div>
  );
};
