import React, { useState, useEffect, useCallback, Suspense } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLoading } from "@/shared";
import { ROOT_API_URL } from "@/config";
import { ApprovalSuccess } from "./Success";
import { toast } from "react-toastify";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import ApprovalRejected from "./Rejected";

const ApproveJob = React.lazy(() => import("./ApproveJob"));
const ApproveItem = React.lazy(() => import("./ApproveItem"));

export const Approval = ({ type }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [jobItem, setJobItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const fetchWithAuth = useCallback(
    async (url: string, options: any = {}) => {
      const defaultHeaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-parent-company": searchParams.get("parentCompany"),
        "x-client-type": "web",
      };

      try {
        const response = await fetch(`${ROOT_API_URL}${url}`, {
          ...options,
          headers: {
            ...defaultHeaders,
            ...options.headers,
          },
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Request failed");
        }
        return data;
      } catch (err) {
        throw new Error(err.message || "Request failed");
      }
    },
    [token, searchParams]
  );

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const data = await fetchWithAuth(
          type === "item"
            ? `/items/${searchParams.get("itemId")}/quote`
            : `/jobs/${searchParams.get("jobId")}/proposal`
        );

        const isSubmitted = data.data.approval?.status !== "pending";
        const isApproved = data.data.approval?.status === "approved";
        const isRejected = data.data.approval?.status === "rejected";

        setJobItem(data.data);
        if (isSubmitted) {
          setIsApproved(isApproved);
          setIsRejected(isRejected);
          setIsSubmitted(true);
        }
      } catch (err) {
        setError(err.message || "Invalid or expired approval link");
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setError("No approval token provided");
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <PageLoading />;
  }

  const handleSubmit = async (data) => {
    setLoading(true);

    try {
      await fetchWithAuth(`/items/${jobItem._id}/client-approvals`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      toast.success("Service approval submitted successfully");
      setIsSubmitted(true);
    } catch (error) {
      toast.error(error.message || "Error submitting approval");
    } finally {
      setLoading(false);
    }
  };

  const handleRejectJob = async (data) => {
    setLoading(true);

    try {
      const response = await fetchWithAuth(`/jobs/${jobItem._id}/reject`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      if (response.data) {
        setJobItem(response.data);
      }

      toast.success("Service approval rejected successfully");
      setIsRejected(true);
      setIsSubmitted(true);
    } catch (error) {
      toast.error(error.message || "Error rejecting approval");
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">{error}</div>
          </div>
        </div>
      </div>
    );
  }

  console.log("isSubmitted", isSubmitted);
  console.log("isApproved", isApproved);
  console.log("isRejected", isRejected);

  if (isSubmitted && isRejected) {
    return <ApprovalRejected />;
  }

  if (isSubmitted) {
    return <ApprovalSuccess />;
  }

  return (
    <div className="w-full">
      <div className="max-w-[90rem] mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <Suspense fallback={<PageLoading />}>
            {jobItem && type === "job" && (
              <ApproveJob
                job={jobItem}
                token={token}
                fetchWithAuth={fetchWithAuth}
                onSubmit={handleSubmit}
                isApproved={isApproved}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                isRejected={isRejected}
                onReject={handleRejectJob}
              />
            )}
            {jobItem && type === "item" && (
              <ApproveItem
                jobItem={jobItem}
                onSubmit={handleSubmit}
                isApproved={isApproved}
                isSubmitted={isSubmitted}
                isRejected={isRejected}
              />
            )}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Approval;
