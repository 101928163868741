import { formatDate } from "@/utils";

export const dateStage = (
  selector,
  start,
  end,
  type,
  parentCompany,
  appType
) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  let sortKey = "_id.day";
  let format = "%m-%d-%Y";
  if (type === "yearly") {
    format = "%m-%Y";
    sortKey = "_id.year";
  }

  if (type === "monthly") {
    sortingDate.day = { $dayOfMonth: `$${selector}` };
    sortKey = "_id.month";
  }

  // Define default status object based on appType
  const defaultStatuses =
    appType === "serviceRepair"
      ? {
          New: 0,
          Pending: 0,
          Booked: 0,
          Assigned: 0,
          "In Progress": 0,
          Completed: 0,
          Invoiced: 0,
          Name: "$name",
          Total: "$countTotal",
        }
      : {
          New: 0,
          Pending: 0,
          "En Route": 0,
          Delivered: 0,
          Invoiced: 0,
          Name: "$name",
          Total: "$countTotal",
        };

  return [
    {
      $match: {
        parentCompany,
        [selector]: {
          $gte: start,
          $lte: end,
        },
      },
    },
    {
      $group: {
        _id: {
          ...sortingDate,
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [defaultStatuses, "$data"],
        },
      },
    },
  ];
};

export const invoiceStage = (
  selector,
  start,
  end,
  type,
  parentCompany,
  appType
) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  let sortKey = "_id.day";
  let format = "%m-%d-%Y";
  if (type === "yearly") {
    format = "%Y-%m";
  }

  if (type === "monthly") {
    sortingDate.day = { $dayOfMonth: `$${selector}` };
    sortKey = "_id.month";
  }
  let totalName = "$calculatedTotalCharges";

  return [
    {
      $match: {
        parentCompany,
        [selector]: {
          $gte: start,
          $lte: end,
        },
      },
    },
    {
      $lookup: {
        from: "jobs",
        localField: "jobs",
        foreignField: "_id",
        as: "jobs",
      },
    },
    {
      $lookup: {
        from: "legs",
        localField: "jobs.legs",
        foreignField: "_id",
        as: "allLegs",
      },
    },
    {
      $addFields: {
        totalAmount: {
          $ifNull: ["$calculatedTotalCharges", 0],
        },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
        totalAmount: { $sum: totalName },
      },
    },
    {
      $group: {
        _id: "$_id.status",
        name: { $first: "$_id.status" },
        data: {
          $push: {
            k: "$_id.status",
            v: "$count",
            amount: "$totalAmount",
          },
        },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        amountTotal: {
          $sum: "$data.amount",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              Review: 0,
              Sent: 0,
              Paid: 0,
              Name: "$name",
              Total: "$countTotal",
              TotalAmount: "$amountTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

// Original;

// export const invoiceStage = (selector, start, end, type, parentCompany) => {
//   const sortingDate = {
//     year: { $year: `$${selector}` },
//     month: { $month: `$${selector}` },
//   };
//   let sortKey = "_id.day";
//   let format = "%m-%d-%Y";
//   if (type === "yearly") {
//     format = "%Y-%m";
//     sortKey = "_id.year";
//   }

//   if (type === "monthly") {
//     sortingDate.day = { $dayOfMonth: `$${selector}` };
//     sortKey = "_id.month";
//   }

//   return [
//     {
//       $match: {
//         parentCompany,
//         [selector]: {
//           $gte: start,
//           $lte: end,
//         },
//       },
//     },
//     {
//       $group: {
//         _id: {
//           date: {
//             $dateToString: { format, date: `$${selector}` },
//           },
//           ...sortingDate,
//           status: "$status",
//         },
//         count: { $sum: 1 },
//       },
//     },
//     {
//       $group: {
//         _id: "$_id.status",
//         name: { $first: "$_id.status" },
//         data: { $push: { k: "$_id.status", v: "$count" } },
//       },
//     },
//     { $sort: { name: 1 } },
//     {
//       $project: {
//         name: "$_id",
//         countTotal: {
//           $sum: "$data.v",
//         },
//         data: {
//           $arrayToObject: {
//             $map: {
//               input: "$data",
//               as: "el",
//               in: { k: "$$el.k", v: "$$el.v" },
//             },
//           },
//         },
//       },
//     },
//     {
//       $replaceRoot: {
//         newRoot: {
//           $mergeObjects: [
//             {
//               Review: 0,
//               Sent: 0,
//               Paid: 0,
//               Name: "$name",
//               Total: "$countTotal",
//             },
//             "$data",
//           ],
//         },
//       },
//     },
//   ];
// };

export const clientJobsStage = (selector, parentCompany, client, appType) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };

  const defaultStatuses =
    appType === "serviceRepair"
      ? {
          New: 0,
          Pending: 0,
          Booked: 0,
          Assigned: 0,
          "In Progress": 0,
          Completed: 0,
          Invoiced: 0,
          Name: "$name",
          Total: "$countTotal",
        }
      : {
          New: 0,
          Pending: 0,
          "En Route": 0,
          Delivered: 0,
          Invoiced: 0,
          Name: "$name",
          Total: "$countTotal",
        };
  // let sortKey = "_id.day";
  let format = "%m-%Y";
  // if (type === "yearly") {
  //   format = "%Y-%m";
  //   sortKey = "_id.month";
  // }

  // if (type === "monthly") {
  //   sortingDate.day = { $dayOfMonth: `$${selector}` };
  // }

  return [
    {
      $match: {
        // parentCompany,
        client,

        // [selector]: {
        //   $gte: start,
        //   $lte: end,
        // },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [defaultStatuses, "$data"],
        },
      },
    },
  ];
};

export const driverJobsStage = (selector, parentCompany, driver) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  // let sortKey = "_id.day";
  let format = "%Y-%m";
  // if (type === "yearly") {
  //   format = "%Y-%m";
  //   sortKey = "_id.month";
  // }

  // if (type === "monthly") {
  //   sortingDate.day = { $dayOfMonth: `$${selector}` };
  // }

  return [
    {
      $match: {
        // parentCompany,
        driver,

        // [selector]: {
        //   $gte: start,
        //   $lte: end,
        // },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: { $push: { k: "$_id.status", v: "$count" } },
      },
    },
    { $sort: { name: 1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              New: 0,
              Pending: 0,
              "En Route": 0,
              Delivered: 0,
              Invoiced: 0,
              Name: "$name",
              Total: "$countTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const driverPayoutStage = (
  startDate,
  endDate,
  driverId,
  parentCompany
) => {
  return [
    {
      $match: {
        dateCompleted: {
          $gte: startDate,
          $lte: endDate,
        },
        status: "Delivered", // Only count completed deliveries
        driver: driverId,
        parentCompany: parentCompany,
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: {
              format: "%Y-%m-%d",
              date: "$dateCompleted",
            },
          },
          jobId: "$_id",
          driverId: "$driver",
          parentCompany: "$parentCompany",
        },
        // Individual job details
        mileage: { $first: "$mileage" },
        baseRate: { $first: "$driverRate" },
        waitTime: { $first: "$waitTime" },
        additionalFees: { $first: "$additionalFees" },
        // Calculate total payout for this job
        jobTotal: {
          $sum: {
            $add: [
              "$driverRate",
              { $multiply: ["$waitTime", "$waitTimeRate"] },
              { $sum: "$additionalFees" },
            ],
          },
        },
      },
    },
    {
      // Group by date and driver to get daily totals
      $group: {
        _id: {
          date: "$_id.date",
          driverId: "$_id.driverId",
          parentCompany: "$_id.parentCompany",
        },
        jobs: {
          $push: {
            jobId: "$_id.jobId",
            mileage: "$mileage",
            baseRate: "$baseRate",
            waitTime: "$waitTime",
            additionalFees: "$additionalFees",
            total: "$jobTotal",
          },
        },
        dailyJobs: { $sum: 1 },
        dailyMileage: { $sum: "$mileage" },
        dailyTotal: { $sum: "$jobTotal" },
      },
    },
    {
      // Get grand totals across all dates
      $group: {
        _id: {
          driverId: "$_id.driverId",
          parentCompany: "$_id.parentCompany",
        },
        payoutDetails: {
          $push: {
            date: "$_id.date",
            jobs: "$jobs",
            totalJobs: "$dailyJobs",
            totalMileage: "$dailyMileage",
            dailyTotal: "$dailyTotal",
          },
        },
        grandTotalJobs: { $sum: "$dailyJobs" },
        grandTotalMileage: { $sum: "$dailyMileage" },
        grandTotal: { $sum: "$dailyTotal" },
      },
    },
    {
      $sort: { "_id.date": 1 },
    },
  ];
};

export const totalStage = (selector, start, end, type, parentCompany) => {};
//   const sortingDate = {
//     year: { $year: `$${selector}` },
//     month: { $month: `$${selector}` },
//   };
//   let sortKey = "_id.day";
//   let format = "%Y-%m-%d";
//   if (type === "yearly") {
//     format = "%Y-%m";
//     sortKey = "_id.month";
//   }

//   if (type === "monthly") {
//     sortingDate.day = { $dayOfMonth: `$${selector}` };
//   }

//   return [
//     {
//       $match: {
//         // parentCompany,
//         [selector]: {
//           $gte: start,
//           $lte: end,
//         },
//       },
//     },
//     {
//       $group: {
//         _id: {
//           date: {
//             $dateToString: { format, date: `$${selector}` },
//           },
//           ...sortingDate,
//           status: "$status",
//         },
//         count: { $sum: 1 },
//       },
//     },
//     {
//       $group: {
//         _id: "$_id.date",
//         // name: { $first: "$_id.date" },
//         total: {
//           $sum: { $sum: "$count" },
//           // data: { $push: { $sum: "$count" } },
//         },
//       },
//     },
//     { $sort: { name: 1 } },
//     {
//       $project: {
//         name: "$_id",
//         total: "$total",
//       },
//     },
//     data: {
//       $arrayToObject: {
//         $map: {
//           input: "$data",
//           as: "el",
//           in: { k: "$$el.k", v: "$$el.v" },
//         },
//       },
//     },
//   },
// },
// {
//   $replaceRoot: {
//     newRoot: { $mergeObjects: [{ name: "$name" }, "$data"] },
//   },
// },
//   ];
// };

export const clientInvoiceStage = (
  selector,
  parentCompany,
  client,
  appType
) => {
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  let format = "%m-%Y";

  return [
    {
      $match: {
        client,
      },
    },
    {
      $lookup: {
        from: "jobs",
        localField: "jobs",
        foreignField: "_id",
        as: "jobs",
      },
    },
    {
      $lookup: {
        from: "legs",
        localField: "jobs.legs",
        foreignField: "_id",
        as: "allLegs",
      },
    },
    {
      $addFields: {
        totalAmount: {
          $ifNull: ["$calculatedTotalCharges", 0],
        },
        paidAmount: {
          $cond: {
            if: { $eq: ["$status", "Paid"] },
            then: { $ifNull: ["$calculatedTotalCharges", 0] },
            else: 0,
          },
        },
        outstandingAmount: {
          $cond: {
            if: { $ne: ["$status", "Paid"] },
            then: { $ifNull: ["$calculatedTotalCharges", 0] },
            else: 0,
          },
        },
      },
    },
    {
      $group: {
        _id: {
          date: {
            $dateToString: { format, date: `$${selector}` },
          },
          ...sortingDate,
          status: "$status",
        },
        count: { $sum: 1 },
        totalAmount: { $sum: "$totalAmount" },
        paidAmount: { $sum: "$paidAmount" },
        outstandingAmount: { $sum: "$outstandingAmount" },
      },
    },
    {
      $group: {
        _id: "$_id.date",
        name: { $first: "$_id.date" },
        data: {
          $push: {
            k: "$_id.status",
            v: "$count",
            amount: "$totalAmount",
            paidAmount: "$paidAmount",
            outstandingAmount: "$outstandingAmount",
          },
        },
      },
    },
    { $sort: { name: -1 } },
    {
      $project: {
        name: "$_id",
        countTotal: {
          $sum: "$data.v",
        },
        amountTotal: {
          $sum: "$data.amount",
        },
        paidTotal: {
          $sum: "$data.paidAmount",
        },
        outstandingTotal: {
          $sum: "$data.outstandingAmount",
        },
        data: {
          $arrayToObject: {
            $map: {
              input: "$data",
              as: "el",
              in: { k: "$$el.k", v: "$$el.v" },
            },
          },
        },
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          $mergeObjects: [
            {
              Review: 0,
              Sent: 0,
              Paid: 0,
              Name: "$name",
              Total: "$countTotal",
              TotalAmount: "$amountTotal",
              PaidAmount: "$paidTotal",
              OutstandingAmount: "$outstandingTotal",
            },
            "$data",
          ],
        },
      },
    },
  ];
};

export const taxLiabilityReport = (
  selector,
  start,
  end,
  parentCompany,
  appType
) => {
  const format = "%Y-%m";
  const sortingDate = {
    year: { $year: `$${selector}` },
    month: { $month: `$${selector}` },
  };
  if (appType === "serviceRepair") {
    return [
      {
        $match: {
          parentCompany,
          [selector]: {
            $gte: start,
            $lte: end,
          },
        },
      },
      {
        $lookup: {
          from: "jobs",
          localField: "_id",
          foreignField: "_id",
          as: "jobs",
        },
      },
      {
        $unwind: "$jobs",
      },
      {
        $project: {
          appType: appType,
          jobs: "$jobs",
        },
      },
      {
        $addFields: {
          // For service repair, calculate specific totals
          grossAmount: {
            $add: [
              { $ifNull: ["$jobs.partsTotal", 0] },
              { $ifNull: ["$jobs.hourlyTotal", 0] },
              { $ifNull: ["$jobs.serviceFeeTotal", 0] },
              { $ifNull: ["$jobs.salesTax", 0] },
            ],
          },

          nonTaxableAmount: {
            $add: [
              { $ifNull: ["$jobs.hourlyTotal", 0] },
              { $ifNull: ["$jobs.serviceFeeTotal", 0] },
            ],
          },
          taxableAmount: {
            $add: [{ $ifNull: ["$jobs.partsTotal", 0] }],
          },
          taxAmount: {
            $add: [{ $ifNull: ["$jobs.salesTax", 0] }],
          },
        },
      },
      {
        $group: {
          _id: {
            date: {
              $dateToString: { format, date: `$${selector}` },
            },
            ...sortingDate,
          },
          gross: { $sum: "$grossAmount" },
          nonTaxable: { $sum: "$nonTaxableAmount" },
          taxable: { $sum: "$taxableAmount" },
          tax: { $sum: "$taxAmount" },
          count: { $sum: 1 },
        },
      },
      {
        $project: {
          dateRange: {
            $concat: [
              formatDate(start, false, "MM/dd/yyyy"),
              " to ",
              formatDate(end, false, "MM/dd/yyyy"),
            ],
          },
          _id: 0,
          name: "$_id.date",
          gross: { $round: ["$gross", 2] },
          nonTaxable: { $round: ["$nonTaxable", 2] },
          taxable: { $round: ["$taxable", 2] },
          tax: { $round: ["$tax", 2] },
          count: 1,
        },
      },
      { $sort: { name: -1 } },
    ];
  } else {
    return [];
  }
};

export const payoutReportStage = (
  startDate,
  endDate,
  parentCompany,
  driver,
  type
) => {
  if (!startDate || !endDate || !parentCompany) {
    console.error("Missing required parameters for payoutReportStage");
    return null;
  }

  return [
    {
      $match: {
        parentCompany: parentCompany,
        driver: driver,
        dateCreated: {
          $gte: startDate,
          $lte: endDate,
        },
      },
    },
    // {
    //   $lookup: {
    //     from: "users",
    //     localField: "driver",
    //     foreignField: "_id",
    //     as: "driverDetails",
    //   },
    // },
    // {
    //   $unwind: {
    //     path: "$driverDetails",
    //     preserveNullAndEmptyArrays: true,
    //   },
    // },
    // {
    //   $group: {
    //     _id: {
    //       year: { $year: "$createdAt" },
    //       month: { $month: "$createdAt" },
    //       driver: "$driver",
    //     },
    //     driverName: { $first: "$driverDetails.name" },
    //     totalPayout: { $sum: "$amount" },
    //     mileagePay: { $sum: "$mileagePay" },
    //     totalMileage: { $sum: "$mileage" },
    //     supplementalPay: { $sum: "$supplementalPay" },
    //     count: { $sum: 1 },
    //   },
    // },
    // {
    //   $project: {
    //     _id: 0,
    //     year: "$_id.year",
    //     month: "$_id.month",
    //     driver: "$_id.driver",
    //     driverName: 1,
    //     totalPayout: 1,
    //     mileagePay: 1,
    //     totalMileage: 1,
    //     supplementalPay: 1,
    //     count: 1,
    //   },
    // },
    // {
    //   $sort: {
    //     year: 1,
    //     month: 1,
    //   },
    // },
  ];
};
