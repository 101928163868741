import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../shared/Button";
import Card from "../../shared/Card";
import classNames from "classnames";
import Select from "../../shared/Select";
import Spinner from "../../shared/Spinner";
import TextArea from "../../shared/TextArea";
import { useAuth } from "../../hooks/useAuth";
import useForm from "../../hooks/useForm";
import {
  fetchFeedback,
  resetFeedback,
  updateFeedback,
} from "../../redux/reducers/feedbacks/feedbackSlice";
import { feedbackStatusOptions } from "../../constants/select";
import { formatDate } from "../../utils";
import { FORM_OPTIONS } from "./table.config";

import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";

const FeedbackShow = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { user } = useAuth();
  const { form, setFormInit, updateForm, clearForm } = useForm(FORM_OPTIONS);

  const feedback = useSelector((state) => state.feedback.feedback);
  const feedbackStatus = useSelector((state) => state.feedback.status);

  const [noteModalVisible, setNoteModalVisible] = useState(false);

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  const actions = [
    // {
    //   id: "edit",
    //   icon: PencilSquareIcon,
    //   onClick: () => navigate(`/jobs/${job._id}/edit`),
    //   label: "Edit",
    // },
    {
      id: "addNote",
      icon: ChatBubbleBottomCenterTextIcon,
      onClick: () => {
        onFormChange({ id: "note", value: feedback.note });
        setNoteModalVisible(true);
      },
      label: "Add Note",
      type: "success",
    },
  ];

  useEffect(() => {
    (async () => {
      if (feedbackStatus === "idle") {
        const feedbackRes = await dispatch(
          fetchFeedback({
            feedbackId: params.id,
            user: user,
          })
        ).unwrap();
        if (feedbackRes.feedback) {
          form.setFormInIt(feedbackRes.feedback);
        }
      }
    })();
  }, [feedbackStatus, user, params.id]);

  async function handleUpdate(e) {
    const update = {
      [e.id]: e.value,
      ...e.data,
    };

    dispatch(
      updateFeedback({
        data: update,
        parentId: user.parentCompany,
        feedbackId: feedback._id,
      })
    );
  }

  useEffect(() => {
    return () => dispatch(resetFeedback());
  }, []);

  function handleCancelNote() {
    setNoteModalVisible(false);
    clearForm();
  }

  const noteModalFooter = (
    <>
      <Button onClick={handleCancelNote} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleUpdate({ id: "note", value: form.note });
          handleCancelNote();
        }}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const noteModalContent = (
    <TextArea
      id="note"
      value={form.note}
      onChange={(e) => onFormChange({ value: e.target.value, id: "note" })}
    />
  );

  return feedbackStatus === "loading" ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <>
      <div className="flex flex-col">
        {/* <div className="flex justify-end"> */}

        {/* <span className="mr-5">
          Created: {formatDate(feedback.dateCreated)}
        </span> */}
        {/* <span>Updated: {formatDate(feedback.dateUpdated)}</span> */}
        {/* </div> */}
        <Card>
          <div className="grid grid-cols-1 gap-4">
            <p>Author: {feedback.author?.fullName}</p>
            <p>Type: {feedback.type}</p>
            <p>Message: {feedback.message?.[0].content}</p>
          </div>
          <div className="w-full">
            <div className="flex justify-end border-b mb-3 md:mb-0 md:border-b-0">
              <div>
                <Select
                  id="status"
                  onChange={handleUpdate}
                  value={feedback.status}
                  size="sm"
                  placeholder={feedback.status}
                  // label="Status"
                  options={feedbackStatusOptions}
                />
              </div>
              {actions.map((item) => {
                const Icon = item.icon;
                const circleClasses = classNames(
                  "border ease-in-out duration-300 rounded-full p-2 cursor-pointer",
                  {
                    "hover:border-gray-300 hover:bg-gray-200":
                      item.type === "default" || !item.type,
                    "hover:border-red-300 hover:bg-red-200":
                      item.type === "danger",
                  }
                );
                return (
                  <div className="flex flex-col items-center ml-4 mb-3 md:mb-0">
                    <div onClick={item.onClick} className={circleClasses}>
                      <Icon className="w-6 h-6" />
                    </div>
                    <p className="font-semibold">{item.label}</p>
                  </div>
                );
              })}
            </div>
            <div className="mx-2 py-2 w-full">
              <span className="font-bold">Notes:</span>
              <div className="whitespace-pre">{feedback.note}</div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default FeedbackShow;
