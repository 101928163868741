import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import Select from "../../../shared/Select";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import request, { renderQueries } from "../../../utils/request";
import { DatePicker, DateRangePicker, Input } from "../../../shared";
import { formatDate } from "../../../utils";
import { useGetDriversQuery } from "../../../redux/services/drivers/driversApi";
import { useGetEmployeesQuery } from "../../../redux/services/employees/employeesApi";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../../hooks/useConfig";
import { useAddPayoutMutation } from "../../../redux/services/payouts/payoutsApi";
const CreateModal = ({
  modalOpen,
  setModalOpen,
  renderId = (id) => id,
  ...props
}) => {
  const { user } = useAuth();
  const { appType } = useConfig();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const [addPayout, { isLoading: addPayoutLoading }] = useAddPayoutMutation();
  // Use RTK Query hooks based on appType
  const { data: driversData, isLoading: driversLoading } = useGetDriversQuery(
    { parent: user.parentCompany, type: "driver" },
    { skip: appType !== "trucking" || !!props.driver }
  );

  const { data: employeesData } = useGetEmployeesQuery({});

  // Transform data for select options
  const driverOptions =
    driversData?.drivers?.map((driver) => ({
      label: driver.fullName,
      value: driver._id,
      ...driver,
    })) || [];

  const employeeOptions =
    employeesData?.employees?.map((employee) => ({
      label: employee.user?.fullName || "No Name",
      value: employee._id,
      ...employee,
    })) || [];

  const handleDateUpdate = () => {
    const query = {
      parent: user.parentCompany,
      // driverId: form.driver?._id,
      [appType === "trucking" ? "driverId" : "employeeId"]:
        appType === "trucking" ? form.driver?._id : form.employee?._id,
      payoutId: null,
      route: true,
      startDate: formatDate(form.startDate, null, true),
      endDate: formatDate(form.endDate, null, true),
    };
    getCount(query);
  };

  const getCount = async (query) => {
    query.countOnly = true;
    const endpoint = appType === "trucking" ? "/legs" : "/shifts";
    const res = await request(`${endpoint}?${renderQueries(query)}`, {
      method: "get",
      data: {},
    });
    updateForm({ id: "count", value: res.data.data });
  };

  useEffect(() => {
    if (form.startDate && form.endDate && (form.driver || form.employee)) {
      handleDateUpdate();
    }
  }, [form.startDate, form.endDate, form.driver, form.employee]);

  useEffect(() => {
    if ((props.driver || props.employee) && modalOpen) {
      setFormInit({
        [appType === "trucking" ? "driver" : "employee"]:
          props.driver || props.employee,
      });
    }
  }, [setFormInit, modalOpen]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function savePayout() {
    const postData = {
      ...form,
      parent: user.parentCompany,
    };

    const response = await addPayout(postData).unwrap();

    if (response.status === "success") {
      navigate("/payouts/" + response.data._id);
    }
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => savePayout()}
        type="primary"
        loading={addPayoutLoading}
      >
        Submit
      </Button>
    </div>
  );

  function handleSelectPerson(selected) {
    updateForm({
      id: appType === "trucking" ? "driver" : "employee",
      value: selected,
    });
  }

  function handleCancel() {
    clearForm(appType === "trucking" ? "driver" : "employee");
  }

  const createModalContent = (
    <div className="">
      {!form.driver && !form.employee ? (
        <div className="space-y-3">
          <div className="col-span-2 md:col-span-1 xl:col-span-2 max-w-md">
            <Select
              search
              searchKeys={["fullName"]}
              id={renderId(appType === "trucking" ? "driver" : "employee")}
              value={appType === "trucking" ? form.driver : form.employee}
              onChange={handleSelectPerson}
              label={appType === "trucking" ? "Driver" : "Employee"}
              options={appType === "trucking" ? driverOptions : employeeOptions}
              placeholder={`Select ${
                appType === "trucking" ? "Driver" : "Employee"
              }`}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5 ">
            <AvatarClient
              selected
              onCancel={handleCancel}
              {...(appType === "trucking" ? form.driver : form.employee)}
            />
          </div>
          <div className="mb-5 items-center gap-4 mx-5 grid grid-cols-1 w-1/2 md:w-full md:grid-cols-3">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date Range
              </label>
              <DateRangePicker
                startDate={form.startDate ? new Date(form.startDate) : null}
                endDate={form.endDate ? new Date(form.endDate) : null}
                onChange={([start, end]) => {
                  updateForm((prevForm) => ({
                    ...prevForm,
                    startDate: start ? start.toISOString() : null,
                    endDate: end ? end.toISOString() : null,
                  }));
                }}
                className="w-full"
              />
            </div>
            <div className="">
              <Input
                disabled
                className=" !w-1/2"
                label="Job Count:"
                value={form.count || 0}
              />
            </div>
          </div>
          {form.count === 0 && form.startDate && form.endDate && (
            <p className="text-red-500 text-sm text-center">
              There are no jobs in this selected date range.
            </p>
          )}

          {/* <AdvancedSettings form={form} updateForm={updateForm} /> */}
        </>
      )}
    </div>
  );

  const title = (
    <span className="mx-4">
      {form.driver || form.employee
        ? "Select a date range"
        : `Select ${appType === "trucking" ? "a driver" : "an employee"}`}
    </span>
  );

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

const AvatarClient = (props) => {
  // Get the correct display name from the data structure
  const displayName =
    props.data?.user?.fullName || props.user?.fullName || props.label || "";
  // Get avatar from user object if it exists
  const avatarSrc =
    props.data?.user?.avatar || props.user?.avatar || props.avatar;

  return (
    <div
      onClick={props.onClick}
      className="flex items-center justify-between hover:bg-gray-100 rounded-lg py-2 px-1 cursor-pointer"
    >
      <div className="flex items-center space-x-2">
        <Avatar size="xs" src={avatarSrc} />
        <span className="font-semi-bold text-sm">{displayName}</span>
      </div>
      <div>
        {props.selected && (
          <XMarkIcon onClick={props.onCancel} className="w-5 h-5" />
        )}
      </div>
    </div>
  );
};

export default CreateModal;
