import {
  CheckCircleIcon,
  PaperAirplaneIcon,
  PencilIcon,
  TrashIcon,
  BanknotesIcon,
  ArrowDownTrayIcon,
  WrenchIcon,
  TruckIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { IconButton } from "../IconButton";
import { useConfig } from "../../hooks";
import { ReactElement } from "react";

interface ActionButtonsProps {
  item?: {
    approval?: {
      status?: string;
    };
    status?: string;
  };
  onApprove?: () => void;
  onSend?: () => void;
  onPay?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onDownload?: () => void;
  className?: string;
}

interface Action {
  id: string;
  icon: ReactElement;
  title: string;
  onClick?: () => void;
  show: boolean;
}

interface IndustryActions {
  [key: string]: Action[];
}

export const ActionButtons = ({
  item,
  onApprove,
  onSend,
  onEdit,
  onDelete,
  className = "flex justify-center space-x-4",
}: ActionButtonsProps): ReactElement => {
  const { configs } = useConfig();

  // Base actions available across all industries
  const baseActions: Action[] = [
    {
      id: "approve",
      icon: <CheckCircleIcon className="w-6 h-6" />,
      title: "Approve",
      onClick: onApprove,
      show: !!onApprove,
    },
    {
      id: "send",
      icon: <PaperAirplaneIcon className="w-6 h-6" />,
      title: "Send",
      onClick: onSend,
      show: !!onSend,
    },
    {
      id: "edit",
      icon: <PencilIcon className="w-6 h-6" />,
      title: "Edit",
      onClick: onEdit,
      show: !!onEdit,
    },
    {
      id: "delete",
      icon: <TrashIcon className="w-6 h-6" />,
      title: "Delete",
      onClick: onDelete,
      show: !!onDelete,
    },
  ];

  // Industry-specific actions
  const industryActions: IndustryActions = {
    service_repair: [
      {
        id: "complete_service",
        icon: <WrenchIcon className="w-6 h-6" />,
        title: "Complete Service",
        onClick: undefined,
        show: false,
      },
      {
        id: "parts_order",
        icon: <ClipboardDocumentCheckIcon className="w-6 h-6" />,
        title: "Order Parts",
        onClick: undefined,
        show: false,
      },
    ],
    trucking: [
      {
        id: "delivery_confirmation",
        icon: <TruckIcon className="w-6 h-6" />,
        title: "Confirm Delivery",
        onClick: undefined,
        show: false,
      },
    ],
  };

  // Get actions based on industry type
  const getIndustryActions = (): Action[] => {
    const industry = configs.industryType?.toLowerCase() || "service_repair";
    return industryActions[industry] || [];
  };

  // Combine base and industry-specific actions
  const allActions: Action[] = [...baseActions, ...getIndustryActions()];

  return (
    <div className={className}>
      {allActions.map(
        (action) =>
          action.show && (
            <IconButton
              key={action.id}
              onClick={action.onClick}
              icon={action.icon}
              title={action.title}
            />
          )
      )}
    </div>
  );
};

export default ActionButtons;
