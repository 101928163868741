import {
  AttachmentsView,
  TechniciansList,
  PartsList,
  IconButton,
} from "../../../../components";
import {
  useAddMediaMutation,
  useGetMediaQuery,
  useRemoveMediaMutation,
} from "../../../../redux/services/media/mediaApi";
import { useState, useRef, useMemo } from "react";
import {
  useDeleteJobItemsMutation,
  useUpdateJobItemMutation,
  useAddPartsToJobItemMutation,
} from "../../../../redux/services/jobs/jobItems";
import {
  CheckCircleIcon,
  PaperAirplaneIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  useAddInventoryItemMutation,
  useUpdateInventoryItemMutation,
} from "../../../../redux/services/inventory/inventoryApi";
import { toast } from "react-toastify";
import { DeleteModal } from "../DeleteModal";
import { PayModal } from "../PayModal";
import { SendPDFModal } from "@/modals";
import { getDocumentFormData, useConfig } from "../../../../hooks";
import HoursSelect from "./HoursSelect";
import EquipmentCard from "./EquipmentCard";
import WorkSummary from "./WorkSummary";
import ServiceTotals from "./ServiceTotals";
import { isAuthorized } from "../../../../utils";
import JobItemLayout from "../../../../layouts/JobItemLayout";
import ItemApprovalModal from "../ItemApprovalModal";
import { useApproveItemMutation } from "@/redux/services/items/itemsApi";
import ActionButtons from "@/components/ActionButtons/ActionButtons";
import { useSendQuoteEmailMutation } from "@/redux/services/items/itemsApi";

export const ExpandedItem = ({
  row: { original, getVisibleCells },
  editItem,
  job,
  setCreateModalVisible,
  isQuoteMode,
  ...props
}) => {
  const { configs } = useConfig();
  let item = { ...original, parentCompany: configs.parentCompany };

  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);

  const [addMedia] = useAddMediaMutation();
  const [removeMedia] = useRemoveMediaMutation();
  const { data: media } = useGetMediaQuery({
    refId: item._id,
  });
  const [deleteItems, { isLoading: isDeleting }] = useDeleteJobItemsMutation();
  const [updateItems, { isLoading: isUpdating }] = useUpdateJobItemMutation();
  const [addInventoryItem] = useAddInventoryItemMutation();
  const [updateInventoryItem] = useUpdateInventoryItemMutation();
  const [addPartsToJobItem] = useAddPartsToJobItemMutation();
  const [approveItem] = useApproveItemMutation();
  const [sendEmail, { isLoading: isSending }] = useSendQuoteEmailMutation();

  const pdfRef = useRef();

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  async function handleDelete() {
    const res = await deleteItems({
      jobItemId: item._id,
      jobId: props.table.options.meta.job._id,
      parent: props.table.options.meta.job.parentCompany,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "deleted-item-" + res.data.deletedIds[0],
    });
  }

  /**
   * Handles file uploads for attachments
   * @param {FileList} files - Files selected by the user
   * @returns {Promise<void>}
   */
  const handleAddFile = async (files) => {
    // Input validation
    if (!files || files.length === 0) {
      toast.warn("No files selected", {
        toastId: "no-files-selected",
      });
      return;
    }

    try {
      // Convert FileList to array and validate file types/sizes if needed
      const fileArray = Array.from(files).map((file) => {
        // Optional: Add file validation here
        if (file.size > 10 * 1024 * 1024) {
          // 10MB limit example
          throw new Error(`File ${file.name} exceeds size limit`);
        }
        return file;
      });

      // Convert files to format expected by getDocumentFormData
      const fileAssets = fileArray.map((file) => ({
        ...file,
        uri: URL.createObjectURL(file),
        mimeType: file.type,
        name: file.name,
      }));

      const formData = await getDocumentFormData(fileAssets, {
        refId: item._id,
        refType: "maintenance",
        type: "attachment",
        uploadPath: `${job._id}/${item._id}`,
      });

      if (!formData) {
        throw new Error("Failed to create form data");
      }

      // Upload files and handle response
      const response = await addMedia({ formData }).unwrap();

      // // Verify response
      // if (!response || !response.data) {
      //   throw new Error("Invalid response from server");
      // }

      // // Show success notification
      // toast.success("Files uploaded successfully", {
      //   toastId: "files-upload-success",
      // });
    } catch (error) {
      // Handle specific error types
      if (error.status === 413) {
        toast.error("Files too large - Maximum size is 10MB", {
          toastId: "files-too-large",
        });
      } else if (error.status === 415) {
        toast.error("Unsupported file type", {
          toastId: "unsupported-file-type",
        });
      } else {
        toast.error(error.message || "Failed to upload files", {
          toastId: "upload-failed",
        });
      }

      // Re-throw error for parent component handling if needed
      throw error;
    }
  };

  const handleAddParts = async (newParts) => {
    try {
      // Process each new part to either create or update inventory
      const processedParts = await Promise.all(
        newParts.map(async (part) => {
          if (part._id) {
            // Update existing inventory item
            const updateRes = await updateInventoryItem({
              id: part._id,
              data: {
                quantity: part.quantity,
                unitPrice: part.unitPrice,
              },
            }).unwrap();
            return {
              part: part._id,
              quantity: part.quantity,
              unitPrice: part.unitPrice,
            };
          } else {
            // Create new inventory item
            const createRes = await addInventoryItem({
              name: part.name,
              partNumber: part.partNumber,
              description: part.description,
              quantity: part.quantity,
              unitPrice: part.unitPrice,
              category: part.category,
              supplier: part.supplier,
            }).unwrap();
            return {
              part: createRes.data._id,
              quantity: part.quantity,
              unitPrice: part.unitPrice,
            };
          }
        })
      );

      // Merge parts arrays, avoiding duplicates by part ID
      const combinedParts = processedParts;

      // Update job item with all parts
      const res = await addPartsToJobItem({
        jobId: job._id,
        jobItemId: item._id,
        data: { parts: combinedParts },
      }).unwrap();

      toast.success("Parts updated successfully");
      return res;
    } catch (error) {
      console.error(
        "Failed to handle parts:",
        error.message || "Unknown error"
      );
      toast.error(error.message || "Failed to update parts");
      throw error;
    }
  };

  const handleAddTechnician = () => {};

  const handleDeleteFile = (item) => {
    removeMedia({ id: item._id });
  };

  const handleOpenPayModal = () => {};

  const handleEdit = () => {};

  const handleEditModal = () => {
    props.form.setFormInit(item, { setErrors: true });
    setEditModalVisible(true);
  };

  /**
   * Handles downloading the service item as a PDF
   * Uses JobItemLayout to generate a properly formatted PDF
   */
  const handleDownloadPdf = async () => {
    try {
      // Render the JobItemLayout component
      <JobItemLayout
        ref={pdfRef}
        jobItem={item}
        itemModel={job.itemsModel}
        download={true}
      />;
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF");
    }
  };

  const jobItem = useMemo(() => {
    return {
      ...item,
      parentCompany: configs.parentCompany,
      media: media || [],
      rates: configs.rates,
    };
  }, [item, configs.parentCompany, media]);

  const renderSummaryValue = () => {
    if (!item.type || item.type === "quote") {
      return item.quotedSummary || item.summary;
    }
    return item.summary;
  };

  const handleCloseApprovalModal = () => {
    setApprovalModalVisible(false);
  };

  const handleApprove = async (data) => {
    const approvedItem = {
      data: {
        status: "approved",
        notes: data.notes,
        appointmentDate: data.appointmentDate,
      },
      itemId: item._id,
    };

    try {
      await approveItem(approvedItem).unwrap();
      toast.success("Service item approved successfully");
      handleCloseApprovalModal();
    } catch (error) {
      toast.error(error.message || "Failed to approve service item");
    }
  };

  const handleSendPDF = async (data) => {
    return sendEmail({
      itemId: jobItem._id,
      data: {
        ...data,
        jobId: jobItem.jobId,
        jobName: job.loadNumber || job.jobId,
        client: jobItem?.client?._id,
      },
    }).unwrap();
  };

  return (
    <>
      <td
        colSpan={getVisibleCells().length}
        className="w-full border-b space-y-6"
      >
        <div className="flex flex-row items-center justify-between gap-4 bg-gray-50 border-b px-3 border-gray-200 py-1">
          {/* Technicians Section */}
          <TechniciansList
            technicians={item.performedBy}
            addTechnician={handleAddTechnician}
            {...props.form}
          />
          <ActionButtons
            item={item}
            onApprove={
              item?.approval?.status === "pending" &&
              (() => setApprovalModalVisible(true))
            }
            onSend={() => setSendModalVisible(true)}
            onPay={() => setPayModalVisible(true)}
            onEdit={() => editItem(item, job)}
            onDelete={() => setDeleteModalVisible(true)}
            onDownload={handleDownloadPdf}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 px-4 pb-4 gap-6">
          {/* Hours Section */}
          <HoursSelect item={item} form={props.form} />

          {/* Service Totals Section */}
          {isAuthorized("owner,admin") && <ServiceTotals item={item} />}

          {/* Equipment Section */}
          <EquipmentCard equipment={item.equipment} />

          {/* Work Summary Section */}
          <WorkSummary summary={renderSummaryValue()} />

          {/* Attachments Section */}
          <div className="col-span-1 bg-white">
            <AttachmentsView
              attachments={media}
              fileUploaded={handleAddFile}
              onDelete={handleDeleteFile}
            />
          </div>

          {/* Parts List Section */}
          <div className="col-span-1 bg-white">
            <PartsList
              parts={item.parts}
              onClick={handleAddParts}
              equipment={item.equipment}
              handleEditItem={handleAddParts}
              job={job}
              jobItem={item}
            />
          </div>
        </div>
      </td>

      <DeleteModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDelete}
        loading={isDeleting}
      />
      <PayModal visible={payModalVisible} setVisible={setPayModalVisible} />
      {sendModalVisible && (
        <SendPDFModal
          visible={sendModalVisible}
          setVisible={setSendModalVisible}
          client={job.client}
          entity={jobItem}
          jobName={job.loadNumber || job.jobId}
          itemModel={job.itemsModel}
          type="WorkOrder"
          isQuoteMode={isQuoteMode}
          onSend={handleSendPDF}
          isLoading={isSending}
        />
      )}
      <ItemApprovalModal
        visible={approvalModalVisible}
        setVisible={setApprovalModalVisible}
        item={item}
        onApprove={handleApprove}
        onClose={handleCloseApprovalModal}
      />
    </>
  );
};

export default ExpandedItem;
