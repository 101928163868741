export const getSampleData = (type) => {
  const commonData = {
    parentCompany: {
      name: "ABC Company",
      address: "123 Business St, Suite 100",
      phone: "(555) 123-4567",
      email: "contact@abccompany.com",
    },
    client: {
      name: "John Smith",
      address: "456 Client Ave",
      phone: "(555) 987-6543",
      email: "john@example.com",
    },
  };

  switch (type) {
    case "invoice":
      return {
        ...commonData,
        invoiceId: "INV-2024-001",
        date: new Date().toLocaleDateString(),
        dueDate: new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000
        ).toLocaleDateString(),
        jobs: [
          {
            jobId: "JOB-001",
            description: "Service Call",
            date: new Date().toLocaleDateString(),
            amount: 150.0,
          },
          {
            jobId: "JOB-002",
            description: "Parts & Labor",
            date: new Date().toLocaleDateString(),
            amount: 350.0,
          },
        ],
        subtotal: 500.0,
        tax: 40.0,
        total: 540.0,
      };

    case "workOrder":
      return {
        ...commonData,
        jobId: "WO-2024-001",
        appointmentDate: new Date().toLocaleDateString(),
        items: [
          {
            serviceType: "HVAC Repair",
            summary: "Diagnosed and repaired AC unit",
            parts: [
              { name: "Air Filter", quantity: 1, price: 45.0 },
              { name: "Capacitor", quantity: 1, price: 85.0 },
            ],
          },
        ],
        laborTotal: 150.0,
        partsTotal: 130.0,
        tax: 22.4,
        total: 302.4,
      };

    case "jobProposal":
      return {
        ...commonData,
        jobId: "PROP-2024-001",
        date: new Date().toLocaleDateString(),
        items: [
          {
            serviceType: "Full HVAC System Replacement",
            quotedSummary:
              "Complete replacement of existing HVAC system including...",
            amount: 8500.0,
          },
        ],
        subtotal: 8500.0,
        tax: 680.0,
        total: 9180.0,
      };

    default:
      return commonData;
  }
};
