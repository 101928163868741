import { useEffect, useState } from "react";
import { Card } from "../../shared";
import { PieChart } from "../../shared/Charts";
import { invoiceStage } from "../../constants/aggregate";
import { getParentCompany } from "../../utils/authority";
import {
  endOfWeek,
  endOfMonth,
  startOfMonth,
  startOfDay,
  endOfToday,
  startOfWeek,
} from "date-fns";
import request from "../../utils/request";

export const InvoiceReport = (props) => {
  const [reportData, setReportData] = useState();

  useEffect(() => {
    fetchReport({ type: props.viewType });
  }, [props.viewType]);

  const newDate = new Date();

  // Start of day
  const startOfDayDate = startOfDay(newDate);
  const endOfDayDate = endOfToday(newDate);

  // Start of week
  const startOfWeekDate = new Date(startOfWeek(newDate));
  const endOfWeekDate = new Date(endOfWeek(newDate));

  // Start of month
  const startOfMonthDate = new Date(startOfMonth(newDate));
  const endOfMonthDate = new Date(endOfMonth(newDate));

  // Start/End of year
  const startOfYear = new Date(newDate.getFullYear(), 0);
  const endOfYear = new Date(newDate.getFullYear(), 12);

  const fetchReport = async (view) => {
    const type = view.type;
    let start = "";
    let end = "";
    if (type === "daily") {
      start = startOfDayDate;
      end = endOfDayDate;
    } else if (type === "weekly") {
      start = startOfWeekDate;
      end = endOfWeekDate;
    } else if (type === "monthly") {
      start = startOfMonthDate;
      end = endOfMonthDate;
    } else if (type === "yearly") {
      start = startOfYear;
      end = endOfYear;
    }

    const stages = invoiceStage(
      "dueDate",
      start.toString(),
      end.toString(),
      type,
      getParentCompany(),
      props.appType
    );

    const response = await request(`/reports`, {
      method: "post",
      data: {
        stages,
        modelName: "Invoice",
        config: {
          0: {
            type: "$match",
            useDate: "dueDate",
            objectId: "parentCompany",
          },
        },
      },
    });

    return Promise.all([response]).then((values) => {
      const finalData = values[0]?.data;
      setReportData(finalData);
    });
  };

  return (
    <div className="min-h-[430px] rounded-lg row-span-1 flex flex-col font-bold h-full">
      <Card className="h-full">
        <div className="mb-3 text-xs md:text-sm uppercase text-gray-500 font-bold">
          Invoice Statuses
        </div>
        <PieChart height={350} data={reportData} />
      </Card>
    </div>
  );
};
