import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import Button from "../Button";

interface ModalProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  onClose?: (value: boolean) => void;
  size?: "sm" | "md" | "lg" | "75%" | "xl" | "full";
  loading?: boolean;
  confirm?: () => void;
  contentWrapperClass?: string;
  footerPosition?: "left" | "center" | "right";
  panelClassName?: string;
  disabled?: boolean;
  footer?: ReactNode;
  cancelText?: string;
  submitText?: string;
  title?: string;
  content?: ReactNode;
  children?: ReactNode;
  hideFooter?: boolean;
}

export function Modal({
  visible = false,
  setVisible,
  onClose,
  size,
  loading,
  confirm,
  contentWrapperClass = "p-3 lg:p-6 space-y-6",
  ...props
}: ModalProps) {
  const isVisible = Boolean(visible);

  function closeModal() {
    onClose?.(false);
    setVisible?.(false);
  }

  const footerClasses = classNames(
    "flex items-center space-x-2 p-2 rounded-b border-t border-gray-200",
    {
      "justify-start": props.footerPosition === "left",
    },
    { "justify-center": props.footerPosition === "center" },
    { "justify-end": props.footerPosition === "right" || !props.footerPosition }
  );

  const panelWrap = classNames(
    props.panelClassName,
    "w-full flex flex-col space-4 transform overflow-visible min-h-[50px]",
    "rounded-xl bg-white text-left align-middle shadow-xl transition-all",
    {
      "md:w-4/12": size === "sm",
      "md:w-6/12": size === "md" || !size,
      "md:w-9/12": size === "lg",
      "md:w-3/4": size === "75%",
      "md:11/12 lg:w-4/5": size === "xl",
      "w-full h-full": size === "full",
    }
  );

  const footer = props.footer ? (
    props.footer
  ) : (
    <>
      <Button onClick={closeModal} type="alternative">
        {props.cancelText || "Cancel"}
      </Button>
      <Button
        loading={loading}
        disabled={loading || props.disabled}
        onClick={confirm}
        type="primary"
      >
        {props.submitText || "Submit"}
      </Button>
    </>
  );

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto p-2">
          <div className="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className={panelWrap}>
                <div className="flex justify-between items-center p-2 rounded-t border-b">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {props.title}
                  </DialogTitle>
                  <button
                    type="button"
                    className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="small-modal"
                    onClick={closeModal}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className={contentWrapperClass}>
                  {props.content || props.children}
                </div>
                {!props.hideFooter && (
                  <div className={footerClasses}>{footer}</div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
