import { useEffect, useState } from "react";
import { isObject } from "../utils";
import React from "react";

export const useForm = (options) => {
  // Normalize options without changing the API
  const normalizedOptions = React.useMemo(
    () => ({
      validations: options?.validations || {},
    }),
    [
      // Stringify validations to ensure stable comparison
      JSON.stringify(options?.validations),
    ]
  );

  const [form, setForm] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasBeenInit, setHasBeenInit] = useState({});

  // Separate validation effect to reduce unnecessary runs
  useEffect(() => {
    // Skip validation if no validations defined
    if (
      !normalizedOptions.validations ||
      Object.keys(normalizedOptions.validations).length === 0
    ) {
      setIsValid(true);
      return;
    }

    const validationResult = isFormValid();
    setIsValid(validationResult);
  }, [
    // Only depend on form values that are actually being validated
    ...Object.keys(normalizedOptions.validations).map((key) => form[key]),
    JSON.stringify(normalizedOptions.validations),
  ]);

  function updateForm(arg, options) {
    if (typeof arg === "function") {
      // Handle callback function
      setForm((prevForm) => {
        const newForm = arg(prevForm);
        // Update hasBeenInit for all changed fields
        const newHasBeenInit = { ...hasBeenInit };
        Object.keys(newForm).forEach((key) => {
          if (newForm[key] !== prevForm[key]) {
            newHasBeenInit[key] = true;
          }
        });
        setHasBeenInit(newHasBeenInit);
        return newForm;
      });
    } else {
      // Handle object with id and value
      const { id, value } = arg;
      if (options) {
        // ... (keep existing options handling)
      }

      if (
        !form[id] ||
        (Array.isArray(form[id]) && form[id].length === 0) ||
        (isObject(form[id]) && Object.keys(form[id]).length === 0)
      ) {
        setHasBeenInit({ ...hasBeenInit, [id]: true });
      }
      setForm((prevForm) => {
        return { ...prevForm, [id]: value };
      });
      return;
    }

    // Check form validity after update
    setTimeout(() => {
      if (isFormValid()) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }, 0);
  }

  function register(key, options = {}) {
    return {
      id: key,
      value: form[key],
      onChange: ({ value }) => updateForm({ id: key, value }),
      ...options,
    };
  }

  function setFormInit(obj, options) {
    if (options) {
      if (options.setErrors) {
        let init = {};
        Object.keys(obj).forEach((key) => (init[key] = true));
        setHasBeenInit(init);
      }

      if (options.valid) {
        setIsValid(true);
      }

      if (options.reset) {
        return setForm({ ...obj });
      }
    }

    return setForm({ ...form, ...obj });
  }

  function clearForm(id) {
    if (Array.isArray(id)) {
      id.forEach((item) => {
        delete form[item];
      });
      setForm({ ...form });
      return;
    }
    if (id) {
      delete form[id];
      setForm({ ...form });
      return;
    }

    return setForm({});
  }

  function valueIsEmpty(value) {
    // Handle null/undefined case first
    if (value === null || value === undefined) {
      return true;
    }

    // Handle Date objects
    if (value instanceof Date) {
      return isNaN(value.getTime());
    }

    // Handle plain objects (but not Date or other special objects)
    if (value.constructor === Object && Object.keys(value).length === 0) {
      return true;
    }

    // Handle arrays
    if (Array.isArray(value)) {
      return value.length === 0;
    }

    // Handle strings
    if (typeof value === "string") {
      return value.trim() === "";
    }

    // Handle numbers
    if (typeof value === "number") {
      return isNaN(value);
    }

    // Handle booleans
    if (typeof value === "boolean") {
      return false;
    }

    return false;
  }

  function isFormValid() {
    let validations = normalizedOptions.validations;

    if (!validations || Object.keys(validations).length === 0) {
      return true;
    }

    let valid = true;
    let newErrors = {};

    Object.keys(validations).forEach((key) => {
      let hasIdBeenInit = hasBeenInit[key];
      const value = form[key];
      const validation = validations[key];

      // Skip validation if field hasn't been initialized
      if (!hasIdBeenInit) {
        valid = false;
        return;
      }

      // Regex
      const pattern = validation?.pattern;
      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false;
        newErrors[key] = pattern.message;
      }

      // Custom
      const custom = validation?.custom;
      const customIsValid = custom?.isValid(value, {
        validations: validations[key],
        form,
        isFormValid,
        validateEmail,
        valueIsEmpty,
        hasBeenInit,
      });
      if (custom?.isValid) {
        if (isObject(customIsValid) && !customIsValid.isValid) {
          valid = false;
          newErrors = { ...newErrors, ...customIsValid.errors };
        }
      }

      // Email
      if (validation?.validations?.email && !validateEmail(value)) {
        valid = false;
        newErrors[key] = "Must be a valid email";
      }

      // Required
      if (
        (validation?.required ||
          validation?.validations?.required ||
          validation?.required?.value) &&
        valueIsEmpty(value)
      ) {
        valid = false;
        newErrors[key] =
          validation?.required?.message || "This field is required";
      }
    });

    if (!valid) {
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  }
  return {
    form,
    register,
    updateForm,
    setFormInit,
    isValid,
    errors,
    clearForm,
    isFormValid,
    hasBeenInit,
  };
};

export default useForm;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * Creates a FormData object containing document/file assets and associated metadata
 * @param {Array} assets - Array of asset objects containing file data (uri, mimeType, name)
 * @param {Object} data - Additional metadata to attach to the form data (type, refType, id, etc)
 * @returns {FormData|null} FormData object with assets and metadata, or null if no assets
 */
export const getDocumentFormData = async (assets, data) => {
  // Return early if no assets provided
  if (!assets || assets.length === 0) return null;

  const formData = new FormData();

  // Validate FormData creation
  if (!formData) {
    console.error("Failed to create FormData");
    return null;
  }

  // Dynamically append any data fields that exist
  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      formData.append(key, String(value));
    }
  });

  // Process each asset and append to FormData
  assets.forEach((asset, index) => {
    // Extract file extension from URI or MIME type
    const extensionFromUri = asset.uri?.split(".").pop() || "";
    const extensionFromMimeType = asset.mimeType
      ? asset.mimeType.split("/").pop()
      : "";
    const extension = extensionFromUri || extensionFromMimeType || "";

    // Generate filename using metadata or construct default name
    const fileName =
      asset.name ||
      `${data.type}-${data.refType}-${data.id}-${index + 1}.${extension}`;

    // Create file object with required properties
    const fileObject = {
      uri: asset.uri,
      type: asset.mimeType || `image/${extension}`,
      name: fileName,
    };

    // Append file to FormData under "docs" field
    formData.append("docs", fileObject);
  });

  return formData;
};
