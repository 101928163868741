import { useEffect, useState } from "react";
import { Button, Dropdown, EditableText, Modal, TextArea } from "../../shared";
import { useForm } from "../../hooks";
import {
  ChevronDownIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PRIORITY_OPTIONS } from "../../constants/select";
import PartsModal from "../PartsModal";
import {
  useAddTemplateMutation,
  useUpdateTemplateMutation,
} from "../../redux/services/templates/api";
import { toast } from "react-toastify";
import { removeEmptyValues } from "../../utils";
import ServiceTypeSelect from "../ServiceTypeSelect";
import TruncatedText from "../TruncatedText";

const TemplateModal = ({
  visible,
  setVisible,
  onClose,
  onSubmit,
  template,
  type,
  templateId,
  editMode,
}) => {
  const { form, setFormInit, updateForm, clearForm } = useForm();

  const [partsModalVisible, setPartsModalVisible] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [addTemplate, { isLoading: isAdding }] = useAddTemplateMutation();
  const [updateTemplate, { isLoading: isUpdating }] =
    useUpdateTemplateMutation();

  const isLoading = isAdding || isUpdating;

  useEffect(() => {
    if (visible && (!form.name || !form.items)) {
      if (!editMode) {
        const items = template?.items ||
          template?.content?.items || [{ priority: "low" }];
        const convertedItems = items.map((item) => ({
          ...item,
          quotedHours: item.quotedHours
            ? item.quotedHours / (60 * 60 * 1000)
            : 0,
        }));

        setFormInit({
          ...template,
          name: template?.name || "Default Template",
          items: convertedItems,
        });
      } else {
        const items = template?.items || template?.content?.items;
        const convertedItems = items.map((item) => ({
          ...item,
          quotedHours: item.quotedHours
            ? item.quotedHours / (60 * 60 * 1000)
            : 0,
        }));

        setFormInit({
          name: template?.name || template?.name,
          items: convertedItems,
        });
      }
    }
  }, [template, visible, editMode]);

  function setFormItems(itemId, id, value) {
    const updatedItems = form.items.map((item) => {
      if (item._id === itemId) {
        return { ...item, [id]: value };
      }
      return item;
    });

    setFormInit({ ...form, items: updatedItems });
  }

  function handleClose() {
    onClose?.();
    clearForm();
    setVisible(false);
    setPartsModalVisible(false);
    setFormInit({}, { reset: true });
  }

  function handlePartsClose() {
    setPartsModalVisible(false);
    setVisible(true);
  }

  function handleAddParts(data) {
    const updatedItems = form.items.map((item) => {
      if (item._id === itemId) {
        const newParts = data.map((d) => ({
          _id: Math.random().toString(36).slice(2, 11),
          quantity: d.quantity || 1,
          unitPrice: d.unitPrice || 0,
          part: {
            name: d.name,
            partNumber: d.partNumber,
            ...d,
          },
        }));

        return {
          ...item,
          parts: [...(item.parts || []), ...newParts],
        };
      }
      return item;
    });

    setFormInit({ ...form, items: updatedItems });
    setVisible(true);
    setPartsModalVisible(false);
    setItemId(null);
  }

  function handleShowPartsModal(itemId) {
    setItemId(itemId);
    setPartsModalVisible(true);
    setVisible(false);
  }

  function handleRemovePart(itemId, partId) {
    const updatedItems = form.items.map((item) => {
      if (item._id === itemId) {
        return {
          ...item,
          parts: item.parts?.filter((p) => p._id !== partId) || [],
        };
      }
      return item;
    });

    setFormInit({ ...form, items: updatedItems });
  }

  async function handleCreateTemplate(data) {
    const convertedItems = data.items.map((item) => ({
      ...item,
      quotedHours: item.quotedHours * 60 * 60 * 1000,
    }));

    const res = await addTemplate({
      type,
      name: data.name,
      content: {
        priority: data.priority,
        items: convertedItems,
        equipment: data.equipment,
        summary: data.summary,
      },
    }).unwrap();

    if (res.status !== "success") {
      toast.error("Failed to create template", {
        toastId: "create-template-error",
      });
      return;
    }

    toast.success("Template created successfully", {
      toastId: "create-template-success",
    });

    onSubmit(data);
    handleClose();
  }

  async function handleUpdateTemplate(data) {
    const convertedItems = data.items.map((item) => ({
      ...item,
      quotedHours: item.quotedHours * 60 * 60 * 1000,
    }));

    const postData = {
      id: templateId,
      data: {
        type,
        name: data.name,
        content: removeEmptyValues({
          priority: data.priority,
          items: convertedItems,
          equipment: data.equipment,
          summary: data.summary,
        }),
      },
    };

    try {
      const res = await updateTemplate(postData).unwrap();

      if (res.status !== "success") {
        toast.error("Failed to update template", {
          toastId: "update-template-error",
        });
        return;
      }

      toast.success("Template updated successfully", {
        toastId: "update-template-success",
      });

      onSubmit(data);
      handleClose();
    } catch (error) {
      console.error("Update template error:", error);
      toast.error(error.message || "Failed to update template", {
        toastId: "update-template-error",
      });
    }
  }

  async function handleSubmit() {
    const data = form;

    if (editMode) {
      await handleUpdateTemplate(data);
    } else {
      await handleCreateTemplate(data);
    }
  }

  return (
    <>
      <Modal
        title={
          <EditableText
            id="name"
            iconPosition="right"
            keepPencilVisible
            value={form.name}
            onSave={(e) => updateForm(e)}
            displayText={(v) => (
              <div className="text-2xl text-gray-700">{form.name}</div>
            )}
          />
        }
        loading={isLoading}
        visible={visible}
        onClose={handleClose}
        confirm={handleSubmit}
        size="md"
      >
        <div className="space-y-6">
          {form.items?.map((item) => (
            <div key={item._id} className="bg-white rounded-lg">
              {/* Priority Badge */}
              <div className="inline-flex flex-col space-y-1 mb-4">
                <p className="text-xs text-gray-400 uppercase">Priority</p>
                <Dropdown
                  data={PRIORITY_OPTIONS}
                  trigger={
                    <div
                      className={`px-4 py-1 rounded-full ${
                        {
                          critical: "bg-red-100 text-red-700",
                          high: "bg-amber-100 text-amber-700",
                          medium: "bg-blue-100 text-blue-700",
                          low: "bg-emerald-100 text-emerald-700",
                        }[item.priority?.toLowerCase()] ||
                        "bg-emerald-50 text-emerald-600"
                      } flex items-center gap-2 font-medium cursor-pointer`}
                    >
                      <span className="capitalize">
                        {item.priority || "low"}
                      </span>
                      <ChevronDownIcon className="w-4 h-4" />
                    </div>
                  }
                  itemClick={(e) => setFormItems(item._id, "priority", e.label)}
                />
              </div>

              {/* Equipment */}
              {/* <div className="mb-4">
                <p className="text-xs text-gray-400 uppercase mb-1">
                  Equipment
                </p>
                <div className="text-gray-400 text-sm inline-flex items-center gap-2">
                  <span>No equipment selected</span>
                  <button className="text-primary-500 hover:text-primary-600">
                    + Add
                  </button>
                </div>
              </div> */}

              {/* Service Type */}
              <div className="mb-4">
                <p className="text-xs text-gray-400 uppercase mb-1">
                  Service Type
                </p>
                <EditableText
                  id="serviceType"
                  type="custom"
                  keepPencilVisible
                  layout={ServiceTypeSelect}
                  hideLabel
                  value={item.serviceType}
                  onSave={(e) => setFormItems(item._id, "serviceType", e.value)}
                  displayText={(v) => (
                    <p
                      className={`text-base ${
                        v ? "text-gray-600" : "text-gray-400 text-sm italic"
                      }`}
                    >
                      {v || "No service type selected"}
                    </p>
                  )}
                />
              </div>

              {/* Hours */}
              <div className="mb-4">
                <p className="text-xs text-gray-400 uppercase mb-1">
                  Quoted Hours
                </p>
                <EditableText
                  id="quotedHours"
                  keepPencilVisible
                  type="number"
                  value={item.quotedHours}
                  onSave={(e) =>
                    setFormItems(item._id, "quotedHours", Number(e.value))
                  }
                  displayText={(v) => (
                    <p className="text-gray-600 text-base">{v || "0"}</p>
                  )}
                />
              </div>

              {/* Work Summary */}
              <div className="mb-4">
                <p className="text-xs text-gray-400 uppercase mb-1">
                  Work Summary
                </p>
                <EditableText
                  id="summary"
                  type="custom"
                  keepPencilVisible
                  value={item.summary}
                  layout={TextAreaLayout}
                  onSave={(e) => setFormItems(item._id, "summary", e.value)}
                  displayText={(v) => <TruncatedText text={v} />}
                />
              </div>

              {/* Parts */}
              <div>
                <p className="text-xs text-gray-400 uppercase mb-2">Parts</p>
                <div className="space-y-2">
                  <div className="grid grid-cols-12 text-xs text-gray-500 px-4">
                    <div className="col-span-5">Description</div>
                    <div className="col-span-2 text-right">Quantity</div>
                    <div className="col-span-2 text-right">Unit Price</div>
                    <div className="col-span-2 text-right">Total</div>
                    <div className="col-span-1"></div>
                  </div>

                  {item.parts?.map((part, i) => {
                    const quantity = parseInt(part.quantity) || 1;
                    const unitPrice = parseFloat(part.unitPrice) || 0;
                    const total = quantity * unitPrice;

                    return (
                      <div
                        key={i}
                        className="grid grid-cols-12 items-center px-4 py-2 bg-white rounded border text-sm"
                      >
                        <div className="col-span-5 overflow-hidden">
                          <div className="font-medium truncate">
                            {part.part?.name || part.part?.partNumber}
                          </div>
                          {part.part?.description && (
                            <div className="text-gray-500 text-sm mt-0.5 truncate">
                              {part.part.description}
                            </div>
                          )}
                        </div>
                        <div className="col-span-2 text-right">{quantity}</div>
                        <div className="col-span-2 text-right">
                          ${unitPrice.toFixed(2)}
                        </div>
                        <div className="col-span-2 text-right">
                          ${total.toFixed(2)}
                        </div>
                        <div className="col-span-1 flex justify-end">
                          <XMarkIcon
                            onClick={() => handleRemovePart(item._id, part._id)}
                            className="w-4 h-4 text-gray-400 hover:text-red-500 cursor-pointer"
                          />
                        </div>
                      </div>
                    );
                  })}

                  <div className="grid grid-cols-12 items-center px-4 py-2 bg-gray-50 font-medium border-t">
                    <div className="col-span-9 text-right pr-4">Total:</div>
                    <div className="col-span-2 text-right">
                      $
                      {item.parts
                        ?.reduce((sum, part) => {
                          const quantity = parseInt(part.quantity) || 1;
                          const unitPrice = parseFloat(part.unitPrice) || 0;
                          return sum + quantity * unitPrice;
                        }, 0)
                        .toFixed(2) || "0.00"}
                    </div>
                    <div className="col-span-1"></div>
                  </div>

                  <Button
                    onClick={() => handleShowPartsModal(item._id)}
                    type="alt"
                    size="sm"
                    icon={<PlusIcon className="w-4 h-4 mr-1" />}
                  >
                    Add Part
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <PartsModal
        visible={partsModalVisible}
        setVisible={setPartsModalVisible}
        onSubmit={handleAddParts}
        equipment={form.equipment}
        onClose={handlePartsClose}
      />
    </>
  );
};

const TextAreaLayout = ({ value, onChange, ...props }) => {
  return (
    <TextArea
      value={value}
      onChange={(e) => onChange({ value: e.target.value })}
      rows={props.rows || 3}
      placeholder={props.placeholder}
    />
  );
};

export default TemplateModal;
