import { useState, useEffect } from "react";
import { useUpdateJobItemMutation } from "../../../../redux/services/jobs/jobItems";
import { toast } from "react-toastify";
import { Radio, Popover, EditableText, Button } from "../../../../shared";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  camelCaseToSpaces,
  formatTimeWorked,
  isAuthorized,
  hoursToMilliseconds,
} from "../../../../utils";
import ServiceDataCard from "./ServiceDataCard";
import { millisecondsToMinutes } from "date-fns";
// Reusable hour type option component
const HourTypeOption = ({
  type,
  hours,
  info,
  isSelected,
  onClick,
  content,
  isEditing,
}) => (
  <div
    className={`px-2 py-1 rounded-lg ${
      isEditing && !isSelected ? "border border-gray-200 cursor-pointer" : ""
    } ${
      isSelected ? "border border-2 border-gray-400" : "hover:border-gray-300"
    }`}
    onClick={isEditing ? onClick : () => {}}
  >
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div>
          <div className="flex items-center text-sm text-gray-500">
            <span className="relative flex items-center">
              {camelCaseToSpaces(type)}
            </span>
            <div className="relative group">
              <InformationCircleIcon className="h-4 w-4 text-gray-400 ml-1" />
              <div className="invisible group-hover:visible absolute border bottom-full left-0 mb-2 p-2 bg-white shadow-lg rounded-lg text-sm w-64 z-10">
                {info}
              </div>
            </div>
          </div>
          {content ? (
            content(hours)
          ) : (
            <div className="text-base font-medium">{hours || "0"}</div>
          )}
        </div>
      </div>
      {(isEditing || isSelected) && (
        <Radio checked={isSelected} onChange={() => {}} size="sm" />
      )}
    </div>
  </div>
);

// Reusable rate and total display component
const RateAndTotal = ({
  useFlatRate,
  hourData,
  onRateChange,
  currentHours,
  hourlyTotal,
}) => {
  const currentHoursDisplay = currentHours.display;

  return (
    <div className="flex justify-between items-center text-sm border rounded-lg p-2">
      <div className="w-full space-y-1">
        {!useFlatRate && (
          <div className="flex justify-between gap-4">
            <span>Rate:</span>
            <EditableText
              value={hourData.hourlyRate}
              size="xs"
              type="number"
              iconPosition="left"
              onSave={onRateChange}
              placeholder="0.00"
              displayText={(v) => <div>${v.toFixed(2)}</div>}
              icon={{
                left: <div className="text-gray-500 text-sm">$</div>,
              }}
            />
          </div>
        )}
        <div className="flex justify-between gap-4">
          <span>Current Hours:</span>
          <span>{currentHoursDisplay}</span>
        </div>
        <div className="flex justify-between gap-4 font-medium">
          <span>Total:</span>
          <span>${(parseFloat(hourlyTotal) || 0).toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

const HoursSelect = ({ item, form }) => {
  const [updateJobItem] = useUpdateJobItemMutation();

  const [isEditing, setIsEditing] = useState(false);
  const [localHourData, setLocalHourData] = useState({
    selectedHourType: item.selectedHourType || "",
    selectedHours: item.selectedHours || 0,
    hourlyRate: item.hourlyRate || 0,
    // hourlyTotal: (item.selectedHours || 0) * (item.hourlyRate || 0),
    quotedHours: item.quotedHours || 0,
    actualHours: item.actualHours || 0,
    hoursWorked: item.hoursWorked || 0,
    adjustedHours: item.adjustedHours || 0,
    hourlyTotal: item.hourlyTotal || 0,
  });

  // Update localHourData when item changes
  useEffect(() => {
    setLocalHourData({
      selectedHourType: item.selectedHourType || "",
      selectedHours: item.selectedHours || 0,
      hourlyRate: item.hourlyRate || 0,
      // hourlyTotal: (item.selectedHours || 0) * (item.hourlyRate || 0),
      quotedHours: item.quotedHours || 0,
      actualHours: item.actualHours || 0,
      hoursWorked: item.hoursWorked || 0,
      adjustedHours: item.adjustedHours || 0,
      hourlyTotal: item.hourlyTotal || 0,
    });
  }, [item]);

  const handleHourSelection = async (hourType, hours, rate) => {
    // Store previous state for rollback
    const previousState = { ...localHourData };

    // Calculate new state
    const numericHours = parseFloat(hours) || 0;
    const numericRate = parseFloat(rate) || 0;

    const updateData = {
      selectedHourType: hourType,
      selectedHours: numericHours,
      hourlyRate: numericRate,
      hourlyTotal: numericHours * numericRate,
    };

    try {
      // Optimistically update UI
      setLocalHourData(updateData);
      form.setFormInit({
        ...updateData,
      });

      // Make API call
      const result = await updateJobItem({
        jobId: item.jobId,
        jobItemId: item._id,
        data: updateData,
      }).unwrap();

      toast.success("Hours updated successfully", {
        toastId: "hours-updated",
      });
    } catch (error) {
      // Rollback to previous state on error
      setLocalHourData(previousState);
      form.setFormInit(previousState);
      toast.error(error.message || "Failed to update hours");
    }
  };

  const handleRateChange = (newRate) => {
    const rate = parseFloat((Number(newRate.value) || 0).toFixed(2));
    handleHourSelection(
      localHourData.selectedHourType,
      localHourData.selectedHours,
      rate
    );
  };

  const handleHourTypeSelect = (hourType, hours) => {
    handleHourSelection(hourType, hours, localHourData.hourlyRate);
  };

  const onSave = async ({ data }) => {
    const result = await updateJobItem({
      jobId: item.jobId,
      jobItemId: item._id,
      data,
    }).unwrap();
  };

  const hourTypes = [
    {
      type: "quotedHours",
      hours: item.quotedHours || 0,
      info: "Hours initially quoted for this service",
    },
    {
      type: "actualHours",
      hours: item.actualHours || 0,
      info: "Calculated from start/end time",
    },
    {
      type: "hoursWorked",
      hours: item.hoursWorked || 0,
      info: "Hours logged by technician",
    },
    {
      type: "adjustedHours",
      hours: item.adjustedHours || 0,
      info: "Hours adjusted by admin",
      content: !isEditing
        ? () => {
            return (
              <EditableText
                id="adjustedHours"
                type="number"
                iconPosition="right"
                value={
                  item.adjustedHours ? item.adjustedHours / (60 * 60 * 1000) : 0
                }
                displayText={(v) => (
                  <div className="text-base font-medium">
                    {formatTimeWorked(v * 60 * 60 * 1000).display}
                  </div>
                )}
                onSave={({ value }) =>
                  onSave({ data: { adjustedHours: value * 60 * 60 * 1000 } })
                }
              />
            );
          }
        : null,
    },
  ];

  const currentHours = hourTypes.find(
    (hourType) => hourType.type === item.selectedHourType
  );

  const displayTime = currentHours
    ? {
        display: formatTimeWorked(currentHours.hours).display,
        decimal: millisecondsToMinutes(currentHours.hours) / 60,
      }
    : {
        display: "0 min",
        decimal: 0,
      };

  return (
    <ServiceDataCard
      header={() => (
        <div className="relative group flex items-center">
          <h3 className="font-medium text-lg text-gray-800">Hours Selection</h3>
          <InformationCircleIcon className="h-4 w-4 text-gray-400 ml-1" />
          <div className="invisible group-hover:visible absolute border top-full left-0 mt-2 p-2 bg-white shadow-lg rounded-lg text-sm w-64 z-10">
            <div className="space-y-2">
              <p className="font-medium">Service Hours Tracking</p>
              <p className="text-sm text-gray-600">
                The selected hours type will be used when generating:
              </p>
              <ul className="list-disc pl-4 space-y-1 text-sm text-gray-600">
                <li>Customer invoices</li>
                <li>Technician payouts</li>
                <li>Service reports</li>
                <li>Financial analytics</li>
              </ul>
              <p className="text-sm text-gray-500 mt-2">
                Select the appropriate hours type to ensure accurate billing and
                compensation.
              </p>
            </div>
          </div>
        </div>
      )}
      actions={
        <Button
          type="alternative"
          size="xs"
          rounded="full"
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? "Done" : "Edit"}
        </Button>
      }
      className="col-span-1 bg-white"
    >
      <div className="px-4 py-1 space-y-2">
        <div className="grid grid-cols-2 gap-2">
          {hourTypes.map(({ type, hours, info, content }) => (
            <HourTypeOption
              key={type}
              type={type}
              hours={formatTimeWorked(hours).display}
              info={info}
              content={content}
              isEditing={isEditing}
              isSelected={localHourData.selectedHourType === type}
              onClick={() => handleHourTypeSelect(type, hours || 0)}
            />
          ))}
        </div>

        {isAuthorized("owner,admin") && (
          <RateAndTotal
            useFlatRate={item.useFlatRate}
            hourData={localHourData}
            currentHours={displayTime}
            onRateChange={handleRateChange}
            hourlyTotal={item.hourlyTotal}
          />
        )}
      </div>
    </ServiceDataCard>
  );
};

export default HoursSelect;
