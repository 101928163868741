import classNames from "classnames";
import React, { ForwardedRef } from "react";
import Spinner from "../Spinner";

interface ButtonProps {
  type?:
    | "primary"
    | "alternative"
    | "alt"
    | "light"
    | "dark"
    | "success"
    | "danger"
    | "warning"
    | "link";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  rounded?: "none" | "sm" | "lg" | "full";
  outline?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Button = React.forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    // COLORS
    const primary =
      "text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-300";
    const primaryDisabled =
      "text-white hover:bg-primary-400 bg-primary-400 cursor-not-allowed";

    const alternative =
      "text-primary-900 bg-white border border-primary-200 hover:bg-gray-50 hover:text-primary-800 focus:z-10 focus:ring-primary-200";
    const alternativeDisabled =
      "text-primary-500 bg-gray-50 border border-gray-200 cursor-not-allowed";

    const light =
      "text-gray-900 bg-white hover:bg-gray-100 focus:ring-gray-200 border border-gray-300";
    const dark = "text-white bg-gray-900 hover:bg-black focus:ring-gray-300";
    const darkDisabled =
      "text-white bg-gray-700 focus:ring-gray-200 cursor-not-allowed";
    const success =
      "text-white bg-green-700 hover:bg-green-800 focus:ring-green-300";
    const danger = "text-white bg-red-500 hover:bg-red-600 focus:ring-red-300";
    const warning =
      "text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300";

    // Size classes
    const sizes: Record<string, string> = {
      xs: "py-1 px-2 text-xs",
      sm: "py-2 px-3 text-sm",
      md: "px-3 py-2 text-base",
      lg: "py-3 px-5 text-base",
      xl: "px-6 py-3.5 text-base",
    };

    const classes = classNames(
      "focus:outline-none focus:ring-3 font-medium",
      {
        "rounded-none": props.rounded === "none",
        "rounded-sm": props.rounded === "sm",
        "rounded-lg": props.rounded === "lg" || !props.rounded,
        "rounded-full": props.rounded === "full",
      },
      {
        [primary]: (props.type === "primary" || !props.type) && !props.disabled,
      },
      {
        [alternative]:
          (props.type === "alternative" || props.type === "alt") &&
          !props.disabled,
      },
      { [light]: props.type === "light" && !props.disabled },
      { [dark]: props.type === "dark" && !props.disabled },
      { [success]: props.type === "success" && !props.disabled },
      { [danger]: props.type === "danger" && !props.disabled },
      { [warning]: props.type === "warning" && !props.disabled },
      sizes[props.size || "md"], // Default to md if no size provided
      {
        [primaryDisabled]:
          props.disabled && (props.type === "primary" || !props.type),
      },
      {
        [alternativeDisabled]:
          props.disabled &&
          (props.type === "alternative" || props.type === "alt"),
      },
      {
        [darkDisabled]: props.disabled && props.type === "dark",
      },
      {
        "inline-flex items-center": props.loading || props.icon,
      },
      props.className
    );

    const spin = props.loading && <Spinner className="mr-2" size="sm" />;
    const icon = props.icon;

    return props.type !== "link" ? (
      <button
        disabled={props.disabled}
        onClick={props.onClick}
        className={classes}
        type="button"
        style={{ minInlineSize: "max-content", ...props.style }}
        ref={ref}
      >
        {spin || icon}
        {props.title || props.children}
      </button>
    ) : (
      <button
        onClick={props.onClick}
        style={{ minInlineSize: "max-content", ...props.style }}
        className={
          props.className +
          " outline-none hover:underline bg-transparent text-sm text-primary-600 focus:outline-none"
        }
        ref={ref}
      >
        {props.title || props.children}
      </button>
    );
  }
);

export default Button;
