import { Button, Modal } from "../../../shared";

export const DeleteModal = ({
  visible,
  setVisible,
  onClose,
  onSubmit,
  loading,
}) => {
  const modalFooter = (
    <>
      <Button onClick={onClose} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={onSubmit}
        type="primary"
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        visible={visible}
        setVisible={setVisible}
        onClose={onClose}
        title="Delete"
        footer={modalFooter}
        content="Are you sure you want to delete this service?"
        size="md"
        footerPosition="right"
      />
    </>
  );
};
