import classNames from "classnames";

interface FormLabelProps {
  htmlFor?: string;
  required?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  htmlFor,
  required,
  children,
  className,
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        "block mb-2 text-sm font-medium text-gray-900",
        className
      )}
    >
      {children}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
  );
};
