import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { FormLabel } from "../Input/FormLabel";

interface TextAreaProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  label?: string;
  className?: string;
  textAreaClass?: string;
  rows?: number;
  placeholder?: string;
  required?: boolean;
  labelClassName?: string;
}

export const TextArea = ({
  id,
  label,
  value,
  onChange,
  className,
  textAreaClass,
  rows = 4,
  placeholder,
  required,
  labelClassName,
}: TextAreaProps) => {
  const textareaClass =
    className ||
    classNames(
      textAreaClass,
      "block w-full text-sm rounded-lg text-gray-900 p-2",
      "border border-gray-300 focus:ring-primary-500 focus:outline-none focus-visible:ring-1 focus:ring-1 focus-visible:ring-primary-500 focus:border-primary-500"
    );

  return (
    <div>
      {label && (
        <FormLabel htmlFor={id} required={required} className={labelClassName}>
          {label}
        </FormLabel>
      )}
      <textarea
        value={value}
        onChange={onChange}
        id={id}
        rows={rows}
        className={textareaClass}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default TextArea;
