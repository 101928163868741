export const menu = {
  organization: [
    "General",
    // "Email",
    "Rates",
    "Templates",
    // "Job",
    // "Teams",
    "Users",
    "Reports",
    // "Feedback",
    // "Naming Conventions",
    // "History",
    // "Features",
    // "Billing",
  ],
};
