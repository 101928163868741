import React, { useState, useRef, useEffect, memo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import Input from "../Input";
import { usePopper } from "react-popper";

// Separate TimeSelector into its own memoized component
const TimeSelector = memo(
  ({
    selectedTime,
    handleTimeSelect,
    selectorRef,
    hours,
    minutes,
    periods,
    is24Hour,
    hourRefs,
    minuteRefs,
    periodRefs,
  }) => {
    // Add useEffect for scrolling
    useEffect(() => {
      const { hour, minute, period } = parseTime(selectedTime);

      // Scroll to selected hour
      const hourEl = hourRefs.current[parseInt(hour)];
      if (hourEl) {
        hourEl.scrollIntoView({ block: "center", behavior: "auto" });
      }

      // Scroll to selected minute
      const minuteEl = minuteRefs.current[parseInt(minute)];
      if (minuteEl) {
        minuteEl.scrollIntoView({ block: "center", behavior: "auto" });
      }

      // Scroll to selected period
      if (!is24Hour) {
        const periodEl = periodRefs.current[period];
        if (periodEl) {
          periodEl.scrollIntoView({ block: "center", behavior: "auto" });
        }
      }
    }, [selectedTime, is24Hour]);

    const parseTime = (timeString) => {
      if (!timeString) return { hour: "12", minute: "00", period: "AM" };
      const [time, period] = timeString.split(" ");
      const [hour, minute] = time.split(":");
      return { hour, minute, period };
    };

    const {
      hour: currentHour,
      minute: currentMinute,
      period: currentPeriod,
    } = parseTime(selectedTime);

    return (
      <div
        className="p-4 bg-white rounded-lg shadow-lg min-w-[160px]"
        ref={selectorRef}
      >
        <div className="grid grid-cols-3 gap-2">
          {/* Hours */}
          <div className="border-r pr-0">
            <div className="text-xs text-gray-500 mb-1">Hour</div>
            <div className="space-y-1 max-h-48 overflow-y-auto no-scrollbar">
              {hours.map((hour) => {
                const isSelected = parseInt(currentHour) === hour;
                return (
                  <button
                    type="button"
                    key={hour}
                    ref={(el) => (hourRefs.current[hour] = el)}
                    className={`w-full text-left px-2 py-1 rounded text-sm ${
                      isSelected
                        ? "bg-primary-100 text-primary-700"
                        : "hover:bg-primary-50"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleTimeSelect(
                        hour,
                        currentMinute || "00",
                        currentPeriod || "AM"
                      );
                    }}
                  >
                    {hour.toString().padStart(2, "0")}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Minutes */}
          <div className="border-r px-2">
            <div className="text-xs text-gray-500 mb-1">Min</div>
            <div className="space-y-1 max-h-48 overflow-y-auto no-scrollbar">
              {minutes.map((minute) => {
                const isSelected = parseInt(currentMinute) === minute;
                return (
                  <button
                    type="button"
                    key={minute}
                    ref={(el) => (minuteRefs.current[minute] = el)}
                    className={`w-full text-left px-2 py-1 rounded text-sm ${
                      isSelected
                        ? "bg-primary-100 text-primary-700"
                        : "hover:bg-primary-50"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleTimeSelect(
                        currentHour || "12",
                        minute,
                        currentPeriod || "AM"
                      );
                    }}
                  >
                    {minute.toString().padStart(2, "0")}
                  </button>
                );
              })}
            </div>
          </div>

          {/* AM/PM */}
          {!is24Hour && (
            <div className="flex-1">
              <div className="text-xs text-gray-500 mb-1">Period</div>
              <div className="space-y-1">
                {periods.map((period) => {
                  const isSelected = currentPeriod === period;
                  return (
                    <button
                      type="button"
                      key={period}
                      ref={(el) => (periodRefs.current[period] = el)}
                      className={`w-full text-left px-2 py-1 rounded text-sm ${
                        isSelected
                          ? "bg-primary-100 text-primary-700"
                          : "hover:bg-primary-50"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTimeSelect(
                          currentHour || "12",
                          currentMinute || "00",
                          period
                        );
                      }}
                    >
                      {period}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export const TimeInput = ({
  value,
  onChange,
  placeholder,
  label,
  size,
  is24Hour = false,
  minuteStep = 1,
  className = "",
  required,
  ...props
}) => {
  // Convert initial value from 24h to 12h format if needed
  const formatInitialValue = (val) => {
    if (!val) return "";

    // Check if value is in 24h format
    const match = val.match(/^(\d{1,2}):(\d{2})$/);
    if (match) {
      const [_, hours, minutes] = match;
      const hour = parseInt(hours);
      if (hour > 12) {
        return `${(hour - 12).toString().padStart(2, "0")}:${minutes} PM`;
      } else if (hour === 12) {
        return `12:${minutes} PM`;
      } else if (hour === 0) {
        return `12:${minutes} AM`;
      } else {
        return `${hour.toString().padStart(2, "0")}:${minutes} AM`;
      }
    }
    return val;
  };

  const [selectedTime, setSelectedTime] = useState(
    formatInitialValue(value) || ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const selectorRef = useRef(null);
  const hourRefs = useRef({});
  const minuteRefs = useRef({});
  const periodRefs = useRef({});

  const hours = is24Hour
    ? Array.from({ length: 24 }, (_, i) => i)
    : Array.from({ length: 12 }, (_, i) => i + 1);

  const minutes = Array.from({ length: 60 }, (_, i) => i);
  const periods = ["AM", "PM"];

  useEffect(() => {
    if (value) setSelectedTime(formatInitialValue(value));
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Add CSS for no-scrollbar
    const style = document.createElement("style");
    style.textContent = `
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
      .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const handleTimeSelect = (hour, minute, period) => {
    let timeString = "";

    if (is24Hour) {
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      timeString = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    } else {
      timeString = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} ${period}`;
    }

    // Prevent default form submission behavior
    setTimeout(() => {
      setSelectedTime(timeString);
      onChange?.({
        id: props.id,
        value: timeString,
      });
    }, 0);
  };

  return (
    <div className="relative" ref={popoverRef}>
      <Popover>
        <Popover.Button className="w-full">
          <div className="relative w-full">
            <Input
              placeholder={placeholder}
              required={required}
              value={selectedTime}
              label={label}
              size={size}
              onBlur={props.onBlur}
              icon={{
                right: <ClockIcon className="w-5 h-5" />,
              }}
            />
          </div>
        </Popover.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute z-10 mt-1">
            <TimeSelector
              selectedTime={selectedTime}
              handleTimeSelect={handleTimeSelect}
              selectorRef={selectorRef}
              hours={hours}
              minutes={minutes}
              periods={periods}
              is24Hour={is24Hour}
              hourRefs={hourRefs}
              minuteRefs={minuteRefs}
              periodRefs={periodRefs}
            />
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default TimeInput;
