import { useState } from "react";
import {
  DocumentIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tabs } from "../../../shared/Tabs";
import { SettingsHeader } from "../index";
import { Button, Modal, SettingsPanel } from "../../../shared";
import {
  useDeleteTemplateMutation,
  useGetTemplatesQuery,
  useUpdateTemplateMutation,
} from "../../../redux/services/templates/api";
import TemplateModal from "../../../components/Templates/TemplateModal";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../utils";
import PDFTemplates from "./PDFTemplates";

const Templates = () => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [template, setTemplate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmContent, setConfirmContent] = useState(null);

  const { data, isLoading, isError } = useGetTemplatesQuery({});
  const [deleteTemplate] = useDeleteTemplateMutation();

  // Handle Edit Template
  function handleEditTemplate(template) {
    setTemplate(template);
    setShowTemplateModal(true);
  }

  // Handle Confirm Modal
  function handleConfirmModal(type, template) {
    setConfirmContent({
      title: `${capitalizeFirstLetter(type)} Template`,
      content: `Are you sure you want to ${type} this template?`,
      template,
      type,
    });
    setShowConfirmModal(true);
  }

  // Handle Delete Template
  async function handleDeleteTemplate(template) {
    try {
      const response = await deleteTemplate(template._id).unwrap();
      if (response.status !== "success") {
        toast.error("Failed to delete template");
        return;
      }
      toast.success("Template deleted successfully");
      setShowTemplateModal(false);
    } catch (error) {
      console.error("Error deleting template:", error);
      toast.error(
        error.message || "An error occurred while deleting the template"
      );
    }
  }

  // Handle Confirm
  function handleConfirm(type) {
    if (type === "delete") {
      handleDeleteTemplate(confirmContent.template);
    }
    setShowConfirmModal(false);
    handleCleanUp();
  }

  // Handle Clean Up
  function handleCleanUp() {
    setShowTemplateModal(false);
    setConfirmContent(null);
    setTemplate(null);
  }

  // Get the templates and group them by type
  const templates = data?.templates
    ? data.templates.reduce((acc, template) => {
        const type = template.type;
        const templateData = {
          title: template.name,
          subtitle: (
            <div className="">
              <span className="text-sm text-gray-500">
                {Array.isArray(template.content?.items) &&
                  `Services: ${template.content.items.length}`}
              </span>
            </div>
          ),
          content: (
            <div className="flex items-center justify-end gap-2">
              <button
                className="p-1 hover:bg-gray-100 rounded"
                onClick={() => {
                  handleEditTemplate(template);
                }}
              >
                <PencilIcon className="w-4 h-4 text-gray-500" />
              </button>
              <button
                className="p-1 hover:bg-gray-100 rounded"
                onClick={() => {
                  handleConfirmModal("delete", template);
                }}
              >
                <TrashIcon className="w-4 h-4 text-gray-500" />
              </button>
            </div>
          ),
        };
        acc[type] = [...(acc[type] || []), templateData];
        return acc;
      }, {})
    : {};

  // Empty Data
  const emptyData = [
    {
      content: (
        <div className="my-10">
          <div className="text-center">
            <DocumentIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
            <h3 className="mt-2 font-semibold text-sm">No Templates</h3>
            <p className="text-sm text-gray-500">
              {/* Get started by adding a template */}
            </p>
            {/* <div className="mt-3">{renderButton("md")}</div> */}
          </div>
        </div>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;

  const tabsData = [
    {
      id: "job",
      title: "Job Templates",
      content: (
        <>
          {/* Settings Panel */}
          <SettingsPanel
            title="Jobs"
            data={templates?.job || emptyData}
            actions={
              <Button size="xs" onClick={() => setShowTemplateModal(true)}>
                Add Template
              </Button>
            }
          />
          {/* Template Modal */}
          <TemplateModal
            type="job"
            editMode={!!template}
            template={template}
            templateId={template?._id}
            visible={showTemplateModal}
            setVisible={setShowTemplateModal}
            onClose={handleCleanUp}
            onSubmit={handleCleanUp}
          />
          {/* Confirm Modal */}
          <Modal
            title={confirmContent?.title}
            visible={showConfirmModal}
            setVisible={setShowConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            confirm={() => handleConfirm(confirmContent?.type)}
          >
            <div>{confirmContent?.content}</div>
          </Modal>
        </>
      ),
    },
    {
      id: "pdf",
      title: "PDF Templates",
      content: <PDFTemplates />,
    },
  ];

  return (
    <>
      {/* Settings Header */}
      <SettingsHeader title="Templates" />
      <>
        {/* Settings Panel */}
        <SettingsPanel
          title="Jobs"
          data={templates?.job || emptyData}
          actions={
            <Button size="xs" onClick={() => setShowTemplateModal(true)}>
              Add Template
            </Button>
          }
        />
        {/* Template Modal */}
        <TemplateModal
          type="job"
          editMode={!!template}
          template={template}
          templateId={template?._id}
          visible={showTemplateModal}
          setVisible={setShowTemplateModal}
          onClose={handleCleanUp}
          onSubmit={handleCleanUp}
        />
        {/* Confirm Modal */}
        <Modal
          title={confirmContent?.title}
          visible={showConfirmModal}
          setVisible={setShowConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          confirm={() => handleConfirm(confirmContent?.type)}
        >
          <div>{confirmContent?.content}</div>
        </Modal>
      </>

      {/* <div className="">
        <Tabs
          useSearchParams
          data={tabsData}
          default={0}
          fullHeight={false}
          tabContentClassName={(isActive) =>
            `inline-flex p-2 group focus:outline-none rounded-lg text-sm font-semibold focus-visible:outline-0 ${
              isActive
                ? "text-primary-600 bg-primary-50 dark:text-primary-500 dark:bg-primary-900/20 border-transparent"
                : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-gray-700 border-transparent"
            }`
          }
          panelClasses="mt-2"
          tabListClasses="mb-1"
        />
      </div> */}
    </>
  );
};

export default Templates;
