import { camelCaseToSpaces, formatTimeWorked } from "../../../../utils";
import ServiceDataCard from "./ServiceDataCard";
import { TotalRow, TotalCharges } from "../../../../components/Receipt";
import { Receipt } from "../../../../components/Receipt";
import { useMemo } from "react";

const ServiceTotals = ({ item }) => {
  const receipt = useMemo(
    () => ({
      total: item?.totalCharges || 0,
      items: [
        {
          label: `Labor (${camelCaseToSpaces(item?.selectedHourType)})`,
          amount: item?.hourlyTotal,
          defaultOpen: true,
          contentClassName: "text-xs text-gray-500 flex justify-between pl-4",
          content: (
            <>
              <span>Rate: ${(item?.hourlyRate || 0).toFixed(2)}/hr</span>
              <span>
                {item?.selectedHourType === "quotedHours" &&
                  formatTimeWorked(item?.quotedHours || 0).display}
                {item?.selectedHourType === "hoursWorked" &&
                  formatTimeWorked(item?.hoursWorked || 0).display}
                {item?.selectedHourType === "actualHours" &&
                  formatTimeWorked(item?.actualHours || 0).display}
                {item?.selectedHourType === "adjustedHours" &&
                  formatTimeWorked(item?.adjustedHours || 0).display}
              </span>
            </>
          ),
        },
        {
          label: "Parts",
          defaultOpen: true,
          amount: item?.partsTotal || 0,
          content: item.parts.map((part, index) => ({
            label: `${part.part?.name || part.part?.partNumber || "Part"} x ${
              part.quantity
            }`,
            amount: (part.unitPrice || 0) * (part.quantity || 0),
          })),
        },
        {
          label: "Service Fee",
          defaultOpen: true,
          amount: item?.serviceFee,
        },
      ].filter((item) => item.amount > 0),
    }),
    [
      item?.totalCharges,
      item?.selectedHourType,
      item?.hourlyTotal,
      item?.hourlyRate,
      item?.quotedHours,
      item?.hoursWorked,
      item?.actualHours,
      item?.adjustedHours,
      item?.partsTotal,
      item?.parts,
      item?.serviceFee,
    ]
  );

  return (
    <ServiceDataCard header="Service Totals" className="col-span-1 bg-white">
      <div className="px-4 py-2">
        <Receipt list={receipt} />
      </div>
    </ServiceDataCard>
  );
};

export default ServiceTotals;
