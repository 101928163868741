import { api } from "../../api";

export const performanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPerformanceLogs: builder.query({
      query: (params) => ({
        url: "performance/logs",
        params: {
          ...params,
          startDate: params.startDate?.toISOString(),
          endDate: params.endDate?.toISOString(),
        },
      }),
    }),
    getPerformanceSummary: builder.query({
      query: (params) => ({
        url: "performance/summary",
        params: {
          ...params,
          startDate: params.startDate?.toISOString(),
          endDate: params.endDate?.toISOString(),
        },
      }),
    }),
    getIndustryStats: builder.query({
      query: (params) => ({
        url: "performance/industry",
        params: {
          ...params,
          startDate: params.startDate?.toISOString(),
          endDate: params.endDate?.toISOString(),
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPerformanceLogsQuery,
  useGetPerformanceSummaryQuery,
  useGetIndustryStatsQuery,
} = performanceApi;
