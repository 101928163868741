import { useState, useEffect } from "react";
import ListView from "../ListView";
import { PartsSubHeader } from "./PartsSubHeader";
import PartsModal from "../PartsModal";
import { isAuthorized } from "../../utils";
import { useRemovePartFromJobItemMutation } from "../../redux/services/jobs/jobItems";
import { PartCard } from "../PartCard";
import PartDeleteModal from "./PartDeleteModal";

export const PartsList = ({
  parts,
  equipment,
  handleEditItem,
  job,
  jobItem,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentParts, setCurrentParts] = useState([]);
  const [isListExpanded, setIsListExpanded] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [partToRemove, setPartToRemove] = useState(null);

  const [removePartFromJobItem, { isLoading: isDeleting }] =
    useRemovePartFromJobItemMutation();

  useEffect(() => {
    if (parts?.length > 0) {
      setCurrentParts([...parts.slice(0, 3)]);
    }
  }, [parts]);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleSubmit = (data) => {
    handleEditItem([...data], job);
    setVisible(false);
  };

  const handleExpandList = (closeList) => {
    setIsListExpanded(closeList);
    if (!closeList) {
      setCurrentParts([...parts.slice(0, 3)]);
      return;
    }
    setCurrentParts([...parts]);
  };

  const onDelete = async (part) => {
    setDeleteModalVisible(true);
    setPartToRemove(part);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
    setPartToRemove(null);
  };

  const filteredParts = () => {
    const newParts = currentParts.filter((p) => p._id !== partToRemove?._id);
    if (newParts.length === 1) {
      newParts.length = 0;
    }
    return newParts;
  };

  const handleDelete = async () => {
    try {
      await removePartFromJobItem({
        jobId: job._id,
        jobItemId: jobItem._id,
        partId: partToRemove._id,
      });
      setCurrentParts(filteredParts());
      handleCloseDeleteModal();
      toast.success({
        title: "Part Removed",
        description:
          "The part has been successfully removed from the job item.",
      });
    } catch (error) {
      toast.error({
        title: "Error Removing Part",
        description: "There was an issue removing the part. Please try again.",
      });
    }
  };

  return (
    <>
      <ListView
        items={currentParts}
        onClick={handleOpenModal}
        subheader={
          isAuthorized("admin, owner") &&
          parts.length > 0 && (
            <PartsSubHeader parts={parts} partsTotal={job?.partsTotal} />
          )
        }
        itemLayout={(item, index, array) => (
          <PartCard
            part={item.part}
            quantity={item.quantity}
            unitPrice={item.unitPrice || 0}
            baseUnitPrice={item.baseUnitPrice || 0}
            isLast={index === array.length - 1}
            onDelete={() => onDelete(item)}
          />
        )}
        title={`Parts Used (${parts.length})`}
        emptyMessage="No parts used in this job item"
        buttonTitle="Add Part"
      />
      {parts.length > 0 && (
        <div
          className="text-center py-4 hover:bg-primary-50 cursor-pointer text-primary-500 hover:text-primary-600"
          onClick={() => handleExpandList(!isListExpanded)}
        >
          {isListExpanded ? "Collapse Parts" : "Expand Parts"}
        </div>
      )}

      <PartsModal
        visible={visible}
        setVisible={setVisible}
        onSubmit={handleSubmit}
        equipment={equipment}
      />
      <PartDeleteModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDelete}
        loading={isDeleting}
        part={partToRemove}
      />
    </>
  );
};

export default PartsList;
