import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import useQuery from "../../hooks/useQuery";

export const Tabs = React.memo((props) => {
  const useSearchParams = props.useSearchParams;
  const queries = useQuery();

  const id = props.id || "tabMenu";
  const queryId = queries.get(id);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollable, setScrollable] = useState(true);

  useEffect(() => {
    if (props.data && props.data[selectedIndex]) {
      setScrollable(props.data[selectedIndex].scrollable !== false);
    }
  }, [selectedIndex, props.data]);

  function handleTabClick(e, item) {
    let path = "";
    if (useSearchParams) {
      const params = new URLSearchParams({
        ...Object.fromEntries(queries),
        [id]: item.id || item.title,
      });
      path = location.pathname + "?" + params.toString() + location.hash;
    }
    props.onClick && props.onClick(e, item, path);
    return;
  }

  useEffect(() => {
    const def = getDefaultTab();
    const defTabIdx =
      props.data && props.data.findIndex((tab) => tab.id === def);

    if (def) setSelectedIndex(defTabIdx);
  }, [queryId, selectedIndex]);

  function getDefaultTab() {
    const menuQuery = queryId;
    return menuQuery;
  }

  function renderTabs() {
    const tabMap = props.data;
    const tabsChildren = tabMap.map((item, i) => {
      return (
        <Tab
          key={i}
          onClick={(e) =>
            item.onClick ? item.onClick(e, item) : handleTabClick(e, item)
          }
          className={({ selected }) => {
            const isActive = selected;
            const classes = props.tabContentClassName
              ? props.tabContentClassName(isActive)
              : classNames(
                  props.tabClass,
                  "inline-flex p-2 group focus:outline-non",
                  "rounded-t-lg border-b-2 border-transparent text-sm font-semibold focus-visible:outline-0",
                  {
                    "text-primary-600 !border-primary-600 dark:text-primary-500 dark:border-primary-500":
                      isActive,
                  },
                  {
                    "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-gray-700":
                      !isActive,
                  }
                );
            return classes;
          }}
        >
          {item.title || item.id}
        </Tab>
      );
    });

    const tabs = (
      <Tab.List className={props.tabListClasses}>{tabsChildren}</Tab.List>
    );

    if (props.tabHeader) {
      return props.tabHeader(tabs);
    }
    return tabs;
  }

  return (
    <Tab.Group
      className={classNames(props.className, {
        "h-full flex flex-col": props.fullHeight,
      })}
      style={props.style}
      as="div"
      defaultIndex={selectedIndex || props.default}
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      {renderTabs()}
      <Tab.Panels
        as="div"
        style={{
          ...props.panelStyle,
          height: props.fullHeight
            ? "calc(100% - 38px)"
            : props.panelStyle?.height,
        }}
        className={classNames(props.panelClasses, {
          "flex-1": props.fullHeight,
        })}
      >
        {props.data &&
          props.data.map((item, i) => {
            return (
              <Tab.Panel
                key={i}
                as="div"
                className={classNames(props.panelItemClasses, {
                  "h-full": props.fullHeight,
                })}
              >
                {item.content}
              </Tab.Panel>
            );
          })}
      </Tab.Panels>
      {props.footer && props.footer(props)}
    </Tab.Group>
  );
});

export default Tabs;
