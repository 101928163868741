import { useMemo } from "react";
import { useDispatch } from "react-redux";
import Card from "../../../shared/Card";
import Table from "../../../shared/Table";
import {
  renderColumnDefs,
  renderCustomColumnDefs,
} from "../../../shared/Table/table.utils";
import {
  jobLegUpdated,
  jobUpdated,
  updateJob,
} from "../../../redux/reducers/jobs/jobSlice";
import { COLUMN_DEFINITIONS } from "./table.config";
import { useAuth } from "../../../hooks/useAuth";
import { updateLeg } from "../../../redux/reducers/legs/legSlice";
import { useUpdateManifestLegMutation } from "../../../redux/services/manifests/manifestsApi";
import { isAuthorized } from "../../../utils/authority";
import { toast } from "react-toastify";
import { LegCreateModal } from "../../Jobs/Legs/LegCreate/Modal";
import { Badge } from "../../../shared";

const JobLegs = ({
  info,
  form = {},
  visible = false,
  setVisible,
  ...props
}) => {
  const legs = info.legs?.map((item) => {
    const data = {
      ...item,
      satus: item.status,
      jobId: item.jobId,
    };
    return {
      ...data,
      subItem: [data],
      // jobId: data._id,
    };
  });
  const { user } = useAuth();

  const [updateManifestLeg, { isLoading }] = useUpdateManifestLegMutation();

  const newTableData = [
    // {
    //   id: "status",
    //   size: 70,
    //   cell: ({ getValue }) => (
    //     <Badge className="!px-1" color="blue">
    //       {getValue()}
    //     </Badge>
    //   ),
    // },
    {
      id: "orderNumber",
      required: true,
      cell: ({ row, getValue, table }) => {
        const value = getValue();

        // const data = row.original;
        return (
          <div>
            {!isAuthorized("owner, admin") ? (
              value
            ) : (
              <div
                className="text-primary-500 hover:underline cursor-pointer"
                onClick={() => {
                  // row.toggleExpanded();

                  handleEditLeg(row.original, row.original.jobId);
                }}
              >
                {value}
              </div>
            )}
            {/* {(data.isHandoff || data.hasHandoff) && (
              <Badge>
                {data.isHandoff && "Handoff"}
                {data.hasHandoff && "Has Handoff"}
              </Badge>
            )} */}
          </div>
        );
      },
    },
    ...COLUMN_DEFINITIONS,
  ];

  // const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  function handleEditLeg(e, jobData) {
    const updateItem = {
      ...e,
      client: jobData?.client,
      origin: e.route?.origin,
      destination: e.route?.destination,
      totalMiles: e.route?.mileage,
      customMileage: e.route?.customMileage,
      useCustomMileage: !!e.route?.customMileage,
      appointmentDate: e.appointmentDate,
      appointmentTime: e.appointment?.time,
      totalWaitTime: e.waitTime?.total,
      waitStart: e.waitTime?.start,
      waitEnd: e.waitTime?.end,
      base: jobData.base || jobData.baseRate,
      fuelRate: jobData.fuelRate,
      orderNumber: e.orderNumber,
      useLegFlatRate: jobData.useFlatRate || e.useLegFlatRate,
      job: jobData,
      edit: true,
    };
    form.setFormInit(updateItem, { setErrors: true });
    setVisible(true);
  }

  function onModalCancel() {
    form.clearForm();
    setVisible(false);
  }

  async function handleUpdate(e, setLoading) {
    let useCustomManifestMileage = false;
    const data = {
      ...e.data,
      useCustomManifestMileage,
    };

    if (e.column.id === "route") {
      data.useCustomManifestMileage = true;
      data.customManifestMileage = e.value;
    }

    delete data.route;

    const updateData = {
      // parent: user.parentCompany,
      legId: e.row.original._id,
      id: info._id,
      data,
    };

    const res = await updateManifestLeg(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-leg-" + res.data._id,
    });

    if (res.status === "success") {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  if (!legs) return "Loading...";
  return (
    <>
      <Table
        pageSize={100}
        data={legs}
        columns={COLUMN_DEFINITIONS}
        hideCheckbox
        updateData={handleUpdate}
        meta={{
          updateData: handleUpdate,
        }}
      />

      {/* <LegCreateModal
        visible={visible}
        setVisible={setVisible}
        onCancel={onModalCancel}
        form={form}
        job={legs.jobId}
      /> */}
    </>
  );
};

export default JobLegs;
