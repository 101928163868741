import React from "react";
import { AreaChart, PieChart } from "../../../shared/Charts";

const ClientInvoiceChart = ({ data, appType }) => {
  return (
    <div className="h-full">
      <div className="mb-3 text-xs md:text-sm uppercase text-gray-500 font-bold">
        Invoices
      </div>
      <AreaChart
        height={350}
        data={data}
        appType={appType}
        dataType="invoices"
      />
      {/* <PieChart data={data} appType={appType} dataType="invoices" /> */}
    </div>
  );
};

export default ClientInvoiceChart;
