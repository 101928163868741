import { useConfig } from "../../hooks";
import { EditableText } from "../../shared";
import { isAuthorized, renderAmount } from "../../utils";

export const PartsSubHeader = ({ parts, onSave, partsTotal }) => {
  const {
    configs: { rates },
  } = useConfig();

  const partsTotalBase = isAuthorized("admin, owner")
    ? parts?.reduce(
        (acc, part) => acc + (part.quantity * part.baseUnitPrice || 0),
        0
      ) || 0
    : null;

  const markupRate = isAuthorized("admin, owner")
    ? rates?.["partsMarkup"]
    : null;

  return (
    <div className="border-t border-b border-gray-200 pt-2 divide-y divide-gray-200">
      <div>
        <Item amount={renderAmount(partsTotalBase)} label="Base Price" />
        {markupRate && (
          <Item
            amount={
              <EditableText
                id="markUpRate"
                iconPosition="left"
                value={markupRate}
                displayText={(v) => `x ${v}%`}
                onSave={onSave}
                disabled
              />
            }
            label="Markup Rate"
          />
        )}
      </div>
      <Item amount={renderAmount(partsTotal)} label="Adjusted Price" />
    </div>
  );
};

const Item = ({ amount, label }) => (
  <div className="flex justify-between text-sm text-gray-600">
    <span>{label}</span>
    <span>{amount}</span>
  </div>
);
