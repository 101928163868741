import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronUpIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { isValidElement, useState, useCallback } from "react";
import Toggle from "../../../shared/Toggle";
import { Input, Select } from "../../../shared";
import { useGetTemplatesQuery } from "../../../redux/services/templates/api";
import TemplateSelect from "../../../components/Templates/TemplateSelect";
import { useConfig } from "../../../hooks";

const AdvancedSettings = ({ form, updateForm, ...props }) => {
  const { appType } = useConfig();

  const handleChange = useCallback(
    (e) => {
      updateForm(e);
    },
    [updateForm]
  );

  const handleTemplateSelect = useCallback(
    (e) => {
      updateForm({
        id: "template",
        value: e.data,
      });
    },
    [updateForm]
  );

  return (
    <div className="">
      <div className="rounded-2xl bg-white">
        <Disclosure defaultOpen={props.defaultOpen}>
          {({ open }) => (
            <div>
              <DisclosureButton className="flex w-full items-center space-x-1 rounded-lg px-2 py-2 text-left text-sm font-medium text-gray-700 focus:outline-none focus-visible:ring ">
                <span>Advanced Settings</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "rotate-180 transform" : ""
                  } h-4 w-4 text-gray-700`}
                />
              </DisclosureButton>
              <DisclosurePanel className="px-4 pt-4 pb-2 text-sm text-gray-700 space-y-3">
                <SettingLayout
                  title={`Custom ${appType === "trucking" ? "load" : "job"} #`}
                >
                  <Input
                    wrapperClasses="w-3/5"
                    size="sm"
                    labelClasses="text-gray-700"
                    onChange={handleChange}
                    id="orderNumber"
                    placeholder="EXAMPLE-0001"
                  />
                </SettingLayout>
                {appType !== "trucking" && (
                  <SettingLayout title="Template">
                    <TemplateSelect
                      type="job"
                      onChange={handleTemplateSelect}
                      value={form.template?.label}
                    />
                  </SettingLayout>
                )}
                {/* <div className="flex items-center justify-between">
                  <div className="font-semibold">Template</div>
                  <Select
                    wrapperClasses="w-3/5"
                    size="sm"
                    labelClasses="text-gray-700"
                    onChange={handleChange}
                    id="template"
                    placeholder="Select Template"
                  />
                </div> */}
                {/* <div className="flex items-center justify-between">
                  <div className="font-semibold">
                    Use global rates
                    <div className="text-slate-400 font-medium text-xs">
                      Overwrite client rates for this job
                    </div>
                  </div>
                  <div className="w-3/5">
                    <Toggle
                      onChange={(e) =>
                        handleChange({
                          id: e.target.id,
                          value: e.target.checked,
                        })
                      }
                      id="useGlobalRates"
                    />
                  </div>
                </div> */}
                {/* <div className="flex items-center justify-between">
                  <div className="font-semibold">
                    Finished Job
                    <div className="text-slate-400 font-medium text-xs">
                      Job is finished just data entry
                    </div>
                  </div>
                  <div className="w-3/5">
                    <Toggle
                      onChange={(e) =>
                        handleChange({
                          id: e.target.id,
                          value: e.target.checked,
                        })
                      }
                      id="useGlobalRates"
                    />
                  </div>
                </div> */}
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

const SettingLayout = ({ children, title, description }) => {
  if (!isValidElement(children)) return null;
  return (
    <div className="flex justify-between items-center space-x-24">
      <div className="font-semibold">
        {title}
        {description && (
          <div className="text-slate-400 font-medium text-xs">
            {description}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default AdvancedSettings;
