import { useDispatch } from "react-redux";
import Table from "../../shared/Table";
import { useAuth } from "../../hooks/useAuth";
import { updateInvoice } from "../../redux/reducers/invoices/invoiceSlice";
import { Card } from "../../shared";
import { COLUMN_DEFINITIONS } from "../Invoices/table.config";

const LatestInvoices = ({ status, invoices, title }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  async function handleUpdate(e, setLoading) {
    const updateData = {
      parentId: user.parentCompany,
      invoiceId: e.row.original._id,
      data: e.data,
    };

    const res = await dispatch(updateInvoice(updateData)).unwrap();

    if (res.status === "success") {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  return (
    <Card className="h-full !p-2">
      <span className="text-xs md:text-sm uppercase text-gray-500 font-bold">
        {title}
      </span>
      <Table
        wrapperClasses="mt-3 !h-auto"
        hideHeader
        pageSize={8}
        id="invoices"
        title={title}
        hideCheckbox
        loading={status === "loading"}
        columns={COLUMN_DEFINITIONS}
        data={invoices}
        showFooter
        updateData={handleUpdate}
        tableOuterWrapStyle={{ minHeight: "20em" }}
      />
    </Card>
  );
};

export default LatestInvoices;
