import { useState, useEffect } from "react";
import { formatDateOrTime, updateDate, updateTime } from "../../utils";
import { DatePicker } from "../DatePicker";
import { TimeInput } from "../TimeInput";

interface DateTimePickerProps {
  value?: string | Date | null;
  onChange: (date: Date | null) => void;
  className?: string;
  labels?:
    | {
        date?: string;
        time?: string;
      }
    | boolean;
  required?: boolean;
}

interface TimeChangeEvent {
  value: string;
}

/**
 * DateTimePicker component that combines date and time selection
 */
export const DateTimePicker = ({
  value,
  onChange,
  className = "",
  labels,
  required,
}: DateTimePickerProps) => {
  const [dateValue, setDateValue] = useState<Date | null>(() => {
    if (!value) return null;
    try {
      const date = new Date(value);
      return !isNaN(date.getTime()) ? date : null;
    } catch (e) {
      console.error("Invalid date value:", value);
      return null;
    }
  });

  useEffect(() => {
    if (value) {
      try {
        const date = new Date(value);
        // Check if date is valid
        if (!isNaN(date.getTime())) {
          setDateValue(date);
        } else {
          setDateValue(null);
        }
      } catch (e) {
        console.error("Invalid date value in useEffect:", value);
        setDateValue(null);
      }
    } else {
      setDateValue(null);
    }
  }, [value]);

  const handleDateChange = ({ value: newDate }: { value: Date | null }) => {
    try {
      if (!newDate) {
        onChange(null);
        setDateValue(null);
        return;
      }
      const updatedDateTime = updateDate(dateValue, newDate);
      if (updatedDateTime && !isNaN(updatedDateTime.getTime())) {
        onChange(updatedDateTime);
      }
    } catch (e) {
      console.error("Error in handleDateChange:", e);
    }
  };

  const handleTimeChange = (e: TimeChangeEvent) => {
    try {
      if (!dateValue) return;
      const newTime = e.value;
      const updatedDateTime = updateTime(dateValue, newTime);
      if (updatedDateTime && !isNaN(updatedDateTime.getTime())) {
        onChange(updatedDateTime);
      }
    } catch (e) {
      console.error("Error in handleTimeChange:", e);
    }
  };

  const formattedDate = (() => {
    try {
      return formatDateOrTime(dateValue, "date");
    } catch (e) {
      console.error("Error formatting date:", e);
      return "";
    }
  })();

  const formattedTime = (() => {
    try {
      return formatDateOrTime(dateValue, "time");
    } catch (e) {
      console.error("Error formatting time:", e);
      return "";
    }
  })();

  const dateLabel =
    typeof labels === "object"
      ? labels?.date || "Appointment date"
      : "Appointment date";
  const timeLabel =
    typeof labels === "object"
      ? labels?.time || "Appointment time"
      : "Appointment time";

  return (
    <div className={`flex gap-4 items-center ${className}`}>
      <div className="flex-1">
        <DatePicker
          label={dateLabel}
          required={required}
          placeholder="Select a date"
          value={formattedDate}
          onChange={handleDateChange}
        />
      </div>
      <div className="flex-1">
        <TimeInput
          label={timeLabel}
          required={required}
          placeholder="Select a time"
          value={formattedTime}
          onChange={handleTimeChange}
          disabled={!dateValue}
          size="md"
        />
      </div>
    </div>
  );
};

// Default export
export default DateTimePicker;
