import { PartCard } from "../PartCard";
import { Modal } from "../../shared";

const PartDeleteModal = ({ visible, setVisible, onClose, onSubmit, part }) => {
  if (!part) return null;
  const modalContent = (
    <div>
      <div className="text-gray-700 mb-2">
        Are you sure you want to remove{" "}
        <strong>{part?.part?.name || part.part.partNumber}</strong> from the
        job?
      </div>
      <div>
        <PartCard
          part={part.part}
          quantity={part.quantity}
          unitPrice={part.unitPrice || 0}
          baseUnitPrice={part.baseUnitPrice || 0}
          isLast={true}
        />
      </div>
    </div>
  );
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={onClose}
      confirm={onSubmit}
      title="Remove Part"
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default PartDeleteModal;
