import React from "react";
import TablePagination from "./Pagination";
import TableSearch from "./TableSearch";
// import TableSettings from "./TableSettings";
import { Filters } from "../Filters";

const TableHeader = ({
  pagination,
  title,
  actions,
  search,
  pageSizeOptions,
  state,
  setGlobalFilter,
  globalFilter,
  renderHeader,
  renderActions,
  ...rest
}) => {
  const props = {
    pagination,
    title,
    actions,
    search,
    pageSizeOptions,
    state,
    setGlobalFilter,
    globalFilter,
    renderHeader,
    renderActions,
    ...rest,
  };
  return renderHeader ? (
    renderHeader(props)
  ) : (
    <span className="p-2 block md:px-3 md:py-2 space-y-1">
      {(title || actions) && (
        <span className="flex flex-row items-center justify-between">
          {title && <span className="text-2xl font-bold">{title}</span>}
          {actions && actions}
          {renderActions && renderActions(props)}
        </span>
      )}
      {(search || pagination || rest.filters || rest.settings) && (
        <div>
          <span className="flex flex-col lg:flex-row lg:justify-between">
            {search && (
              <TableSearch
                table={rest.table}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}
            {!rest.hideSettings && (
              <span className="flex items-center justify-between mt-4 lg:mt-0">
                {pagination && (
                  <TablePagination
                    state={state}
                    pagination={pagination}
                    {...rest}
                  />
                )}
                {/* <TableSettings
                  {...rest}
                  state={state}
                  pageSize={rest.table.getState().pagination?.pageSize}
                  pageSizeOptions={pageSizeOptions}
                /> */}
              </span>
            )}
          </span>
          {rest.filters && <Filters {...props} />}
        </div>
      )}
    </span>
  );
};

export default TableHeader;
