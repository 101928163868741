import TextArea from "../../../../shared/TextArea";
import Button from "../../../../shared/Button";
import { updateJob } from "../../../../redux/reducers/jobs/jobSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../../hooks/useAuth";

const Notes = ({ job, updateForm, form, onFormChange }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  async function handleUpdate(e) {
    const update = {
      [e.id]: e.value,
      ...e.data,
    };

    await dispatch(
      updateJob({
        data: update,
        parentId: user.parentCompany,
        jobId: job._id,
      })
    ).unwrap();
  }

  return (
    <>
      <div className="p-3 bg-gray-50 h-full">
        <div>
          <TextArea
            textAreaclassName="resize-none w-full"
            rows={28}
            id="note"
            value={form.note}
            onChange={(e) =>
              onFormChange({ value: e.target.value, id: "note" })
            }
          />
        </div>
        {/* <Button onClick={handleUpdate} type="alternative">
        Cancel
      </Button> */}
        <div className="space-x-2 pt-2 flex">
          <Button
            onClick={() => {
              handleUpdate({ id: "note", value: form.note });
            }}
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default Notes;
