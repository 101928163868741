import Card from "../../../shared/Card";
import {
  ArrowDownTrayIcon,
  DocumentChartBarIcon,
  XMarkIcon,
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  ArrowUpOnSquareStackIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../../shared/Modal";
import { useEffect, useState } from "react";
import Button from "../../../shared/Button";
import Input from "../../../shared/Input";
import EditableText from "../../../shared/EditableText";
import { useNavigate } from "react-router-dom";
import Select from "../../../shared/Select";
import classNames from "classnames";
import TextArea from "../../../shared/TextArea";
import { useDispatch } from "react-redux";
import request from "../../../utils/request";
import {
  useDeleteInvoicesMutation,
  useSendInvoiceEmailMutation,
} from "../../../redux/services/invoices/invoicesApi";
import { toast } from "react-toastify";
import { isAuthorized } from "../../../utils/authority";
import { renderMenuItems } from "../../../utils/route.utils";
import InvoiceGeneratorButton from "./InvoiceGeneratorButton";
import { useConfig } from "../../../hooks";
import { addDays, formatDate } from "../../../utils";
import DatePicker from "../../../shared/DatePicker";
import { SendPDFModal } from "../../../modals";

const InvoiceInfo = ({
  user,
  info,
  form,
  handleUpdate,
  handleInvUpdate,
  onFormChange,
  loading,
  clearForm,
  ...props
}) => {
  const navigate = useNavigate();

  const { appType } = useConfig();

  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [docName, setDocName] = useState("doc");
  const [emails, setEmails] = useState(info?.client?.email);

  const [activeUpdateId, setActiveUpdateId] = useState("");

  const [deleteInvoices, { isLoading: isDeleting }] =
    useDeleteInvoicesMutation();

  const [sendInvoiceEmail, { isLoading: isSending }] =
    useSendInvoiceEmailMutation();

  useEffect(() => {
    if (activeUpdateId && !loading) setActiveUpdateId("");
  }, [loading]);

  function onUpdate(e) {
    setActiveUpdateId(e.id);
    handleInvUpdate(e);
  }

  const handleSendInvoice = async (data) => {
    try {
      await sendInvoiceEmail({
        invoiceId: info._id,
        data: {
          invoiceName: info.invoiceId,
          ...data,
        },
      }).unwrap();
      toast.success("Invoice sent successfully");
      setSendModalVisible(false);
    } catch (error) {
      toast.error(error.data?.message || "Failed to send invoice");
    }
  };

  const billTo = (
    <div>
      <p className="text-gray-500">BILL TO</p>
      <p className="font-semibold">{info.client.name}</p>
      {info.client.coowner && (
        <p className="text-gray-500">
          C/o:{" "}
          <span className="font-semibold text-black">
            {info.client.coowner}
          </span>
        </p>
      )}
      <p className="font-semibold">{info.client.address?.address}</p>
      <p className="font-semibold">
        {info.client.address?.city} {info.client.address?.state}{" "}
        {info.client.address?.zipcode}
      </p>
    </div>
  );

  function handlePdfEmail(e) {
    const output = props.getPdfOutput();
    const res = request("/mail", {
      method: "post",
      data: { attachment: Buffer.from(output, "base64").toString() },
    });
    // send email
  }

  function handlePdfDownload(e) {
    props.handleDownloadPdf();
  }

  function handleCancelEmail() {
    setEmailModalVisible(false);
    setEmailModalVisible(false);
  }

  function handleCancelNote() {
    setNoteModalVisible(false);
    clearForm();
  }

  const noteModalFooter = (
    <>
      <Button onClick={handleCancelNote} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleInvUpdate({ id: "note", value: form.note });
          handleCancelNote();
        }}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const noteModalContent = (
    <TextArea
      id="note"
      value={form.note}
      onChange={(e) => onFormChange({ value: e.target.value, id: "note" })}
    />
  );

  const emailModalFooter = (
    <>
      <Button onClick={handleCancelEmail} type="alternative">
        Cancel
      </Button>
      <Button onClick={handlePdfEmail} type="primary">
        Send
      </Button>
    </>
  );

  const emailModalContent = (
    <div>
      <Input
        id="emails"
        label={"Emails - seperate by commas"}
        value={emails}
        onChange={({ value }) => setEmails(value)}
      />
      <Card className="p-2 mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-4">
            <DocumentChartBarIcon className="w-10 h-10" />
          </div>
          <div className="text-sm">
            <EditableText
              id="documentName"
              value={docName}
              displayText={(e) => <span className="">{e}.pdf</span>}
              iconClasses="h-3 w-3"
              onSave={(e) => setDocName(e.value)}
            />
          </div>
        </div>
        <div>
          <div
            onClick={handlePdfDownload}
            className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
          </div>
        </div>
      </Card>
    </div>
  );

  async function handleDelete() {
    const res = await deleteInvoices({
      data: [info._id],
      jobs: info.jobs.map((i) => i._id),
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "delete-invoices",
    });

    if (res.status === "success") {
      navigate("/invoices");
    }

    return res;
  }

  function handleCancelDeleteModal() {
    setDeleteModalVisible(false);
  }

  const deleteModalFooter = (
    <>
      <Button onClick={handleCancelDeleteModal} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={handleDelete}
        loading={isDeleting}
        disabled={isDeleting}
        type="primary"
      >
        Delete
      </Button>
    </>
  );

  const deleteModalContent = (
    <div>Are you sure you want to delete this invoice?</div>
  );

  const statusOptions = [
    { id: "paid", label: "Paid ", value: "Paid" },
    { id: "sent", label: "Sent", value: "Sent" },
    { id: "review", label: "Review ", value: "Review" },
  ];

  const termsOptions = [
    { id: "Net 60", label: "Net 60", value: "Net 60" },
    { id: "Net 30", label: "Net 30", value: "Net 30" },
    { id: "Net 15", label: "Net 15", value: "Net 15" },
    { id: "Net 7", label: "Net 7", value: "Net 7" },
    {
      id: "Due on Due Date",
      label: "Due on Due Date",
      value: "Due on Due Date",
    },
    {
      id: "Due Upon Receipt",
      label: "Due Upon Receipt",
      value: "Due Upon Receipt",
    },
    {
      id: "Not Applicable",
      label: "Not Applicable",
      value: "Not Applicable",
    },
  ];

  const calculateDueDate = (terms) => {
    const today = new Date();
    switch (terms) {
      case "Net 60":
        return addDays(today, 60);
      case "Net 30":
        return addDays(today, 30);
      case "Net 15":
        return addDays(today, 15);
      case "Net 7":
        return addDays(today, 7);
      case "Due Upon Receipt":
        return today;
      default:
        return today;
    }
  };

  const handleTermsChange = (e) => {
    const newDueDate = calculateDueDate(e.value);
    handleInvUpdate({ id: "terms", value: e.value });
    handleInvUpdate({ id: "dueDate", value: newDueDate });
  };

  const actionsMap = [
    {
      id: "addNote",
      icon: ChatBubbleBottomCenterTextIcon,
      onClick: () => {
        onFormChange({ id: "note", value: info.note });
        setNoteModalVisible(true);
      },
      label: "Add Note",
      type: "success",
    },
    // appType === "serviceRepair" && {
    //   id: "sendCopy",
    //   icon: PaperAirplaneIcon,
    //   onClick: () => setEmailModalVisible(true),
    //   label: "Send",
    //   type: "success",
    // },
    {
      id: "pdf",
      icon: ArrowDownTrayIcon,
      onClick: appType === "trucking" ? handlePdfDownload : null,
      layout:
        appType === "serviceRepair" &&
        ((button) => <InvoiceGeneratorButton invoice={info} button={button} />),
      label: "To PDF",
      type: "warning",
    },
    {
      id: "edit",
      icon: PencilSquareIcon,
      onClick: () => navigate(`/invoices/${info._id}/edit`),
      label: "Edit",
      type: "primary",
      authority: isAuthorized("owner, admin"),
    },
    {
      id: "delete",
      icon: XMarkIcon,
      onClick: () => setDeleteModalVisible(true),
      label: "Delete",
      type: "danger",
      authority: isAuthorized("owner, admin"),
    },
  ].filter(Boolean);

  const actions = renderMenuItems(actionsMap);

  return (
    <>
      <Card className="row-span-2 px-10 py-4">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div>
            {/* {invoiceNumber} */}
            {/* {companyInfo} */}
            {billTo}
          </div>
          <div className="flex flex-col items-center">
            {/* <InvoiceTotals form={form} handleUpdate={handleUpdate} info={info} /> */}

            <div className="flex justify-center">
              {actions.map((item) => {
                const Icon = item.icon;
                const circleClasses = classNames(
                  "border ease-in-out duration-300 rounded-full p-2 cursor-pointer",
                  {
                    "hover:border-gray-300 hover:bg-gray-200":
                      item.type === "default" || !item.type,
                    "hover:border-red-300 hover:bg-red-200":
                      item.type === "danger",
                    "hover:border-primary-300 hover:bg-primary-200":
                      item.type === "primary",
                    "hover:border-green-300 hover:bg-green-200":
                      item.type === "success",
                    "hover:border-orange-300 hover:bg-orange-200":
                      item.type === "warning",
                  }
                );

                const button = (
                  <div className="flex flex-col items-center mx-2">
                    <div onClick={item.onClick} className={circleClasses}>
                      <Icon className="w-6 h-6" />
                    </div>
                    <p className="font-semibold">{item.label}</p>
                  </div>
                );

                if (item.layout) {
                  return item.layout(button);
                }

                return button;
              })}
            </div>
            {isAuthorized("owner, admin") && (
              <div className="mx-2 py-2 w-full">
                <Select
                  label="Status"
                  id="status"
                  onChange={handleInvUpdate}
                  value={info.status}
                  size="sm"
                  placeholder={info.status}
                  options={statusOptions}
                />
                <Select
                  label="Terms"
                  id="terms"
                  onChange={handleTermsChange}
                  value={info.terms}
                  size="sm"
                  placeholder="Select Terms"
                  options={termsOptions}
                />
                <DatePicker
                  label="Due Date"
                  id="dueDate"
                  onChange={handleInvUpdate}
                  value={formatDate(info.dueDate)}
                  size="sm"
                  placeholder="Select Due Date"
                />
              </div>
            )}
            <div className="mx-2 py-2 w-full">
              <span className="font-bold">Notes:</span>
              <div className="whitespace-pre">{info.note}</div>
            </div>
          </div>
        </div>
      </Card>

      <Modal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        title="Delete invoice"
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      />
      <SendPDFModal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        onClose={() => setEmailModalVisible(false)}
        title="Send Invoice"
        // footer={emailModalFooter}
        // content={emailModalContent}
        onSend={handleSendInvoice}
        isLoading={isSending}
        client={info.client}
        entity={info}
        type="Invoice"
        jobName={info.invoiceId}
      />
      <Modal
        visible={noteModalVisible}
        setVisible={setNoteModalVisible}
        onClose={() => setNoteModalVisible(false)}
        title="Add note"
        footer={noteModalFooter}
        content={noteModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );
};

export default InvoiceInfo;
