export const IconButton = ({ icon, title, onClick }) => {
  return (
    <div
      className="flex flex-col items-center cursor-pointer text-gray-800 hover:text-primary-500"
      onClick={(e) => {
        onClick?.(e);
      }}
    >
      {icon}
      {title}
    </div>
  );
};
