import React, { useState } from "react";
import { Modal, Select, Button, Spinner } from "../../../shared";
import { useGeneratePDFMutation } from "@/redux/services/pdf/pdfTemplateApi";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

const PDFPreviewModal = ({ visible, template, onClose }) => {
  const [previewData, setPreviewData] = useState("sample");
  const [generatePDF, { isLoading }] = useGeneratePDFMutation();
  const [pdfUrl, setPdfUrl] = useState(null);

  React.useEffect(() => {
    if (visible && template) {
      generatePreview();
    } else {
      setPdfUrl(null);
    }
  }, [visible, template, previewData]);

  const generatePreview = async () => {
    try {
      const sampleData = getSampleData(template.type, previewData);
      const response = await generatePDF({
        templateId: template._id,
        data: sampleData,
        options: {
          preview: true,
        },
      }).unwrap();

      // Create blob URL from the PDF buffer
      const blob = new Blob([response], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    } catch (error) {
      console.error("Preview generation error:", error);
    }
  };

  const getSampleData = (type, dataType = "sample") => {
    // Sample data for different template types
    const samples = {
      invoice: {
        sample: {
          company: {
            name: "Sample Company",
            address: "123 Business St",
            phone: "(555) 555-5555",
            email: "info@sample.com",
          },
          customer: {
            name: "John Doe",
            address: "456 Customer Ave",
            phone: "(555) 123-4567",
            email: "john@customer.com",
          },
          invoice: {
            number: "INV-2024-001",
            date: new Date().toISOString(),
            dueDate: new Date(
              Date.now() + 30 * 24 * 60 * 60 * 1000
            ).toISOString(),
            items: [
              { description: "Service 1", quantity: 2, rate: 100, amount: 200 },
              { description: "Service 2", quantity: 1, rate: 150, amount: 150 },
            ],
            subtotal: 350,
            tax: 35,
            total: 385,
          },
        },
        empty: {
          company: {},
          customer: {},
          invoice: { items: [] },
        },
      },
      workorder: {
        sample: {
          company: {
            name: "Service Pro Company",
            address: "789 Service Rd",
            phone: "(555) 999-8888",
          },
          customer: {
            name: "Jane Smith",
            address: "321 Customer Blvd",
            phone: "(555) 777-6666",
          },
          workOrder: {
            number: "WO-2024-001",
            date: new Date().toISOString(),
            description: "Regular maintenance service",
            services: [
              { service: "Inspection", hours: 1, rate: 85, amount: 85 },
              { service: "Repair", hours: 2, rate: 95, amount: 190 },
            ],
            notes: "Customer reported noise from equipment",
            total: 275,
          },
        },
        empty: {
          company: {},
          customer: {},
          workOrder: { services: [] },
        },
      },
    };

    return samples[type]?.[dataType] || samples.invoice[dataType];
  };

  const renderExampleContent = (type) => {
    // You can either:
    // 1. Load example data from a static file
    // 2. Generate example data dynamically
    // 3. Fetch example template from your API

    switch (type) {
      case "workOrder":
        return {
          // Example work order data
          companyName: "Example Company",
          orderNumber: "WO-2024-001",
          // ... more example data
        };
      case "jobProposal":
        // Example proposal data
        return {
          // ... example data
        };
      case "invoice":
        // Example invoice data
        return {
          // ... example data
        };
      default:
        return {};
    }
  };

  return (
    <Modal
      title={
        template?.isExample
          ? `Example ${template.type} Template`
          : "Template Preview"
      }
      visible={visible}
      onClose={onClose}
      size="lg"
      footer={
        <div className="flex justify-between items-center">
          <Select
            value={previewData}
            onChange={setPreviewData}
            options={[
              { label: "Sample Data", value: "sample" },
              { label: "Empty Template", value: "empty" },
            ]}
            className="w-40"
          />
          <Button
            variant="primary"
            disabled={!pdfUrl}
            onClick={() => {
              const link = document.createElement("a");
              link.href = pdfUrl;
              link.download = `preview-${template?.name || "template"}.pdf`;
              link.click();
            }}
          >
            <ArrowDownTrayIcon className="w-4 h-4 mr-2" />
            Download
          </Button>
        </div>
      }
    >
      {template?.isExample ? (
        // Render example template preview
        <div className="p-4">
          {/* Add your example template preview content here */}
          <pre className="bg-gray-50 p-4 rounded">
            {JSON.stringify(renderExampleContent(template.type), null, 2)}
          </pre>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center items-center h-[600px]">
          <Spinner size="lg" />
        </div>
      ) : (
        <iframe
          src={pdfUrl}
          className="w-full h-[600px] border-0"
          title="PDF Preview"
        />
      )}
    </Modal>
  );
};

export default PDFPreviewModal;
