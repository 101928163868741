import React, { Suspense } from "react";
import Modal from "./index";
import Spinner from "../Spinner";

// Reusable LazyModal component
export const LazyModal = ({ children, ...props }) => (
  <Suspense
    fallback={
      <Modal {...props} content={<Spinner size="lg" className="m-auto" />} />
    }
  >
    {children}
  </Suspense>
);

// Utility function to create lazy-loaded modal components
export const createLazyModal = (importFn) => {
  const LazyLoadedModal = React.lazy(importFn);

  return (props) => (
    <LazyModal {...props}>
      <LazyLoadedModal {...props} />
    </LazyModal>
  );
};

export default LazyModal;
