import React, { useState } from "react";
import { useConfig } from "../hooks/useConfig";
import { Checkbox, Badge, Button } from "../shared";
import { useJob } from "@/hooks/useJob";

interface MaintenanceItem {
  _id: string;
  summary: string;
  quotedSummary: string;
  totalCharges: number;
  type: string;
  status: string;
  appointmentDate?: Date;
  serviceType?: string;
}

interface LegItem {
  _id: string;
  loadNumber: string;
  loadType: string;
  totalCharges: number;
  status: string;
  orderNumber?: string;
  weight?: number;
}

interface StartJobFormProps {
  form: any;
  updateForm: (data: { id: string; value: string[] }) => void;
}

const StartJobForm = ({ updateForm }: StartJobFormProps) => {
  return (
    <div className="p-6">
      <div className="text-center">
        <p className="text-md text-gray-600">
          Are you sure you want to start this job? This will mark the job as "In
          Progress".
        </p>
      </div>
    </div>
  );
};

export default StartJobForm;
