import { createLazyModal } from "../shared/Modal/LazyModal";

export const DeleteJobModal = createLazyModal(() =>
  import("@/pages/Jobs/DeleteModal")
);
export const TemplateModal = createLazyModal(() =>
  import("@/components/Templates/TemplateModal")
);
export const CreateInvoiceModal = createLazyModal(() =>
  import("@/modals/CreateInvoiceModal")
);
export const SendPDFModal = createLazyModal(() =>
  import("@/modals/SendPdfModal")
);
export const JobActionModal = createLazyModal(() =>
  import("@/modals/JobActionModal")
);
export const JobApproveModal = createLazyModal(() =>
  import("@/modals/JobApproveModal")
);
export const SummaryModal = createLazyModal(() =>
  import("@/modals/SummaryModal")
);
