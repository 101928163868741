import { useState } from "react";

interface TruncatedTextProps {
  text?: string;
  maxLength?: number;
  className?: string;
  textClassName?: string;
  onExpandToggle?: (isCurrentlyExpanded: boolean) => void;
  onClick?: (isCurrentlyExpanded: boolean) => void;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  maxLength = 100,
  className,
  textClassName,
  onExpandToggle,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isLong = text?.length > maxLength;

  const handleClick = () => {
    if (onExpandToggle) {
      onExpandToggle(!isExpanded);
    } else {
      onClick?.(!isExpanded);
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div>
      <p
        className={
          className ||
          `text-gray-600 text-base whitespace-pre-wrap inline ${textClassName}`
        }
      >
        {isExpanded ? text : isLong ? text?.slice(0, maxLength) + "..." : text}
        {isLong && (
          <button
            onClick={handleClick}
            className="text-primary-600 hover:text-primary-800 text-sm font-medium ml-1"
          >
            {isExpanded ? "View less" : "View more"}
          </button>
        )}
      </p>
    </div>
  );
};

export default TruncatedText;
