import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Avatar } from "../shared";

const UserCard = ({ avatar, name }) => {
  return (
    <div className="flex items-center bg-white border rounded-lg px-2 py-1 hover:bg-gray-50 cursor-pointer transition-colors duration-50 ease-in-out">
      {avatar ? (
        <Avatar size="xs" src={avatar} />
      ) : (
        <PlusCircleIcon className="w-5 h-5 text-gray-500" />
      )}
      <div className="ml-2">
        <p className="text-sm font-medium text-gray-700">{name}</p>
      </div>
    </div>
  );
};

export default UserCard;
