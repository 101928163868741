import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const ApprovalRejected = () => {
  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto h-12 w-12 text-red-500" />
          <h2 className="mt-4 text-lg font-medium text-gray-900">
            Approval Rejected
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            Thank you for reviewing the service request. Your response has been
            recorded.
          </p>
          <p className="mt-2 text-sm text-gray-500">
            This window can now be safely closed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApprovalRejected;
