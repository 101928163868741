import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./redux";
import Root from "./layouts/Root";
import { AuthProvider } from "./hooks/useAuth";
import { ToastContainer } from "react-toastify";
import ConfigProvider from "./hooks/useConfig";
import { CrumbsProvider } from "./hooks/useCrumbs";
import "./config/i18n";
import "react-toastify/dist/ReactToastify.css";
import { ROOT_URL, SENTRY_DSN, STAGE } from "./config/defaultSettings";
import * as Sentry from "@sentry/react";
import "./styles/main.scss";
// import { NotificationProvider } from "./contexts/NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: STAGE !== "dev",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ROOT_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider>
          {/* <NotificationProvider> */}
          <CrumbsProvider>
            <Root>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <App />
            </Root>
          </CrumbsProvider>
          {/* </NotificationProvider> */}
        </ConfigProvider>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
